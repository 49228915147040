import React, { memo, useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

const chartColors = ['#93A8F9', '#81DADA', '#FDDB60'];

const options = {
  cutoutPercentage: 70,
  legend: {
    display: false,
  },
  Doughnut: {},
  animation: {
    animateScale: true,
  },
};

function Index({ weeklyActivity }) {
  const [totalTime, setTotalTime] = useState(0);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (Object.keys(weeklyActivity).length) {
      const { totalBreakTime, totalOverTime, totalShiftTime } = weeklyActivity;
      const totalHrs = totalBreakTime + totalOverTime + totalShiftTime;
      setTotalTime(getHrs(totalHrs));
      setChartData([
        getHrs(totalShiftTime),
        getHrs(totalOverTime),
        getHrs(totalBreakTime),
      ]);
    }
  }, [weeklyActivity]);

  const getHrs = (min) => {
    return (min / 60).toFixed(2);
  };

  return (
    <div>
      <h3 className="text-center font-weight-bold">Total Hours</h3>
      <div style={styles.relative}>
        <Doughnut
          data={{
            maintainAspectRatio: false,
            responsive: false,
            labels: ['hours worked', 'overTime', 'Break'],
            datasets: [
              {
                data: chartData,
                backgroundColor: chartColors,
                borderWidth: [0, 0, 0],
                borderColor: chartColors,
                hoverBorderWidth: [15, 15, 15],
              },
            ],
          }}
          options={options}
          height={270}
        />
        <div style={styles.pieContainer}>
          <h5 style={styles.totalHr}>{totalTime}</h5>
        </div>
      </div>
    </div>
  );
}

const styles = {
  pieContainer: {
    width: '90%',
    height: '40%',
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  relative: {
    position: 'relative',
  },
  totalHr: {
    fontSize: 18,
  },
};

export default memo(Index);
