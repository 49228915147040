import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { Table, Avatar, Space, Typography, Card, Button, Tooltip } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import socketHandler from '../../config/socket';
import DepartmentFilter from '../../components/departmentFilter';
import ActivityFilter from './activityFilter';
import AddModal from './createTimesheet';
import {
  getDailyTimeSheets,
  getTimeSheetTotal,
} from '../../redux/timesheets/action';
import { renderColumn2, parseTimeSheets, formatTime } from './util';
import Item from './shiftlistItem';
import LeaveItem from './leaveListItem';
import { styles } from './style';
import TotalTimes from './totalTimes';
import UserDetailsModal from '../../components/userDetailsModal';
import AddAbsence from './addAbsence';

const { Text } = Typography;
const renderTitle = (title) => {
  return (
    <div>
      <Text strong className="d-block text-light">
        {title}
      </Text>
    </div>
  );
};

const renderTime = (time) => {
  return <Text>{formatTime(time)}</Text>;
};

const renderDate = (date) => {
  if (!date) {
    return <Text>-----</Text>;
  }
  return <Text>{moment(date).format('HH:mm:ss')}</Text>;
};

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dailyTimesheet, dailyLeaves, initialD } = useSelector(
    (state) => state.timesheetsReducer
  );
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [totalTimes, setTotalTimes] = useState(null);
  const [selectedDpt, setDpt] = useState(null);
  const [activity, setActivity] = useState(null);
  const [absenceModal, setAbsenceModal] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const depRef = useRef(null);
  const actRef = useRef(null);

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    if (initialD) {
      setLoader(true);
      dispatch(getDailyTimeSheets(selectedDpt, activity, stopLoader));
      dispatch(
        getTimeSheetTotal({
          queryType: 'daily',
          dept: selectedDpt,
          activity,
          cb: timeSheetTotalCB,
        })
      );
    }
  }, []);

  const timeSheetTotalCB = (success, data) => {
    if (success) {
      setTotalTimes(data);
    }
  };

  useEffect(() => {
    if (Array.isArray(dailyTimesheet)) {
      const data = parseTimeSheets(dailyTimesheet, true, dailyLeaves);
      setData(data);
    }
  }, [dailyTimesheet, dailyLeaves]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getDailyTimeSheets(selectedDpt, activity, stopLoader));
    dispatch(
      getTimeSheetTotal({
        queryType: 'daily',
        dept: selectedDpt,
        activity,
        cb: timeSheetTotalCB,
      })
    );
  };

  const goDetails = (user) => {
    history.push(`/app/timesheets/${user.id}`);
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    depRef.current = dept;
    setLoader(true);
    dispatch(getDailyTimeSheets(dept, activity, stopLoader));
    dispatch(
      getTimeSheetTotal({
        queryType: 'daily',
        dept,
        activity,
        cb: timeSheetTotalCB,
      })
    );
  };

  const onChangeActivity = (value) => {
    setActivity(value);
    actRef.current = value;
    setLoader(true);
    dispatch(getDailyTimeSheets(selectedDpt, value, stopLoader));
    dispatch(
      getTimeSheetTotal({
        queryType: 'daily',
        dept: selectedDpt,
        activity: value,
        cb: timeSheetTotalCB,
      })
    );
  };

  const showModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const fetchUpdates = useCallback((data) => {
    dispatch(getDailyTimeSheets(depRef?.current, actRef?.current, () => {}));
    dispatch(
      getTimeSheetTotal({
        queryType: 'daily',
        dept: depRef?.current,
        activity: actRef?.current,
        cb: timeSheetTotalCB,
      })
    );
  }, []);

  useEffect(() => {
    socketHandler.on('timesheet', fetchUpdates);
    return () => {
      socketHandler.off('timesheet', fetchUpdates);
    };
  }, []);

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space align="center" className="c-pointer">
          <Avatar
            onClick={() => onRowAvatar(user)}
            style={styles.avatar}
            src={
              user?.profilePic ??
              'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
            }
            size="medium"
          />
          <Text
            onClick={() => goDetails(user)}
            strong
            className="pl-2 font-weight-bold"
          >
            {user?.name?.split(' ')[0]}
          </Text>
        </Space>
      );
    }
  };

  return (
    <React.Fragment>
      <div style={{ ...styles.totalComCont, marginBottom: 20 }}>
        <TotalTimes
          totalWorked={totalTimes?.totalWorkedTime || 0}
          totalBreaks={totalTimes?.totalBreakTime || 0}
          totalOvertime={totalTimes?.totalOverTime || 0}
        />
      </div>
      <Space className="mb-3 flex-wrap">
        <DepartmentFilter
          pickerStyle={{
            width: 215,
          }}
          onSelectDpt={onChangeDept}
        />
        <ActivityFilter
          pickerStyle={{
            width: 215,
          }}
          onChange={onChangeActivity}
        />
        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshData}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Row>
        <Col md={12}>
          <Card className="tmb">
            <Space className="float-right mb-2">
              <Button
                type="primary"
                className="bg-primary border-primary"
                icon={<PlusOutlined />}
                onClick={showModal}
              >
                Add Time Entry
              </Button>
              <Button
                type="primary"
                className="bg-primary border-primary"
                icon={<PlusOutlined />}
                onClick={() => setAbsenceModal(true)}
              >
                Add Absence
              </Button>
            </Space>
            <Table
              className="table-curved"
              columns={renderColumn2(
                renderTitle,
                renderAvatar,
                renderTime,
                renderDate
              )}
              dataSource={data}
              scroll={data.length > 0 && { x: true }}
              size="medium"
              pagination={false}
              loading={loader}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <TableRB responsive className="borderless">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th style={styles.centerAlign}>Activity</th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Clock In
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Clock Out
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Total Hours
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Break Hours
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Overtime
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Hours Worked
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Leaves
                          </th>
                          <th>Edit Time</th>
                        </tr>
                      </thead>
                      {record?.shifts?.map((arr, index) => {
                        if (arr[0].renderType === 'leave') {
                          return arr.map((item, index) => {
                            return <LeaveItem item={item} key={index} />;
                          });
                        } else {
                          let obj = {
                            ...arr[0],
                            time: arr.reduce((a, b) => a + b.time, 0),
                            breakTime: arr.reduce((a, b) => a + b.breakTime, 0),
                            overTime: arr.reduce((a, b) => a + b.overTime, 0),
                            totalTime: arr.reduce((a, b) => {
                              a =
                                a +
                                (b.totalTime
                                  ? b.totalTime
                                  : b.time + b.breakTime);
                              return a;
                            }, 0),
                          };
                          return (
                            <Item
                              item={obj}
                              key={index}
                              sheets={arr}
                              refreshData={refreshData}
                              sentFrom="daily"
                            />
                          );
                        }
                      })}
                    </TableRB>
                  );
                },
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
            />
          </Card>
        </Col>
      </Row>
      <AddModal
        show={show}
        handleCancel={closeModal}
        dept={selectedDpt}
        refreshData={refreshData}
        daily={true}
      />
      <AddAbsence
        visible={absenceModal}
        onClose={setAbsenceModal}
        dept={selectedDpt}
        refreshData={refreshData}
        daily={true}
      />
      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
    </React.Fragment>
  );
}
