import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Avatar,
  Space,
  Typography,
  Card,
  Button,
  Tooltip,
  Badge,
  DatePicker,
  Select,
} from 'antd';
import {
  UserAddOutlined,
  ReloadOutlined,
  ExportOutlined,
  EyeOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import { getSafetyEmergencies } from '../../redux/activities/action';
import { getSummery } from '../../redux/performanceQ/action';
import { styles } from '../dashboard/style';
import SafetyDetails from '../dashboard/safetyDetails';
import socketHandler from '../../config/socket';
import { generateExcelFileAttendace } from '../timeSheets/util';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function Item({ item, callback }) {
  return (
    <>
      <Tooltip title="view Details">
        <Button
          type="secondary"
          shape="circle"
          icon={<EyeOutlined />}
          onClick={() => callback(item)}
        />
      </Tooltip>
    </>
  );
}

const Dashboard = () => {
  const [endDate, setEndDate] = useState(moment().subtract(30, 'days'));
  const [startDate, setStartDate] = useState(moment());
  const [selectedDpt, setDpt] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { sos } = useSelector((state) => state.activitiesReducer);
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const { summery } = useSelector((state) => state.pqReducer);

  const [loader, setLoader] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const [page, setPage] = useState(1);

  const pRef = useRef(1);
  const depRef = useRef(null);
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getSummery(selectedDpt, setLoader));
    depRef.current = selectedDpt;
  }, [selectedDpt]);

  const exportToExcel = () => {
    setLoader(true);

    const _data = summery.map((x) => {
      return {
        'Employee Name': x.user.name,
        Department: allDeptByIds[x.user?.organization]?.name
          ? allDeptByIds[x.user?.organization]?.name
          : '',
        Date: moment(x.createdAt).format('YYYY-MM-DD'),
        Time: moment(x.createdAt).format('h:mm a'),
        Type: x.status,
        Latitude: x?.location?.latitude ? x.location?.latitude : '--',
        Longitude: x?.location?.longitude ? x?.location.longitude : '--',
        'File ': x.file ? x.file : '--',
        'Description ': x.text ? x.text : '--',
      };
    });
    generateExcelFileAttendace(_data, moment());
    setLoader(false);
  };

  const onRowAvatar = (event, record) => {
    event.stopPropagation();
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };
    selectUser(userRecord);
    setDetailModal(true);
  };

  const goDetails = (user) => {
    history.push(`/app/analytics/performance/${user.id}`);
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    dispatch(getSummery(selectedDpt, setLoader));
  };

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space align="center" className="c-pointer">
          <Badge dot color={'green'} offset={[0, 30]}>
            <Avatar
              onClick={() => onRowAvatar(user)}
              style={styles.avatar}
              src={
                user?.profilePic ??
                'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
              }
              size="medium"
            />
          </Badge>

          <Text
            onClick={() => goDetails(user)}
            strong
            className="pl-2 font-weight-bold"
          >
            {user?.name?.split(' ')[0]}
          </Text>
        </Space>
      );
    }
  };

  const renderDepartment = (user) => {
    var dpt = user.organization;
    return (
      <Text>{allDeptByIds[dpt]?.name ? allDeptByIds[dpt]?.name : ''}</Text>
    );
  };

  const renderDate = (date) => {
    return <Text>{date} Questions Answered</Text>;
  };
  const renderAction = (item) => {
    return <Item item={item} callback={goDetails} />;
  };

  const renderColumnAt = () => {
    const result = [
      {
        title: 'Members',
        dataIndex: 'user',
        render: renderAvatar,
        align: 'left',
        fixed: true,
        width: 200,
      },
      {
        title: 'Department',
        dataIndex: 'user',
        render: renderDepartment,
        align: 'center',
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Questions Answered',
        dataIndex: 'count',
        align: 'center',
        render: renderDate,
        className: 'd-none d-sm-table-cell',
      },

      {
        title: '',
        dataIndex: 'user',
        align: 'center',
        render: renderAction,
        className: 'd-none d-sm-table-cell',
      },
    ];
    return result;
  };

  return (
    <>
      <Space className="mb-3 flex-wrap">
        <DepartmentFilter
          pickerStyle={{
            width: 215,
          }}
          onSelectDpt={onChangeDept}
        />

        <Tooltip title="Fetch Latest Data">
          <Button onClick={(onChangeDept) => {}}>
            <ReloadOutlined />
          </Button>
        </Tooltip>

        <Button onClick={exportToExcel} className=" btn-primary">
          <ExportOutlined /> Export
        </Button>
      </Space>
      <Row>
        <Col md={12}>
          <Table
            className="table-curved"
            dataSource={summery?.map((item) => {
              return { key: item.id, ...item, item };
            })}
            columns={renderColumnAt()}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
