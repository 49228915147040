import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Tooltip,
  Typography,
  List,
  Popconfirm,
  Button,
  Badge,
} from 'antd';
import {
  SearchOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateTaskStatus } from '../../../redux/tasks/action';
const { Text } = Typography;

function Index({ task, editTask, deleteTask }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [dloader, setDLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  let [check, setChecked] = useState(task?.status == 'completed');

  useEffect(() => {
    setChecked(task?.status == 'completed');
  }, [task]);

  const completeTask = () => {
    setLoader(true);
    let params = { taskId: task.id };
    let status = !check == true ? 'completed' : 'pending';
    dispatch(
      updateTaskStatus(params, { status }, { ...task, status }, () => {
        setLoader(false);
        setChecked(!check);
      })
    );
  };

  const confirm = () => {
    setDLoader(true);
    deleteTask(task, () => {
      setDLoader(false);
      setVisible(false);
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const showPopUp = () => {
    setVisible(true);
  };

  const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrw6TFJfxQTpixJo4fe2VDEBKrNfyPUhkTdw&usqp=CAU" />
        }
        title={<a href="https://ant.design">Task Title</a>}
        description="steave Jobs "
      />
      <div>
        <Tooltip title="View">
          <Button type="default" shape="circle" icon={<EyeOutlined />} />
        </Tooltip>
        <Tooltip title="Approve">
          <Button
            type="primary"
            className=" mx-1"
            shape="circle"
            icon={<CheckOutlined />}
          />
        </Tooltip>
        <Tooltip title="Reject">
          <Button type="danger" shape="circle" icon={<CloseOutlined />} />
        </Tooltip>
      </div>
    </List.Item>
  );
}

export default Index;
