import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';

import {
  Table,
  Avatar,
  Space,
  Typography,
  Card,
  Button,
  Tooltip,
  Badge,
  DatePicker,
  Select,
} from 'antd';
import {
  UserAddOutlined,
  ReloadOutlined,
  ExportOutlined,
  EyeOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import { getSafetyEmergencies } from '../../redux/activities/action';
import { styles } from '../dashboard/style';
import SafetyDetails from '../dashboard/safetyDetails';
import socketHandler from '../../config/socket';
import { generateExcelFileAttendace } from '../timeSheets/util';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function Item({ item }) {
  let [visible, setVisible] = useState(false);

  let onClose = () => {
    setVisible(false);
  };

  let openDetails = () => {
    setVisible(true);
  };
  return (
    <>
      <Tooltip title="view Details">
        <Button
          type="secondary"
          shape="circle"
          icon={<EyeOutlined />}
          onClick={openDetails}
        />
      </Tooltip>
      <SafetyDetails visible={visible} onClose={onClose} item={item} />
    </>
  );
}

const Dashboard = () => {
  const [endDate, setEndDate] = useState(moment().subtract(30, 'days'));
  const [startDate, setStartDate] = useState(moment());
  const [selectedDpt, setDpt] = useState(null);

  const dispatch = useDispatch();
  const { sos, totalSos } = useSelector((state) => state.activitiesReducer);
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);

  const [loader, setLoader] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const [page, setPage] = useState(1);

  const pRef = useRef(1);
  const depRef = useRef(null);
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getSafetyEmergencies(page, selectedDpt, setLoader));
    depRef.current = selectedDpt;
  }, [selectedDpt]);

  const changePage = (page) => {
    setPage(Number(page));
    pRef.current = Number(page);
    setLoader(true);
    dispatch(getSafetyEmergencies(Number(page), selectedDpt, stopLoader));
  };

  const exportToExcel = () => {
    setLoader(true);

    const _data = sos.map((x) => {
      return {
        'Employee Name': x.user.name,
        Department: allDeptByIds[x.user?.organization]?.name
          ? allDeptByIds[x.user?.organization]?.name
          : '',
        Date: moment(x.createdAt).format('YYYY-MM-DD'),
        Time: moment(x.createdAt).format('h:mm a'),
        Type: x.status,
        Latitude: x?.location?.latitude ? x.location?.latitude : '--',
        Longitude: x?.location?.longitude ? x?.location.longitude : '--',
        'File ': x.file ? x.file : '--',
        'Description ': x.text ? x.text : '--',
      };
    });
    generateExcelFileAttendace(_data, moment());
    setLoader(false);
  };

  const onRowAvatar = (event, record) => {
    event.stopPropagation();
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };
    selectUser(userRecord);
    setDetailModal(true);
  };

  const fetchAlert = useCallback(() => {
    dispatch(getSafetyEmergencies(pRef?.current, depRef?.current, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('alert', fetchAlert);
    return () => {
      socketHandler.on('alert', fetchAlert);
    };
  }, []);

  const onChangeDept = (dept) => {
    setDpt(dept);
    dispatch(getSafetyEmergencies(page, selectedDpt, setLoader));
  };

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space align="center" className="c-pointer">
          <Badge dot color={'green'} offset={[0, 30]}>
            <Avatar
              onClick={() => onRowAvatar(user)}
              style={styles.avatar}
              src={
                user?.profilePic ??
                'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
              }
              size="medium"
            />
          </Badge>

          <Text
            onClick={() => goDetails(user)}
            strong
            className="pl-2 font-weight-bold"
          >
            {user?.name?.split(' ')[0]}
          </Text>
        </Space>
      );
    }
  };

  const renderDepartment = (user) => {
    var dpt = user.organization;
    return (
      <Text>{allDeptByIds[dpt]?.name ? allDeptByIds[dpt]?.name : ''}</Text>
    );
  };

  const renderJob = (user) => {
    return <Text>{user?.jobType ? user?.jobType : '--'}</Text>;
  };

  const renderTime = (time) => {
    return <Text>{moment(time).format('HH:mm a')}</Text>;
  };

  const renderDate = (date) => {
    if (!date) {
      return <Text>-----</Text>;
    }
    return <Text>{moment(date).format('YYYY MMM Do')}</Text>;
  };
  const renderAction = (item) => {
    return <Item item={item} />;
  };

  const renderColumnAt = () => {
    const result = [
      {
        title: 'Members',
        dataIndex: 'user',
        render: renderAvatar,
        align: 'left',
        fixed: true,
        width: 200,
      },
      {
        title: 'Department',
        dataIndex: 'user',
        render: renderDepartment,
        align: 'center',
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Type',
        dataIndex: 'status',
        align: 'center',
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        align: 'center',
        render: renderDate,
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        align: 'center',
        render: renderTime,
        className: 'd-none d-sm-table-cell',
      },
      {
        title: '',
        dataIndex: 'item',
        align: 'center',
        render: renderAction,
        className: 'd-none d-sm-table-cell',
      },
    ];
    return result;
  };

  return (
    <>
      <Space className="mb-3 flex-wrap">
        <DepartmentFilter
          pickerStyle={{
            width: 215,
          }}
          onSelectDpt={onChangeDept}
        />
        <RangePicker
          onChange={(onChangeDept) => {}}
          defaultValue={[startDate, endDate]}
        />

        <Tooltip title="Fetch Latest Data">
          <Button onClick={(onChangeDept) => {}}>
            <ReloadOutlined />
          </Button>
        </Tooltip>

        <Button onClick={exportToExcel} className=" btn-primary">
          <ExportOutlined /> Export
        </Button>
      </Space>
      <Row>
        <Col md={12}>
          <Table
            className="table-curved"
            dataSource={sos?.map((item) => {
              return { key: item.id, ...item, item };
            })}
            columns={renderColumnAt()}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
