import React, { useState, useEffect, memo } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Tooltip,
} from 'antd';
import { PlusOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments } from '../../redux/members/action';
import CreateModal from './addCustomer';
import EditableCell from './editableCell';
import { getCustomers, updateCustomer } from '../../redux/customers/action';
import { styles } from '../dashboard/style';

const EditableTable = () => {
  const dispatch = useDispatch();
  // const { organizations } = useSelector((state) => state.organizationsReducer);
  const { customers, totalResults, customerTypes, initial } = useSelector(
    (state) => state.customersReducer
  );
  const { allDept } = useSelector((state) => state.membersReducer);
  const renderData = () => {
    const data = customers.map((item) => {
      return {
        key: item.id,
        name: item.name,
        type: item?.type,
        departments: item?.departments,
        status: item?.active ? 'active' : 'disabled',
      };
    });
    return data;
  };
  const [form] = Form.useForm();
  const [data, setData] = useState(customers.length > 0 ? renderData() : []);
  const [loader, setLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [editingKey, setEditingKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isEditing = (record) => record.key === editingKey;

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (initial) {
      setLoader(true);
      dispatch(getCustomers(page, stopLoader));
    }
  }, []);

  useEffect(() => {
    if (allDept.length === 0) {
      setDLoader(true);
      dispatch(getAllDepartments(() => setDLoader(false)));
    }
  }, [allDept]);

  useEffect(() => {
    if (Array.isArray(customers)) {
      const data = renderData();
      setData(data);
    }
  }, [customers]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getCustomers(1, stopLoader));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getCustomers(page, stopLoader));
    cancel();
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      departments: record.departments.map((dep) => dep.id),
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const updateApi = (key, row) => {
    const updatedObj = {
      name: row.name,
      type: row.type,
      active: row.status === 'active',
    };

    if (row.departments.length) {
      updatedObj.departments = row.departments;
    }

    setLoader(true);
    dispatch(updateCustomer(key, updatedObj, stopLoader));
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      updateApi(key, row);

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];

        if (row?.departments?.length) {
          const departments = [];
          row.departments.forEach((deptId) => {
            const dept = allDept.filter((item) => item.id === deptId)?.[0];
            departments.push(dept);
          });
          row.departments = departments;
        } else {
          delete row.departments;
        }
        row.status = row.status ? 'active' : 'disabled';
        newData.splice(index, 1, { ...item, ...row });

        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      align: 'left',
    },

    {
      title: 'Type',
      dataIndex: 'type',
      editable: true,
      align: 'center',
    },
    {
      title: 'Departments',
      dataIndex: 'departments',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
      render: (item) => {
        let styleName =
          item === 'active' ? styles?.isActive : styles?.isDisabled;
        return (
          <div>
            <p style={styleName}>{item}</p>
          </div>
        );
      },
    },

    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Update?"
              onConfirm={() => save(record.key)}
            >
              <a>Save</a>
            </Popconfirm>

            <a
              href="javascript:;"
              onClick={cancel}
              style={{
                marginLeft: 8,
              }}
            >
              Cancel
            </a>
          </span>
        ) : (
          <Space align="center" size="large">
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit <EditOutlined />
            </Typography.Link>
          </Space>
        );
      },
    },
  ];
  const getInputType = (dataIndex) => {
    if (dataIndex === 'type') {
      return 'select';
    } else if (dataIndex === 'departments') {
      return 'tag';
    } else if (dataIndex === 'status') {
      return 'status';
    } else {
      return 'text';
    }
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: getInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        allDept: allDept,
        dLoader: dLoader,
        customerTypes,
      }),
    };
  });

  return (
    <React.Fragment>
      <h3>Assignments</h3>
      <Divider className="m-0 pb-2" />
      <Button
        type="primary"
        className="bg-primary border-primary mb-3"
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        Add Assignment
      </Button>

      <Tooltip title="Fetch Latest Data">
        <Button onClick={refreshData} className="ml-3">
          <ReloadOutlined />
        </Button>
      </Tooltip>

      <Card className="tmb">
        <Form form={form} component={false}>
          <Table
            className="table-curved"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: changePage,
              current: page,
              total: totalResults,
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          />
        </Form>
      </Card>
      <CreateModal
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
      />
    </React.Fragment>
  );
};

export default memo(EditableTable);
