export const styles = {
  reqFromRadio: {
    width: 60,
    margin: 'auto',
  },
  swUserName: {
    marginTop: 10,
    marginBottom: 0,
  },

  noMB: {
    marginBottom: 0,
  },
  menuBtn: {
    outline: 'none',
  },
  swapName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#757575',
  },
  shNameCont: {
    maxWidth: 200,
  },
  changeHeading: {
    fontWeight: '500',
  },
  statusBg: {
    backgroundColor: '#f56a00',
    padding: '5px',
    borderRadius: 8,
    color: 'white',
    fontSize: 17,
    marginBottom: 0,
  },
  statusBox: {
    paddingTop: '6px',
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'center',
  },

  shiftTime: {
    textTransform: 'lowercase',
  },

  reqPagination: {
    textAlign: 'right',
    margin: 40,
    marginBottom: 120,
    width: '100%',
  },
  openShiftName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#757575',
    marginBottom: 0,
  },
  openShiftNameShow: {
    color: '#757575',
    marginBottom: 0,
  },
};
