import { message } from 'antd';
import * as constants from './constants';
import CustomersApi from './customersApi';
import socketHandler from '../../config/socket';

const Api = new CustomersApi();

export const addCustomer = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.addCustomer(data);
    dispatch({
      type: constants.ADD_CUSTOMER,
      payload: res.data,
    });
    cb?.(true);
    socketHandler.emit('updateProjects', data);
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
    cb?.();
  }
};

export const getCustomers = (page, cb) => async (dispatch) => {
  try {
    const res = await Api.getCustomers({
      page,
      limit: 10,
      sortBy: '-createdAt',
    });
    dispatch({
      type: constants.GET_CUSTOMERS,
      payload: res.data,
    });
    cb?.();
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllCustomers = (cb) => async (dispatch) => {
  try {
    const res = await Api.getCustomers({ getAll: true });
    dispatch({
      type: constants.GET_CUSTOMERS_ALL,
      payload: res.data,
    });
    cb?.();
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getSpecificCustomers = (params, cb) => async (dispatch) => {
  try {
    const res = await Api.getSpecificCustomers(params);
    cb?.(res.data.length > 0 ? res.data : null);
  } catch (error) {
    cb?.(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateCustomer = (id, data, cb) => async (dispatch) => {
  try {
    await Api.updateCustomer(id, data);
    cb?.();
    message.success('Updated Successfully!');
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};
