import { INSTANCE } from '../../config/networkInstance';

export default class AnalyticsApi {
  getSampleDashboard = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'analytics/sampleDashboards',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  addDashboard = (data, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'analytics/addDashboard',
        data,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updatePBIData = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: 'analytics/updatePowerBI',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getInitialData = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'analytics/initialData',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getStats = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'analytics/stats',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getEmployeesByDate = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'analytics/employees',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getPerformanceStats = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'analytics/performance/stats',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAnswers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'analytics/performance/answers',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
