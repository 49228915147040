import React, { Fragment } from 'react';
import { Drawer, Image, Typography, Divider, Timeline } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { styles } from './style';

const { Text } = Typography;

const imageUrl = (url) => {
  const getImg = url.split(/[#?]/)[0].split('.').pop().trim();

  if (['png', 'jpg'].includes(getImg)) {
    return true;
  }

  return false;
};

function PerformanceAnswers({ PQ }) {
  if (PQ?.question?.type === 'signature') {
    return (
      <Timeline.Item>
        <div>
          <Text className="d-block">{PQ?.question?.question}</Text>
          <div style={styles.signImgCont}>
            <Image src={PQ?.answer} />
          </div>
        </div>
      </Timeline.Item>
    );
  }

  if (PQ?.question?.type === 'attachment') {
    const isImage = imageUrl(PQ?.answer);

    return (
      <Timeline.Item>
        <div>
          <a target="_blank" rel="noreferrer" download href={PQ?.answer}>
            <div style={styles.downloadCont}>
              <Text className="d-block">{PQ?.question?.question}</Text>
              <CloudDownloadOutlined style={styles.downloadIcon} />
            </div>
          </a>

          {isImage && (
            <div style={styles.signImgCont}>
              <Image src={PQ?.answer} />
            </div>
          )}
        </div>
      </Timeline.Item>
    );
  }

  return (
    <Timeline.Item>
      <div>
        <Text className="d-block">{PQ?.question.question}</Text>
        <Text type="secondary d-block">{PQ?.answer}</Text>
      </div>
    </Timeline.Item>
  );
}

export default PerformanceAnswers;
