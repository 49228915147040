import React, { Fragment, useEffect, useState } from 'react';
import {
  Drawer,
  Image,
  Typography,
  Divider,
  Timeline,
  Modal,
  Button,
} from 'antd';
import {
  EnvironmentOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import MapAddress from '../../components/mapAddress';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocations } from '../../redux/shifts/action';

import { getTaskAnswers } from '../../redux/performanceQ/action';
import { getAllocationSOS } from '../../redux/activities/action';
import moment from 'moment';
import { styles } from './style';

const Index = ({ visible, onClose, item }) => {
  const dispatch = useDispatch();
  const { allLocations } = useSelector((state) => state.shiftsReducer);
  useEffect(() => {
    if (allLocations.length == 0) {
      //setLocLoader(true);
      dispatch(getAllLocations(() => {}));
    }
  }, []);

  const toObject = (arr, key) =>
    arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});
  const locationObject = toObject(allLocations, 'id');

  const { Text } = Typography;
  const inLat = item?.location?.latitude;
  const inLng = item?.location?.longitude;

  const [selectedTest, setSelectedTest] = useState(null);
  const [inQuestions, setInQuestionst] = useState([]);
  const [outQuestions, setOutQuestionst] = useState([]);
  const [showMap, setShowMap] = useState(null);
  const [showAns, setShowAns] = useState(null);

  useEffect(() => {
    if (visible) {
      dispatch(getTaskAnswers(item?.id, getPQ));
      //dispatch(getAllocationSOS(item?.allocation?.id, getSOS));
    }
  }, [visible]);

  const getPQ = (data) => {
    var _in = [];
    var _out = [];

    data.forEach((element) => {
      if (element.question.questionType == 'in') {
        _in = [..._in, element];
      } else {
        _out = [..._out, element];
      }
    });

    setInQuestionst(_in);
    setOutQuestionst(_out);

    //
  };

  const dImg =
    'https://png.pngtree.com/png-vector/20190501/ourmid/pngtree-users-icon-design-png-image_1014936.jpg';

  return (
    <>
      <Drawer
        title={`${item?.title}`}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={340}
        zIndex={4234234234234243}
        bodyStyle={styles.sideBar}
      >
        {item?.location && (
          <>
            <Text strong>Task Assigned Location </Text>
            <MapAddress
              latitude={locationObject[item?.location]?.coordinates?.latitude}
              longitude={locationObject[item?.location]?.coordinates?.longitude}
              // error={!item?.location}
            />
          </>
        )}

        {item?.endLocation && (
          <>
            <Text strong>Task Completed Location </Text>
            <MapAddress
              latitude={item?.endLocation?.latitude}
              longitude={item?.endLocation?.longitude}
              // error={!item?.location}
            />
          </>
        )}

        {/*<MapAddress
        latitude={outLat}
        longitude={outLng}
        error={!item?.allocation?.geoFenceOut}
  /> */}

        {item?.endLocation && <></>}
        {(item?.endLocation || item?.location) && (
          <>
            <Divider />
          </>
        )}

        <div className="w-100">
          <Text strong>Times </Text>
          {item.times.length > 0 ? (
            item.times.map((_time, key) => {
              return (
                <Timeline key={key} className="mt-3">
                  <Timeline.Item color="green">
                    <div>
                      <div className="row mx-2">
                        <Text className=" mr-auto">Start Time</Text>
                        {(_time?.locationIn &&
                          _time?.locationIn?.latitude != 0 &&
                          _time?.locationIn?.longitude != 0) ||
                        (_time?.locationOut &&
                          _time?.locationOut?.latitude != 0 &&
                          _time?.locationOut?.longitude != 0) ? (
                          <Button
                            type="warning"
                            className="px-2 "
                            shape="circle"
                            onClick={() => {
                              if (key === showMap) {
                                setShowMap(null);
                              } else {
                                setShowMap(key);
                              }
                            }}
                          >
                            <EnvironmentOutlined />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>

                      <Text type="secondary d-block" style={styles.date}>
                        {moment(_time?.in).format('DD-MM-YYYY')}{' '}
                        {moment(_time?.in).format('HH:mm:ss')}
                      </Text>
                      {showMap === key ? (
                        _time?.locationIn &&
                        _time?.locationOut?.latitude != 0 &&
                        _time?.locationOut?.longitude != 0 && (
                          <MapAddress
                            latitude={_time?.locationIn?.latitude}
                            longitude={_time?.locationIn?.longitude}
                            // error={!item?.location}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </Timeline.Item>

                  {_time?.out && (
                    <Timeline.Item color="red">
                      <div>
                        <Text className="d-block">End Time</Text>
                        <Text type="secondary d-block" style={styles.date}>
                          {moment(_time?.out).format('DD-MM-YYYY')} -{' '}
                          {moment(_time?.out).format('HH:mm:ss')}
                        </Text>

                        {showMap === key ? (
                          _time?.locationOut &&
                          _time?.locationOut?.latitude != 0 &&
                          _time?.locationOut?.longitude != 0 && (
                            <MapAddress
                              latitude={_time?.locationOut?.latitude}
                              longitude={_time?.locationOut?.longitude}
                              // error={!item?.location}
                            />
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </Timeline.Item>
                  )}
                </Timeline>
              );
            })
          ) : (
            <h6 className="text-center pt-5 text-primary">No Times Added</h6>
          )}
        </div>

        <div className="w-100">
          <div className="mb-5">
            <Text strong>Performance Questions on Start Task </Text>
          </div>
          {inQuestions.length > 0 ? (
            inQuestions.map((ans, key1) => {
              return (
                <div className="mt-3" key={key1}>
                  <Timeline>
                    <Timeline.Item color="green">
                      <Text className="pb-1 d-block">
                        <div className="row mx-2">
                          <Text className="mr-auto">
                            {ans?.question?.question}
                          </Text>

                          <Button
                            type="warning"
                            className="px-2 ml-5"
                            shape="circle"
                            onClick={() => {
                              if (key1 === showAns) {
                                setShowAns(null);
                              } else {
                                setShowAns(key1);
                              }
                            }}
                          >
                            <EyeOutlined />
                          </Button>
                        </div>
                      </Text>
                      {showAns === key1 && (
                        <>
                          {ans?.question?.type === 'signature' ? (
                            <div
                              style={{
                                padding: 5,
                                border: '1px solid #F0F0F0',
                                marginRight: 15,
                              }}
                            >
                              <Image src={ans?.answer} />
                            </div>
                          ) : ans?.question?.type === 'attachment' ? (
                            <div>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                download
                                href={ans?.answer}
                              >
                                <div
                                  style={{
                                    padding: 5,
                                    border: '1px solid #F0F0F0',
                                    marginRight: 15,
                                  }}
                                >
                                  <CloudDownloadOutlined
                                    style={{
                                      fontSize: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  />
                                </div>
                              </a>
                            </div>
                          ) : (
                            <Text strong>{ans?.answer}</Text>
                          )}
                        </>
                      )}
                    </Timeline.Item>
                  </Timeline>
                </div>
              );
            })
          ) : (
            <h6 className="pt-0 small text-primary text-center">
              No Performance Questions Answered
            </h6>
          )}
        </div>

        <div className="w-100">
          <div className="mb-5">
            <Text strong>Performance Questions on Stop Task</Text>
          </div>
          {outQuestions.length > 0 ? (
            outQuestions.map((ans, key1) => {
              return (
                <div className="mt-3" key={key1}>
                  <Timeline>
                    <Timeline.Item color="green">
                      <Text className="pb-1 d-block">
                        <div className="row mx-2">
                          <Text className="mr-auto">
                            {ans?.question?.question}
                          </Text>

                          <Button
                            type="warning"
                            className="px-2 ml-5"
                            shape="circle"
                            onClick={() => {
                              if (key1 === showAns) {
                                setShowAns(null);
                              } else {
                                setShowAns(key1);
                              }
                            }}
                          >
                            <EyeOutlined />
                          </Button>
                        </div>
                      </Text>
                      {showAns === key1 && (
                        <>
                          {ans?.question?.type === 'signature' ? (
                            <div
                              style={{
                                padding: 5,
                                border: '1px solid #F0F0F0',
                                marginRight: 15,
                              }}
                            >
                              <Image src={ans?.answer} />
                            </div>
                          ) : ans?.question?.type === 'attachment' ? (
                            <div>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                download
                                href={ans?.answer}
                              >
                                <div
                                  style={{
                                    padding: 5,
                                    border: '1px solid #F0F0F0',
                                    marginRight: 15,
                                  }}
                                >
                                  <CloudDownloadOutlined
                                    style={{
                                      fontSize: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  />
                                </div>
                              </a>
                            </div>
                          ) : (
                            <Text strong>{ans?.answer}</Text>
                          )}
                        </>
                      )}
                    </Timeline.Item>
                  </Timeline>
                </div>
              );
            })
          ) : (
            <h6 className="pt-0 small text-primary text-center">
              No Performance Questions Answered
            </h6>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Index;
