import moment from 'moment';
import excelJs from 'exceljs';
import saveAs from 'file-saver';

export const renderColumn = (renderTitle, renderAvatar, renderTime) => {
  const result = [
    {
      title: 'Members',
      dataIndex: 'members',
      render: renderAvatar,
      align: 'left',
      fixed: true,
      width: 200,
    },
    {
      title: renderTitle('Job Title'),
      dataIndex: 'jobType',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Total Hours'),
      dataIndex: 'totalTime',
      render: renderTime,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Break Hours'),
      dataIndex: 'break',
      render: renderTime,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Over Time'),
      dataIndex: 'overtime',
      render: renderTime,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Hours Worked'),
      dataIndex: 'worked',
      render: renderTime,
      align: 'center',
    },
    {
      title: renderTitle('Leave Days'),
      dataIndex: 'leaveDays',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
  ];
  return result;
};

export const renderColumn2 = (
  renderTitle,
  renderAvatar,
  renderTime,
  renderDate
) => {
  const result = [
    {
      title: 'Members',
      dataIndex: 'members',
      render: renderAvatar,
      align: 'left',
      fixed: true,
      width: 200,
    },
    {
      title: renderTitle('Job Title'),
      dataIndex: 'jobType',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Clocked In'),
      dataIndex: 'in',
      align: 'center',
      render: renderDate,
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Clocked Out'),
      dataIndex: 'out',
      align: 'center',
      render: renderDate,
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Total Hours'),
      dataIndex: 'totalTime',
      render: renderTime,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Break Hours'),
      dataIndex: 'break',
      render: renderTime,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Over Time'),
      dataIndex: 'overtime',
      render: renderTime,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Hours Worked'),
      dataIndex: 'worked',
      render: renderTime,
      align: 'center',
    },
    {
      title: renderTitle('Leave Days'),
      dataIndex: 'leaveDays',
      className: 'd-none d-sm-table-cell',
      align: 'center',
    },
  ];
  return result;
};

export const renderColumn3 = (renderTitle, renderTime, renderDate) => {
  const result = [
    {
      title: 'Days',
      dataIndex: 'days',
      render: renderDate,
      align: 'center',
    },
    {
      title: renderTitle('Job Title'),
      dataIndex: 'jobType',
      align: 'center',
    },
    {
      title: renderTitle('Clocked In'),
      dataIndex: 'in',
      align: 'center',
    },
    {
      title: renderTitle('Clocked Out'),
      dataIndex: 'out',
      align: 'center',
    },
    {
      title: renderTitle('Total Hours'),
      dataIndex: 'worked',
      render: renderTime,
      align: 'center',
    },
    {
      title: renderTitle('Break Hours'),
      dataIndex: 'break',
      render: renderTime,
      align: 'center',
    },
    {
      title: renderTitle('Over Time'),
      dataIndex: 'overtime',
      render: renderTime,
      align: 'center',
    },
  ];
  return result;
};

export const renderColumn4 = (renderTitle, renderAvatar, renderTime) => {
  const result = [
    {
      title: 'Members',
      dataIndex: 'members',
      render: renderAvatar,
      align: 'left',
      fixed: true,
      width: 200,
    },
    {
      title: renderTitle('Job Title'),
      dataIndex: 'jobType',
      align: 'center',
    },
    {
      title: renderTitle('Total Hours'),
      dataIndex: 'worked',
      render: renderTime,
      align: 'center',
    },
    {
      title: renderTitle('Break Hours'),
      dataIndex: 'break',
      render: renderTime,
      align: 'center',
    },
  ];
  return result;
};

export const renderColumnAt = (
  renderTitle,
  renderAvatar,
  renderTime,
  renderDate
) => {
  const result = [
    {
      title: 'Members',
      dataIndex: 'members',
      render: renderAvatar,
      align: 'left',
      fixed: true,
      width: 200,
    },
    {
      title: renderTitle('Job Title'),
      dataIndex: 'jobType',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Clocked In'),
      dataIndex: 'in',
      align: 'center',
      render: renderDate,
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Clocked Out'),
      dataIndex: 'out',
      align: 'center',
      render: renderDate,
      className: 'd-none d-sm-table-cell',
    },
  ];
  return result;
};

export const renderColumnAt2 = (
  renderTitle,
  renderAvatar,
  renderDepartment
) => {
  const result = [
    {
      title: 'Members',
      dataIndex: 'members',
      render: renderAvatar,
      align: 'left',
      fixed: true,
      width: 200,
    },
    {
      title: renderTitle('Departments'),
      dataIndex: 'organization',
      align: 'center',
      render: renderDepartment,
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Job Title'),
      dataIndex: 'jobType',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: renderTitle('Days Worked'),
      dataIndex: 'days',
      align: 'center',
    },
  ];
  return result;
};

export const formatTime = (a) => {
  let hours = Math.trunc(a / 60);
  let minutes = a % 60;

  hours = `${hours}`.padStart(2, '0');
  minutes = `${minutes}`.padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const parseUserTimesheets = (data, leaves) => {
  let filterAllocations;
  if (leaves) {
    const userData = [...data, ...leaves];

    filterAllocations = embedByAllocationsLeaves(userData);
    return filterAllocations;
  } else {
    filterAllocations = embedByAllocations(data);
    return filterAllocations;
  }
};

const getLeavePeriod = (item) => {
  if (item?.leavePeriod === 'halfDay') {
    return `${moment(item?.start).format('hh:mm')} - ${moment(item?.end).format(
      'hh:mm'
    )}`;
  }
  return 'Full Day';
};

export const parseExportDateUser = (timesheets, leaves) => {
  const renderActivity = (item) => {
    if (item?.allocation?.customer) {
      return item?.allocation?.customer?.name;
    } else if (item?.allocation?.shift) {
      return item?.allocation?.shift?.name;
    } else if (!item?.allocation) {
      return item?.type;
    } else {
      return '---';
    }
  };

  let data = timesheets
    ?.concat(leaves)
    ?.sort((a, b) => new Date(b.date) - new Date(a.date));

  return data?.map((item) => {
    return {
      date: item?.allocation
        ? moment(item?.allocation?.date).format('DD-MM-YYYY')
        : moment(item?.date).format('DD-MM-YYYY'),
      Activity: renderActivity(item),
      ClockedIn: item?.allocation
        ? moment(item?.allocation?.clockin).format('hh:mm a')
        : '---',
      ClockedOut: item?.allocation
        ? moment(item?.allocation?.clockout).format('hh:mm a')
        : '---',
      HoursWorked: item?.allocation ? (item?.time / 60).toFixed(2) : '---',
      BreakHours: item?.allocation ? (item?.breakTime / 60).toFixed(2) : '---',
      Overtime: item?.allocation ? (item?.overTime / 60).toFixed(2) : '---',
      Leave: item?.allocation ? '---' : getLeavePeriod(item),
    };
  });
};

const embedByAllocations = (arr) => {
  const objectMap = arr.reduce(function (o, i) {
    if (!o[i.allocation.id]) o[i.allocation.id] = [];
    o[i.allocation.id].push(i);
    return o;
  }, {});

  const sortedKeys = Object.keys(objectMap).sort();
  const filterAllocations = sortedKeys.reduce(function (d, k) {
    d.push(objectMap[k]);
    return d;
  }, []);

  return filterAllocations;
};

const embedByAllocationsLeaves = (arr) => {
  const objectMap = arr.reduce(function (o, i) {
    if (i?.renderType === 'leave') {
      if (!o[i?.id]) o[i?.id] = [];
      o[i?.id].push(i);
    } else {
      if (i?.allocation) {
        if (!o[i?.allocation?.id]) o[i?.allocation?.id] = [];
        o[i?.allocation?.id].push(i);
      }
    }
    return o;
  }, {});
  const sortedKeys = Object.keys(objectMap);
  const filterAllocations = sortedKeys.reduce(function (d, k) {
    if (objectMap[k]) d.push(objectMap[k]);
    return d;
  }, []);
  //////////// for timesheets without allocation
  if (
    arr.filter((item) => !item.allocation && item?.renderType !== 'leave')
      .length > 0
  ) {
    arr
      .filter((item) => !item.allocation)
      .map((item) => {
        filterAllocations.push([item]);
      });
  }
  ///////////// end here

  return filterAllocations;
};

export const parseTimeSheets = (api, daily = false, leaves = []) => {
  let apiData = [...api];
  const result = [];
  if (leaves.length) {
    apiData = apiData.concat(leaves);
  }
  const objectMap = apiData.reduce(function (o, i) {
    if (!o[i.user.id]) o[i.user.id] = [];
    o[i.user.id].push(i);
    return o;
  }, {});
  const sortedKeys = Object.keys(objectMap);
  const filterUsers = sortedKeys.reduce(function (d, k) {
    d.push(objectMap[k]);
    return d;
  }, []);

  filterUsers?.map((userArr) => {
    const dataObj = {};
    const userLeaves = leaves.filter(
      (item) => item.user.id == userArr[0]?.user.id
    );
    dataObj.key = userArr[0].id;
    dataObj.members = userArr[0]?.user;
    dataObj.organization = userArr[0]?.user.organization;
    dataObj.user_id = userArr[0]?.user.id;
    dataObj.days = userArr[0]?.days ?? '--';
    dataObj.jobType = userArr[0]?.user?.jobType ?? 'Admin';
    dataObj.worked = userArr.reduce((help, current) => {
      help = help + current.time || 0;
      return help;
    }, 0);

    dataObj.totalTime = userArr.reduce((help, current) => {
      if (current?.totalTime || current?.time) {
        help =
          help +
          (current.totalTime
            ? current.totalTime
            : current.time + current.breakTime);
        return help;
      }
      return help;
    }, 0);

    dataObj.break = userArr.reduce((help, current) => {
      help = help + current.breakTime || 0;
      return help;
    }, 0);
    dataObj.overtime = userArr.reduce((help, current) => {
      help = help + current.overTime || 0;
      return help;
    }, 0);

    dataObj.shifts = embedByAllocationsLeaves(userArr, userLeaves);

    if (daily) {
      dataObj.in = userArr[0]?.allocation?.clockin;
      dataObj.out = userArr[0]?.allocation?.clockout;
    }

    dataObj.leaveDays = userLeaves.length;

    result.push(dataObj);
    return true;
  });

  return result;
};

export const parseAttendanceTimeSheets = (api, daily = false, leaves = []) => {
  let apiData = [...api];
  const result = [];
  if (leaves.length) {
    apiData = apiData.concat(leaves);
  }
  const objectMap = apiData.reduce(function (o, i) {
    if (!o[i.user.id]) o[i.user.id] = [];
    o[i.user.id].push(i);
    return o;
  }, {});
  const sortedKeys = Object.keys(objectMap);
  const filterUsers = sortedKeys.reduce(function (d, k) {
    d.push(objectMap[k]);
    return d;
  }, []);

  filterUsers?.map((userArr) => {
    const dataObj = {};
    const userLeaves = leaves.filter(
      (item) => item.user.id == userArr[0]?.user.id
    );
    dataObj.key = userArr[0].id;
    dataObj.members = userArr[0]?.user;
    dataObj.user_id = userArr[0]?.user.id;
    dataObj.days = userArr[0]?.days ?? '--';
    dataObj.jobType = userArr[0]?.user?.jobType ?? 'Admin';
    dataObj.worked = userArr.reduce((help, current) => {
      help = help + current.time || 0;
      return help;
    }, 0);

    dataObj.totalTime = userArr.reduce((help, current) => {
      if (current?.totalTime || current?.time) {
        help =
          help +
          (current.totalTime
            ? current.totalTime
            : current.time + current.breakTime);
        return help;
      }
      return help;
    }, 0);

    dataObj.break = userArr.reduce((help, current) => {
      help = help + current.breakTime || 0;
      return help;
    }, 0);
    dataObj.overtime = userArr.reduce((help, current) => {
      help = help + current.overTime || 0;
      return help;
    }, 0);

    dataObj.shifts = embedByAllocationsLeaves(userArr, userLeaves);

    if (daily) {
      dataObj.in = userArr[0]?.clockin;
      dataObj.out = userArr[0]?.clockout;
    }

    dataObj.leaveDays = userLeaves.length;

    result.push(dataObj);
    return true;
  });

  return result;
};

const getTotalSheetMinutes = (time) => {
  const min = Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
  const result = (min / 60).toFixed(2);
  return result;
};

const textFormatter = (value, format) => {
  if (format == 'lowercase') {
    return String(value).toLowerCase();
  } else if (format == 'uppercase') {
    return String(value).toUpperCase();
  } else if (format == 'trim') {
    return String(value).trim();
  } else {
    return value;
  }
};

export function truncateToDecimals(num, dec = 0) {
  const calcDec = Math.pow(10, dec);
  return Math.trunc(num * calcDec) / calcDec;
}
const timeDateFormatter = (value, format) => {
  if (value) {
    return moment(value).local().format(String(format));
  }
  return '---';
};

const durationFormatter = (value, format) => {
  if (['0', '1', '2', '3', '4'].includes(format)) {
    return (value / 60).toFixed(Number(format));
  } else if (format == 'HH:mm') {
    return formatTime(value);
  } else if (format == 'HH') {
  } else if (format == 'min') {
    return value;
  } else if (format == 'sec') {
    return Number(value) * 60;
  } else {
    return Number(value) * 60 * 1000;
  }
};

const getValueByFormat = (key, item, schema) => {
  switch (key) {
    case 'name':
      return textFormatter(item?.user?.name, schema[key].format);
    case 'fname':
      let fname = item?.user?.name?.split(' ')[0];
      return textFormatter(fname, schema[key].format);
    case 'lname':
      let lname = item?.user?.name?.split(' ')?.[1] || '---';
      return textFormatter(lname, schema[key].format);
    case 'jobType':
      return textFormatter(item?.user?.jobType, schema[key].format);
    case 'role':
      let role = item?.user?.role == 'user' ? 'worker' : item?.user?.role;
      return textFormatter(role, schema[key].format);
    case 'organization':
      return textFormatter(
        item?.user?.organization?.name ?? '---',
        schema[key].format
      );
    case 'customer':
      return textFormatter(item?.customer?.name ?? '---', schema[key].format);
    case 'clockin':
      if (moment(item?.allocation?.clockin).isSame(moment(item?.date), 'day')) {
        return timeDateFormatter(item?.allocation?.clockin, schema[key].format);
      }
      return '--';

    case 'clockout':
      if (
        moment(item?.allocation?.clockout).isSame(moment(item?.date), 'day')
      ) {
        return timeDateFormatter(
          item?.allocation?.clockout,
          schema[key].format
        );
      }
      return '--';
    case 'date':
      return timeDateFormatter(item?.date, schema[key].format);
    case 'totalTime':
      return durationFormatter(item?.totalTime, schema[key].format);
    case 'breakTime':
      return durationFormatter(item?.breakTime, schema[key].format);
    case 'overTime':
      return durationFormatter(item?.overTime, schema[key].format);
    case 'time':
      return durationFormatter(item?.time, schema[key].format);
  }
};

export const parseExportData = (api, schema) => {
  let formatted = api.map((item) => {
    let obj = {};
    let keys = Object.keys(schema);
    keys.map((key) => {
      if (schema[key].active) {
        obj[schema[key].label] = getValueByFormat(key, item, schema);
      }
    });
    return obj;
  });

  return formatted;
};

export const parseForExport = (data) => {
  const result = data.map((item) => {
    const obj = {};
    const keys = Object.keys(item);
    keys.map((key) => {
      if (key === 'members') {
        obj.Members = item[key].name;
      } else if (key === 'total') {
        if (item[key]) {
          obj['Total Time'] = getTotalSheetMinutes(item[key]);
        } else {
          obj['Total Time'] = '0.00';
        }
      } else {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          obj[key] = '0.00';
        } else {
          obj[key] = getEachDayTime(item[key], true);
        }
      }
    });
    return obj;
  });
  return result;
};

export const getEachDayTime = (items, decimal = false) => {
  const times = items.map((item) => item.time);
  if (decimal) {
    const totalMinutes = times.reduce((a, b) => a + b, 0);
    return (totalMinutes / 60).toFixed(2);
  } else {
    return times;
  }
};

export const generateExcelFile = (parseResult, range) => {
  let start = moment(range[0]).format('DD-MM-YYYY');
  let end = moment(range[1]).format('DD-MM-YYYY');
  const workbook = new excelJs.Workbook();

  const headers = Object.keys(parseResult?.[0] || {});

  const sheet = workbook.addWorksheet('SheetJS');
  sheet.mergeCells(1, 1, 1, headers.length || 5);

  sheet.getRow(2).values = headers;

  sheet.columns = headers.map((item) => ({
    header: item,
    key: item,
    width: 30,
    style: {
      font: { name: 'Arial Black' },
      alignment: {
        vertical: 'center',
        horizontal: item === 'Members' ? 'left' : 'center',
      },
    },
  }));

  sheet.addRows(parseResult);
  sheet.getCell('A1').value = `Gigsasa Time Tracker (${start} to ${end})`;
  sheet.getCell('A1').style = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0099ff' },
    },
    font: {
      size: 16,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  sheet.getRow(2).style = {
    font: {
      size: 11,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  sheet.getCell('A2').style = {
    font: {
      size: 11,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'left',
    },
  };

  sheet.getRow(1).height = 40;
  sheet.getRow(2).height = 20;

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'gigsasaTimeSheet.xlsx'
    );
  });
};

export const generateExcelFileGeneral = (parseResult, title) => {
  const workbook = new excelJs.Workbook();

  const headers = Object.keys(parseResult?.[0] || {});

  const sheet = workbook.addWorksheet('SheetJS');
  sheet.mergeCells(1, 1, 1, headers.length || 5);

  sheet.getRow(2).values = headers;

  sheet.columns = headers.map((item) => ({
    header: item,
    key: item,
    width: 30,
    style: {
      font: { name: 'Arial Black' },
      alignment: {
        vertical: 'center',
        horizontal: item === 'Members' ? 'left' : 'center',
      },
    },
  }));

  sheet.addRows(parseResult);
  sheet.getCell('A1').value = `${title}`;
  sheet.getCell('A1').style = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0099ff' },
    },
    font: {
      size: 16,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  sheet.getRow(2).style = {
    font: {
      size: 11,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  sheet.getCell('A2').style = {
    font: {
      size: 11,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'left',
    },
  };

  sheet.getRow(1).height = 40;
  sheet.getRow(2).height = 20;

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${title + ''.toLocaleUpperCase().trim().replace(/ /g, '_')}.xlsx`
    );
  });
};

const getDates = (start, end) => {
  let dates = [];
  for (var m = moment(start); m.isBefore(end); m.add(1, 'days')) {
    dates.push(m.format('YYYY-MM-DD'));
  }
  return dates;
};

let days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const daysDTA = (start, end) => {
  let result = getDates(start, end).map((date, index) => {
    return {
      id: index + 1,
      name: days[new Date(date).getDay()],
      date,
    };
  });

  return result;
};

export const transformWeekObject = (data) => {
  let obj = {};
  data.map((item) => {
    obj[item?.date] = [];
  });
  return obj;
};

export const generateExcelFileAttendace = (parseResult, _start) => {
  let start = moment(_start).format('DD-MM-YYYY');
  const workbook = new excelJs.Workbook();

  const headers = Object.keys(parseResult?.[0] || {});

  const sheet = workbook.addWorksheet('SheetJS');
  sheet.mergeCells(1, 1, 1, headers.length || 5);

  sheet.getRow(2).values = headers;

  sheet.columns = headers.map((item) => ({
    header: item,
    key: item,
    width: 30,
    style: {
      font: { name: 'Arial Black' },
      alignment: {
        vertical: 'center',
        horizontal: item === 'Members' ? 'left' : 'center',
      },
    },
  }));

  sheet.addRows(parseResult);
  sheet.getCell('A1').value = `Gigsasa Daily Attendance Report (${start})`;
  sheet.getCell('A1').style = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0099ff' },
    },
    font: {
      size: 16,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  sheet.getRow(2).style = {
    font: {
      size: 11,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  sheet.getCell('A2').style = {
    font: {
      size: 11,
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'left',
    },
  };

  sheet.getRow(1).height = 40;
  sheet.getRow(2).height = 20;

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `gigsasaTimeSheet-${start}.xlsx`
    );
  });
};
