import React, { Suspense, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from './components/Loader/Loader';

const FilterRoutes = ({ routes = [], routes2 = [], routes3 = [] }) => {
  let { user } = useSelector((state) => state.auth);

  const roleRoutes =
    user?.role === 'user'
      ? routes3
      : user?.role === 'supervisor'
      ? routes2
      : routes;

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {roleRoutes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderSubRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const renderSubRoutes = (routes = []) => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    <Component {...props} />
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const renderRoutes = (routes = [], routes2 = [], routes3 = []) => {
  return <FilterRoutes routes={routes} routes2={routes2} routes3={routes3} />;
};

export { renderRoutes };
