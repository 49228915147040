import { Avatar, Modal } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { SwapUserCard } from '../components';
import swapicon from '../../../assets/images/swap.png';
import { useDispatch } from 'react-redux';
import { assignShift } from '../../../redux/swapShift/action';
import { Spinner } from 'react-bootstrap';

function RequestApproval({
  show,
  setClose,
  openFormModal,
  reqItem,
  appliedUsers,
}) {
  const openReq = () => {
    setClose(false);
    openFormModal();
  };

  const [active, setActive] = useState(-1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const setActiveHandle = (item, index) => {
    setActive(index);
    setSelectedUser(item);
  };

  const assignShiftHandler = () => {
    if (!selectedUser?.id || !reqItem.id) return;

    setLoader(true);

    dispatch(
      assignShift(
        {
          user: selectedUser?.id,
          id: reqItem.id,
          ...(appliedUsers && { applied: appliedUsers }),
        },
        reqItem.createdBy,
        () => {
          setLoader(false);
          setClose();
        }
      )
    );
  };
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (!reqItem) return;
    if (appliedUsers) {
      setUsers(reqItem?.appliedUser?.map((item) => item));
    } else {
      setUsers(reqItem?.recommendedUsers?.map((item) => item));
    }
    return () => setUsers([]);
  }, [reqItem, appliedUsers]);
  return (
    <Modal
      className="confirmModalCon"
      bodyStyle={{ borderRadius: 10, overflow: 'hidden' }}
      width={1000}
      footer={null}
      onCancel={() => setClose(false)}
      visible={show}
    >
      <p
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '24px',
          textTransform: 'capitalize',

          color: '#000000',
        }}
      >
        Shift Change Request
      </p>
      {!appliedUsers && (
        <>
          <SwapUserCard borderStatic reqItem={reqItem} leaveStatus />
          <div className="w-100 text-center py-4">
            <Avatar size={20} src={swapicon} />
          </div>
        </>
      )}

      {users.length > 0 ? (
        <>
          {users?.map((user, index) => (
            <SwapUserCard
              setActiveHandle={setActiveHandle}
              index={index}
              key={index}
              user={user}
              reqItem={reqItem}
              active={active}
            />
          ))}
        </>
      ) : (
        <div className="w-100 d-flex justify-content-center p-3">
          {appliedUsers ? (
            <p>No User Has Been Applied To This Shift</p>
          ) : (
            <p>
              You Do not Have Recommendation,To Approve The Post, Kindly Open
              This For Community To The Community
            </p>
          )}
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center">
        {appliedUsers ? (
          <>
            {users?.length > 0 && (
              <button
                disabled={!selectedUser?.id || loader}
                onClick={assignShiftHandler}
                className="btn  rounded rounded-3 btn-primary mt-5"
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Assign Shift'
                )}
              </button>
            )}
          </>
        ) : (
          <button
            onClick={openReq}
            className="btn  rounded rounded-3 btn-secondary mt-5"
          >
            Post Request
          </button>
        )}

        {!appliedUsers && (
          <>
            <button
              disabled={!selectedUser?.id || loader}
              onClick={assignShiftHandler}
              className="btn ml-4 rounded rounded-3  btn-primary mt-5"
            >
              {loader ? (
                <Spinner size="sm" animation="border" variant="light" />
              ) : (
                'Approve Swap'
              )}
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default memo(RequestApproval);
