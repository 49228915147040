import { message } from 'antd';
import ApiClass from './api';
import * as constants from './constants';
import socketHandler from '../../config/socket';

let Api = new ApiClass();

export const getHealthTypes = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.getHealthTypes(data);
    dispatch({
      type: constants.GET_TYPES_HEALTH,
      payload: res?.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createHealthTypes = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.createHealthTypes(data);
    dispatch({
      type: constants.CREATE_TYPE_HEALTH,
      payload: res?.data,
    });
    cb();
    socketHandler.emit('updateHealths', data);
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateHealthTypes = (data, params, cb) => async (dispatch) => {
  try {
    await Api.updateHealthTypes(data, params);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteHealthTypes = (params, cb) => async (dispatch) => {
  try {
    await Api.deleteHealthTypes(params);
    dispatch({
      type: constants.DELETE_TYPE_HEALTH,
      payload: params.typeId,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getBreakTypes = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.getBreakTypes(data);
    dispatch({
      type: constants.GET_TYPES_BREAK,
      payload: res?.data,
    });
    cb?.();
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createBreakTypes = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.createBreakTypes(data);
    dispatch({
      type: constants.CREATE_TYPE_BREAK,
      payload: res?.data,
    });
    cb();
    socketHandler.emit('updateBreaks', data);
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateBreakTypes = (data, params, cb) => async (dispatch) => {
  try {
    await Api.updateBreakTypes(data, params);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteBreakTypes = (params, cb) => async (dispatch) => {
  try {
    await Api.deleteBreakTypes(params);
    dispatch({
      type: constants.DELETE_TYPE_BREAK,
      payload: params.typeId,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getUserAssignments = (cb) => async (dispatch) => {
  try {
    let res = await Api.getUserAssignments();
    dispatch({
      type: constants.GET_USER_ASSIGNMENTS,
      payload: res.data,
    });
    cb(res.data);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};
