import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Select,
  Drawer,
  Space,
  Switch,
  Typography,
  message,
  Collapse,
  Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { stringData, dateFormats, timesFormat, numericFormat } from './data';
import { exportSchema } from '../../redux/timesheets/exportSchema';
import {
  updateExportSchema,
  resetExportSchema,
} from '../../redux/timesheets/action';
const { Option } = Select;
const { Text } = Typography;
const { Panel } = Collapse;

function Index({ show, handleCancel }) {
  const dispatch = useDispatch();
  const { exportSchema: schema } = useSelector(
    (state) => state.timesheetsReducer
  );
  const [state, setState] = useState(exportSchema);

  useEffect(() => {
    setState(schema);
  }, []);

  const onFinish = () => {
    let keys = Object.keys(state).filter((key) => state[key].active);

    let isEmpty = keys.filter((key) => state[key].label === '').length > 0;

    if (isEmpty) {
      message.warning('Labels can not be blank string!');
      return;
    }

    dispatch(updateExportSchema(state));
    message.success('Updated!');
    handleCancel();
  };

  const reset = () => {
    dispatch(resetExportSchema());
    message.success('Reset values Successfully!');
  };

  const boldText = (text) => {
    return <Text strong>{text}</Text>;
  };

  const changeName = (e) => {
    let { name, value } = e.target;

    setState({
      ...state,
      [name]: {
        ...state[name],
        label: value,
      },
    });
  };

  const changeFormat = (value, name) => {
    setState({
      ...state,
      [name]: {
        ...state[name],
        format: value,
      },
    });
  };

  const handleSwitch = (value, e, name) => {
    e.stopPropagation();
    setState({
      ...state,
      [name]: {
        ...state[name],
        active: value,
      },
    });
  };

  const genExtra = (name) => {
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Disable"
        onClick={(value, e) => handleSwitch(value, e, name)}
        checked={state[name].active}
      />
    );
  };

  return (
    <>
      <Drawer
        title="Customize Export"
        visible={show}
        onClose={handleCancel}
        width={window.innerWidth > 900 ? 620 : window.innerWidth - 100}
        zIndex={1050}
        footer={
          <Space>
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={reset}>Reset Values</Button>
              <Button
                type="primary"
                className="bg-primary border-primary"
                onClick={onFinish}
              >
                Update
              </Button>
            </Space>
          </Space>
        }
      >
        <Form
          name="dynamic_form_nest_item"
          layout="vertical"
          requiredMark={false}
        >
          <div>
            <Space direction="vertical" className="w-100">
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Display Name</Text>

                      <Text strong>Text</Text>
                    </Space>
                  }
                  extra={genExtra('name')}
                  key="1"
                >
                  <Form.Item label={boldText('Column Header')} className="mt-3">
                    <Input
                      placeholder="Full Name"
                      size="large"
                      onChange={changeName}
                      name="name"
                      value={state['name'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'name')}
                      value={state['name'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>First Name</Text>

                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('fname')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="First Name"
                      size="large"
                      onChange={changeName}
                      name="fname"
                      value={state['fname'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'fname')}
                      value={state['fname'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Last Name</Text>

                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('lname')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Last Name"
                      size="large"
                      onChange={changeName}
                      name="lname"
                      value={state['lname'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'lname')}
                      value={state['lname'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Job Title</Text>

                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('jobType')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Job Title"
                      size="large"
                      onChange={changeName}
                      name="jobType"
                      value={state['jobType'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'jobType')}
                      value={state['jobType'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Role</Text>

                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('role')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Role"
                      size="large"
                      onChange={changeName}
                      name="role"
                      value={state['role'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'role')}
                      value={state['role'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Department</Text>
                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('organization')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Department"
                      size="large"
                      onChange={changeName}
                      name="organization"
                      value={state['organization'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'organization')}
                      value={state['organization'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Activity</Text>
                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('customer')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Activity Name"
                      size="large"
                      onChange={changeName}
                      name="customer"
                      value={state['customer'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'customer')}
                      value={state['customer'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>TimeSheet Date</Text>
                      <Text strong>Date yyyy-mm-dd</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('date')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="TimeSheet Date"
                      size="large"
                      onChange={changeName}
                      name="date"
                      value={state['date'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'date')}
                      value={state['date'].format}
                    >
                      {dateFormats.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Start Time</Text>
                      <Text strong>Time HH:mm</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('clockin')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Start Time"
                      size="large"
                      onChange={changeName}
                      name="clockin"
                      value={state['clockin'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'clockin')}
                      value={state['clockin'].format}
                    >
                      {timesFormat.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>End Time</Text>
                      <Text strong>Time HH:mm</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('clockout')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="End Time"
                      size="large"
                      onChange={changeName}
                      name="clockout"
                      value={state['clockout'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'clockout')}
                      value={state['clockout'].format}
                    >
                      {timesFormat.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Work Duration</Text>
                      <Text strong>Numeric</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('totalTime')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Work Duration"
                      size="large"
                      onChange={changeName}
                      name="totalTime"
                      value={state['totalTime'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'totalTime')}
                      value={state['totalTime'].format}
                    >
                      {numericFormat.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Break Duration</Text>
                      <Text strong>Numeric</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('breakTime')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="Break Duration"
                      size="large"
                      onChange={changeName}
                      name="breakTime"
                      value={state['breakTime'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'breakTime')}
                      value={state['breakTime'].format}
                    >
                      {numericFormat.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>OverTime Duration</Text>
                      <Text strong>Numeric</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('overTime')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="OverTime Duration"
                      size="large"
                      onChange={changeName}
                      name="overTime"
                      value={state['overTime'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'overTime')}
                      value={state['overTime'].format}
                    >
                      {numericFormat.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Work Without Break Duration</Text>
                      <Text strong>Numeric</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('time')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="OverTime Duration"
                      size="large"
                      onChange={changeName}
                      name="time"
                      value={state['time'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'time')}
                      value={state['time'].format}
                    >
                      {numericFormat.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse>

              {/* <Collapse expandIconPosition={'left'}>
                <Panel
                  header={
                    <Space direction="vertical">
                      <Text>Location</Text>
                      <Text strong>Text</Text>
                    </Space>
                  }
                  key="1"
                  extra={genExtra('location')}
                >
                  <Form.Item label={boldText('Column Header')}>
                    <Input
                      placeholder="OverTime Duration"
                      size="large"
                      onChange={changeName}
                      name="location"
                      value={state['location'].label}
                    />
                  </Form.Item>
                  <Form.Item label={boldText('Format modifier')}>
                    <Select
                      placeholder="Select Format"
                      size="large"
                      onChange={(value) => changeFormat(value, 'location')}
                      value={state['location'].format}
                    >
                      {stringData.map((item) => {
                        return <Option key={item?.value}>{item?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Panel>
              </Collapse> */}
            </Space>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default Index;
