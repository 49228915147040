import React, { memo } from 'react';
import { Form, Select, Input } from 'antd';
const { Option } = Select;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  getJobTypes,
  allDept,
  jobTypes,
  dLoader,
  user,
  ...restProps
}) => {
  const inputNode =
    dataIndex === 'organization' ? (
      <Select
        className="w-100"
        onChange={getJobTypes}
        loading={dLoader}
        disabled={dLoader}
      >
        {allDept?.map((item, i) => {
          return (
            <Option key={i} value={item.name}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    ) : dataIndex === 'jobType' ? (
      <Select className="w-100" disabled={jobTypes.length === 0}>
        {jobTypes?.map((item, i) => {
          return (
            <Option key={i} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    ) : dataIndex === 'active' ? (
      <Select className="w-100">
        <Option value={true}>Active</Option>
        <Option value={false}>InActive</Option>
      </Select>
    ) : dataIndex === 'role' ? (
      <Select className="w-100">
        {['root'].includes(user?.role) && <Option value="root">Root</Option>}
        {['admin', 'root'].includes(user?.role) && (
          <Option value="admin">Admin</Option>
        )}
        <Option value="supervisor">Supervisor/ Manager</Option>
        <Option value="user">Worker</Option>
      </Select>
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          className="m-0 p-0"
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default memo(EditableCell);
