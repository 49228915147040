import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Avatar, Space, Typography, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import socketHandler from '../../config/socket';
import { getAllClockInAndOut } from '../../redux/shifts/action';
import { styles } from './style';
import UserDetailsModal from '../../components/userDetailsModal';

const { Text } = Typography;
function Index({ selectedDpt }) {
  const dispatch = useDispatch();
  const {
    clockedIn,
    clockedOut,
    breakIn,
    totalClockIn,
    totalClockOut,
    totalBreakIn,
  } = useSelector((state) => state.shiftsReducer);
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const [data, setData] = useState([]);
  const [totalResults, setTotal] = useState(0);
  const [tab, setTab] = useState('in');
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const pRef = useRef(1);
  const depRef = useRef(null);

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(getAllClockInAndOut(page, selectedDpt, stopLoader));
    depRef.current = selectedDpt;
  }, [selectedDpt]);

  useEffect(() => {
    changeTab(tab);
  }, [clockedIn, clockedOut, breakIn]);

  const changeTab = (value) => {
    setTab(value);
    switch (value) {
      case 'in':
        setData(clockedIn);
        setTotal(totalClockIn);
        break;
      case 'out':
        setData(clockedOut);
        setTotal(totalClockOut);

        break;
      case 'break':
        setData(breakIn);
        setTotal(totalBreakIn);

        break;

      default:
        break;
    }
  };

  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';

    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const renderInOutTitle = () => {
    return (
      <div style={{ background: '#fff' }}>
        <h5>Activity</h5>
        <div className="d-flex flex-row justify-content-between align-items-center px-2 pt-3 pb-0">
          <div
            className="d-flex flex-column align-items-center c-pointer"
            onClick={() => changeTab('in')}
          >
            <h5 style={{ color: '#93A8F9' }}>{totalClockIn}</h5>
            <h5 style={{ color: '#93A8F9' }}>
              {tab === 'in' ? <u>IN</u> : 'IN'}
            </h5>
          </div>
          <div
            className="d-flex flex-column align-items-center c-pointer"
            onClick={() => changeTab('break')}
          >
            <h5 style={{ color: '#FDDB60' }}>{totalBreakIn}</h5>
            <h5 style={{ color: '#FDDB60' }}>
              {tab === 'break' ? <u>BREAK</u> : 'BREAK'}
            </h5>
          </div>
          <div
            className="d-flex flex-column align-items-center c-pointer"
            onClick={() => changeTab('out')}
          >
            <h5 style={{ color: '#81DADA' }}>{totalClockOut}</h5>
            <h5 style={{ color: '#81DADA' }}>
              {tab === 'out' ? <u>OUT</u> : 'OUT'}
            </h5>
          </div>
        </div>
      </div>
    );
  };

  const getTime = (item) => {
    if (tab === 'in') {
      return item.clockin;
    } else if (tab === 'out') {
      return item.clockout;
    } else {
      return item.updatedAt;
    }
  };

  const getColor = (item) => {
    return item?.status.toLowerCase() === 'completed'
      ? 'red'
      : item?.status.toLowerCase() === 'onbreak'
      ? 'orange'
      : 'green';
  };

  const columns = [
    {
      title: renderInOutTitle(),
      className: 'bg-transparent pt-0 border-0',
      dataIndex: 'inout',
      render: (item) => (
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={styles.listItem}
        >
          <Space
            align="center"
            className="c-pointer"
            onClick={() => onRowAvatar(item?.user)}
          >
            <Badge dot color={getColor(item)} offset={[0, 30]}>
              <Avatar
                src={
                  item?.user?.profilePic ??
                  'https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg'
                }
              />
            </Badge>
            <div className="pl-2">
              <Text strong className="d-block">
                {item?.user?.name?.split(' ')[0]}
              </Text>
              <Text
                type="secondary"
                className="d-block"
                style={styles.roleText}
              >
                {item?.user?.role}
              </Text>
            </div>
          </Space>
          <div>
            <Text className="d-block" style={styles.date}>
              {moment(getTime(item)).format('DD-MM-YYYY')}
            </Text>
            <Text type="secondary" className="d-block" style={styles.time}>
              {moment(getTime(item)).format('hh:mm:ss a')}
            </Text>
          </div>
        </div>
      ),
    },
  ];

  const changePage = (page) => {
    setPage(Number(page));
    pRef.current = Number(page);
    setLoader(true);
    dispatch(getAllClockInAndOut(Number(page), selectedDpt, stopLoader));
  };

  const fetchUpdates = useCallback(() => {
    dispatch(getAllClockInAndOut(pRef?.current, depRef?.current, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('activity', fetchUpdates);
    return () => {
      socketHandler.off('activity', fetchUpdates);
    };
  }, []);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.map((item) => {
          return {
            key: item.id,
            inout: item,
          };
        })}
        size="small"
        loading={loader}
        pagination={{
          onChange: changePage,
          current: page,
          total: totalResults,
          hideOnSinglePage: true,
          pageSize: 10,
        }}
      />

      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
    </>
  );
}

export default Index;
