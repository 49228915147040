import React from 'react';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Tooltip,
} from 'devextreme-react/chart';
import moment from 'moment';

class Index extends React.Component {
  customizeTooltip = (arg) => {
    return {
      text: `${arg.percentText} - ${this.getHrs(arg.valueText)}`,
    };
  };

  getHrs = (min) => {
    return (min / 60).toFixed(2);
  };

  render() {
    let startOfWeek = moment().startOf('week').toDate();
    let endOfWeek = moment().endOf('week').toDate();
    const { weeklyActivity = {} } = this.props;

    return (
      <Chart
        id="chart"
        dataSource={weeklyActivity.dayTimes}
        rotated
        style={{ height: 350 }}
        title={`${moment(startOfWeek).format('DD-MM-YYYY')} to ${moment(
          endOfWeek
        ).format('DD-MM-YYYY')}`}
      >
        <CommonSeriesSettings argumentField="day" type="fullstackedbar" />

        <Series
          valueField="dayTime"
          name="Hour Worked"
          stack="male"
          color="#93A8F9"
        />
        <Series
          valueField="overTime"
          name="Over Time"
          stack="male"
          color="#81DADA"
        />
        <Series
          valueField="breakTime"
          name="Break Time"
          stack="male"
          color="#FDDB60"
        />

        <Legend verticalAlignment="top" horizontalAlignment="center" />
        <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
      </Chart>
    );
  }
}

export default Index;
