import React, { useState } from 'react';
import { Typography, Image, Form, Input } from 'antd';
import { Spinner } from 'react-bootstrap';
import { Slide } from 'react-awesome-reveal';
import { useDispatch } from 'react-redux';
import { styles } from './style';
import Organization from '../../assets/images/auth/createOrg.png';
import { joinTeam } from '../../redux/auth/action';
import Stepper from './stepper';
const { Text } = Typography;

function LeftJoin({ steps }) {
  return (
    <div>
      <Image
        src={Organization}
        preview={false}
        className="text-center img-responsive mt-auto mb-auto"
      />
      <Stepper steps={steps} />
    </div>
  );
}

function RightJoin({ setSteps, step }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      setSteps('b');
    }
  };

  const handleJoinTeam = (values) => {
    const { email } = values;
    setLoader(true);
    dispatch(joinTeam(email, stopLoader));
  };
  return (
    <Slide direction="right">
      <div>
        <button style={styles.backBtn} onClick={() => setSteps(1)}>
          <i className="feather icon-arrow-left" style={styles.backIcon} />
        </button>
        <h3 className="font-weight-bold text-center  pt-4">Join a team</h3>
        <Text
          className="d-block text-center"
          type="secondary"
          style={styles.desc}
        >
          Type in the email of the organization owner for this company.
        </Text>
        <Form form={form} layout="vertical" onFinish={handleJoinTeam}>
          <Form.Item
            name="email"
            className="mb-3 mt-4 font-weight-bold text-uppercase"
            label="Email of Organization owner*"
            rules={[
              {
                type: 'email',
                message: 'Email is not Valid',
              },
              {
                message: 'Email is required',
              },
            ]}
          >
            <Input
              type="email"
              size="large"
              placeholder="Type email address"
              style={styles.input}
            />
          </Form.Item>
          <Form.Item>
            <button
              className="btn btn-primary mb-4 mt-4"
              style={styles.button}
              htmlType="submit"
            >
              {loader ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'Send Request to Join'
              )}
            </button>
          </Form.Item>
        </Form>
      </div>
    </Slide>
  );
}

export { LeftJoin, RightJoin };
