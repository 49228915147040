import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import {
  Table,
  Avatar,
  Space,
  Typography,
  Button,
  Upload,
  Modal,
  message,
} from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import DatePicker from '../../components/datePicker';
import { eachDayOfInterval } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import socketHandler from '../../config/socket';
import {
  getShiftsAllocation,
  uploadBulkShifts,
} from '../../redux/shifts/action';
import { renderColumn, parseShifts } from './util';
import ShiftCell from './shiftCell';
import { styles } from './style';
import DepartmentFilter from '../../components/departmentFilter';
import UserDetailsModal from '../../components/userDetailsModal';
import ShiftModal from './createShifts';
import { sample } from './data';
let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const XLSX = require('xlsx');
const { Text } = Typography;

const renderColumnDate = (date) => {
  const check = moment(date);
  return (
    <Space align="center">
      <h1>{check.date()}</h1>
      <Text type="secondary">{check.format('MMM')}</Text>
      <Text strong>{check.format('ddd')}</Text>
    </Space>
  );
};

const renderTitle = () => {
  return (
    <Text strong className="text-center">
      Members
    </Text>
  );
};

function Index() {
  const dispatch = useDispatch();
  const { shifts } = useSelector((state) => state.shiftsReducer);
  const { user, company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const [data, setData] = useState([]);
  const [selectedDpt, setDpt] = useState(null);
  const dayStartOfWeek = moment(new Date()).startOf('isoWeek').startOf('day');
  const dayEndOfWeek = moment(dayStartOfWeek).endOf('isoWeek').endOf('day');
  const [period, setPeriod] = useState({
    start: dayStartOfWeek.toDate(),
    end: dayEndOfWeek.toDate(),
  });
  const [loader, setLoader] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState([]);
  const [uloader, setULoader] = useState(false);
  const periodRef = useRef({
    start: dayStartOfWeek.toDate(),
    end: dayEndOfWeek.toDate(),
  });
  const depRef = useRef(null);

  const onChangeTimePeriod = (selection) => {
    const start = moment(selection.startDate).startOf('day').toDate();
    const end = moment(selection.endDate).endOf('day').toDate();
    let period = { start: start, end: end };
    setPeriod(period);
    periodRef.current = period;
  };
  const days = eachDayOfInterval({
    start: moment(period.start).toDate(),
    end: moment(period.end).toDate(),
  });
  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    dispatch(getShiftsAllocation(s, e, selectedDpt, stopLoader));
  }, [period]);

  useEffect(() => {
    if (Array.isArray(shifts)) {
      const data = parseShifts(shifts, days);
      setData(data);
    }
  }, [shifts]);

  const fetchUpdates = useCallback(() => {
    const s = moment(periodRef?.current?.start).toDate();
    const e = moment(periodRef?.current?.end).toDate();
    dispatch(getShiftsAllocation(s, e, depRef?.current, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('shift', fetchUpdates);
    return () => {
      socketHandler.off('shift', fetchUpdates);
    };
  }, []);

  useEffect(() => {
    socketHandler.on('activity', fetchUpdates);
    return () => {
      socketHandler.off('activity', fetchUpdates);
    };
  }, []);

  const onChangeDept = (dept) => {
    setDpt(dept);
    depRef.current = dept;
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    setLoader(true);
    dispatch(getShiftsAllocation(s, e, dept, stopLoader));
  };

  const refreshData = () => {
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    dispatch(getShiftsAllocation(s, e, selectedDpt, stopLoader));
  };

  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };
    selectUser(userRecord);
    setDetailModal(true);
  };

  const createShift = () => {
    setOpen(true);
  };

  const closeCreate = () => {
    setOpen(false);
  };

  const openUploader = () => {
    setShow(true);
  };

  const cancelShow = () => {
    setShow(false);
  };

  const downloadSample = () => {
    const parseResult = sample.map((item) => {
      return item;
    });
    const ws = XLSX.utils.json_to_sheet(parseResult);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    XLSX.writeFile(wb, 'gigsasaSampleDate_for_bulk.xlsx');
  };

  const handleUpload = ({ fileList }) => {
    if (fileList.length > 1) {
      message.warning('One file at a time!');
      return;
    }
    setFile(fileList);
  };

  const parseExportShifts = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const parse = data.slice(1);
        const empData = [];
        parse.forEach((item, index) => {
          if (item.length) {
            if (!item?.[0]) {
              message.warning('Name is required!');
              parse.length = 0;
              return;
            } else if (!item?.[1]) {
              message.warning('Employees is required!');
              parse.length = 0;
              return;
            } else if (!item?.[2]) {
              message.warning('Shift Date is Invalid!');
              parse.length = 0;
              return;
            } else if (!item?.[3] || !String(item?.[3]).includes(':')) {
              message.warning('Shift Time is Invalid!');
              parse.length = 0;
              return;
            } else if (!item?.[4]) {
              message.warning('Shift Duration is Invalid!');
              parse.length = 0;
              return;
            } else {
              let shiftDuration = Number(item[4]) * 60;
              let start = new Date(`${item[2]} ${item[3]}`);
              let end = moment(moment(start))
                .add(shiftDuration, 'minutes')
                .toDate();
              empData.push({
                name: item[0],
                users: item[1]
                  ?.split(',')
                  .map((item) => {
                    if (item) {
                      if (item?.match(regexEmail)) {
                        return item;
                      } else {
                        //phone number
                        return String?.(item)?.[0] !== '+' ? `+${item}` : item;
                      }
                    }
                    return null;
                  })
                  .filter((item) => item),
                start,
                end,
                shiftDuration,
              });
            }
          }
        });
        if (parse.length > 0) {
          setULoader(true);
          dispatch(
            uploadBulkShifts(empData, () => {
              setULoader(false);
              setShow(false);
              refreshData();
            })
          );
        }
      } catch (error) {
        setULoader(false);
      }
    };
    if (rABS) reader.readAsBinaryString(file[0].originFileObj);
    else reader.readAsArrayBuffer(file[0].originFileObj);
  };

  const renderSchedule = (shifts) => {
    if (shifts?.length > 0) {
      return shifts?.map((item, index) => {
        return (
          <ShiftCell
            key={index}
            item={item}
            index={index}
            fetchUpdates={fetchUpdates}
          />
        );
      });
    }
  };

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space
          align="center"
          className="c-pointer shiftAvaCont"
          onClick={() => onRowAvatar(user)}
        >
          <div className="avaContU">
            <Avatar src={user?.profilePic} style={styles.avatar}>
              {user?.name[0]}
            </Avatar>
          </div>

          <div>
            <Text strong className="pl-2 font-weight-bold userNameShift">
              {user?.name}
            </Text>
          </div>
        </Space>
      );
    }
    return (
      <div style={{ width: 100, height: 100, backgroundColor: 'red' }}></div>
    );
  };

  return (
    <React.Fragment>
      <h3>Schedule</h3>
      <Text type="secondary">
        {moment(period.start).format('MMMM Do YYYY')}~
        {moment(period.end).format('MMMM Do YYYY')}
      </Text>
      <Row className="align-items-center">
        <DatePicker
          onChange={(dates) => {
            onChangeTimePeriod(dates);
          }}
          startDate={dayStartOfWeek}
          endDate={dayEndOfWeek}
        />

        {user.role !== 'user' && (
          <React.Fragment>
            <div
              style={styles.deptFilterCont}
              className="d-none d-sm-table-cell orgPickerShift"
            >
              <DepartmentFilter
                pickerStyle={{
                  width: 215,
                  height: 40,
                  borderRadius: 8,
                }}
                onSelectDpt={onChangeDept}
              />
            </div>
          </React.Fragment>
        )}
        {user.role !== 'user' && (
          <>
            <Button
              type="primary"
              className="bg-primary border-primary d-block ml-3"
              icon={<PlusOutlined />}
              onClick={createShift}
              size="large"
            >
              Create Shift
            </Button>
            <Button
              type="primary"
              className="bg-primary border-primary d-block ml-3"
              icon={<UploadOutlined />}
              onClick={openUploader}
              size="large"
            >
              Import Shifts
            </Button>
          </>
        )}
      </Row>
      <Row className="tmb">
        <Col style={{ marginTop: 10 }} md={12}>
          <Table
            columns={renderColumn(
              days,
              renderTitle,
              renderAvatar,
              renderColumnDate,
              renderSchedule
            )}
            dataSource={data}
            bordered
            scroll={{ x: true }}
            size="small"
            pagination={false}
            loading={loader}
            className="shifts"
          />
        </Col>
      </Row>

      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
      {user.role !== 'user' && (
        <ShiftModal
          show={open}
          setClose={closeCreate}
          refreshData={refreshData}
        />
      )}

      <Modal
        title="Upload Shifts"
        visible={show}
        onCancel={cancelShow}
        footer={null}
      >
        <ol>
          <li className="mt-3">
            You will need to download the template that will be used to import
            your shifts. Please download it{' '}
            <Text
              strong
              className="text-primary c-pointer"
              onClick={downloadSample}
            >
              here
            </Text>
          </li>
          <li className="mt-3">
            The template will have sample data on how to populate it
            successfully.
          </li>
        </ol>
        <Upload
          beforeUpload={() => false}
          fileList={file}
          disabled={file.length > 1}
          onChange={handleUpload}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <Button style={styles.buttonUpload} icon={<UploadOutlined />}>
            Upload File
          </Button>
        </Upload>
        <div className="text-right">
          <Space className="mt-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={cancelShow}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => parseExportShifts(file)}
              disabled={uloader || file.length == 0 || !file}
            >
              {uloader ? (
                <Spinner size="sm" animation="border" variant="light" />
              ) : (
                'Submit'
              )}
            </button>
          </Space>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default memo(Index);
