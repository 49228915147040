import axios from 'axios';
import { message } from 'antd';
import ApiClass from './api';
import { cloudinaryImageUrl } from '../../services/cloudinaryImage';
import * as constants from './constants';
import * as authConstants from '../auth/constants';
import { getDayNow } from '../../helpers/getCurrentDay';
import { getTimeToDate } from '../../helpers/getDateWithTime';
import socketHandler from '../../config/socket';
import settingsApi from '../settings/settingsApi';
import moment from 'moment';

let Api = new ApiClass();
const SettingsApi = new settingsApi();

const checkCompanySchedule = async () => {
  try {
    let res = await Api.getCompanyWorkSchedule();
    let schedule = res?.data;
    if (schedule) {
      let end = getTimeToDate(schedule?.end);
      if (end > new Date()) {
        return {
          start: schedule?.start,
          end: schedule?.end,
          name: 'default',
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getActiveShift =
  (cb = () => {}) =>
  async (dispatch) => {
    try {
      let start = moment().startOf('day').toDate();
      let end = moment().endOf('day').toDate();
      let res = await Api.getActive({ start, end });
      if (res?.data) {
        if (res?.data?.timer) {
          dispatch(startTimer(res?.data?.timer));
        }
        dispatch({
          type: constants.GET_ACTIVE,
          payload: res?.data,
        });
      } else {
        let allocation = {
          type: 'schedule',
          status: 'allocation',
        };
        // find work schedule
        let day = getDayNow();
        let result = await Api.getCurrentWorkSchedule({ day, start, end });
        if (result?.data) {
          let end = getTimeToDate(result?.data[day]?.end);
          if (end > new Date()) {
            allocation['schedule'] = {
              start: result?.data[day]?.start,
              end: result?.data[day]?.end,
              name: result?.data?.name,
            };
          } else {
            let cSchedule = await checkCompanySchedule();
            cSchedule
              ? (allocation['schedule'] = cSchedule)
              : (allocation = null);
          }
        } else {
          // find default company  schedule
          let cSchedule = await checkCompanySchedule();
          cSchedule
            ? (allocation['schedule'] = cSchedule)
            : (allocation = null);
        }
        dispatch({
          type: constants.GET_ACTIVE,
          payload: allocation,
        });
      }
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getPerformance = (type, cb) => async (dispatch, getState) => {
  try {
    let { user } = getState().auth;
    if (user?.role === 'root') {
      cb([]);
      return;
    }
    let res = await Api.getPerformance(type);
    cb(res.data);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const clockIn = (data, cb) => async (dispatch, getState) => {
  try {
    let { company, user, coords } = getState().auth;
    let res = await Api.clockIn(data);
    dispatch(saveClockTime(res.data.id));
    dispatch({
      type: constants.UPDATE_SHIFT,
      payload: res.data,
    });
    cb();
    socketHandler.emit('activity', { ...res.data, company: company.id });
    socketHandler.emit('locationUpdate', {
      user: user.id,
      company: company.id,
      location: coords,
    });
    socketHandler.emit('mobileUpdate', { user });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const clockOut = (data, cb) => async (dispatch, getState) => {
  try {
    let { company, user } = getState().auth;
    let res = await Api.clockOut({
      ...data,
      tzOffset: new Date().getTimezoneOffset(),
    });
    dispatch({
      type: constants.SHIFT_DONE,
    });
    cb();
    dispatch(getActiveShift());
    socketHandler.emit('activity', {
      company: company.id,
      ...res.data,
    });
    socketHandler.emit('mobileUpdate', { user });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const breakIn = (data, cb) => async (dispatch, getState) => {
  try {
    let { company, user } = getState().auth;
    let res = await Api.breakIn(data);
    dispatch({
      type: constants.UPDATE_SHIFT,
      payload: res.data,
    });
    cb(true);
    socketHandler.emit('activity', { company: company.id, ...res.data });
    socketHandler.emit('mobileUpdate', { user });
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const breakOut = (data, cb) => async (dispatch, getState) => {
  try {
    let { company, user } = getState().auth;
    let res = await Api.breakOut(data);
    dispatch({
      type: constants.UPDATE_SHIFT,
      payload: res.data,
    });
    cb();
    socketHandler.emit('activity', { company: company.id, ...res.data });
    socketHandler.emit('mobileUpdate', { user });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const verifyFaceRekognition = (faceData, img, cb) => {
  return async (dispatch, getState) => {
    let { user } = getState().auth;
    try {
      let result = await Api.verifyImage(faceData);
      let image = await cloudinaryImageUrl(img);
      let imageURL = image?.data?.secure_url;
      // console.log('images faces are', result?.data?.FaceMatches);
      if (
        result?.data?.FaceMatches?.filter(
          (item) => item?.Face?.ExternalImageId == String(user?.id)
        )?.length > 0
      ) {
        cb(true, imageURL);
        message.success('Successfully Verified!');
      } else {
        cb(null, imageURL);
        message.warning('Not a Valid user');
      }
    } catch (error) {
      cb(false);
      message.error('Image has no Face!');
    }
  };
};

export const addVerification = (faceData, img, cb) => {
  return async (dispatch) => {
    try {
      await Api.addVerification(faceData);
      message.info('A few more minutes...');
      let image = await cloudinaryImageUrl(img);
      let imageURL = image?.data?.secure_url;
      await SettingsApi.updateProfile({
        profilePic: imageURL,
      });
      // add profile image
      dispatch({
        type: authConstants.UPDATE_IMAGE,
        payload: imageURL,
      });
      cb(true);
    } catch (error) {
      cb(false);
      message.error('Image has no Face!');
    }
  };
};

export const SkipImage = (cb) => async (dispatch, getState) => {
  try {
    await Api.skipImage();
    dispatch({
      type: authConstants.UPDATE_IMAGE,
      payload:
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
    });

    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const saveClockTime = (allocation) => async (dispatch) => {
  try {
    let inTime = new Date();
    dispatch({
      type: constants.TIMER_IN,
      payload: inTime,
    });
    await Api.createTimer({
      inTime,
      allocation,
    });
  } catch (error) {}
};

export const startTimer = (data) => {
  return {
    type: constants.START_TIMER,
    payload: data,
  };
};

export const resetTimer = () => {
  return {
    type: constants.RESET_TIMER,
  };
};
