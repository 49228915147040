import React, { useContext, useState } from 'react';
import { Avatar, Spin, Typography, Drawer } from 'antd';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { logoutUser } from '../../../../redux/auth/action';
import ContactUs from '../../../../views/contactUs';
import ClockActivity from '../../../../views/clockInOut/index';

const NavRight = ({ continueTour, perf, hideShift = false }) => {
  const dispatch = useDispatch();
  const { user, company } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [logoutLoading, setLOLoading] = useState(false);
  const configContext = useContext(ConfigContext);
  const { rtlLayout } = configContext.state;

  const handleLogout = async () => {
    $crisp.push(['do', 'session:reset']);
    setLOLoading(true);
    dispatch(logoutUser(() => setLOLoading(false)));
    if (window.talkSession) {
      window.talkSession.destroy();
    }
  };

  const openHelp = () => {
    window.open('https://resources.gigsasa.com/', '_blank');
  };

  const openContact = () => {
    setVisible(true);
  };

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto">
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            {!hideShift && <ClockActivity />}
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              <Avatar
                src={
                  user?.profilePic
                    ? user?.profilePic
                    : 'https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png'
                }
                size="large"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head bg-secondary">
                <span>{user?.name ? user?.name : 'Admin'}</span> <br />
                <span>{company?.name ? company?.name : '--'}</span>
                <br />
                {/* <span>{user?.role ? user?.role : 'User'}</span> */}
              </div>
              <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" />{' '}
                    {user?.email ?? user?.phone}
                  </Link>
                </ListGroup.Item>

                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item" onClick={openHelp}>
                    <i className="feather icon-help-circle" /> How to Guide
                  </Link>
                </ListGroup.Item>

                {['skipAtDpt', 'skip', 'skipAtMember'].includes(
                  user?.tourStatus
                ) || !perf ? (
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        continueTour?.(user?.tourStatus);
                      }}
                    >
                      <i className="feather icon-book" /> Continue Tour
                    </Link>
                  </ListGroup.Item>
                ) : null}

                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item" onClick={openContact}>
                    <i className="feather icon-mail" /> Contact Us
                  </Link>
                </ListGroup.Item>

                <Spin spinning={logoutLoading}>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      <i className="feather icon-log-out" /> Logout
                    </Link>
                  </ListGroup.Item>
                </Spin>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <Drawer
        onClose={() => setVisible(!visible)}
        visible={visible}
        zIndex={4234234234234243}
        width={window.innerWidth > 900 ? 520 : window.innerWidth - 100}
      >
        <ContactUs />
      </Drawer>
    </React.Fragment>
  );
};

export default NavRight;
