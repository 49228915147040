import React, { useState, useEffect } from 'react';
import { Modal, List, Avatar, Typography, Space, Image } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getPA } from '../../redux/performanceQ/action';
import moment from 'moment';

export default function Index({ item, show, setClose }) {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState([]);
  const [loader, setLoader] = useState(false);

  const stopLoader = (data) => {
    if (data.length > 0) {
      setAnswers(data);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (show) {
      setLoader(true);
      dispatch(getPA(item.key, stopLoader));
    }
  }, [show]);

  const renderName = (item) => {
    if (item?.type == 'shift') {
      return item?.shift?.name;
    } else if (item?.type == 'schedule') {
      if (item?.schedule?.name === 'default') {
        return 'Regular Shift';
      }
      return item?.schedule?.name;
    } else {
      return 'Shift';
    }
  };

  return (
    <Modal
      title={`Question: ${item?.question} ?`}
      visible={show}
      onCancel={setClose}
      width={800}
      footer={null}
    >
      <Row>
        <Col md={12} lg={12}>
          <List itemLayout="horizontal" loading={loader}>
            {answers?.length == 0 && !loader && (
              <Text type="secondary">No Answers Yet!</Text>
            )}
            {answers &&
              answers?.map((ans, i) => {
                return (
                  <List.Item
                    key={i}
                    extra={
                      <Space className="float-right">
                        <Text type="secondary">
                          Time : {moment(ans?.created_at).format('HH:mm:ss')}
                        </Text>
                      </Space>
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar src={`${ans?.user?.profilePic}`} size="large" />
                      }
                      title={ans?.user?.name}
                      description={
                        <>
                          <Text className="pb-2 d-block">
                            Shift Name :{' '}
                            <Text strong>{renderName(ans?.allocation)}</Text>
                          </Text>
                          {item.type === 'signature' ? (
                            <div
                              style={{
                                padding: 5,
                                border: '1px solid #F0F0F0',
                                marginRight: 15,
                              }}
                            >
                              <Image src={ans?.answer} />
                            </div>
                          ) : item?.type === 'attachment' ? (
                            <div>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                download
                                href={ans?.answer}
                              >
                                <div
                                  style={{
                                    padding: 5,
                                    border: '1px solid #F0F0F0',
                                    marginRight: 15,
                                  }}
                                >
                                  <CloudDownloadOutlined
                                    style={{
                                      fontSize: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  />
                                </div>
                              </a>
                            </div>
                          ) : (
                            <Text>Answer: {ans?.answer}</Text>
                          )}
                        </>
                      }
                    />
                  </List.Item>
                );
              })}
          </List>
        </Col>
      </Row>
    </Modal>
  );
}
