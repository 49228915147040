export const GET_TASKS = 'GET_TASKS';

export const DELETE_TASKS = 'DELETE_TASKS';
export const UPDATE_TASK = 'UPDATE_TASK';
export const GET_MY_TASKS = 'GET_MY_TASKS';
export const GET_RAW_TASKS = 'GET_RAW_TASKS';
export const GET_ONE_TASK = 'GET_ONE_TASK';

export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const GET_ALL_TASKS_EXPORT = 'GET_ALL_TASKS_EXPORT';

export const TOGGLE_TASK = 'TOGGLE_TASK';
export const TASK_QUESTIONS = 'TASK_QUESTIONS';
export const UPDATE_TASK_SELF = 'UPDATE_TASK_SELF';

export const DASHBOARD_FILTER = 'DASHBOARD_FILTER';
export const MY_FILTER = 'MY_FILTER';
export const ALL_FILTER = 'ALL_FILTER';
export const ONGOING_TASK = 'ONGOING_TASK';
