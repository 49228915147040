import React from 'react';

const G_MAP_KEY = 'AIzaSyD4bLo0gWQydOneofumWsQxOiy9VhC8PDo';

const circle = (lat, lng, rad, detail = 8) => {
  const uri = 'https://maps.googleapis.com/maps/api/staticmap?';
  let staticMapSrc = 'center=' + lat + ',' + lng;
  staticMapSrc += '&size=256x128';
  staticMapSrc += `&key=${G_MAP_KEY}`;
  staticMapSrc += '&scale=2';
  staticMapSrc += '&zoom=16';
  staticMapSrc += '&path=color:orange|weight:1|fillcolor:orange';

  const r = 6371;

  const pi = Math.PI;

  const _lat = (lat * pi) / 180;
  const _lng = (lng * pi) / 180;
  const d = rad / 1000 / r;

  let i = 0;

  for (i = 0; i <= 360; i += detail) {
    const brng = (i * pi) / 180;

    let pLat = Math.asin(
      Math.sin(_lat) * Math.cos(d) +
        Math.cos(_lat) * Math.sin(d) * Math.cos(brng)
    );
    const pLng =
      ((_lng +
        Math.atan2(
          Math.sin(brng) * Math.sin(d) * Math.cos(_lat),
          Math.cos(d) - Math.sin(_lat) * Math.sin(pLat)
        )) *
        180) /
      pi;
    pLat = (pLat * 180) / pi;

    staticMapSrc += '|' + pLat + ',' + pLng;
  }

  return uri + encodeURI(staticMapSrc);
};

export default function StaticMap({ lat, lng, radius, width = 256 }) {
  return <img src={circle(lat, lng, radius)} alt="Image" width={width} />;
}
