import React, { useState, memo, Fragment } from 'react';
import { Button, Popover, Popconfirm, List, Typography } from 'antd';
import { Table as TableRB } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  CarryOutOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { deleteTimesheet } from '../../redux/timesheets/action';
import TimeDetails from './timeDetails';
import EditTime from './editTime';
import { formatTime } from './util';
import { styles } from './style';
const { Text } = Typography;

function Index({ item, sheets, refreshData, sentFrom }) {
  const dispatch = useDispatch();
  const supervisor = item?.editor ? item?.editor : item?.creator;
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dloader, setDLoader] = useState(false);

  const onClose = () => {
    setVisible(false);
  };
  const openDrawer = () => {
    if (item?.allocation) {
      setVisible(true);
    }
  };

  const showModal = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const stopDeleteLoader = () => {
    setDLoader(false);
    refreshData();
  };

  const handleDelete = () => {
    const ids = sheets.map((item) => item?.id);
    const data = {
      timesheets: ids,
      allocation: item?.allocation?.id,
    };

    setDLoader(true);
    dispatch(deleteTimesheet(data, stopDeleteLoader));
  };

  const renderName = () => {
    if (item?.customer) {
      return item?.customer?.name;
    } else if (item?.allocation?.shift) {
      return item?.allocation?.shift?.name;
    } else {
      return '---';
    }
  };

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const content = (
    <div>
      <p> </p>
      <p>Job Title: {supervisor?.jobType ?? 'Admin'}</p>
      <p>At: {moment(item?.createdAt).format('DD-MM-YYYY HH:mm')}</p>
    </div>
  );

  return (
    <>
      <tr
        className={!supervisor && item?.allocation ? 'c-pointer' : ''}
        onClick={openDrawer}
        disabled={supervisor}
      >
        <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {renderName()}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {item?.allocation?.clockin ? (
            <>
              <small>
                <b>{days[moment(item?.allocation?.clockin).day()]}</b>
              </small>
              <br></br>
              {moment(item?.allocation?.clockin).format('HH:mm') + 'HRS'}
            </>
          ) : (
            '--'
          )}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {item?.allocation?.clockout ? (
            <>
              <small>
                <b>{days[moment(item?.allocation?.clockout).day()]}</b>
              </small>
              <br></br>
              {moment(item?.allocation?.clockout).format('HH:mm') + 'HRS'}
            </>
          ) : (
            '--'
          )}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {formatTime(item?.totalTime)}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {formatTime(item?.breakTime)}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {formatTime(item?.overTime)}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {formatTime(item?.time)}
        </td>
        {['custom', 'userList', 'daily'].includes(sentFrom) && (
          <td style={styles.centerAlign} className="d-none d-sm-table-cell">
            ----
          </td>
        )}
        <td>
          {item?.allocation && (
            <Button
              size="small"
              type="primary"
              className="bg-primary border-primary"
              onClick={showModal}
            >
              <EditOutlined />
            </Button>
          )}
          <div className="d-inline" onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                type="primary"
                danger
                className="ml-3"
                loading={dloader}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
          {(item?.editor || item?.creator) && (
            <Popover
              className="ml-3 c-pointer"
              content={content}
              title={`${item?.editor ? 'Edited' : 'Created'} By ${
                supervisor?.name
              }`}
            >
              <EyeOutlined className="text-primary" />
            </Popover>
          )}
        </td>
      </tr>
      {item?.allocation?.tasks.length > 0 && (
        <List className="w-100">
          {item?.allocation?.tasks?.map((task, index) => {
            return (
              <Fragment key={index}>
                {task?.times
                  ?.filter((time) => time.allocation == item.allocation.id)
                  ?.map((time, index) => {
                    return (
                      <List.Item
                        className="w-100 d-flex flex-row align-items-center justify-content-between"
                        key={index}
                      >
                        <CarryOutOutlined />
                        <Text>{task?.title}</Text>
                        <Text>{moment(time?.in).format('HH:mm')}</Text>
                        <Text>{moment(time?.out).format('HH:mm')}</Text>
                        <Text>
                          {formatTime(
                            moment(time?.out).diff(moment(time?.in), 'minutes')
                          )}
                        </Text>
                      </List.Item>
                    );
                  })}
              </Fragment>
            );
          })}
        </List>
        // <TableRB
        //   responsive
        //   className="borderless"
        //   style={{
        //     width: '100%',
        //   }}
        // >
        //   <thead>
        //     <tr
        //       style={{
        //         width: '100%',
        //       }}
        //     >
        //       <th style={styles.centerAlign}>Task Name</th>
        //       <th style={styles.centerAlign}>Start Time</th>
        //       <th style={styles.centerAlign}>End Time</th>
        //       <th style={styles.centerAlign}>Duration</th>
        //     </tr>
        //   </thead>

        //   {item?.allocation?.tasks?.map((task) => {
        //     return (
        //       <>
        //         {task?.times
        //           ?.filter((time) => time.allocation == item.allocation.id)
        //           ?.map((time) => {
        //             return (
        //               <tr>
        //                 <td>{task?.title}</td>
        //                 <td>{moment(time?.in).format('HH:mm')}</td>
        //                 <td>{moment(time?.out).format('HH:mm')}</td>
        //                 <td>
        //                   {formatTime(
        //                     moment(time?.out).diff(moment(time?.in), 'minutes')
        //                   )}
        //                 </td>
        //               </tr>
        //             );
        //           })}
        //       </>
        //     );
        //   })}
        // </TableRB>
      )}

      {!item?.creator && (
        <TimeDetails visible={visible} onClose={onClose} item={item} />
      )}
      <EditTime
        show={isModalVisible}
        handleCancel={handleCancel}
        item={item}
        sheets={sheets}
        refreshData={refreshData}
      />
    </>
  );
}

export default memo(Index);
