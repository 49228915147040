import React, { useEffect, useState, memo } from 'react';
import { Modal, Form, Input, Space, Radio, message, Spin } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBreakTypes } from '../../redux/types/action';

const { TextArea } = Input;
export const reasons = [
  { name: 'Lunch break' },
  { name: 'Feeling sick' },
  { name: 'Personal errand' },
  { name: 'Travelling' },
  { name: 'other' },
];

export default memo(function Index({ show, setClose, getReason }) {
  const dispatch = useDispatch();
  const { typesBreak: types } = useSelector((state) => state.types);
  const { company } = useSelector((state) => state.auth);
  const [value, setValue] = useState('Lunch break');
  const [text, setText] = useState('');
  const [loader, setLoader] = useState(false);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (show) {
      setText('');
      setLoader(true);
      dispatch(getBreakTypes({ company: company.id }, stopLoader));
    }
  }, [show]);

  const submitReason = () => {
    if (text.length > 0) {
      getReason(text);
    } else {
      getReason(value);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleText = (e) => {
    setText(e.target.value);
  };

  let data = types?.length > 0 ? types : reasons;

  return (
    <>
      <Modal
        title="Break Reason"
        visible={show}
        onCancel={setClose}
        width={700}
        footer={null}
      >
        <Form layout="vertical">
          <Row>
            <Col md={12} lg={12}>
              <Form.Item label={'Select your break reason'}>
                {loader ? (
                  <Spin />
                ) : (
                  <Radio.Group onChange={handleChange} value={value}>
                    <Space direction="vertical">
                      {data?.map((option, i) => {
                        return (
                          <Radio key={i} value={option?.name}>
                            {option?.name}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                )}

                <TextArea
                  placeholder="Comment here"
                  size="large"
                  className="w-100 mt-3"
                  onChange={handleText}
                  value={text}
                  showCount
                  maxLength={300}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={setClose}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={submitReason}>
                  Submit
                </button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});
