export const styles = {
  tag: {
    padding: 5,
    borderRadius: 5,
    paddingRight: 15,
    paddingLeft: 15,
  },
  pickerStyle: { width: 215, height: 40, borderRadius: 8, marginBottom: 10 },

  totalComCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 25,
  },

  totalCont: {
    marginLeft: 20,
    borderRadius: 8,
    marginTop: 10,
    width: 200,
    padding: 7,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '-2px 2px 13px 2px rgba(207,207,207,0.75)',
  },
  totalValue: {
    textAlign: 'center',
    fontSize: 34,
    fontWeight: 500,
    color: '#ffff',
    marginBottom: 0,
    fontFamily: 'Roboto',
  },
  valueType: {
    marginBottom: 0,
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: 'Roboto',
    textAlign: 'center',
    // fontWeight: 500,
    // fontSize: 22
  },
};
