import { message } from 'antd';
import authApi from '../auth/authApi';
import { FINISH_TOUR, TOUR_VISIBLE, CONTINUE_TOUR } from './constant';
import { updateTourState } from '../auth/action';
import performanceApi from '../performanceQ/performanceApi';

const Api = new authApi();
const PerfApi = new performanceApi();

export const showTour = (payload) => ({
  type: TOUR_VISIBLE,
  payload,
});

export const finishTour = () => ({
  type: FINISH_TOUR,
});

export const updateTourStatus = (status, userId) => async (dispatch) => {
  try {
    await Api.updateTourStatus({ status, userId });

    dispatch({
      type: FINISH_TOUR,
    });

    dispatch(updateTourState(status));
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const continueTour = (payload) => {
  return {
    type: CONTINUE_TOUR,
    payload,
  };
};

export const getPQ = async (cb) => {
  try {
    const res = await PerfApi.getPerformanceQ({
      page: 1,
      limit: 1,
    });
    cb(res?.data?.results?.length);
  } catch (error) {
    cb(0);
  }
};
