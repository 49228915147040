import { INSTANCE } from '../../config/networkInstance';

export default class MembersApi {
  createOrganization = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'organization',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getOrganizations = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'organization',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  searchOrganizations = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'organization/search',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateOrganization = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `organization/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteOrganization = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: `organization/delete/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
