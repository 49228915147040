import React from 'react';
import { Image } from 'antd';
import { styles } from './style';
import Check from '../../assets/images/auth/check.png';
import UnCheck from '../../assets/images/auth/uncheck.png';

function Index({ steps }) {
  const second =
    steps == 2 || steps == 3 || steps == 'a' || steps == 'b' ? Check : UnCheck;
  const third = steps == 3 || steps == 'b' ? Check : UnCheck;
  return (
    <div className="d-flex align-items-center justify-content-center mt-5">
      <Image
        src={Check}
        preview={false}
        className="img-responsive"
        style={styles.stepperIcon}
      />
      <div style={styles.line} />
      <Image
        src={second}
        preview={false}
        className="img-responsive"
        style={styles.stepperIcon}
      />
      <div style={styles.line} />
      <Image
        src={third}
        preview={false}
        className="img-responsive"
        style={styles.stepperIcon}
      />
    </div>
  );
}

export default Index;
