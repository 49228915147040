import React from 'react';
import { Tabs, Divider, Typography } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import { useSelector } from 'react-redux';
import Custom from './dashboard';
import Applications from './applications';
import Approved from './approved';
import Pending from './prending';
import Policy from './policies';

import Rejected from './rejected';
import NewPolicy from './newPolicy';
import Dashboard from './dashboard';

import moment from 'moment';

const { TabPane } = Tabs;
const { Text } = Typography;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar color-primary"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

function Index() {
  const { user, company } = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <h3>Leave Management</h3>
      <Text type="secondary">{moment().format('MMMM Do YYYY')}~</Text>
      <Divider className="m-0 pb-2" />

      <StickyContainer>
        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} size="large">
          {user.role !== 'user' && (
            <>
              <TabPane tab="New Policy" key="5">
                <NewPolicy />
              </TabPane>
              <TabPane tab=" Leave Policies" key="7">
                <Policy />
              </TabPane>
              <TabPane tab="Dashboard " key="1">
                <Dashboard />
              </TabPane>

              <TabPane tab="Accepted Request" key="3">
                <Approved />
              </TabPane>
              <TabPane tab="Rejected Request" key="4">
                <Rejected />
              </TabPane>
            </>
          )}

          <TabPane tab="My Applications" key="6">
            <Applications />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </React.Fragment>
  );
}

export default Index;
