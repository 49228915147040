import { message } from 'antd';
import analyticsApi from './analyticsApi';
import * as constants from './constants';
import { updatePowerBiData } from '../auth/action';

const api = new analyticsApi();

export const getSampleDashboard = (cb) => async (dispatch) => {
  try {
    const dashboards = await api.getSampleDashboard();
    cb(dashboards?.data);
  } catch (error) {
    message.error(error.message ? error.message : error.message);
    cb();
  }
};

export const addReport =
  (sampleReportId, start, end, cb) => async (dispatch) => {
    try {
      const res = await api.addDashboard(sampleReportId, {
        start,
        end,
      });

      dispatch(updatePowerBiData(res.data?.userPowerBi));
      cb(res.data?.token);
    } catch (error) {
      message.error(error.message ? error.message : error.message);
      cb();
    }
  };

export const getToken = (start, end, cb) => async (dispatch) => {
  try {
    const res = await api.updatePBIData({
      start,
      end,
    });

    cb?.(res.data);
  } catch (error) {
    message.error(error.message ? error.message : error.message);
    cb(null);
  }
};

export const getInitialData =
  (cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await api.getInitialData();
      dispatch({
        type: constants.GET_INITIAL_DATA,
        payload: res?.data,
      });
      cb(true);
    } catch (error) {
      cb(null);
    }
  };

export const getStats =
  (params, cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await api.getStats(params);
      dispatch({
        type: constants.GET_STATS_ANALYTICS,
        payload: res?.data,
      });
      cb(true);
    } catch (error) {
      cb(null);
    }
  };

export const getEmployeesByDate =
  (cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await api.getEmployeesByDate();
      dispatch({
        type: constants.GET_EMPLOYEES_STATS,
        payload: res?.data?.map((item) => [
          new Date(item?._id?.createdAt).getTime(),
          item?.count,
        ]),
      });
      cb(true);
    } catch (error) {
      cb(null);
    }
  };

export const getPerformanceStats =
  (params, cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await api.getPerformanceStats(params);
      dispatch({
        type: constants.GET_PERFORMANCE_STATS,
        payload: res?.data,
      });
      cb(true);
    } catch (error) {
      cb(null);
    }
  };

export const getAnswers =
  (params, cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await api.getAnswers(params);
      if (params.type == 'options') {
        dispatch({
          type: constants.UPDATE_ANSWERS_A,
          payload: res?.data,
        });
      } else {
        dispatch({
          type: constants.UPDATE_ANSWERS_B,
          payload: res?.data,
        });
      }
      cb(true);
    } catch (error) {
      cb(null);
    }
  };
