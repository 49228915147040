import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Select,
  Input,
  Space,
  Divider,
  Radio,
  Tooltip,
} from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments } from '../../redux/members/action';
import { createPQuestions } from '../../redux/performanceQ/action';
import DoneModal from '../tourView/doneModal';
import { updateTourStatus, showTour } from '../../redux/tour/action';
const { Option } = Select;

export default function Index({ show, handleOk, setClose, history }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allDept, allDeptByIds } = useSelector(
    (state) => state.membersReducer
  );
  const { isPerformanceHelp, perfDoneModal } = useSelector(
    (state) => state.tourReducer
  );
  const { user } = useSelector((state) => state.auth);
  const [dLoader, setDLoader] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [name, setName] = useState('');
  const [value, setValue] = useState('text');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setDLoader(true);
    dispatch(getAllDepartments(() => setDLoader(false)));
  }, []);

  const getJobTypes = (id) => {
    const jobTypes = allDeptByIds[id]?.jobTypes;
    setJobTypes(jobTypes);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    setJobTypes([name, ...jobTypes]);
    setName('');
  };

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      handleOk();
      if (isPerformanceHelp) {
        dispatch(
          showTour({
            perfDoneModal: true,
            isProgressListVisible: true,
          })
        );
      }
    }
  };
  function onToggle(e) {
    setValue(e.target.value);
  }

  const addPQ = (values) => {
    const { question, dept_id, jobType, options, type, optional, fetchType } =
      values;

    setLoader(true);
    const questionsData = {
      question: question,
      type: value,
      questionType: type,
      organization: dept_id,
      jobType: jobType,
      ...(value == 'options' ? { options } : {}),
      ...(optional == 'Optional' && {
        optional: true,
      }),
      ...(fetchType && { fetchType }),
    };

    dispatch(createPQuestions(questionsData, stopLoader));
  };

  const finishHelper = (nav) => {
    dispatch(updateTourStatus('done', user.id));
    if (nav) {
      history.push('/app/settings');
    }
  };

  return (
    <>
      <Modal
        title="Create New Question"
        visible={show}
        onCancel={setClose}
        width={700}
        footer={null}
      >
        <Row>
          <Col md={12} lg={12}>
            <Form layout="vertical" form={form} onFinish={addPQ}>
              <Form.Item
                name="question"
                label="Question"
                tooltip="This is a required field"
                className="mb-2"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Question',
                  },
                ]}
              >
                <Input placeholder="Enter Question here" size="large" />
              </Form.Item>

              <Tooltip
                title="Choose whether this question answer should
                be multiple choice or a number or just simple
                free text. This is how it looks like when your
                team member is answering it. "
                color={'#F89722'}
                defaultVisible={isPerformanceHelp}
                placement="left"
              >
                <Radio.Group
                  onChange={onToggle}
                  value={value}
                  className="mb-4 mt-1"
                >
                  <Radio value={'options'}>Multiple Choice</Radio>
                  <Radio value={'number'}>Number</Radio>
                  <Radio value={'text'}>Free Text</Radio>
                  <Radio value={'attachment'}>Attachment</Radio>
                  <Radio value={'signature'}>Signature</Radio>
                </Radio.Group>
              </Tooltip>
              {value == 'options' && (
                <Form.Item
                  name="options"
                  label="Add Options"
                  tooltip="This is a required field"
                  className="mb-4"
                  rules={[
                    {
                      required: true,
                      message: 'Please Input Question Options',
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    size={'large'}
                    placeholder="Input Question Options"
                    style={{ width: '100%' }}
                  >
                    {[]}
                  </Select>
                </Form.Item>
              )}

              <Form.Item name="fetchType" label="Shift / Task">
                <Select
                  size="large"
                  className="w-100"
                  placeholder="Select a Type"
                  defaultValue={'shift'}
                >
                  <Option value="shift">Shift</Option>;
                  <Option value="task">Task</Option>;
                </Select>
              </Form.Item>

              <Form.Item
                name="type"
                label="Question Type"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Type',
                  },
                ]}
              >
                <Select
                  size="large"
                  className="w-100"
                  placeholder="Select a Type"
                >
                  <Option value="in">In</Option>;
                  <Option value="out">Out</Option>;
                </Select>
              </Form.Item>

              <Form.Item
                name="dept_id"
                label="Department"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Department',
                  },
                ]}
              >
                <Select
                  size="large"
                  className="w-100"
                  onChange={getJobTypes}
                  loading={dLoader}
                  disabled={dLoader}
                  placeholder="Select a Department"
                >
                  {allDept?.map((item, i) => {
                    return (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Tooltip
                title="Specify which Position Title and organization
                this performance question should apply to. "
                color={'#F89722'}
                defaultVisible={isPerformanceHelp}
                placement="left"
              >
                <Form.Item
                  name="jobType"
                  label="Job Title"
                  tooltip="This is a required field"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Job Title',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Add a Job Title"
                    className="w-100"
                    dropdownRender={(menu) => (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            padding: 8,
                          }}
                        >
                          <Input
                            style={{ flex: 'auto' }}
                            value={name}
                            onChange={onNameChange}
                          />
                          <a
                            style={{
                              flex: 'none',
                              padding: '8px',
                              display: 'block',
                              cursor: 'pointer',
                            }}
                            className={`btn btn-primary ml-2 ${
                              name == '' && 'disabled'
                            }`}
                            onClick={addItem}
                          >
                            <PlusOutlined /> Add New
                          </a>
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                        {menu}
                      </div>
                    )}
                  >
                    {jobTypes?.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Tooltip>

              <Form.Item name="optional" label="Mandatory/Optional">
                <Select
                  size="large"
                  className="w-100"
                  defaultValue={'Mandatory'}
                >
                  {['Mandatory', 'Optional']?.map((item, i) => {
                    return (
                      <Option key={i} value={item}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={setClose}
                >
                  Cancel
                </button>

                <Tooltip
                  title="Once you are done, click here to complete creating your performance question"
                  color={'#F89722'}
                  defaultVisible={isPerformanceHelp}
                >
                  <button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={loader}
                  >
                    {loader ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      'Create'
                    )}
                  </button>
                </Tooltip>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
      <DoneModal
        isVisible={perfDoneModal}
        header="You are all set!"
        bodyA="You have now set up performance monitoring for your team members!"
        bodyB="You team member will now answer the questions you have set for them when they are clocking and clocking out"
        bodyC={() => {
          return (
            <>
              <p style={{ textAlign: 'center' }} className="tourBodyC">
                Please see below some other cool features that you can take
                advantage of with Gigsasa!
              </p>
              <ul className="tourBodyC">
                <li className="tourBodyC">Image Recognition</li>
                <li className="tourBodyC">Location Geofencing</li>
                <li className="tourBodyC">Shift and Schedules</li>
              </ul>
            </>
          );
        }}
        ctaLeft="Explore and enable above features"
        onLeftCtaClick={() => finishHelper(true)}
        ctaRight="I'll do this later"
        onRightCtaClick={finishHelper}
      />
    </>
  );
}
