import React, { useEffect, useState, memo } from 'react';
import {
  Modal,
  Form,
  Select,
  Input,
  Space,
  TimePicker,
  Typography,
} from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import DepartmentSelector from '../../components/departmentFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { createSchedular, updateSchedular } from '../../redux/schedular/action';
import moment from 'moment';
import { days, timesState } from './data';
const { Option } = Select;
const { Text } = Typography;

export default function InviteModal({
  show,
  handleOk,
  setClose,
  edit = false,
}) {
  const dispatch = useDispatch();
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [oLoader, setOLoader] = useState(false);
  const [dept, setDpt] = useState(null);
  const [times, setTimes] = useState(timesState);
  const format = 'HH:mm';

  const stopLoaderEmp = () => {
    setOLoader(false);
  };

  useEffect(() => {
    setOLoader(true);
    dispatch(getAllEmployees(stopLoaderEmp, dept));
  }, []);

  const onChangeDept = (dept) => {
    setDpt(dept);
    setOLoader(true);
    dispatch(getAllEmployees(stopLoaderEmp, dept));
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        name: edit?.name,
        users: edit?.users.map((user) => user),
      });
      const objStates = {};
      Object.keys(times).map((key) => {
        if (edit[key]) {
          objStates[key] = {
            start: moment(edit[key].start, format),
            end: moment(edit[key].end, format),
          };
        }
      });
      setTimes({
        ...times,
        ...objStates,
      });
    }
  }, [edit]);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      handleOk();
    }
  };

  const handleTime = (value, name) => {
    const start = value[0];
    const end = value[1];
    const time = { start, end };
    if (name == 'monday') {
      setTimes({
        ...times,
        monday: time,
        tuesday: time,
        wednesday: time,
        thursday: time,
        friday: time,
      });
      return;
    }
    setTimes({
      ...times,
      [name]: time,
    });
  };

  const createSchedularFunc = (values) => {
    const data = {
      ...values,
      creator: user?.id,
      company: user?.company_id?.id,
    };
    Object.keys(times).map((key) => {
      if (times[key]) {
        data[key] = {
          start: moment(times[key].start).format('HH:mm'),
          end: moment(times[key].end).format('HH:mm'),
        };
      }
    });
    setLoader(true);
    if (edit) {
      dispatch(updateSchedular(edit.id, data, stopLoader));
    } else {
      dispatch(createSchedular(data, stopLoader));
    }
  };

  return (
    <Modal
      title={edit ? 'Update Work Schedular' : 'Create Work Schedular'}
      visible={show}
      onCancel={setClose}
      width={700}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={createSchedularFunc}>
        <Row>
          <Col sm={12}>
            <Form.Item
              name="name"
              label="Enter your work schedule name"
              rules={[
                {
                  required: true,
                  message: 'Name is required!',
                },
              ]}
            >
              <Input placeholder="Name" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <div className="pb-3">
          <Text className="d-block pb-2">Filter employee by Department</Text>
          <DepartmentSelector
            onSelectDpt={onChangeDept}
            pickerStyle={{
              width: '100%',
              marginBottom: 10,
            }}
            size="large"
          />
        </div>

        <Form.Item
          label="Employee (Select/Add employee)"
          className="mb-3"
          name="users"
          rules={[
            {
              required: true,
              message: 'Please Select Employees',
            },
          ]}
        >
          <Select
            mode="multiple"
            size={'large'}
            placeholder="Please select"
            style={{ width: '100%' }}
            loading={oLoader}
            disabled={oLoader}
          >
            {allEmployees.map((item) => {
              return (
                <Option
                  key={item.id}
                >{`${item.name} (${item?.jobType})`}</Option>
              );
            })}
          </Select>
        </Form.Item>

        <Text className="d-block pb-3 pt-2">Start/End Times for each day</Text>

        <Row>
          {days.map((item, i) => {
            return (
              <Col lg={4} key={i} md={6}>
                <div className="mb-3">
                  <Text className="pb-1 d-block text-capitalize">
                    {item.name}
                  </Text>
                  <TimePicker.RangePicker
                    onChange={(value) => handleTime(value, item.name)}
                    format={format}
                    value={[times[item.name]?.start, times[item.name]?.end]}
                    clearIcon={false}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Space className="mt-3 float-right">
              <button
                type="button"
                className="btn btn-light"
                onClick={setClose}
              >
                Cancel
              </button>

              <button
                className="btn btn-primary"
                htmlType="submit"
                disabled={
                  Object.keys(times).filter((item) => !times[item]).length ===
                    7 || loader
                }
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Create'
                )}
              </button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
