import React from 'react';
import { Row } from 'react-bootstrap';
import { styles } from './style';

function TotalTimes({
  totalLeaves,
  totalPending,
  totalApproved,
  totalRejected,
}) {
  return (
    <Row>
      {/* total time */}
      <div style={{ ...styles.totalCont, border: '2px solid #4339F2' }}>
        <p style={{ ...styles.totalValue, color: '#4339F2' }}>{totalLeaves}</p>
        <p style={{ ...styles.valueType, color: '#4339F2' }}>
          Total Number of leave
        </p>
      </div>
      <div style={{ ...styles.totalCont, border: '2px solid #34B53A' }}>
        <p style={{ ...styles.totalValue, color: '#34B53A' }}>
          {totalApproved}
        </p>
        <p style={{ ...styles.valueType, color: '#34B53A' }}>Approved leave</p>
      </div>
      <div style={{ ...styles.totalCont, border: '2px solid #F89722' }}>
        <p style={{ ...styles.totalValue, color: '#F89722' }}>{totalPending}</p>
        <p style={{ ...styles.valueType, color: '#F89722' }}>
          Pending approval
        </p>
      </div>
      <div style={{ ...styles.totalCont, border: '2px solid #FF3A29' }}>
        <p style={{ ...styles.totalValue, color: '#FF3A29' }}>
          {totalRejected}
        </p>
        <p style={{ ...styles.valueType, color: '#FF3A29' }}>Declined leave</p>
      </div>
    </Row>
  );
}

export default TotalTimes;
