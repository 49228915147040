import * as constants from './constants';

let initState = {
  user: null,
  token: null,
  refreshToken: null,
  isLogin: false,
  isEmailVerified: false,
  coords: {
    latitude: 0,
    longitude: 0,
  },
  step: 1,
  preDetails: {},
  company: {},
  actives: [],
};

function authReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      if (payload.user.isEmailVerified) {
        return {
          ...state,
          user: payload.user,
          token: payload?.tokens?.access,
          isLogin: true,
          isEmailVerified: true,
        };
      }
      return {
        ...state,
        isEmailVerified: false,
      };

    case constants.UPDATE_PROFILE:
      return {
        ...state,
        user: payload,
      };
    case constants.UPDATE_PAYMENT:
      return {
        ...state,
        user: {
          ...state.user,
          paymentDetails: payload,
        },
      };
    case constants.UPDATE_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          profilePic: payload,
        },
      };
    case constants.HELP_DONE:
      return {
        ...state,
        isHelp: false,
      };

    case constants.UPDATE_TOUR:
      const updatedUserObj = { ...state.user, tourStatus: payload };

      return {
        ...state,
        user: updatedUserObj,
      };
    case constants.SHOW_HELP:
      return {
        ...state,
        isHelp: true,
      };
    case constants.GET_COORDS:
      return {
        ...state,
        coords: payload,
      };
    case constants.UPDATE_STEPS:
      return {
        ...state,
        step: payload,
      };
    case constants.PRE_DETAILS:
      const newData = { ...state.preDetails, ...payload };
      return {
        ...state,
        preDetails: newData,
      };
    case constants.LOG_OUT:
      return initState;

    case constants.CREATE_COMPANY:
      const updatedUser = { ...state.user, company_id: payload.id };
      return {
        ...state,
        company: payload,
        user: updatedUser,
      };

    case constants.GET_COMPANY:
      return {
        ...state,
        company: payload,
      };

    case constants.NEW_ORG_ID:
      const userOrg = state.user.descendants_depts;
      userOrg.push(payload);
      const newUser = { ...state.user };
      newUser.descendants_depts = userOrg;
      return {
        ...state,
        user: newUser,
      };

    case constants.NEW_DESCENDANT:
      const userDes = state.user.descendants_users;
      userDes.push(payload);
      const updatedUserDes = { ...state.user };
      updatedUserDes.descendants_users = userDes;

      return {
        ...state,
        user: updatedUserDes,
      };

    case constants.UPDATE_COMPANY:
      const newCompanyObj = { ...state.company, ...payload };

      return {
        ...state,
        company: newCompanyObj,
      };

    case constants.UPDATE_TOKEN:
      return {
        ...state,
        token: payload?.access,
      };

    case constants.USER_POWER_BI:
      const getUser = { ...state.user, powerBi: payload };

      return {
        ...state,
        user: getUser,
      };

    case constants.SET_ACTIVE_USERS: {
      return {
        ...state,
        actives: payload,
      };
    }

    case constants.REMOVE_ACTIVE_USER: {
      return {
        ...state,
        actives: [...state.actives].filter(
          (item) => item?.user?.id !== payload
        ),
      };
    }

    default:
      return state;
  }
}

export default authReducer;
