import React, { useEffect, useState, memo } from 'react';
import { Modal, Form, Space, Radio, Spin, Typography } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAssignments } from '../../redux/types/action';
import { styles } from './style';
const { Text } = Typography;

export default memo(function Index({ show, setClose, getProject }) {
  const dispatch = useDispatch();
  const { assignments } = useSelector((state) => state.types);
  const [value, setValue] = useState(null);
  const [loader, setLoader] = useState(false);

  const stopLoader = (result) => {
    setLoader(false);
    if (result.length == 0) {
      setClose();
    }
  };

  const fetchAssignments = () => {
    setLoader(true);
    dispatch(getUserAssignments(stopLoader));
  };

  useEffect(() => {
    if (show) {
      fetchAssignments();
    }
  }, [show]);

  const submit = () => {
    getProject(value);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Modal
        title="Assignments"
        visible={show}
        onCancel={setClose}
        width={700}
        footer={null}
      >
        <Form layout="vertical">
          <Row>
            <Col md={12} lg={12}>
              <Form.Item label={'Select Your Assignment'}>
                {loader ? (
                  <Spin />
                ) : (
                  <Radio.Group onChange={handleChange} value={value}>
                    <Space direction="vertical">
                      {assignments?.map((option, i) => {
                        return (
                          <Radio key={i} value={option?.id}>
                            {option?.name}&nbsp;&nbsp;&nbsp;
                            <Text style={styles.labelRadio} type="secondary">
                              ({option?.type})
                            </Text>
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={setClose}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={submit}>
                  Submit
                </button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});
