import { INSTANCE } from '../../config/networkInstance';

export default class AuthApi {
  getActivities = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'activities',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSafetyEmergencies = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'health',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllocationSOS = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'health/allocation',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
