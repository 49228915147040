import { Spin } from 'antd';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscription } from '../../redux/chargeBee/action';

function Index({ plan, CardUrl }) {
  const { activePlan, card, customer } = useSelector(
    (state) => state.chargeBee
  );
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const subscribeHandler = () => {
    if (!card && !customer?.payment_method) {
      window.open(CardUrl, '_self');
      return;
    }
    setLoader(true);
    dispatch(
      updateSubscription({ itemId: plan.id }, () => {
        setLoader(false);
      })
    );
  };
  const isPlatinum = ['Platinum-Plan'].includes(plan?.item_id);

  return (
    <div className="col-lg-4 mt-3 mb-3">
      <Spin spinning={loader} delay={500}>
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">
              {plan.external_name}
            </h5>
            {activePlan?.subscription?.subscription_items.find(
              (e) => e.item_price_id === plan?.id
            ) && (
              <h5 className="card-title  text-primary text-uppercase text-center">
                {activePlan?.subscription?.cancelled_at
                  ? '(Cancelled)'
                  : '(Subscribed)'}
              </h5>
            )}
            <h6 className="card-price text-center">
              ${plan.price ? plan.price / 100 : plan.price}
              <span className="period">(per user)</span>
              <span className="period">/{plan.period_unit}</span>
            </h6>
            <hr />
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Time tracking and Scheduling
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Reports and timesheets
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Activity Tracking
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Clock in-out with facial recognition
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Geo-location
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Geo-fencing
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Export timesheets
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Reports and Analytics
              </li>

              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                Push Notifications
              </li>
              <li className={isPlatinum ? 'text-muted' : ''}>
                <span className="fa-li">
                  <i
                    className={isPlatinum ? 'fas fa-times' : 'fas fa-check'}
                  ></i>
                </span>
                Leave Request
              </li>
              <li className={isPlatinum ? 'text-muted' : ''}>
                <span className="fa-li">
                  <i
                    className={isPlatinum ? 'fas fa-times' : 'fas fa-check'}
                  ></i>
                </span>
                Approve/Reject leaves
              </li>
              <li className={isPlatinum ? 'text-muted' : ''}>
                <span className="fa-li">
                  <i
                    className={isPlatinum ? 'fas fa-times' : 'fas fa-check'}
                  ></i>
                </span>
                Performance Tracking
              </li>
            </ul>
            {activePlan?.subscription?.subscription_items.find(
              (e) => e.item_price_id === plan?.id
            ) ? (
              ''
            ) : (
              <button
                onClick={subscribeHandler}
                className={'btn btn-block  btn-primary text-uppercase'}
              >
                subscribe
              </button>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default memo(Index);
