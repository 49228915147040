export const styles = {
  mapContainer: {
    height: 400,
    width: '100%',
    position: 'relative',
  },
  mapValues: {
    background: 'rgba(255,255,255,0.5',
    padding: '0.8rem',
    border: '1px solid #ccc',
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    zIndex: 2342444,
    width: '25%',
  },
  buttonUpload: {
    marginLeft: 20,
    marginTop: 10,
  },
  smallText: {
    fontSize: 12,
  },
  isActive: {
    marginBottom: 0,
    color: 'rgb(51, 153, 51)',
    fontWeight: '500',
  },

  isDisabled: {
    marginBottom: 0,
    color: '#ff8080',
    fontWeight: '500',
  },
};
