import React from 'react';
import { ListGroup } from 'react-bootstrap';

const NavLeft = () => {
  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav mr-auto">
        <ListGroup.Item
          as="li"
          bsPrefix=" "
          className="nav-item d-flex flex-row align-items-center"
        ></ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavLeft;
