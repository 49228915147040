import React from 'react';
import SocialLogin from 'react-social-login';
import { Image } from 'antd';
import Facebook from '../../assets/images/auth/fb.png';
import { styles } from './style';

function Index({ children, triggerLogin, ...props }) {
  return (
    <Image
      src={Facebook}
      preview={false}
      style={styles.smallIcon}
      onClick={() => triggerLogin()}
      {...props}
      className="c-pointer ml-5"
    />
  );
}

export default SocialLogin(Index);
