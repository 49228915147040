import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getCurrent } from '../../redux/auth/action';

function Index({ history }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
    history.push('/app/dashboard/analytics');
  };
  const goToHome = () => {
    setLoader(true);
    dispatch(getCurrent(stopLoader));
  };
  return (
    <div className="jumbotron text-center bg-light">
      <h1 className="display-3">Thank You!</h1>
      <p className="lead">
        <strong>Please check your email</strong> to get Invoice of the current
        Purchase
      </p>
      <hr />
      <p className="lead">
        <button className="btn btn-primary btn-sm" onClick={goToHome}>
          {loader ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            'Continue to homepage'
          )}
        </button>
      </p>
    </div>
  );
}

export default Index;
