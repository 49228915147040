import React from 'react';
import { Typography, Image } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { styles } from './style';
import Register from '../../assets/images/auth/createOrg.png';
import { Slide } from 'react-awesome-reveal';
import Stepper from './stepper';
const { Text } = Typography;

function LeftChoose({ steps }) {
  return (
    <div>
      <Image
        src={Register}
        preview={false}
        className="text-center img-responsive mt-auto mb-auto"
      />
      <Stepper steps={steps} />
    </div>
  );
}

function RightChoose({ setSteps }) {
  const createNew = () => {
    setSteps(2);
  };
  const joinTeam = () => {
    setSteps('a');
  };
  return (
    <Slide direction="right">
      <div>
        <h3 className="font-weight-bold text-center pt-5">
          Let’s get started...
        </h3>
        <Text
          className="d-block text-center pt-1"
          type="secondary"
          style={styles.desc}
        >
          Do you need to create a new business or
        </Text>
        <Text
          className="d-block text-center"
          type="secondary"
          style={styles.desc}
        >
          organization or are you part of a team?
        </Text>
        <Row className="mt-5">
          <Col sm={12} xl={4} className="d-flex justify-content-center">
            <button
              className="btn btn-primary d-block"
              style={styles.button}
              onClick={() => createNew()}
            >
              Create a new Business
            </button>
          </Col>
          <Col sm={12} xl={4} className="d-flex justify-content-center">
            <div
              className="text-center d-flex justify-content-center align-items-center"
              style={styles.button}
            >
              <Text underline strong className="text-secondary">
                OR
              </Text>
            </div>
          </Col>
          <Col sm={12} xl={4} className="d-flex justify-content-center">
            <button
              className="btn btn-primary d-block"
              style={styles.button}
              onClick={joinTeam}
            >
              Join a team
            </button>
          </Col>
        </Row>
      </div>
    </Slide>
  );
}

export { LeftChoose, RightChoose };
