import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Pagination,
  Card,
  Button,
  Divider,
  List,
  Avatar,
  Skeleton,
  Space,
  Tooltip,
  Empty,
  Checkbox,
} from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Row } from 'react-bootstrap';
import socketHandler from '../../config/socket';
import CreateModal from './addTask';
import MyTask from './myTasks';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { getTasks } from '../../redux/tasks/action';
import DepartmentFilter from '../../components/departmentFilter';
import { styles } from './style';
const { Link, Text } = Typography;

function Index() {
  const dispatch = useDispatch();
  const { tasks, initial, selfTasks } = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [show, setShow] = useState(false);
  const [dept, setDpt] = useState(null);
  const [loader, setLoader] = useState(false);

  const [activeTasks, setActiveTasks] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPageIndex, setminPageIndex] = useState(0);
  const [pageSize, setpageSize] = useState(10);
  const [maxPageIndex, setmaxPageIndex] = useState(pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setminPageIndex((page - 1) * pageSize);
    setmaxPageIndex(page * pageSize);
  };

  const [viewed, setViewed] = useState([]);
  const depRef = useRef(null);
  const stopLoader = () => {
    setViewed(selfTasks);
    setLoader(false);
  };
  const fetchTasks = () => {
    setLoader(true);
    dispatch(getTasks({ organization: dept }, stopLoader));
  };
  useEffect(() => {
    if (allEmployees.length == 0) {
      dispatch(getAllEmployees(() => {}, dept));
    }

    setViewed(selfTasks);

    if (selfTasks.length < 1) {
      fetchTasks();
    }
  }, []);

  const fetchUpdates = useCallback(() => {
    dispatch(getTasks({ organization: depRef?.current }, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('taskComplete', fetchUpdates);
    return () => {
      socketHandler.off('taskComplete', fetchUpdates);
    };
  }, []);

  const refreshData = () => {
    dispatch(getTasks({ organization: dept }, () => {}));
  };

  const refreshPage = () => {
    setLoader(true);
    dispatch(getTasks({ organization: dept }, stopLoader));
  };

  const setClose = () => {
    setActiveTasks(null);
    setShow(false);
  };

  const handleOk = () => {
    setClose();
    refreshData();
  };

  const handleTasks = (tasks) => {
    setActiveTasks(tasks);
    openTask();
  };

  const openTask = () => {
    setShow(true);
  };

  const onChangeDept = (dept) => {
    depRef.current = dept;
    setDpt(dept);
    setLoader(true);
    dispatch(getAllEmployees(() => {}, dept));
    dispatch(getTasks({ organization: dept }, stopLoader));
  };

  const onChange = (
    checkedValues = ['completed', 'ongoing', 'inreview', 'rejected']
  ) => {
    var current = [];
    if (checkedValues.includes('completed')) {
      current = [
        ...current,
        ...selfTasks.filter((_) => _.status == 'completed'),
      ];
    }

    if (checkedValues.includes('ongoing')) {
      current = [
        ...current,
        ...selfTasks.filter(
          (_) => _.status == 'progress' || _.status == 'pending'
        ),
      ];
    }

    if (checkedValues.includes('inreview')) {
      current = [
        ...current,
        ...selfTasks.filter((_) => _.status == 'inreview'),
      ];
    }

    if (checkedValues.includes('rejected')) {
      current = [
        ...current,
        ...selfTasks.filter((_) => _.status == 'rejected'),
      ];
    }
    setViewed(current);
  };

  return (
    <React.Fragment>
      <Row>
        <Button
          type="primary"
          className="bg-primary border-primary"
          icon={<PlusOutlined />}
          onClick={openTask}
          style={styles.buttonUpload}
        >
          Add Task
        </Button>
        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshPage} style={styles.buttonUpload}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Row>
      <div className="pl-4 pr-4 pt-3">
        <List loading={loader}>
          {selfTasks.map(
            (task, index) =>
              index >= minPageIndex &&
              index < maxPageIndex && <MyTask task={task} key={index} />
          )}
          <Pagination
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePageChange}
            total={viewed.length}
          />
        </List>
        {/* {mine || user?.role == 'user' ? (
          <></>
        ) : (
          <List loading={loader}>
            {selfTasks?.length > 0 &&
              selfTasks?.map((task, index) => {
                return <MyTask task={task} key={index} />;
              })}
          </List>
        )} */}
      </div>
      <CreateModal
        show={show}
        setClose={setClose}
        handleOk={handleOk}
        activeTasks={activeTasks}
      />
    </React.Fragment>
  );
}

export default Index;
