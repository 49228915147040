import { INSTANCE } from '../../config/networkInstance';

export default class AuthApi {
  /**
   * Api to login
   * @param  {} params: { email: string, password: string, phone: string | null}
   */
  login = (params) => {
    const { email, password, phone = null, isSocialAuth = false } = params;
    let data = { email, password, ...(isSocialAuth ? { isSocialAuth } : {}) };
    let url = 'auth/login';
    if (phone) {
      data = { phone, password };
      url = 'auth/phoneLogin';
    }

    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Api to register a new user
   * @param  {} params: {first_name: string, other_names: string, email: string: phone: string; password: string}
   */
  register = (params) => {
    const {
      first_name,
      other_names,
      email,
      password,
      phone,
      isSocialAuth = false,
      isEmailVerified = false,
    } = params;

    const userData = {
      name: `${first_name} ${other_names}`,
      ...(phone ? { phone } : {}),
      ...(email && { email }),
      password,
      isEmailVerified,
      ...(isSocialAuth ? { isSocialAuth } : {}),
    };

    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/register',
        data: userData,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  adminRegister = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/admin-signup',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getCurrent = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'auth/current',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Get auth token from refresh token
   * @param  {} refreshToken: string
   */
  getRefreshToken = (refreshToken) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/refresh-tokens',
        data: { refreshToken },
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Api for Forgot password
   * @param  {} email: string
   */
  forgotPassword = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/forgot-password',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Api to reset password
   * @param  {} token
   */
  resetPassword = (token, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/reset-password',
        params: { token },
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Api to reset password 2
   * @param  {} token
   */
  resetPassword2 = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/resetPassword',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Api to create a company
   * @param  {} params
   */
  createCompany = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'company',
        data: params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getCompany = (companyId) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'company',
        params: {
          companyId,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  };

  joinTeam = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/joinTeam',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  otpValidation = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/validateOTP',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  otpValidation2 = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/validateOTP2',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Logout api
   * @param  {} params: { refreshToken: string }
   */
  logout = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/logout',
        data: params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  /**
   * Resend Verification Email
   */
  resentVerificationEmail = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/send-verification-otp',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateUsers = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `users/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  joinTeam = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/joinRequest',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateTourStatus = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/updateTourStatus',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  validatePhone = (phone) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/validatePhone',
        data: { phone },
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
