import React, { useEffect, Fragment, useRef, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Talk from 'talkjs';
import { useSelector } from 'react-redux';
import NewContact from './addNew';

function Messaging() {
  let talkReference = useRef();
  let [show, setShow] = useState(false);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    setTimeout(() => {
      renderInbox();
    }, 500);
  }, []);

  const renderInbox = () => {
    Talk.ready
      .then(() => {
        const me = new Talk.User({
          id: user?.id,
          name: user?.name,
          email: user?.email,
          photoUrl: user?.profilePic,
          welcomeMessage: 'Hey there! How are you? :-)',
          role: 'default',
        });
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: 'tLInDPcW',
            me: me,
          });
        }
        var inbox = window.talkSession.createInbox();
        inbox.mount(talkReference?.current);
      })
      .catch((e) => {});
  };

  const setClose = () => {
    setShow(false);
  };
  const showModal = () => {
    setShow(true);
  };
  return (
    <Fragment>
      <Button
        type="primary"
        className="bg-primary border-primary mb-5"
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        New Conversation
      </Button>
      <div
        style={{ height: '500px' }}
        className="inbox-container"
        ref={talkReference}
      >
        Loading...
      </div>
      <NewContact show={show} setClose={setClose} />
    </Fragment>
  );
}

export default Messaging;
