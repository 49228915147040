import React, { useState, useEffect, memo } from 'react';
import { Tabs, Pagination, Spin } from 'antd';
import { Row } from 'react-bootstrap';
import socketHandler from '../../../config/socket';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAvailableShifts,
  getUserSwap,
} from '../../../redux/swapShift/action';
import { NoData, OpenShiftAppCard, UserCard } from '../components';

const { TabPane } = Tabs;

function SwapShift() {
  const dispatch = useDispatch();

  const { availableShifts, totalAvailableShifts, userReq, totalUserReq } =
    useSelector((state) => state.swapShifts);

  const limit = 10;
  // for available Shifts
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    setLoader(true);

    dispatch(getAvailableShifts({ page, limit }, stopLoader));
  }, []);
  const stopLoader = () => {
    setLoader(false);
  };

  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getAvailableShifts({ page, limit }, stopLoader));
  };

  const refreshData = () => {
    setLoader(true);
    dispatch(getAvailableShifts({ page, limit }, stopLoader));
  };

  useEffect(() => {
    socketHandler.on('SwapPostRequest', fetchAvailableUpdates);
    return () => {
      socketHandler.off('SwapPostRequest', fetchAvailableUpdates);
    };
  }, []);

  const fetchAvailableUpdates = () => {
    dispatch(getAvailableShifts({ page, limit }, () => {}));
  };
  //for req shifts

  const [loaderReq, setLoaderReq] = useState(false);
  const [pageReq, setPageReq] = useState(1);

  const stopLoaderReq = () => {
    setLoaderReq(false);
  };

  const changePageForReq = (page) => {
    setPageReq(page);
    setLoaderReq(true);
    dispatch(getUserSwap({ page, limit }, stopLoaderReq));
  };

  const refreshReqData = () => {
    setLoaderReq(true);
    dispatch(getUserSwap({ page, limit }, stopLoaderReq));
  };

  useEffect(() => {
    setLoaderReq(true);

    dispatch(getUserSwap({ page, limit }, stopLoaderReq));
  }, []);

  useEffect(() => {
    socketHandler.on('updateRequest', fetchUpdates);
    return () => {
      socketHandler.off('updateRequest', fetchUpdates);
    };
  }, []);

  const fetchUpdates = () => {
    dispatch(getUserSwap({ page, limit }, () => {}));
  };
  return (
    <React.Fragment>
      <h3>Shifts Requests</h3>

      <Tabs size={'large'} centered defaultActiveKey="1">
        <TabPane tab="Change Of Shift Board" key="1">
          {loader ? (
            <div
              className="justify-content-center align-items-center d-flex "
              style={{ height: '200px' }}
            >
              <Spin spinning={loader} />
            </div>
          ) : (
            <>
              <Row className=" mx-4 g-3">
                {availableShifts.length ? (
                  availableShifts.map((item, i) => {
                    return (
                      <UserCard
                        refreshData={refreshData}
                        shiftItem={item}
                        key={i}
                      />
                    );
                  })
                ) : (
                  <NoData />
                )}
              </Row>
              <div
                style={{
                  textAlign: 'left',
                  margin: '50px',
                }}
              >
                <Pagination
                  onChange={changePage}
                  current={page}
                  total={totalAvailableShifts}
                  position={['bottomRight']}
                  hideOnSinglePage={true}
                  show
                />
              </div>
            </>
          )}
        </TabPane>

        <TabPane tab="Open Shift Application" key="2">
          {loaderReq ? (
            <div
              className="justify-content-center align-items-center d-flex "
              style={{ height: '200px' }}
            >
              <Spin spinning={loaderReq} />
            </div>
          ) : (
            <>
              <Row className="mx-4 g-3">
                {userReq.length ? (
                  userReq.map((item, i) => {
                    return (
                      <OpenShiftAppCard
                        refreshData={refreshReqData}
                        reqItem={item}
                        key={i}
                      />
                    );
                  })
                ) : (
                  <NoData />
                )}
              </Row>
              <div
                style={{
                  textAlign: 'left',
                  margin: '50px',
                }}
              >
                <Pagination
                  onChange={changePageForReq}
                  current={pageReq}
                  total={totalUserReq}
                  position={['bottomRight']}
                  hideOnSinglePage={true}
                  show
                />
              </div>
            </>
          )}
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default memo(SwapShift);
