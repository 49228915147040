import React, { useEffect, useState, memo } from 'react';
import {
  Modal,
  Form,
  Select,
  Input,
  Space,
  Divider,
  Tooltip,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Spinner } from 'react-bootstrap';
import CountryPhoneInput from 'antd-country-phone-input';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments, createEmployee } from '../../redux/members/action';
import DoneModal from '../tourView/doneModal';
import { showTour, updateTourStatus } from '../../redux/tour/action';
import authApi from '../../redux/auth/authApi';
import { styles } from './style';
const { Option } = Select;
const { Text } = Typography;
const Api = new authApi();

export default memo(function InviteModal({ show, handleOk, setClose }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allDept, allDeptByIds, user } = useSelector((state) => ({
    allDept: state.membersReducer.allDept,
    allDeptByIds: state.membersReducer.allDeptByIds,
    user: state.auth.user,
  }));
  const { isMemberHelp, memDoneModal } = useSelector(
    (state) => state.tourReducer
  );
  const [dLoader, setDLoader] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false);
  const [vloader, setVLoader] = useState(false);

  useEffect(() => {
    if (allDept.length == 0) {
      setDLoader(true);
      dispatch(getAllDepartments(() => setDLoader(false)));
    }
  }, []);

  const getJobTypes = (orgId) => {
    const selectedOrg = allDeptByIds[orgId];
    if (selectedOrg) {
      setJobTypes(selectedOrg.jobTypes);
    }
  };

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      handleOk();

      if (isMemberHelp) {
        dispatch(
          showTour({
            memDoneModal: true,
            isProgressListVisible: true,
          })
        );
      }
    }
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    setJobTypes([name, ...jobTypes]);
    setName('');
  };

  const addMember = (values) => {
    const { first_name, other_names, dept_id, jobType, email, phone, role } =
      values;
    setLoader(true);
    const empObj = {
      name: `${first_name} ${other_names}`,
      ...(email && { email }),
      role: role,
      ...(phone?.phone && { phone: `+${phone.code}${phone.phone}` }),
      jobType: jobType,
      organization: dept_id,
      company_id: user?.company_id?.id,
    };
    dispatch(createEmployee(empObj, stopLoader));
  };

  const doneDeptHelper = () => {
    dispatch(
      showTour({
        isMemberHelp: false,
        performanceHelp: true,
        memDoneModal: false,
        isProgressListVisible: false,
      })
    );
  };

  const finishHelper = () => {
    dispatch(updateTourStatus('skipAtMember', user.id));
  };
  return (
    <>
      <Modal
        title="Add Member"
        visible={show}
        onCancel={setClose}
        width={700}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={addMember}
          initialValues={{
            phone: {
              short: 'gb',
            },
          }}
        >
          <Row>
            <Col md={12} lg={6}>
              <Form.Item
                name="first_name"
                label="Name"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Input First Name',
                  },
                ]}
              >
                <Input placeholder="Enter Name here" size="large" />
              </Form.Item>
            </Col>
            <Col md={12} lg={6}>
              <Form.Item
                name="other_names"
                label="Other Names"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Other Name',
                  },
                ]}
              >
                <Input placeholder="Enter Other Names here" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      let phone = getFieldValue('phone').phone;
                      if (!phone) {
                        if (!value) {
                          return Promise.reject(new Error('Email is Empty!'));
                        }
                      }
                    },
                  }),
                ]}
              >
                <Input placeholder="Enter Email here" size="large" />
              </Form.Item>

              <Text className="text-center d-block font-weight-bold">OR</Text>

              <Form.Item
                name="phone"
                label="Mobile Number"
                className="mb-0 pb-0"
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      let email = getFieldValue('email');
                      if (!email) {
                        setVLoader(true);
                        let res = (
                          await Api.validatePhone(
                            `+${value.code}${value.phone}`
                          )
                        ).data;
                        setVLoader(false);
                        if (res.valid) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Invalid Phone Number!')
                        );
                      }
                    },
                  }),
                ]}
              >
                <CountryPhoneInput />
              </Form.Item>

              <Text
                style={styles.smallText}
                type="secondary"
                className="d-block mt-1 mb-3"
              >
                {vloader ? 'The information is being validated...' : ''}
              </Text>

              <Form.Item
                name="dept_id"
                label="Department"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Department',
                  },
                ]}
              >
                <Select
                  placeholder="Select a Department"
                  size="large"
                  className="w-100"
                  loading={dLoader}
                  disabled={dLoader}
                  onChange={getJobTypes}
                >
                  {allDept?.map((item, i) => {
                    return (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="jobType"
                label="Job Title"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Job Title',
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Add a Job Title"
                  className="w-100"
                  dropdownRender={(menu) => (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: 'auto' }}
                          value={name}
                          onChange={onNameChange}
                        />
                        <a
                          style={{
                            flex: 'none',
                            padding: '8px',
                            display: 'block',
                            cursor: 'pointer',
                          }}
                          className={`btn btn-primary ml-2 ${
                            name == '' && 'disabled'
                          }`}
                          onClick={addItem}
                          disabled={name == ''}
                        >
                          <PlusOutlined /> Add New
                        </a>
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  )}
                >
                  {jobTypes?.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="role"
                label="Roles"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Role',
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select a Role"
                  className="w-100"
                >
                  {user?.role == 'root' && <Option value="root">Root</Option>}
                  {['admin', 'root'].includes(user?.role) && (
                    <Option value="admin">Admin</Option>
                  )}
                  <Option value="supervisor">Supervisor/ Manager</Option>
                  <Option value="user">Worker</Option>
                </Select>
              </Form.Item>
              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={setClose}
                >
                  Cancel
                </button>

                <Tooltip
                  title="Once you are done, click here to
                complete inviting your employee."
                  color={'#F89722'}
                  style={{ padding: 100 }}
                  defaultVisible={isMemberHelp}
                >
                  <button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={loader}
                  >
                    {loader ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      'Create'
                    )}
                  </button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <DoneModal
        isVisible={memDoneModal}
        header="Yay!"
        bodyA="You have now created your first team member!"
        bodyB="You team member has received an invitation link to accept on their email. The have also received a link to download the Gigsasa mobile application from the android or iOS app stores. "
        bodyC={() => (
          <p className="tourBodyC" style={{ textAlign: 'center' }}>
            Would you like to set some performance questions that your employees
            can answer when clocking in and clocking out of their shift?
          </p>
        )}
        ctaLeft="Yes, proceed"
        onLeftCtaClick={doneDeptHelper}
        ctaRight="I'll do this later"
        onRightCtaClick={finishHelper}
      />
    </>
  );
});
