import React from 'react';
import { Popover } from 'antd';
import './Marker.css';
import { styles } from './style';

const Marker = (props) => {
  const { color, id, user } = props;

  const content = (
    <div>
      <p>Job Title : {user?.jobType ?? 'Root User'}</p>
    </div>
  );
  return (
    <Popover content={content} title={user?.name} key={id}>
      <div>
        <div
          className="pin bounce"
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={user?.name}
        />

        <div className="pulse" />
      </div>
      <div style={{ ...styles.label, background: color }}>{user?.name}</div>
    </Popover>
  );
};

export default Marker;
