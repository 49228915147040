import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { styles } from './style';

function Index({ left, right }) {
  return (
    <Container fluid style={styles.container}>
      <Row>
        <Col
          md={5}
          sm={12}
          className="d-none d-lg-flex d-xl-flex d-md-flex justify-content-center align-items-center px-4 py-0"
          style={{ background: '#FAFBFC', ...styles.fullHeight }}
        >
          {left}
        </Col>
        <Col
          md={7}
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center p-4"
          style={styles.fullHeight}
        >
          {right}
        </Col>
      </Row>
    </Container>
  );
}

export default Index;
