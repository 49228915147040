import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';

import {
  getCardUrl,
  getCurrentStatus,
  getChargeBeePlans,
  getCustomer,
} from '../../redux/chargeBee/action';
import PaymentCard from './paymentCard';
import NavbarRight from '../../layouts/AdminLayout/NavBar/NavRight';

function InActiveSubscription(props) {
  const dispatch = useDispatch();
  const { activePlan, monthlyPlans, yearlyPlans, isActiveSub, customer, card } =
    useSelector((state) => state.chargeBee);

  const { company, isLogin, user } = useSelector((state) => state.auth);
  const [isYearly, setYearly] = useState(false);
  const [CardUrl, setCardUrl] = useState(null);

  useEffect(() => {
    dispatch(
      getCardUrl(company?.paymentDetails?.chargeBeeUser, (res) => {
        setCardUrl(res?.hosted_page?.url);
      })
    );

    dispatch(getCurrentStatus());
    dispatch(getChargeBeePlans());
    dispatch(getCustomer(company));
  }, []);

  useEffect(() => {
    if (isActiveSub || !isLogin) {
      props.history.push('/app/dashboard');
    }
  }, [isActiveSub, isLogin]);

  const changeCharge = (check) => {
    setYearly(check);
  };

  return (
    <>
      <header className="navbar pcoded-header navbar-expand-lg">
        <NavbarRight hideShift={true} />
      </header>
      {user?.role == 'root' ? (
        <Container className="mt-5 mb-5 p-3">
          <Row>
            <Col lg={6}>
              <h1 className="text-capitalize">
                {activePlan?.chargeItem?.external_name ||
                  'No Active Subscription'}
              </h1>

              <p>Please Activate Your Subscription</p>
              <div
                className="prefSwitchCont"
                style={{ marginTop: 15, marginBottom: 60 }}
              >
                {(card && customer?.payment_method) || !CardUrl ? null : (
                  <a
                    href={CardUrl}
                    className="btn border p-2  border-primary text-white bg-primary mr-1 text-uppercase mt-3"
                  >
                    Add Payment Method
                  </a>
                )}
              </div>
            </Col>
          </Row>
          {}
          <section className="pricing pt-1">
            <div className="container">
              <div className="row pb-3 justify-content-center">
                <Switch
                  checkedChildren="YEARLY"
                  unCheckedChildren="MONTHLY"
                  onClick={changeCharge}
                />
              </div>
              <div className="row justify-content-center">
                {isYearly
                  ? yearlyPlans?.map((plan, index) => (
                      <PaymentCard
                        plan={plan.item_price}
                        key={index}
                        CardUrl={CardUrl}
                      />
                    ))
                  : monthlyPlans?.map((plan, index) => (
                      <PaymentCard
                        plan={plan.item_price}
                        key={index}
                        CardUrl={CardUrl}
                      />
                    ))}
              </div>
            </div>
          </section>
        </Container>
      ) : (
        <Container className="mt-5 mb-5 p-3">
          <Row>
            <Col lg={8}>
              <h3 className="text-capitalize">ACCOUNT TEMPORARILY SUSPENDED</h3>

              <p>Please Contact to Your HR Administer or Employer</p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default InActiveSubscription;
