import * as constants from './constants';
import _ from 'lodash';

let initState = {
  typesHealth: [],
  typesBreak: [],
  assignments: [],
};
export default function (state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_TYPES_HEALTH:
      return {
        ...state,
        typesHealth: payload,
      };
    case constants.CREATE_TYPE_HEALTH:
      return {
        ...state,
        typesHealth: [payload, ...state.typesHealth],
      };

    case constants.DELETE_TYPE_HEALTH:
      return {
        ...state,
        typesHealth: state.typesHealth.filter((type) => type.id !== payload),
      };

    case constants.GET_TYPES_BREAK:
      return {
        ...state,
        typesBreak: payload,
      };
    case constants.CREATE_TYPE_BREAK:
      return {
        ...state,
        typesBreak: [payload, ...state.typesBreak],
      };

    case constants.DELETE_TYPE_BREAK:
      return {
        ...state,
        typesBreak: state.typesBreak.filter((type) => type.id !== payload),
      };

    case constants.GET_USER_ASSIGNMENTS:
      return {
        ...state,
        assignments: payload,
      };

    default:
      return state;
  }
}
