import React, { useState, useEffect, memo } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Tooltip,
  Input,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizations,
  updateOrganization,
  searchOrganizations,
  deleteOrganization,
} from '../../redux/organizations/action';
import { getAllDepartments } from '../../redux/members/action';
import { getAllLocations } from '../../redux/shifts/action';
import CreateModal from './addOrganization';
import EditableCell from './editableCell';
import moment from 'moment';
const { Text } = Typography;
const { Search } = Input;
const EditableTable = () => {
  const dispatch = useDispatch();
  const { organizations, totalOrganizations, initial } = useSelector(
    (state) => state.organizationsReducer
  );
  const { isDeptHelp } = useSelector((state) => state.tourReducer);
  const { user } = useSelector((state) => state.auth);
  const { allDept } = useSelector((state) => state.membersReducer);
  const { allLocations } = useSelector((state) => state.shiftsReducer);
  const renderData = () => {
    const data = organizations.map((item) => {
      return {
        key: item?.id,
        name: item?.name,
        members: item?.members?.length,
        job_types: item?.jobTypes,
        parent: item?.parent?.name,
        location: item?.location?.name ?? 'No Location',
      };
    });
    return data;
  };
  const [form] = Form.useForm();
  const [data, setData] = useState(
    organizations.length > 0 ? renderData() : []
  );
  const [loader, setLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);
  const [locLoader, setLocLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [editingKey, setEditingKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [text, setText] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (initial) {
      setLoader(true);
      dispatch(getOrganizations(page, stopLoader));
    }
  }, []);

  useEffect(() => {
    if (allDept.length == 0) {
      setDLoader(true);
      dispatch(getAllDepartments(() => setDLoader(false)));
    }
    if (allLocations.length == 0) {
      setLocLoader(true);
      dispatch(getAllLocations(() => setLocLoader(false)));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(organizations)) {
      const data = renderData();
      setData(data);
    }
  }, [organizations]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getOrganizations(1, stopLoader));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    if (text.length > 0) {
      dispatch(searchOrganizations(text, page, stopLoader));
    } else {
      dispatch(getOrganizations(page, stopLoader));
    }
    cancel();
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      members: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const isValidId = (parent) => {
    return parent.length == '24' && parent.match(/\d+/g);
  };

  const updateApi = (key, row) => {
    const updatedObj = {
      name: row?.name,
      jobTypes: row?.job_types,
      ...(row?.location &&
        isValidId(row?.location) && { location: row?.location }),
      ...(row?.parent && isValidId(row?.parent) && { parent: row?.parent }),
    };

    setLoader(true);
    dispatch(updateOrganization(key, updatedObj, stopLoader));
  };

  const deleteOrg = (key) => {
    setLoader(true);
    dispatch(deleteOrganization(key, stopLoader));
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      updateApi(key, row);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        if (
          row?.parent &&
          row?.parent?.length == '24' &&
          row?.parent?.match(/\d+/g)
        ) {
          row.parent = allDept.filter(
            (item) => item.id == row.parent
          )?.[0]?.name;
        }

        if (
          row?.location &&
          row?.location?.length == '24' &&
          row?.location?.match(/\d+/g)
        ) {
          row.location = allLocations.filter(
            (item) => item.id == row.location
          )?.[0]?.name;
        }

        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const renderParent = (dept) => {
    if (dept) {
      return <Text>{dept}</Text>;
    } else {
      return <Text>No Parent</Text>;
    }
  };

  const handleText = (text) => {
    setText(text);
    if (text === '') {
      setLoader(true);
      dispatch(getOrganizations(1, stopLoader));
    }
  };

  const onSearch = (value) => {
    setLoader(true);
    dispatch(searchOrganizations(value, page, stopLoader));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      align: 'left',
    },

    {
      title: 'Members',
      dataIndex: 'members',
      editable: false,
      align: 'center',
    },
    {
      title: 'Job Titles',
      dataIndex: 'job_types',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Parent',
      dataIndex: 'parent',
      editable: true,
      align: 'center',
      render: renderParent,
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Update?"
              onConfirm={() => save(record.key)}
            >
              <a>Save</a>
            </Popconfirm>

            <a
              href="javascript:;"
              onClick={cancel}
              style={{
                marginLeft: 8,
              }}
            >
              Cancel
            </a>
          </span>
        ) : (
          <Space align="center" size="large">
            <Typography.Link
              disabled={editingKey !== '' || user?.organization == record.key}
              onClick={() => edit(record)}
            >
              Edit <EditOutlined />
            </Typography.Link>

            <Popconfirm
              title=" Delete Department ?"
              onConfirm={() => deleteOrg(record.key)}
            >
              <DeleteOutlined className="text-danger" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const getInputType = (dataIndex) => {
    if (dataIndex == 'job_types') {
      return 'tag';
    } else if (dataIndex == 'parent') {
      return 'select';
    } else if (dataIndex == 'location') {
      return 'location';
    } else {
      return 'text';
    }
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: getInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        allDept: allDept,
        dLoader: dLoader,
        locLoader: locLoader,
        allLocations: allLocations,
      }),
    };
  });

  return (
    <React.Fragment>
      <h3>Departments</h3>
      <Text type="secondary">{moment().format('MMMM Do YYYY')}~</Text>
      <Divider className="m-0 pb-2" />
      {Number(user.role_id) !== 2 && (
        <Tooltip
          title="Click here to add a new department"
          color={'#F89722'}
          defaultVisible={isDeptHelp}
        >
          <Button
            type="primary"
            className="bg-primary border-primary"
            icon={<PlusOutlined />}
            onClick={showModal}
          >
            Add Department
          </Button>
        </Tooltip>
      )}
      <Tooltip title="Fetch Latest Data">
        <Button className="ml-3" onClick={refreshData}>
          <ReloadOutlined />
        </Button>
      </Tooltip>

      <Card className="tmb mt-2">
        <Search
          placeholder="Search By Name"
          onSearch={onSearch}
          enterButton
          className="w-50 mb-2"
          onChange={(e) => handleText(e.target.value)}
        />

        <Form form={form} component={false}>
          <Table
            className="table-curved"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: changePage,
              current: page,
              total: totalOrganizations,
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          />
        </Form>
      </Card>
      <CreateModal
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
      />
    </React.Fragment>
  );
};

export default memo(EditableTable);
