import { INSTANCE } from '../../config/networkInstance';

export default class Api {
  getActive = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/shift/currentAllocation',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getPerformance = (type, fetchType = 'shift') => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/performance/user',
        params: {
          type,
          ...(fetchType && { fetchType }),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  };
  clockIn = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/shift/clockin',
        data,
      })
        .then(resolve)

        .catch(reject);
    });
  };

  clockOut = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/shift/clockout',
        data,
      })
        .then(resolve)

        .catch(reject);
    });
  };

  breakIn = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/shift/breakin',
        data,
      })
        .then(resolve)

        .catch(reject);
    });
  };

  breakOut = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/shift/breakout',
        data,
      })
        .then(resolve)

        .catch(reject);
    });
  };

  skipImage = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/auth/skipImage',
      })
        .then(resolve)

        .catch(reject);
    });
  };

  createTimer = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/timer',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getHealthTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/health/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createHealthTypes = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/health/types',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateHealthTypes = (data, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: '/health/types',
        data,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteHealthTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: '/health/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getCurrentWorkSchedule = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/schedular/current',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getCompanyWorkSchedule = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/shift/companySchedule',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  verifyImage = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/shift/verifyImage',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  addVerification = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/shift/addVerifyImage',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
