import React, { useState } from 'react';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import AnswersModal from './performanceAnswers';

function Index({ item, editingKey }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openAnswers = () => {
    setIsModalVisible(true);
  };
  return (
    <React.Fragment>
      <Button
        size="small"
        icon={<EyeOutlined />}
        onClick={openAnswers}
        type="primary"
        className="bg-primary border-primary"
        disabled={editingKey !== ''}
      >
        Answers
      </Button>
      <AnswersModal show={isModalVisible} setClose={handleCancel} item={item} />
    </React.Fragment>
  );
}

export default Index;
