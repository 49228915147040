import * as constants from './constants';
import _ from 'lodash';
let initState = {
  allMembers: [],
  members: [],
  allDept: [],
  allDeptByIds: {},
  totalMembers: 0,
  initial: true,
};
function membersReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_MEMBERS:
      return {
        ...state,
        members: payload.results,
        totalMembers: payload.totalResults,
        initial: false,
      };
    case constants.GET_ALL_MEMBERS:
      return {
        ...state,
        allMembers: payload.results,
        initial: false,
      };
    case constants.UPDATE_LOCATION:
      const temp = action.payload;

      const index = state.members.findIndex((item) => item.id === temp.user);
      const index2 = state.members.findIndex((item) => item.id === temp.user);
      if (index) {
        state.members[index].location = temp.location;
      }

      if (index2) {
        state.allMembers[index2].location = temp.location;
      }

      return {
        ...state,
      };
    case constants.NEW_EMPLOYEE:
      return {
        ...state,
        members: [payload, ...state.members],
      };
    case constants.ALL_DEPT:
      const deptByIds = {};
      payload.forEach((element) => {
        deptByIds[element.id] = element;
      });
      return {
        ...state,
        allDept: payload,
        allDeptByIds: deptByIds,
      };
    case constants.UPDATE_ALL_DEPT: {
      return {
        ...state,
        allDept: [payload, ...state.allDept],
        allDeptByIds: { ...state.allDeptByIds, [payload.id]: payload },
      };
    }
    case constants.DELETE_MEMBER:
      let nMembers = [...state.members];
      nMembers.splice(
        _.indexOf(nMembers, _.find(nMembers, { id: payload })),
        1
      );
      return {
        ...state,
        members: nMembers,
      };
    default:
      return state;
  }
}

export default membersReducer;
