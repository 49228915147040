import { INSTANCE } from '../../config/networkInstance';

export default class SettingsApi {
  updateCompany = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `company/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  sendComplain = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'complain',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateProfile = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/updateProfile',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  changePassword = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'auth/change-password',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  sendOtp = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/sendOtp',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  verityOtp = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/verifyOtp',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
