import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  Typography,
  Progress,
  Popconfirm,
  Button,
  Badge,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  CommentOutlined,
  EyeOutlined,
  AntDesignOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { updateTaskStatus } from '../../../redux/tasks/action';

const { Text } = Typography;

function Index({ task, employee, editTask, deleteTask }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [dloader, setDLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  let [check, setChecked] = useState(task?.status == 'completed');
  const [membersObject, setMembersObject] = useState({});

  useEffect(() => {
    setChecked(task?.status == 'completed');
  }, [task]);

  const completeTask = () => {
    setLoader(true);
    let params = { taskId: task.id };
    let status = !check == true ? 'completed' : 'pending';
    dispatch(
      updateTaskStatus(params, { status }, { ...task, status }, () => {
        setLoader(false);
        setChecked(!check);
      })
    );
  };

  const confirm = () => {
    setDLoader(true);
    deleteTask(task, () => {
      setDLoader(false);
      setVisible(false);
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const showPopUp = () => {
    setVisible(true);
  };

  const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

  const timeLeft = (endTime, startTime) => {
    var totalTime = moment(endTime).valueOf() - moment(startTime).valueOf();
    var timeTaken = moment().valueOf() - moment(startTime).valueOf();

    var test = (timeTaken / totalTime) * 100;

    if (test > 100) {
      return 100;
    }
    return test;
  };

  const goDetails = () => {
    history.push(`/app/tasks/${task.id}`);
  };

  return (
    <div className="mb-3">
      <Card
        title={
          <>
            <span className="font-weight-900">{task?.title}</span>
            <br />
            <span className="text-muted row mx-1">
              {task?.user_id.name}
              <div className="mx-2">
                {moment(task.endTime).valueOf() > moment().valueOf() &&
                  moment(task.startTime).valueOf() < moment().valueOf() && (
                    <Badge
                      style={{ backgroundColor: '#52c41a' }}
                      count={'onging'}
                    ></Badge>
                  )}
                {moment().valueOf() > moment(task.endTime).valueOf() && (
                  <Badge count={'overdue'}></Badge>
                )}
                {_.status == 'rejected' && (
                  <Badge
                    style={{ backgroundColor: '#f79425' }}
                    count={'rejected'}
                  ></Badge>
                )}
              </div>
            </span>
          </>
        }
        extra={
          <Button
            onClick={goDetails}
            type="link"
            icon={<EyeOutlined />}
            size={'small'}
          >
            View{' '}
          </Button>
        }
      >
        {task.assignTo.length > 0 && (
          <div className="row ">
            <div className="float-left">
              <Avatar.Group
                maxCount={3}
                maxPopoverTrigger="click"
                size="small"
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                  cursor: 'pointer',
                }}
              >
                {task.assignTo.map((_, key) => (
                  <Avatar
                    key={key}
                    src={employee[_]?.profilePic ? employee[_]?.profilePic : ''}
                  />
                ))}
              </Avatar.Group>
            </div>
          </div>
        )}

        <div className="row mt-1">{task?.description}</div>

        <div className="row mt-1">
          {timeLeft(task.endTime, task.startTime) < 75 ? (
            <Progress
              percent={timeLeft(task.endTime, task.startTime)}
              size="small"
              strokeColor={'#2ed8b6'}
              showInfo={false}
            />
          ) : timeLeft(task.endTime, task.startTime) > 75 &&
            timeLeft(task.endTime, task.startTime) < 100 ? (
            <Progress
              percent={timeLeft(task.endTime, task.startTime)}
              size="small"
              strokeColor={'#f79425'}
              showInfo={false}
            />
          ) : (
            <Progress percent={100} size="small" status="exception" />
          )}
        </div>

        <div className=" d-flex justify-content-between mt-3">
          <span>
            <CommentOutlined /> 4
          </span>
          {task.priority == 'high' ? (
            <Badge count={task.priority}></Badge>
          ) : task.priority == 'medium' ? (
            <Badge
              count={task.priority}
              style={{
                backgroundColor: '#f79425',
              }}
            ></Badge>
          ) : (
            <Badge
              style={{
                backgroundColor: '#52c41a',
              }}
              count={task.priority}
            ></Badge>
          )}
        </div>
      </Card>
    </div>
  );
}

export default Index;
