import * as constants from './constants';

const initialState = {
  monthlyPlans: null,
  yearlyPlans: null,
  activePlan: null,
  isActiveSub: false,
  activeSub: null,
  isPlatinumPlan: false,
  card: null,
  customer: null,
};

function chargeBeeReducer(state = initialState, action) {
  let { payload, type } = action;

  switch (type) {
    case constants.GET_PLANS: {
      const monthlyPlans = payload.filter(
        (v) =>
          v.item_price?.period_unit === 'month' &&
          v.item_price?.status === 'active' &&
          v.item_price?.item_type === 'plan'
      );
      const yearlyPlans = payload.filter(
        (v) =>
          v.item_price?.period_unit === 'year' &&
          v.item_price?.status === 'active' &&
          v.item_price?.item_type === 'plan'
      );

      return {
        ...state,
        monthlyPlans,
        yearlyPlans,
      };
    }

    case constants.GET_ACTIVE_PLANE: {
      const planId =
        payload?.subscription?.subscription_items?.[0]?.item_price_id;
      const platinum =
        ['Platinum-Plan-USD-Monthly', 'Platinum-Plan-USD-Yearly'].includes(
          planId
        ) && payload?.subscription?.status !== 'in_trial';

      return {
        ...state,
        activePlan: payload,
        isPlatinumPlan: platinum,
      };
    }

    case constants.GET_SUB_STATUS: {
      if (payload === 'active') {
        return {
          ...state,
          isActiveSub: true,
          isPlatinumPlan: false,
        };
      }
      const status = payload?.subscription?.status;
      const isActive = ['in_trial', 'active'].includes(status);
      const activePlanId =
        payload?.subscription?.subscription_items?.[0]?.item_price_id;

      const isPlatinum =
        ['Platinum-Plan-USD-Monthly', 'Platinum-Plan-USD-Yearly'].includes(
          activePlanId
        ) && status !== 'in_trial';

      return {
        ...state,
        isActiveSub: isActive,
        activeSub: payload,
        isPlatinumPlan: isPlatinum,
        customer: payload?.customer,
        card: payload?.card ? payload?.card : null,
      };
    }

    case constants.CANCEL_SUB:
      const newActivePlan = { ...state.activePlan, ...payload };
      return {
        ...state,
        activePlan: newActivePlan,
      };

    case constants.CHARGEBEE_CUSTOMER: {
      return {
        ...state,
        customer: payload?.customer,
        card: payload?.card ? payload?.card : null,
      };
    }

    default:
      return state;
  }
}

export default chargeBeeReducer;
