import React, { useState, memo } from 'react';
import { Space, Typography, Popover } from 'antd';
import moment from 'moment';
import Content from './content';
import ShiftDetails from './shiftDetails';
import { styles } from './style';

function Index({ item, index, fetchUpdates }) {
  const { Text } = Typography;
  const [visible, setVisible] = useState(false);
  const closeBar = () => {
    setVisible(false);
  };

  const renderBorderClass = (status) => {
    switch (status) {
      case 'allocation':
        return 'shiftCellAllocation';
      case 'going':
        return 'shiftCellGoing';
      case 'break':
        return 'shiftCellBreak';
      default:
        return 'shiftCellBorder';
    }
  };

  const renderName = () => {
    if (item?.type == 'shift') {
      return item?.shift?.name;
    } else if (item?.type == 'schedule') {
      if (item?.schedule?.name === 'default') {
        return 'Regular Shift';
      }
      return item?.schedule?.name;
    } else {
      return 'Shift';
    }
  };

  const renderTime = () => {
    if (item?.type == 'shift') {
      return `${moment(item?.shift?.start).format('HH:mm')} -
      ${moment(item?.shift?.end).format('HH:mm')}`;
    } else if (item?.type == 'schedule') {
      return `${item?.schedule?.start} - ${item?.schedule?.end}`;
    } else {
      if (item?.clockout) {
        return `${moment(item?.clockin).format('HH:mm')}-${moment(
          item?.clockout
        ).format('HH:mm')}`;
      }
      return `${moment(item?.updatedAt).format('HH:mm')}`;
    }
  };

  const borderClass = renderBorderClass(item?.status);
  return (
    <>
      <Popover
        content={() => <Content item={item} />}
        title={renderName()}
        onClick={() => setVisible(true)}
        className={`c-pointer ${borderClass}`}
      >
        <Space
          direction="vertical"
          className="rounded p-2 shiftItemCell"
          align="start"
          style={{
            ...styles.scheduleDiv,
            marginTop: index !== 0 ? 10 : 0,
          }}
        >
          <Text
            strong
            className="text-light d-block shiftCellText"
            style={{ fontSize: 12, color: 'rgba(3, 5, 15, 0.5)' }}
          >
            {renderName()?.length > 24
              ? renderName().slice(0, 24) + '...'
              : renderName()}
          </Text>

          <Space>
            <Text
              strong
              className="text-light shiftCellText"
              style={{ fontSize: 11, color: 'rgba(3, 5, 15, 0.5)' }}
            >
              {renderTime()}
            </Text>
          </Space>
        </Space>
      </Popover>
      <ShiftDetails
        visible={visible}
        onClose={closeBar}
        item={item}
        fetchUpdates={fetchUpdates}
      />
    </>
  );
}

export default memo(Index);
