import React, { memo } from 'react';
import { Input, Form, Select, Tag } from 'antd';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  getJobTypes,
  allDept,
  jobTypes,
  dLoader,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' ? (
      dataIndex === 'organization' ? (
        <Select
          className="w-100"
          onChange={getJobTypes}
          loading={dLoader}
          disabled={dLoader}
        >
          {allDept?.map((item, i) => {
            return (
              <Option key={i} value={item.name}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ) : dataIndex === 'jobType' ? (
        <Select className="w-100">
          {jobTypes?.map((item, i) => {
            return (
              <Option key={i} value={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      ) : dataIndex === 'active' ? (
        <Select className="w-100">
          <Option value={true}>Active</Option>
          <Option value={false}>Disabled</Option>
        </Select>
      ) : dataIndex === 'options' ? (
        <Select mode="tags" placeholder="Input Options">
          {record?.options?.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : (
        <Select className="w-100">
          <Option value={'in'}>Clock In</Option>
          <Option value={'out'}>Clock Out</Option>
        </Select>
      )
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <>
          {dataIndex === 'options' && record?.type !== 'options' ? (
            children
          ) : (
            <Form.Item
              name={dataIndex}
              className="m-0 p-0"
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          )}
        </>
      ) : dataIndex === 'status' ? (
        <Tag color="success">{children}</Tag>
      ) : (
        children
      )}
    </td>
  );
};

export default memo(EditableCell);
