import { INSTANCE } from '../../config/networkInstance';

export default class Api {
  getAllType = (company) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/leave/types/getAll/' + company,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createTypes = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/leave/types/add',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateType = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/leave/types/update',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  removeType = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: `/leave/types/remove?id=${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  addLeaveRequest = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/leave/request/add',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllRequest = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/leave/request/getAll/',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllUserRequest = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: `/leave/request/getAllUser?id=${id}`,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  approveRequest = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: `/leave/request/approve/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  rejectRequest = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: `/leave/request/reject/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
