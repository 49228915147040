import React, { memo, useState } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { Divider, Input, Form, Typography, message } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { sendComplainAction } from '../../redux/settings/action';

const Settings = ({ next }) => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      message.success('Successfully Submitted Request!');
      form.resetFields();
    }
  };

  const sendComplain = (values) => {
    setLoader(true);
    dispatch(sendComplainAction(values, stopLoader));
  };

  return (
    <>
      <div className="bg-white p-3">
        <h4 className="pb-0 mb-1">Contact Us</h4>
        <Text type="secondary">
          Couldn&apos;t find what you wanted? You can contact our support team
          by logging your query here
        </Text>
        <Row>
          <Col md={12} lg={12}>
            <Form
              layout="vertical"
              className="pt-4"
              form={form}
              onFinish={sendComplain}
            >
              <Row>
                <Col md={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Your full name" />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Your Email here" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Subject!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Subject here" />
              </Form.Item>
              <Form.Item
                label="Your Comment"
                name="comment"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Comment!',
                  },
                ]}
              >
                <TextArea
                  size="large"
                  placeholder="Enter your Comment here"
                  autoSize={{ minRows: 5, maxRows: 7 }}
                />
              </Form.Item>
              <button
                className="btn btn-block btn-primary mb-0 mt-4 py-2"
                htmlType="submit"
              >
                {loader ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  'Send Your Message'
                )}
              </button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(Settings);
