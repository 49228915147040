import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Drawer,
  Image,
  Card,
  Timeline,
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  AutoComplete,
  Divider,
  List,
  message,
  Avatar,
  Skeleton,
  Space,
  Tooltip,
  Empty,
  Progress,
  Checkbox,
  Table,
  Statistic,
} from 'antd';
import {
  UserSwitchOutlined,
  SyncOutlined,
  CloudDownloadOutlined,
  EyeOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Row, Col, Spinner, Table as TableRB } from 'react-bootstrap';
import socketHandler from '../../config/socket';
import CreateModal from './addTask';
import MyTask from './myTasks';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { getOneTask } from '../../redux/tasks/action';
import { createTask, updateTasks, deleteTasks } from '../../redux/tasks/action';
import { getAllLocations } from '../../redux/shifts/action';
import { getAllDepartments } from '../../redux/members/action';
import ActivityFilter from '../timeSheets/activityFilter';
import MapAddress from '../../components/mapAddress';
import { useHistory } from 'react-router-dom';

import DepartmentFilter from '../../components/departmentFilter';

import { styles } from './style';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const { Link, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function Index() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const { allLocations } = useSelector((state) => state.shiftsReducer);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { initial, singleTask } = useSelector((state) => state.tasks);
  const [form] = Form.useForm();

  const [loader, setLoader] = useState(false);
  const [activity, setActivity] = useState(null);
  const [locLoader, setLocLoader] = useState(false);
  const [item, setItem] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [oLoader, setOLoader] = useState(false);
  const [_location, _setLocation] = useState('');
  const [dept, setDept] = useState(false);
  const [approvall, settApprovall] = useState(false);
  const [editable, setEditable] = useState(false);
  const [selectNo, setSelectNo] = useState(0);
  const [selectedTime, setSelectedTime] = useState({});
  const [showAns, setShowAns] = useState(null);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const locRef = useRef(null);
  const { allDept } = useSelector((state) => state.membersReducer);
  useEffect(() => {
    if (allDept.length === 0) {
      setLocLoader(true);
      dispatch(getAllDepartments(() => setLocLoader(false)));
    }
  }, []);

  var groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    setLocLoader(true);
    dispatch(getOneTask({ id }, () => setLocLoader(false)));
  }, []);

  useEffect(() => {
    if (allLocations.length == 0) {
      setLocLoader(true);
      dispatch(getAllLocations(() => setLocLoader(false)));
    }
  }, []);

  const stopLoader = (success, _data = null) => {
    setLoader(false);
    if (success) {
      message.success('Successfully Updated!');

      dispatch(
        getOneTask({ id }, () => {
          form.setFieldsValue({
            title: _data?.title,
            startTime: moment(_data?.startTime),
            endTime: moment(_data?.endTime),
            description: _data?.description,
            priority: _data?.priority,
            project: activity,

            user_id: _data?.user_id?.id,
          });
        })
      );
    }
  };

  const [componentDisabled, setComponentDisabled] = useState(true);

  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  const createTaskFunc = (values) => {
    setLoader(true);
    let params = { taskId: singleTask.id };
    const tt = allLocations.find((o) => o.name === values.location)?.id;
    if (currentUser?.role == 'user') {
      let obj = {
        ...values,
        organization: currentUser?.organization?.id,
        company: currentUser?.company_id?.id,
      };
      const tt = allLocations.find((o) => o.name === obj.location)?.id;

      if (tt) {
        obj.location = tt;
      }

      if (values.project) {
        temp.project;
      }

      dispatch(updateTasks(params, obj, stopLoader));
      return;
    }
    var user_id = '';
    if (values.user_id && values.user_id == 'none') {
      user_id = values.assignTo[0];
    } else if (values.user_id) {
      user_id = values.user_id;
    } else {
      user_id = values.assignTo[0];
    }

    var temp = {
      title: values.title,
      description: values.description,
      startTime: values.startTime,
      endTime: values.endTime,
      assignTo: values.assignTo,
      user_id: user_id,
      priority: values.priority,
      isVerifiable: approvall,
    };

    if (tt) {
      temp.location = tt;
    }

    if (values.project) {
      temp.project;
    }

    dispatch(updateTasks(params, temp, stopLoader));

    return;
  };

  const onChangeActivity = (value) => {
    setActivity(value);
  };

  const find = (location) => {
    return allLocations.find((o) => o.name === __test).id;
  };

  const findUser = (id) => {
    var emp = allEmployees.find((o) => o.id === id);
    if (emp) {
      return emp.name;
    } else {
      return '--';
    }
  };
  /**
   * this is when task type is changed
   * @param {event} e
   */
  function onChange(e) {
    setDept(e.target.checked);
  }

  function onChangeApproval(e) {
    settApprovall(e.target.checked);
  }

  function handleChange(value) {}

  const deleteTask = (task, cb) => {
    dispatch(
      deleteTasks(task, () => {
        setTasks([...tasks].filter((obj) => obj.id !== task.id));
        cb();
      })
    );
  };

  const handleClose = () => {
    setItem(null);
    setClose();
    form?.resetFields();
  };
  function Func() {
    var input, filter, a;

    // take user input
    input = document.getElementById('userInput');

    // convert the input to upper case letter for easy comparison
    filter = input.value.toUpperCase();

    div = document.getElementById('Dropdown');

    // get all the options as a list
    a = div.getElementsByTagName('option');

    // iterate through the entire list and output relevant results if found
    for (var i = 0; i < a.length; i++) {
      txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  }

  const setFields = () => {
    form.setFieldsValue({
      title: singleTask?.title,
      startTime: moment(singleTask?.startTime),
      endTime: moment(singleTask?.endTime),
      description: singleTask?.description,
      priority: singleTask?.priority,
      project: singleTask?.project,
      user_id: singleTask?.user_id?.id,
    });
  };

  setFields();

  const isDisabled = () => {
    if (singleTask?.createdBy) {
      if (currentUser.id == singleTask?.createdBy) {
        return true;
      }
      return false;
    } else {
      if (currentUser.id == singleTask?.user_id?.id) {
        return true;
      }
      return false;
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={8}>
          <div className="row  mx-1 mb-3">
            <Space className="mt-3 float-right mx-1">
              <button
                onClick={() => {
                  history.goBack();
                }}
                disabled={loader}
                className="btn btn-secondary px-3"
                htmlType="button"
              >
                Back
              </button>
            </Space>
          </div>
          <Row className="mx-1" md={12}>
            <Card style={{ width: '100%' }}>
              <Row>
                <Col md={12}>
                  <Form layout="vertical" form={form} onFinish={createTaskFunc}>
                    <Row>
                      <Col md={12}>
                        <Form.Item
                          name="title"
                          label="Task Title"
                          required
                          style={{ width: '100%' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please Input Title',
                            },
                          ]}
                        >
                          <Input
                            value={singleTask?.title}
                            disabled={!isDisabled()}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <Form.Item
                          name="description"
                          label="Task Description"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please Input Title',
                            },
                          ]}
                        >
                          <TextArea
                            size="large"
                            disabled={!isDisabled()}
                            placeholder="Description of the task"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="startTime"
                          label="Start Date"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please Input Start Date',
                            },
                          ]}
                          disabled={!editable}
                        >
                          <DatePicker
                            size="large"
                            format="DD-MM-YYYY HH:mm"
                            placeholder="Task Start Time"
                            className="w-100"
                            showTime={{
                              defaultValue: moment('00:00', 'HH:mm'),
                            }}
                            disabled={!isDisabled()}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="endTime"
                          label="End Date"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please Input End Date',
                            },
                          ]}
                        >
                          <DatePicker
                            size="large"
                            format="DD-MM-YYYY HH:mm"
                            placeholder="Task End Time"
                            className="w-100"
                            showTime={{
                              defaultValue: moment('00:00', 'HH:mm'),
                            }}
                            disabled={!isDisabled()}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="priority"
                          label="Priority"
                          defaultValue="medium"
                        >
                          <Select
                            size="large"
                            className="w-100"
                            disabled={!isDisabled()}
                          >
                            <Option value="high">High</Option>
                            <Option value="medium">Medium</Option>
                            <Option value="low">Low</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="location"
                          label="Location (optional)"
                          required={false}
                        >
                          <AutoComplete
                            size="large"
                            disabled={!isDisabled()}
                            options={allLocations.map((item) => {
                              return { value: item.name, label: item.name };
                            })}
                            filterOption={(inputValue, option) => {
                              return (
                                option.label
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              );
                            }}
                            placeholder="input here"
                          ></AutoComplete>
                        </Form.Item>
                      </Col>

                      {currentUser?.role !== 'user' && (
                        <Col md={12}>
                          <Form.Item
                            name="assignTo"
                            label="Assign To"
                            rules={[
                              {
                                required: true,
                                message: 'Please Select User',
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select User to be assigned"
                              size="large"
                              className="w-100"
                              mode="tags"
                              defaultValue={
                                singleTask?.assignTo ? singleTask?.assignTo : []
                              }
                              disabled={!isDisabled()}
                              onChange={(value) => {
                                setSelectNo(value.length);
                              }}
                            >
                              {[{ ...currentUser }, ...allEmployees]?.map(
                                (item, i) => {
                                  return (
                                    <Option key={i} value={item?.id}>
                                      {item?.name == currentUser?.name
                                        ? 'Me'
                                        : item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}

                      <Col md={12}>
                        <Form.Item
                          name="user_id"
                          label="Task Lead"
                          disabled={!isDisabled()}
                        >
                          <Select
                            placeholder="Select User to be assigned"
                            size="large"
                            className="w-100"
                            disabled={!isDisabled()}
                            onChange={(value) => {}}
                          >
                            <Option value="none">None</Option>
                            {[{ ...currentUser }, ...allEmployees]?.map(
                              (item, i) => {
                                return (
                                  <Option key={i} value={item?.id}>
                                    {item?.name == currentUser?.name
                                      ? 'Me'
                                      : item?.name}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      {isDisabled() && (
                        <Col md={12}>
                          <Form.Item
                            name="project"
                            label="Project (optional)"
                            required={false}
                          >
                            <ActivityFilter
                              pickerStyle={{
                                width: '100%',
                              }}
                              onChange={onChangeActivity}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col md={12}>
                        {isDisabled() && (
                          <Space className="mt-3 float-right">
                            <button
                              disabled={loader}
                              className="btn btn-primary"
                              htmlType="submit"
                            >
                              {loader ? (
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  variant="light"
                                />
                              ) : (
                                'Update'
                              )}
                            </button>
                          </Space>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Row>

          {/* <Row className="mt-3 mx-2">
            <Col md={12}>
              <List header={<h6>Sub Tasks</h6>}>
                <List.Item>
                  <List.Item.Meta
                    title={<a href="https://ant.design">Task Name </a>}
                    description={
                      <>
                        <span className="font-weight-800 small">
                          {' '}
                          Assigned to :{' '}
                        </span>
                        <span className="font-weight-bold small">
                          {' '}
                          James Camron
                        </span>{' '}
                        <br />
                        <span className="font-weight-800 small">
                          {' '}
                          Status {'  '}:{' '}
                        </span>
                        <span className="text-danger small"> ongoing </span>{' '}
                      </>
                    }
                  />
                  <div>
                    <Tooltip title="Assign User">
                      <Button
                        type="default"
                        shape="circle"
                        icon={<UserSwitchOutlined />}
                      />
                    </Tooltip>
                    <Tooltip title="Change Status">
                      <Button
                        type="default"
                        className="mx-1"
                        shape="circle"
                        icon={<SyncOutlined />}
                      />
                    </Tooltip>
                    <Tooltip title="Delete Subtask">
                      <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </div>
                </List.Item>
              </List>
            </Col>
          </Row> */}
        </Col>
        <Col md={4}>
          <List>
            {singleTask?.times?.map((time, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={
                    <span>
                      {time?.user ? time.user.name : singleTask?.user_id?.name}
                    </span>
                  }
                  description={
                    <>
                      <span className="font-weight-800 small">
                        {' '}
                        Start Date :{' '}
                      </span>
                      <span className="text-success small">
                        {moment(time?.in).format('MMM DD YYYY h:mm a')}
                      </span>{' '}
                      <br />
                      <span className="font-weight-800 small">
                        {' '}
                        End Date {'  '}:
                      </span>{' '}
                      {time?.out ? (
                        <span className="text-danger small">
                          {moment(time?.out).format('MMM DD YYYY h:mm a')}
                        </span>
                      ) : (
                        <>--</>
                      )}{' '}
                    </>
                  }
                />
                <div>
                  <Tooltip title="View">
                    <Button
                      type="default"
                      onClick={() => {
                        showDrawer();
                        setSelectedTime(time);
                      }}
                      shape="circle"
                      icon={<EyeOutlined />}
                    />
                  </Tooltip>
                </div>
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>

      <Drawer
        title="View Time"
        width={340}
        closable={true}
        zIndex={4234234234234243}
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <div className="w-100">
          <Text strong>Time </Text>
          <Timeline className="mt-3">
            <Timeline.Item color="green">
              <div>
                <div className="row mx-2">
                  <Text className=" mr-auto">Start Time</Text>
                  {(selectedTime?.locationIn &&
                    selectedTime?.locationIn?.latitude != 0 &&
                    selectedTime?.locationIn?.longitude != 0) ||
                  (selectedTime?.locationOut &&
                    selectedTime?.locationOut?.latitude != 0 &&
                    selectedTime?.locationOut?.longitude != 0) ? (
                    <Button type="warning" className="px-2 " shape="circle">
                      <EnvironmentOutlined />
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>

                <Text type="secondary d-block" style={styles.date}>
                  {moment(selectedTime?.in).format('DD-MM-YYYY')}{' '}
                  {moment(selectedTime?.in).format('HH:mm:ss')}
                </Text>

                {selectedTime?.locationIn &&
                  selectedTime?.locationOut?.latitude != 0 &&
                  selectedTime?.locationOut?.longitude != 0 && (
                    <MapAddress
                      latitude={selectedTime?.locationIn?.latitude}
                      longitude={selectedTime?.locationIn?.longitude}
                      // error={!item?.location}
                    />
                  )}
              </div>
            </Timeline.Item>

            {selectedTime?.out && (
              <Timeline.Item color="red">
                <div>
                  <Text className="d-block">End Time</Text>
                  <Text type="secondary d-block" style={styles.date}>
                    {moment(selectedTime?.out).format('DD-MM-YYYY')} -{' '}
                    {moment(selectedTime?.out).format('HH:mm:ss')}
                  </Text>

                  {selectedTime?.locationOut &&
                    selectedTime?.locationOut?.latitude != 0 &&
                    selectedTime?.locationOut?.longitude != 0 && (
                      <MapAddress
                        latitude={selectedTime?.locationOut?.latitude}
                        longitude={selectedTime?.locationOut?.longitude}
                        // error={!item?.location}
                      />
                    )}
                </div>
              </Timeline.Item>
            )}
          </Timeline>
        </div>

        <div className="w-100">
          <div className="mb-5">
            <Text strong>Performance Questions on Start Task </Text>
          </div>
          {selectedTime?.answersIn?.map((ans, key) => (
            <div className="mt-3" key={key}>
              <Timeline>
                <Timeline.Item color="green">
                  <Text className="pb-1 d-block">
                    <div className="row mx-2">
                      <Text
                        className="mr-auto"
                        onClick={() => {
                          if (key === showAns) {
                            setShowAns(null);
                          } else {
                            setShowAns(key);
                          }
                        }}
                      >
                        {ans?.question?.question}
                      </Text>
                    </div>
                  </Text>
                  {showAns === key && (
                    <>
                      {ans?.question?.type === 'signature' ? (
                        <div
                          style={{
                            padding: 5,
                            border: '1px solid #F0F0F0',
                            marginRight: 15,
                          }}
                        >
                          <Image src={ans?.answer} />
                        </div>
                      ) : ans?.question?.type === 'attachment' ? (
                        <div>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            download
                            href={ans?.answer}
                          >
                            <div
                              style={{
                                padding: 5,
                                border: '1px solid #F0F0F0',
                                marginRight: 15,
                              }}
                            >
                              <CloudDownloadOutlined
                                style={{
                                  fontSize: 25,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              />
                            </div>
                          </a>
                        </div>
                      ) : (
                        <Text strong>{ans?.answer}</Text>
                      )}
                    </>
                  )}
                </Timeline.Item>
              </Timeline>
            </div>
          ))}
        </div>

        <div className="w-100">
          <div className="mb-5">
            <Text strong>Performance Questions on Stop Task</Text>
          </div>
          {selectedTime?.answersOut?.map((ans, key) => (
            <div className="mt-3" key={key}>
              <Timeline>
                <Timeline.Item color="green">
                  <Text className="pb-1 d-block">
                    <div className="row mx-2">
                      <Text
                        className="mr-auto"
                        onClick={() => {
                          if (key === showAns) {
                            setShowAns(null);
                          } else {
                            setShowAns(key);
                          }
                        }}
                      >
                        {ans?.question?.question}
                      </Text>
                    </div>
                  </Text>
                  {showAns === key && (
                    <>
                      {ans?.question?.type === 'signature' ? (
                        <div
                          style={{
                            padding: 5,
                            border: '1px solid #F0F0F0',
                            marginRight: 15,
                          }}
                        >
                          <Image src={ans?.answer} />
                        </div>
                      ) : ans?.question?.type === 'attachment' ? (
                        <div>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            download
                            href={ans?.answer}
                          >
                            <div
                              style={{
                                padding: 5,
                                border: '1px solid #F0F0F0',
                                marginRight: 15,
                              }}
                            >
                              <CloudDownloadOutlined
                                style={{
                                  fontSize: 25,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              />
                            </div>
                          </a>
                        </div>
                      ) : (
                        <Text strong>{ans?.answer}</Text>
                      )}
                    </>
                  )}
                </Timeline.Item>
              </Timeline>
            </div>
          ))}
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default Index;
