import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AuthLayout from '../layouts/authlayout';
import { useDispatch } from 'react-redux';

import { Typography, Image, Form, Input } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { styles } from './style';
import Login from '../../assets/images/illustrations/otp.png';
import Logo from '../../assets/images/logos/logo1.png';
import {
  login,
  logoutUser,
  resendEmail,
  ValidateOTP,
} from '../../redux/auth/action';

let regexOtp = /\b\d{5}\b/g;
const { Text } = Typography;

function Index({ history }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [resend, setResend] = useState(false);

  const stopLoader = (success, user = null) => {
    setLoader(false);
    $crisp.push(['do', 'session:reset']);
    if (success) {
      if (
        user &&
        user?.isEmailVerified &&
        user?.active &&
        !user?.company_id?._id
      ) {
        history.push('/auth/steps', { email: user?.email });
      } else if (user.role == 'user') {
        history.push('/schedules/shifts');
      } else {
        history.push('/app/dashboard/analytics');
      }
      //if is root
      //if worker
    }
  };

  const logout = () => {
    setLoader(true);
    dispatch(
      logoutUser(() => {
        setLoader(false);
        $crisp.push(['do', 'session:reset']);
        history.push('/auth/signin');
      })
    );
  };

  const resendCode = () => {
    setLoader(true);
    setResend(true);
    dispatch(
      resendEmail(() => {
        setLoader(false);
        setTimeout(function () {
          setResend(false);
        }, 30000);
      })
    );
  };

  const loginUser = (values) => {
    let { type } = values;
    if (!type?.match(regexOtp)) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
    setLoader(true);
    dispatch(ValidateOTP(values.type, stopLoader));
  };

  const goRegister = () => {
    history.push('/auth/signup');
  };

  const handleSocialLogin = (user) => {
    const values = {
      email: user?._profile?.email,
      password: user?._profile?.id,
      isSocialAuth: true,
    };
    loginUser(values);
  };
  const handleSocialLoginFailure = () => {};

  return (
    <AuthLayout
      left={
        <div>
          <Image
            src={Login}
            preview={false}
            className="text-center img-responsive mt-auto mb-auto"
          />
        </div>
      }
      right={
        <>
          <Row className="mb-5 ">
            <Image
              src={Logo}
              preview={false}
              height={65}
              className="text-left img-responsive "
            />
          </Row>

          <Row className="w-100 justify-content-center">
            <Col lg={7} xl={8} xxl={9} sm={12}>
              <Form form={form} layout="vertical" onFinish={loginUser}>
                <h2 className="font-weight-bold text-primary text-center">
                  Authentication Code{' '}
                </h2>
                <Text className="d-block  " style={styles.desc}>
                  Enter to access code activate account
                </Text>

                <Row className="mt-4">
                  <Col sm={12}>
                    <Form.Item
                      name="type"
                      required={false}
                      className="mb-3  font-weight-bold"
                      label=""
                      rules={[
                        { required: true, message: 'Please enter code' },
                        {
                          min: 5,
                          max: 5,
                          message: 'Code must be  5 characters.',
                        },
                      ]}
                    >
                      <Input
                        type={'text'}
                        maxLength={5}
                        size="large"
                        placeholder="Enter 5 digit code "
                        style={styles.input}
                        suffix={<LockOutlined style={{ color: '#C0C0C0' }} />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  {invalid && (
                    <Text className="text-danger text-center">
                      Code should be Numerical
                    </Text>
                  )}
                </Row>

                <Form.Item className="pb-1 mb-0 w-100">
                  <button
                    className="btn btn-secondary mt-4"
                    style={styles.loginButton}
                    htmlType="submit"
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      'Verify'
                    )}
                  </button>
                </Form.Item>
                {resend ? (
                  <Text className=" text-center">
                    OTP has been sent please check phone/email{' '}
                  </Text>
                ) : (
                  <Text className=" text-center">
                    Didn’t recieve the Code ?{' '}
                    <Text
                      strong
                      className="text-primary c-pointer"
                      onClick={resendCode}
                    >
                      Resend
                    </Text>
                  </Text>
                )}
                <Row className="mt-4">
                  <Text className=" mx-auto">
                    Verify code later ?{' '}
                    <Text
                      strong
                      className="text-primary c-pointer"
                      onClick={logout}
                    >
                      Logout
                    </Text>
                  </Text>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      }
    />
  );
}

export default Index;
