import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { styles } from './style';

function TotalTimes({ totalWorked, totalBreaks, totalOvertime }) {
  const getHrs = (min) => {
    if (min) {
      return (min / 60).toFixed(2);
    }

    return 0;
  };

  return (
    <Row>
      {/* total time */}
      <div style={{ ...styles.totalCont, border: '2px solid #34B53A' }}>
        <p style={{ ...styles.totalValue, color: '#34B53A' }}>
          {getHrs(totalWorked)}
        </p>
        <p style={{ ...styles.valueType, color: '#34B53A' }}>Total hours</p>
      </div>
      <div style={{ ...styles.totalCont, border: '2px solid #F89722' }}>
        <p style={{ ...styles.totalValue, color: '#F89722' }}>
          {getHrs(totalBreaks)}
        </p>
        <p style={{ ...styles.valueType, color: '#F89722' }}>
          Total Break hours
        </p>
      </div>
      <div style={{ ...styles.totalCont, border: '2px solid #FF3A29' }}>
        <p style={{ ...styles.totalValue, color: '#FF3A29' }}>
          {getHrs(totalOvertime)}
        </p>
        <p style={{ ...styles.valueType, color: '#FF3A29' }}>Total overtime</p>
      </div>
    </Row>
  );
}

export default TotalTimes;
