import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Popconfirm, Button } from 'antd';
import { Spinner } from 'react-bootstrap';
import { deleteShift } from '../../redux/shifts/action';

const Content = ({ item }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const deleteShiftFunc = () => {
    setLoader(true);
    dispatch(deleteShift(item.id, () => setLoader(false)));
  };

  const disabled =
    item?.status !== 'allocation' ||
    user?.role == 'user' ||
    item?.user?.id === user?.id;

  const renderStatus = (status) => {
    switch (status) {
      case 'allocation':
        return 'allocated';
      case 'going':
        return 'On Going';
      case 'break':
        return 'On Break';
      default:
        return status;
    }
  };

  return (
    <div>
      <List>
        <List.Item bordered>
          <span>Status: </span>
          <span style={{ textTransform: 'capitalize' }}>
            {renderStatus(item?.status)}
          </span>
        </List.Item>
        <List.Item bordered>
          <Popconfirm
            title="Are you sure to remove it?"
            onConfirm={deleteShiftFunc}
            disabled={disabled}
          >
            <Button className="w-100 btn" disabled={disabled}>
              {loader ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : (
                'Remove'
              )}
            </Button>
          </Popconfirm>
        </List.Item>
      </List>
    </div>
  );
};

export default Content;
