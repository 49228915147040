import React, { useState, useEffect, Fragment } from 'react';
import { Drawer, Image, Typography, Divider, Timeline } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import MapAddress from '../../components/mapAddress';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { styles } from './style';
import { getAllocationAnswers } from '../../redux/performanceQ/action';
import { getAllocationSOS } from '../../redux/activities/action';
const defaultImage =
  'https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg';

const Index = ({ visible, onClose, item, mode }) => {
  const { Text } = Typography;
  const [PAns, setPAns] = useState(null);
  const [sos, setSOS] = useState(null);

  const dispatch = useDispatch();

  const img =
    mode === 'clockout'
      ? item?.allocation?.clockoutImage
      : item?.allocation?.clockinImage;

  const coords =
    mode === 'clockout'
      ? item?.allocation?.clockoutCoord
      : item?.allocation?.clockinCoord;

  const activityTime = item?.updatedAt;

  useEffect(() => {
    if (visible) {
      dispatch(getAllocationAnswers(item.allocation?.id, getPQ));
      dispatch(getAllocationSOS(item?.allocation?.id, getSOS));
    }
  }, [visible]);

  const getPQ = (PQ) => {
    const selectedQ = PQ?.filter((item) => {
      if (mode === 'clockout') {
        return item?.question?.questionType === 'out';
      } else {
        return item?.question?.questionType === 'in';
      }
    });

    setPAns(selectedQ);
  };

  const getSOS = (data) => {
    setSOS(data);
  };

  const imageUrl = (url) => {
    const getImg = url.split(/[#?]/)[0].split('.').pop().trim();
    if (['png', 'jpg'].includes(getImg)) {
      return true;
    }
    return false;
  };

  let isFailed =
    mode == 'clockout'
      ? !item?.allocation?.facialOut
      : mode == 'clockin'
      ? !item?.allocation?.facialIn
      : false;

  let isFailedGeo =
    mode == 'clockout'
      ? !item?.allocation?.geoFenceOut
      : mode == 'clockin'
      ? !item?.allocation?.geoFenceIn
      : false;

  const renderType = (type) => {
    switch (type) {
      case 'clockin':
        return 'Clocked In';
      case 'clockout':
        return 'Clocked Out';
      case 'breakin':
        return 'Break In';
      case 'breakout':
        return 'Break Out';
    }
  };

  return (
    <Drawer
      title={`${item?.user?.name}`}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={340}
      zIndex={4234234234234243}
      bodyStyle={styles.sideBar}
    >
      <div className="position-relative">
        <Image
          width={280}
          src={img || (item?.user?.profilePic ?? defaultImage)}
          placeholder={
            <Image
              preview={false}
              src={`${
                img || (item?.user?.profilePic ?? defaultImage)
              }?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
              width={280}
            />
          }
        />
        {isFailed ? (
          <Text strong style={styles.failedText}>
            Facial Recognition Failed
          </Text>
        ) : (
          <Text strong style={styles.nameText}>
            {`${item?.user?.name}`}
          </Text>
        )}
      </div>

      <>
        <Divider />
        <MapAddress
          latitude={coords?.latitude}
          longitude={coords?.longitude}
          error={isFailedGeo}
        />
      </>

      <Divider />
      <div className="w-100">
        <Text strong>Activity</Text>
        <div>
          <div className="d-flex flex-row justify-content-between">
            <Text className="d-block pt-2">{renderType(item?.type)}</Text>
            <Text type="secondary">
              {moment(activityTime).format('DD-MM-YYYY HH:mm:ss')}
            </Text>
          </div>

          {item?.type == 'breakin' && (
            <div className="d-flex flex-row justify-content-between">
              <Text className="d-block pt-2">Reason</Text>
              <Text type="secondary">{item?.reason}</Text>
            </div>
          )}
        </div>
      </div>
      {PAns?.length > 0 && (
        <Fragment>
          <Divider />
          <div className="w-100">
            <Text strong>Performance</Text>
            <Timeline mode={'left'} className="mt-4">
              {PAns?.map((PQ, i) => {
                if (PQ?.question?.type === 'signature') {
                  return (
                    <Timeline.Item key={i}>
                      <div>
                        <Text className="d-block">
                          {PQ?.question?.question}
                        </Text>
                        <div style={styles.signImgCont}>
                          <Image src={PQ?.answer} />
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                }
                if (PQ?.question?.type === 'attachment') {
                  const isImage = imageUrl(PQ?.answer);
                  return (
                    <Timeline.Item key={i}>
                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          download
                          href={PQ?.answer}
                        >
                          <div style={styles.downloadCont}>
                            <Text className="d-block">
                              {PQ?.question?.question}
                            </Text>
                            <CloudDownloadOutlined
                              style={styles.downloadIcon}
                            />
                          </div>
                        </a>

                        {isImage && (
                          <div style={styles.signImgCont}>
                            <Image src={PQ?.answer} />
                          </div>
                        )}
                      </div>
                    </Timeline.Item>
                  );
                }
                return (
                  <Timeline.Item key={i}>
                    <div>
                      <Text className="d-block">{PQ?.question.question}</Text>
                      <Text type="secondary d-block">{PQ?.answer}</Text>
                    </div>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
        </Fragment>
      )}

      <Divider />

      {sos && sos.length > 0 && (
        <div className="pt-1">
          <Text strong className="text-danger">
            Health and Safety
          </Text>
          {sos?.map((item, index) => {
            return (
              <Fragment key={index}>
                {item?.file && (
                  <div className="position-relative pt-3">
                    <Text strong className="pb-2 d-block">
                      Attachement
                    </Text>
                    <Image
                      width={280}
                      src={item?.file}
                      placeholder={
                        <Image
                          preview={false}
                          src={`${item?.file}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                          width={280}
                        />
                      }
                    />
                  </div>
                )}

                <MapAddress
                  latitude={item?.location?.latitude}
                  longitude={item?.location?.longitude}
                  title="Location"
                />

                {item?.status && (
                  <div className="w-100 mt-3">
                    <Text className="d-block" strong>
                      Status
                    </Text>
                    <Text>{item?.status}</Text>
                  </div>
                )}

                {item?.text && (
                  <div className="w-100 mt-3">
                    <Text className="d-block" strong>
                      Detail
                    </Text>
                    <Text>{item?.text}</Text>
                  </div>
                )}
                <Divider />
              </Fragment>
            );
          })}
        </div>
      )}
    </Drawer>
  );
};

export default Index;
