import React, { memo, useState, useEffect } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { message, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import CountryPhoneInput from 'antd-country-phone-input';
import Preferences from './preferences';
import {
  updateCompanyAction,
  sendOtp,
  verifyOtp,
} from '../../redux/settings/action';
import OtpInputModal from '../../components/otpInputModal';
import authApi from '../../redux/auth/authApi';

const Api = new authApi();

const Settings = () => {
  const { company } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    name: '',
    phone: '',
    image_recognition: true,
    performance_questions: true,
    leave_management: true,
    geoFence: false,
    autoClockOut: false,
    timesheetApproval: false,
    newsletter: false,
    kioskOnly: false,
    strictMode: false,
    workSchedule: '',
    autoClockOutTime: '',
  });

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [otpVerifyLoader, setVerifyLoader] = useState(false);
  const [vloader, setVLoader] = useState(false);

  useEffect(() => {
    if (company) {
      setState({
        ...state,
        name: company?.name,
        phone: {
          phone:
            typeof company?.phone == 'object'
              ? company?.phone?.phone
              : company?.phone,
          short: company?.phone?.short ?? 'GB',
          code: company?.phone?.code ?? 44,
        },
        image_recognition: company?.image_recognition,
        performance_questions: company?.performance_management,
        leave_management: company?.leave_management,
        autoClockOut: company?.autoClockOut,
        timesheetApproval: company?.timesheetApproval,
        workSchedule: company?.workSchedule,
        newsletter: company?.newsletter,
        geoFence: company?.geoFence,
        kioskOnly: company?.kioskOnly ?? false,
        strictMode: company?.strictMode ?? false,
        autoClockOutTime: company?.autoClockOutTime ?? 8,
      });
    }
  }, [company]);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      message.success('Successfully Updated!');
    }
  };

  const toggleCheckBox = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSendOtp = (isSuccess) => {
    setLoader(false);
    if (isSuccess) {
      setOtpModal(true);
    }
  };

  const updateProfile = async () => {
    const { name, phone } = state;
    if (name === '') {
      message.warning('Name is required!');
      return;
    }
    if (phone === '') {
      message.warning('Contact Number is required!');
      return;
    }

    if (phone?.phone !== company?.phone?.phone) {
      // // // phone validation
      setVLoader(true);
      let res = (await Api.validatePhone(`+${phone?.code}${phone?.phone}`))
        .data;
      setVLoader(false);
      if (res.valid == false) {
        message.warning('Contact Number is Invalid!');
        return;
      }
      // // //

      let number = `+${phone?.code}${phone?.phone}`;
      // validate phone
      setNewPhone(number);
      setLoader(true);
      sendOtp(number, onSendOtp);
    } else {
      updateComDetails();
    }
  };

  const updateComDetails = () => {
    const {
      name,
      phone,
      image_recognition,
      performance_questions,
      leave_management,
      timesheetApproval,
      autoClockOut,
      autoClockOutTime,
      newsletter,
      geoFence,
      kioskOnly,
      strictMode,
    } = state;
    setLoader(true);
    const dataObj = {
      name,
      phone,
      image_recognition,
      performance_management: performance_questions,
      leave_management,
      timesheetApproval,
      autoClockOut,
      autoClockOutTime,
      newsletter,
      geoFence,
      kioskOnly,
      strictMode,
    };
    dispatch(updateCompanyAction(company.id, dataObj, stopLoader));
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePhone = (value) => {
    setState({
      ...state,
      phone: value,
    });
  };

  const onOtpVerifyRes = (isSuccess) => {
    setVerifyLoader(false);
    if (isSuccess) {
      setOtpModal(false);
      updateComDetails();
    }
  };

  const getOtpVerify = (otpValue) => {
    setVerifyLoader(true);
    verifyOtp(otpValue, onOtpVerifyRes);
  };

  const onResendOtp = () => {
    sendOtp(newPhone, onSendOtp);
  };

  return (
    <>
      <Container fluid className="bg-white p-5">
        <Row className="gSettingsRow">
          <Col md={12} lg={8}>
            <Form layout="vertical" className="pt-3">
              <Form.Item label="Company Name">
                <Input
                  size="large"
                  placeholder="Your full name"
                  value={state.name}
                  onChange={handleChange}
                  name="name"
                />
              </Form.Item>
              <Form.Item
                label="Company Contact Number"
                help={vloader ? 'The information is being validated...' : ''}
              >
                <CountryPhoneInput
                  value={state.phone}
                  onChange={onChangePhone}
                />
              </Form.Item>
            </Form>
            <Preferences
              image_recognition={state.image_recognition}
              geoFence={state.geoFence}
              performance_questions={state.performance_questions}
              leave_management={state.leave_management}
              autoClockOut={state.autoClockOut}
              autoClockOutTime={state.autoClockOutTime}
              timesheetApproval={state.timesheetApproval}
              workSchedule={state.workSchedule}
              newsletter={state.newsletter}
              kioskOnly={state.kioskOnly}
              strictMode={state.strictMode}
              toggleCheckBox={toggleCheckBox}
            />
            <div className="comSaveCont">
              <button
                onClick={updateProfile}
                className="btn btn-primary mb-0 mt-4 py-2 comSaveBtn"
                disabled={loader}
              >
                {loader ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <OtpInputModal
        isVisible={otpModal}
        onClose={() => {
          setOtpModal(false);
        }}
        onVerify={getOtpVerify}
        loader={otpVerifyLoader}
        newPhone={newPhone}
        onResendOtp={onResendOtp}
      />
    </>
  );
};

export default memo(Settings);
