export const getExportableData = (data, allDept) => {
  const exportableData = data.map((item) => ({
    name: item?.name,
    kiosk: item?.kiosk,
    email: item?.email,
    phone: item?.phone,
    department: allDept?.[item?.organization]?.name || '',
    jobType: item?.jobType,
    role: item?.role,
  }));

  return exportableData;
};
