import { MoreOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Popover } from 'antd';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { styles } from './style';

function Index({ reqItem, leaveStatus }) {
  const [showDesc, setShowDesc] = useState(false);

  const toggleDesc = () => {
    setShowDesc(!showDesc);
  };

  return (
    <Col lg={12}>
      <Row className="mt-4 superUserCard border-primary w-100 mr-4 d-flex border">
        <Col
          lg={4}
          md={6}
          sm={12}
          style={{ marginTop: '10px' }}
          className="d-flex  h-100 flex-column justify-content-around align-items-start"
          onClick={toggleDesc}
        >
          <p
            className={'w-75'}
            style={showDesc ? styles.openShiftName : styles.openShiftNameShow}
          >
            {reqItem?.shift?.name}
          </p>
        </Col>

        <Col
          lg={4}
          md={6}
          sm={12}
          style={{ marginTop: '10px' }}
          className="d-flex flex-column  align-items-start "
        >
          <div className="d-flex flex-column  align-items-center justify-content-center ">
            <p style={{ ...styles.noMB, textTransform: 'lowercase' }}>
              {moment(reqItem?.shift?.start).format('hh:mm a')} -{' '}
              {moment(reqItem?.shift?.end).format('hh:mm a')}
            </p>
            <p style={{ color: '#757575', ...styles.noMB }}>
              {' '}
              {moment(reqItem?.allocation?.date).format('DD/MM/YYYY')}
            </p>
          </div>
        </Col>
        <Col
          lg={4}
          md={6}
          sm={12}
          className="d-flex justify-content-end  align-items-center"
        >
          <p style={{ ...styles.statusBg, width: '100px' }}>{reqItem.status}</p>
        </Col>
      </Row>
    </Col>
  );
}

export default memo(Index);
