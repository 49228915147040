import { INSTANCE } from '../../config/networkInstance';

export default class Api {
  getHealthTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/health/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createHealthTypes = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/health/types',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateHealthTypes = (data, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: '/health/types',
        data,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteHealthTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: '/health/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getBreakTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/breaks/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createBreakTypes = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/breaks/types',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateBreakTypes = (data, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: '/breaks/types',
        data,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteBreakTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: '/breaks/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getUserAssignments = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/customers/assignments',
      })
        .then(resolve)

        .catch(reject);
    });
  };
}
