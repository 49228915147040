import React, { useState, useEffect } from 'react';
import { Input, List, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { addActiveUsers } from '../../redux/auth/action';

import { getAllMembers } from '../../redux/members/action';
import { getAllEmployees } from '../../redux/shifts/action';
import DepartmentSelector from '../../components/departmentFilter';
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Marker from './marker';
import { styles } from './style';

const getMapOptions = () => {
  return {
    disableDefaultUI: true,
    mapTypeControl: true,
    streetViewControl: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
};

export default function Index({ visible, handleCancel }) {
  const dispatch = useDispatch();
  const { coords, user } = useSelector((state) => state.auth);
  const { allDept, allMembers } = useSelector((state) => state.membersReducer);
  const [latLng, setLatLng] = useState({
    lat: coords.latitude,
    lng: coords.longitude,
  });
  const [address, setAddress] = useState('');
  const [dept, setDpt] = useState(null);
  const [active, setActive] = useState([]);

  const onGoogleApiLoaded = ({ map, maps }) => {};

  const handleAddress = (value) => {
    setAddress(value);
  };

  useEffect(() => {
    if (allMembers.length == []) {
      dispatch(getAllMembers(dept, () => {}));
    }
  }, []);

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      setAddress(results[0].formatted_address);
      const { lat, lng } = await getLatLng(results[0]);
      setLatLng({ lat, lng });
    } catch (err) {}
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    dispatch(getAllMembers(dept, () => {}));
  };

  return (
    <Modal
      visible={visible}
      footer={false}
      onCancel={handleCancel}
      className="modal-loc"
      zIndex={324234234234234234}
      centered
    >
      <div style={styles.mapContainer}>
        <div style={styles.searchBar}>
          <DepartmentSelector
            onSelectDpt={onChangeDept}
            pickerStyle={{
              minWidth: 333,
              marginBottom: 10,
            }}
            size="large"
          />
          <PlacesAutocomplete
            value={address}
            onChange={handleAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: 'Search a Location ...',
                    className: 'location-search-input',
                  })}
                  size="large"
                />
                <div className="autocomplete-dropdown-container mt-2">
                  {loading && <div>.....</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    const style = suggestion.active
                      ? {
                          backgroundColor: '#fafafa',
                          cursor: 'pointer',
                        }
                      : {
                          backgroundColor: '#ffffff',
                          cursor: 'pointer',
                        };
                    return (
                      <List
                        key={i}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <List.Item className="p-2">
                          {suggestion.description}
                        </List.Item>
                      </List>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          options={getMapOptions}
          onGoogleApiLoaded={onGoogleApiLoaded}
          bootstrapURLKeys={{
            key: 'AIzaSyD4bLo0gWQydOneofumWsQxOiy9VhC8PDo',
          }}
          center={latLng}
          defaultZoom={15}
        >
          <Marker
            lat={coords.latitude}
            lng={coords.longitude}
            user={user}
            color="#ff9900"
          />
          {allMembers?.map((item, index) => {
            return (
              <Marker
                lat={item?.location?.latitude}
                lng={item?.location?.longitude}
                user={item}
                color="#6699ff"
                key={index}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </Modal>
  );
}
