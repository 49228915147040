import * as constants from './constants';

const initState = {
  swapShifts: [],
  totalSwapShifts: 0,
  availableShifts: [],
  totalAvailableShifts: 0,
  postedSwaps: [],
  totalPostedSwaps: 0,
  userReq: [],
  totalUserReq: 0,
};
function swapShiftReducer(state = initState, action) {
  let { payload } = action;

  switch (action.type) {
    case constants.GET_SWAP_SHIFT:
      return {
        ...state,
        swapShifts: payload?.results,
        totalSwapShifts: payload?.totalResults,
      };

    case constants.POSTED_SWAP_SHIFTS:
      return {
        ...state,
        postedSwaps: payload?.results,
        totalPostedSwaps: payload?.totalResults,
      };

    case constants.UPDATE_SWAP:
      const filteredAccShifts = state.swapShifts.filter(
        (item) => item.id !== payload
      );

      return {
        ...state,
        swapShifts: filteredAccShifts,
      };
    case constants.GET_AVAILABLE_SHIFTS:
      return {
        ...state,
        availableShifts: payload?.results,
        totalAvailableShifts: payload?.totalResults,
      };
    case constants.GET_USER_REQ:
      return {
        ...state,
        userReq: payload?.results,
        totalUserReq: payload?.totalResults,
      };

    case constants.DECLINE_REQUEST:
      const filteredShifts = state.swapShifts.filter(
        (item) => item.id !== payload
      );

      return {
        ...state,
        swapShifts: filteredShifts,
      };

    case constants.APPLY_FOR_SHIFT:
      const filteredAppShifts = state.swapShifts.filter(
        (item) => item.id !== payload
      );

      return {
        ...state,
        availableShifts: filteredAppShifts,
      };
    case constants.ASSIGN_FOR_SHIFT:
      if (payload?.applied) {
        const filteredPostedShifts = state.postedSwaps.filter(
          (item) => item.id !== payload?.id
        );

        return {
          ...state,
          postedSwaps: filteredPostedShifts,
        };
      }
      const filteredAssignShifts = state.swapShifts.filter(
        (item) => item.id !== payload?.id
      );
      return {
        ...state,
        swapShifts: filteredAssignShifts,
      };
    default:
      return state;
  }
}

export default swapShiftReducer;
