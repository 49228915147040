import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Avatar, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import socketHandler from '../../config/socket';
import { getActivities } from '../../redux/activities/action';

import ActivityDetails from './activityDetails';
import { styles } from './style';
import UserDetailsModal from '../../components/userDetailsModal';

const { Text } = Typography;

const renderTitle = (title) => {
  return <h5 strong>{title}</h5>;
};

const renderActivity = (value) => {
  return <Activity value={value} />;
};

const Activity = ({ value }) => {
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const onClose = () => {
    setVisible(false);
  };
  const openDrawer = () => {
    setVisible(true);
  };
  const onRowAvatar = (event, record) => {
    event.stopPropagation();
    const deptName = allDeptByIds?.[record?.organization]?.name || '';

    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const getActivityText = (type) => {
    switch (type) {
      case 'clockin':
        return 'has Clocked In';
      case 'clockout':
        return 'has Clocked Out';
      case 'breakin':
        return 'has gone on Break';
      case 'breakout':
        return 'has resumed from Break';
    }
  };

  const img =
    value?.type === 'clockout'
      ? value?.allocation?.clockoutImage
      : value?.allocation?.clockinImage;

  const activityTime = value?.updatedAt;

  const selectedBorder =
    value?.type === 'clockout'
      ? styles.borderOut
      : value.type === 'clockin'
      ? styles.borderIn
      : styles.borderBreak;

  let isFailed =
    value?.type == 'clockout'
      ? !value?.allocation?.facialOut
      : value?.type == 'clockin'
      ? !value?.allocation?.facialIn
      : false;

  let isFailedGeo =
    value?.type == 'clockout'
      ? !value?.allocation?.geoFenceOut
      : value?.type == 'clockin'
      ? !value?.allocation?.geoFenceIn
      : false;

  return (
    <>
      <div
        className="c-pointer d-flex align-items-center justify-content-between px-4 "
        onClick={() => {
          value?.status !== 'deleted' && openDrawer();
        }}
        style={{
          ...styles.listItem,
          ...(selectedBorder || {}),
          ...(value.status === 'deleted' && { opacity: 0.7 }),
        }}
      >
        <Space align="center" size="large">
          <Avatar
            onClick={(e) => onRowAvatar(e, value?.user)}
            src={
              img ||
              (value?.user?.profilePic ??
                'https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg')
            }
            style={styles.avatar}
            size="large"
          />
          <div>
            {value?.shift?.name ? (
              <Text className="d-block">
                <Text strong>{value?.user?.name}</Text>{' '}
                {getActivityText(value?.type)} to &quot;{value?.shift?.name}
                &quot; <b className="text-primary">{value?.mode}</b>
              </Text>
            ) : (
              <Text className="d-block">
                <Text strong>{value?.user?.name} </Text>
                {getActivityText(value?.type)} -{' '}
                <b className="text-primary">{value?.mode}</b>
              </Text>
            )}
            {value?.byAuthorizedAdmin && (
              <Text type="secondary" className="d-block">
                Entry Via: {value?.byAuthorizedAdmin?.name}
              </Text>
            )}
          </div>
        </Space>
        <Space direction="vertical" align="center">
          <Text type="secondary" className="font-italic pl-5">
            {value.status === 'deleted'
              ? 'Activity Deleted'
              : moment(activityTime).format('HH:mm Do MMM-YYYY')}
          </Text>
          {(isFailed || isFailedGeo) && (
            <Space>
              {isFailed && (
                <Text style={styles.smallFailed}>
                  Facial Recognition Failed
                </Text>
              )}
              {isFailedGeo && (
                <Text style={{ ...styles.smallFailed, color: '#ff8c1a' }}>
                  Geo Fence Failed
                </Text>
              )}
            </Space>
          )}
        </Space>
      </div>
      <ActivityDetails
        visible={visible}
        onClose={onClose}
        item={value}
        mode={value?.type}
      />

      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
    </>
  );
};

const columns = [
  {
    title: renderTitle('Activity Logs'),
    className: 'bg-transparent pt-0 border-0',
    dataIndex: 'logs',
    render: renderActivity,
    align: 'left',
    fixed: true,
  },
];

function Index({ selectedDpt, logs, date, type }) {
  const dispatch = useDispatch();
  const { activities, totalActivities } = useSelector(
    (state) => state.activitiesReducer
  );

  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const pRef = useRef(1);
  const depRef = useRef(null);
  const logRef = useRef(null);
  const typeRef = useRef(null);
  const dateRef = useRef(null);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getActivities(page, selectedDpt, logs, type, date, stopLoader));
    depRef.current = selectedDpt;
    logRef.current = logs;
    typeRef.current = type;
    dateRef.current = date;
  }, [selectedDpt, logs, type, date]);

  const changePage = (page) => {
    setPage(Number(page));
    pRef.current = Number(page);
    setLoader(true);
    dispatch(
      getActivities(Number(page), selectedDpt, logs, type, date, stopLoader)
    );
  };

  const fetchUpdates = useCallback(() => {
    dispatch(
      getActivities(
        pRef?.current,
        depRef?.current,
        logRef?.current,
        typeRef.current,
        dateRef?.current,
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    socketHandler.on('activity', fetchUpdates);
    return () => {
      socketHandler.off('activity', fetchUpdates);
    };
  }, []);

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={activities.map((item) => {
          return {
            key: item.id,
            logs: item,
          };
        })}
        size="small"
        loading={loader}
        pagination={{
          onChange: changePage,
          current: page,
          total: totalActivities,
          pageSize: 10,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
      />
    </React.Fragment>
  );
}

export default Index;
