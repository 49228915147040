import axios from 'axios';
var CryptoJS = require('crypto-js');
export const cloudinaryImageUrl = (file) =>
  new Promise((resolve, reject) => {
    let timestamp = ((Date.now() / 1000) | 0).toString();
    let api_key = '683169487975822'; //384388171352842
    let api_secret = 'RV902erp_1wSg60CD9NTNJeRWWo'; //xOYekZfxMuQbMeyXaSw8nw41RGg
    let cloud = 'groll'; //gigsasa
    let hash_string = 'timestamp=' + timestamp + api_secret;
    let signature = CryptoJS.SHA1(hash_string).toString();
    let upload_url = 'https://api.cloudinary.com/v1_1/' + cloud + '/upload';

    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('timestamp', timestamp);
    formdata.append('api_key', api_key);
    formdata.append('signature', signature);
    axios.post(upload_url, formdata).then(resolve).catch(reject);
  });
