/* eslint-disable react/display-name */
import React from 'react';
import { Redirect } from 'react-router-dom';
import AdminLayout from './layouts/AdminLayout';
import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import { BASE_URL } from './config/constant';
import Login from './views/authentication/login2';
import Signup from './views/authentication/register2';
import AdminSignup from './views/authentication/adminSignup';
import ResetPass from './views/authentication/resetPassword';
import ChangePass from './views/authentication/changePassword';
import UpdatePassword from './views/authentication/updatePassword';
import VerifyImage from './views/authentication/imageVerification';
import Steps from './views/steps/index2';
import VerifyEmail from './views/authentication/verifyEmail';
// Authenticated routes
import WorkSchedule from './views/workSchedules/index';
import Shifts from './views/shifts/shifts';

import Attendance from './views/timeSheets/attendance';

import MainShifts from './views/shifts/index';
import TimeSheets from './views/timeSheets';
import UserTimesheet from './views/timeSheets/userSheet';
import Members from './views/members';
import Organizations from './views/organizations';
import PerformanceQuestions from './views/performanceQuestions';
import LeaveManagement from './views/leaveManagement';
import LeaveTypes from './views/leaveManagement/types';
import OtPVerification from './views/authentication/otp';

import BreakTypes from './views/settings/breakTypes';
import HealthTypes from './views/settings/healthTypes';
import Locations from './views/location';
import Settings from './views/settings/index2';
import Profile from './views/settings/profile';
import ChangePassword from './views/settings/changePassword';
import Dashboard from './views/dashboard';
import Pricing from './views/subscriptions';
import ThankYou from './views/ThankuPage';
import Analytics from './views/analytics/index';
import CustomerAndProjects from './views/customersAndProjects';
import swapShift from './views/swapShift/supervisor/swapShift';
import AvailableShifts from './views/swapShift/employee/availableShifts';
import Subscription from './views/subscriptions';
import InActiveSubscription from './views/subscriptions/inActiveSubscription';
import Inbox from './views/chat/index';
import Tasks from './views/tasks';

import TasksDetail from './views/tasks/viewTask';
import TasksProject from './views/tasks/dashboard55';
import TasksProjectDetails from './views/tasks/viewTask';

import AnalyticsPerformanceDetails from './views/analytics/performance_user';
import AnalyticsPerformanceReports from './views/analytics/performance_report';
import AnalyticsIncidentReports from './views/analytics/incident_report';
import AnalyticsDailyAttendance from './views/timeSheets/dashboard.attendance';
import AnalyticsAttendanceSummery from './views/timeSheets/summary.attendance';

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signup',
    component: Signup,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/adminSignup/:token',
    component: AdminSignup,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/login',
    component: Login,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/verifyEmail',
    component: VerifyEmail,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/reset-password',
    component: ResetPass,
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/change-password/:id',
    component: ChangePass,
  },
  {
    exact: true,
    path: '/pricing',
    component: Pricing,
  },
  {
    exact: true,
    path: '/auth/otp',
    component: OtPVerification,
  },

  {
    exact: true,
    path: '/steps',
    component: Steps,
  },
  {
    exact: true,
    path: '/update-password',
    component: UpdatePassword,
  },
  {
    exact: true,
    path: '/addVerification',
    component: VerifyImage,
  },
  {
    exact: true,
    path: '/subscribeSuccess',
    component: ThankYou,
  },

  {
    exact: true,
    path: '/activateSubscription',
    component: InActiveSubscription,
  },

  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/schedules/shifts',
        component: Shifts,
      },

      {
        exact: true,
        path: '/app/attendance',
        component: Attendance,
      },
      {
        exact: true,
        path: '/app/analytics/dailyAttendance',
        component: AnalyticsDailyAttendance,
      },
      {
        exact: true,
        path: '/app/analytics/attendanceSummery',
        component: AnalyticsAttendanceSummery,
      },
      {
        exact: true,
        path: '/app/analytics/incidentReports',
        component: AnalyticsIncidentReports,
      },
      {
        exact: true,
        path: '/app/analytics/performanceReports',
        component: AnalyticsPerformanceReports,
      },
      {
        exact: true,
        path: '/schedules/main',
        component: MainShifts,
      },
      {
        exact: true,
        path: '/schedules/swapShift',
        component: swapShift,
      },

      {
        exact: true,
        path: '/schedules/workSchedules',
        component: WorkSchedule,
      },
      {
        exact: true,
        path: '/app/timesheets',
        component: TimeSheets,
      },
      {
        exact: true,
        path: '/app/timesheets/:id',
        component: UserTimesheet,
      },
      {
        exact: true,
        path: '/app/analytics/performance/:id',
        component: AnalyticsPerformanceDetails,
      },
      {
        exact: true,
        path: '/app/members',
        component: Members,
      },
      {
        exact: true,
        path: '/app/inbox',
        component: Inbox,
      },
      {
        exact: true,
        path: '/app/organizations',
        component: Organizations,
      },
      {
        exact: true,
        path: '/app/customers',
        component: CustomerAndProjects,
      },
      {
        exact: true,
        path: '/app/performance-questions',
        component: PerformanceQuestions,
      },
      {
        exact: true,
        path: '/app/leaves',
        component: LeaveManagement,
      },
      {
        exact: true,
        path: '/app/leaveTypes',
        component: LeaveTypes,
      },

      {
        exact: true,
        path: '/app/healthTypes',
        component: HealthTypes,
      },

      {
        exact: true,
        path: '/app/breakTypes',
        component: BreakTypes,
      },

      {
        exact: true,
        path: '/app/settings',
        component: Settings,
      },
      {
        exact: true,
        path: '/app/subscription',
        component: Subscription,
      },
      {
        exact: true,
        path: '/app/profile',
        component: Profile,
      },
      {
        exact: true,
        path: '/app/changePassword',
        component: ChangePassword,
      },

      {
        exact: true,
        path: '/app/locations',
        component: Locations,
      },

      {
        exact: true,
        path: '/app/dashboard/analytics',
        component: Dashboard,
      },
      {
        exact: true,
        path: '/app/analytics',
        component: Analytics,
      },
      {
        exact: true,
        path: '/app/tasks',
        component: Tasks,
      },

      {
        exact: true,
        path: '/app/tasks/:id',
        component: TasksDetail,
      },
      {
        exact: true,
        path: '/app/task/project',
        component: TasksProject,
      },
      {
        exact: true,
        path: '/app/task/project/:id',
        component: TasksDetail,
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
];

// const routes1 = [
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/auth/signup',
//     component: Signup,
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/auth/adminSignup/:token',
//     component: AdminSignup,
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/auth/login',
//     component: Login,
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/auth/verifyEmail',
//     component: VerifyEmail,
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/auth/reset-password',
//     component: ResetPass,
//   },

//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/auth/change-password/:id',
//     component: ChangePass,
//   },

//   {
//     exact: true,
//     path: '/update-password',
//     component: UpdatePassword,
//   },
//   {
//     exact: true,
//     path: '/addVerification',
//     component: VerifyImage,
//   },

//   {
//     path: '*',
//     layout: AdminLayout,
//     guard: AuthGuard,
//     routes: [
//       {
//         exact: true,
//         path: '/schedules/shifts',
//         component: Shifts,
//       },
//       {
//         exact: true,
//         path: '/schedules/main',
//         component: MainShifts,
//       },
//       {
//         exact: true,
//         path: '/schedules/swapShift',
//         component: swapShift,
//       },

//       {
//         exact: true,
//         path: '/schedules/workSchedules',
//         component: WorkSchedule,
//       },
//       {
//         exact: true,
//         path: '/app/timesheets',
//         component: TimeSheets,
//       },
//       {
//         exact: true,
//         path: '/app/timesheets/:id',
//         component: UserTimesheet,
//       },
//       {
//         exact: true,
//         path: '/app/members',
//         component: Members,
//       },
//       {
//         exact: true,
//         path: '/app/inbox',
//         component: Inbox,
//       },
//       {
//         exact: true,
//         path: '/app/organizations',
//         component: Organizations,
//       },
//       {
//         exact: true,
//         path: '/app/customers',
//         component: CustomerAndProjects,
//       },
//       {
//         exact: true,
//         path: '/app/performance-questions',
//         component: PerformanceQuestions,
//       },
//       {
//         exact: true,
//         path: '/app/leaves',
//         component: LeaveManagement,
//       },
//       {
//         exact: true,
//         path: '/app/leaveTypes',
//         component: LeaveTypes,
//       },

//       {
//         exact: true,
//         path: '/app/healthTypes',
//         component: HealthTypes,
//       },

//       {
//         exact: true,
//         path: '/app/breakTypes',
//         component: BreakTypes,
//       },

//       {
//         exact: true,
//         path: '/app/settings',
//         component: Settings,
//       },
//       {
//         exact: true,
//         path: '/app/subscription',
//         component: Subscription,
//       },
//       {
//         exact: true,
//         path: '/app/profile',
//         component: Profile,
//       },
//       {
//         exact: true,
//         path: '/app/changePassword',
//         component: ChangePassword,
//       },

//       {
//         exact: true,
//         path: '/app/locations',
//         component: Locations,
//       },

//       {
//         exact: true,
//         path: '/app/dashboard/analytics',
//         component: Dashboard,
//       },
//       {
//         exact: true,
//         path: '/app/tasks',
//         component: Tasks,
//       },
//       {
//         path: '*',
//         exact: true,
//         component: () => <Redirect to={BASE_URL} />,
//       },
//     ],
//   },
// ];

const routes2 = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signup',
    component: Signup,
  },

  {
    exact: true,
    path: '/auth/otp',
    component: OtPVerification,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/adminSignup/:token',
    component: AdminSignup,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/login',
    component: Login,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/verifyEmail',
    component: VerifyEmail,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/reset-password',
    component: ResetPass,
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/change-password/:id',
    component: ChangePass,
  },

  {
    exact: true,
    path: '/steps',
    component: Steps,
  },
  {
    exact: true,
    path: '/update-password',
    component: UpdatePassword,
  },
  {
    exact: true,
    path: '/addVerification',
    component: VerifyImage,
  },

  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/schedules/shifts',
        component: Shifts,
      },
      {
        exact: true,
        path: '/schedules/main',
        component: MainShifts,
      },
      {
        exact: true,
        path: '/app/attendance',
        component: Attendance,
      },
      {
        exact: true,
        path: '/schedules/workSchedules',
        component: WorkSchedule,
      },
      {
        exact: true,
        path: '/app/timesheets',
        component: TimeSheets,
      },
      {
        exact: true,
        path: '/app/timesheets/:id',
        component: UserTimesheet,
      },
      {
        exact: true,
        path: '/app/analytics/performance/:id',
        component: AnalyticsPerformanceDetails,
      },
      {
        exact: true,
        path: '/app/leaves',
        component: LeaveManagement,
      },
      {
        exact: true,
        path: '/app/inbox',
        component: Inbox,
      },
      {
        exact: true,
        path: '/app/customers',
        component: CustomerAndProjects,
      },

      {
        exact: true,
        path: '/app/members',
        component: Members,
      },
      {
        exact: true,
        path: '/app/profile',
        component: Profile,
      },
      {
        exact: true,
        path: '/app/changePassword',
        component: ChangePassword,
      },

      {
        exact: true,
        path: '/app/dashboard/analytics',
        component: Dashboard,
      },
      {
        exact: true,
        path: '/app/tasks',
        component: Tasks,
      },
      {
        exact: true,
        path: '/app/tasks/:id',
        component: TasksDetail,
      },
      {
        exact: true,
        path: '/app/task/project',
        component: TasksProject,
      },
      {
        exact: true,
        path: '/app/task/project/:id',
        component: TasksDetail,
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
];

const routes3 = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signup',
    component: Signup,
  },
  {
    exact: true,
    path: '/auth/otp',
    component: OtPVerification,
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/adminSignup/:token',
    component: AdminSignup,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/login',
    component: Login,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/verifyEmail',
    component: VerifyEmail,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/reset-password',
    component: ResetPass,
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/change-password/:id',
    component: ChangePass,
  },

  {
    exact: true,
    path: '/steps',
    component: Steps,
  },
  {
    exact: true,
    path: '/update-password',
    component: UpdatePassword,
  },
  {
    exact: true,
    path: '/addVerification',
    component: VerifyImage,
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/schedules/shifts',
        component: Shifts,
      },
      {
        exact: true,
        path: '/app/inbox',
        component: Inbox,
      },
      {
        exact: true,
        path: '/app/tasks',
        component: Tasks,
      },
      {
        exact: true,
        path: '/schedules/availableShifts',
        component: AvailableShifts,
      },
      {
        exact: true,
        path: '/app/leaves',
        component: LeaveManagement,
      },

      {
        exact: true,
        path: '/app/profile',
        component: Profile,
      },
      {
        exact: true,
        path: '/app/changePassword',
        component: ChangePassword,
      },
      {
        exact: true,
        path: '/app/tasks/:id',
        component: TasksDetail,
      },
      {
        exact: true,
        path: '/app/task/project',
        component: TasksProject,
      },
      {
        exact: true,
        path: '/app/task/project/:id',
        component: TasksDetail,
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={'/schedules/shifts'} />,
      },
    ],
  },
];

export { routes, routes2, routes3 };
