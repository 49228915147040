import React, { useState, useEffect, memo } from 'react';
import { Table, Form, Card, Space, Button, Tabs, Spin, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import socketHandler from '../../../config/socket';
import moment from 'moment';
import {
  assignShift,
  getPostedSwapShift,
  getSwapShifts,
} from '../../../redux/swapShift/action';
import { Row } from 'react-bootstrap';

import { styles } from '../components/style';
import SwapShiftItem from './swapItem';
import { NoData, UserCard } from '../components';
import ApproveSwap from './approveSwap';

const { TabPane } = Tabs;
function SwapShift() {
  const dispatch = useDispatch();

  const { swapShifts, totalSwapShifts, postedSwaps, totalPostedSwaps } =
    useSelector((state) => state.swapShifts);
  const limit = 10;

  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getSwapShifts({ page, limit }, stopLoader));
  }, []);
  useEffect(() => {
    socketHandler.on('swapRequest', fetchUpdates);
    return () => {
      socketHandler.off('swapRequest', fetchUpdates);
    };
  }, []);

  const fetchUpdates = () => {
    dispatch(getSwapShifts({ page, limit }, () => {}));
  };
  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getSwapShifts({ page, limit }, stopLoader));
  };

  const refreshData = () => {
    setLoader(true);
    dispatch(getSwapShifts({ page, limit }, stopLoader));
  };

  const asignShift = (id, item) => {
    if (!item) return;

    setLoader(true);
    dispatch(assignShift({ id: id, user: item?.id }, refreshData));
  };

  // open shift application
  const [pageAppl, setPageAppl] = useState(1);
  const [loaderAppl, setLoaderAppl] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [appliedItem, setappliedItem] = useState(null);

  const stopLoaderAppl = () => {
    setLoaderAppl(false);
  };
  const changePageAppl = (page) => {
    setPageAppl(page);
    setLoaderAppl(true);
    dispatch(getPostedSwapShift({ page, limit }, stopLoaderAppl));
  };
  const userApplied = (item) => {
    setappliedItem(item);
  };
  const openAppliedModal = () => {
    setApproveModal(true);
  };
  useEffect(() => {
    setLoader(true);

    dispatch(getPostedSwapShift({ pageAppl, limit }, stopLoaderAppl));
  }, []);

  useEffect(() => {
    socketHandler.on('applyRequest', fetchPostedUpdates);
    return () => {
      socketHandler.off('applyRequest', fetchPostedUpdates);
    };
  }, []);

  const fetchPostedUpdates = () => {
    dispatch(getPostedSwapShift({ pageAppl, limit }, () => {}));
  };

  const [activeTab, setActiveTab] = useState('1');
  return (
    <>
      <h3>Shifts Requests</h3>
      <Tabs
        size={'large'}
        centered
        defaultActiveKey="1"
        activeKey={activeTab}
        onTabClick={(e) => {
          setActiveTab(e);
        }}
      >
        <TabPane tab="Change Of Shift" key="1">
          {loader ? (
            <div
              className="justify-content-center align-items-center d-flex"
              style={{ height: '200px' }}
            >
              <Spin spinning={loader} />
            </div>
          ) : (
            <Row className="mx-4 g-3">
              {swapShifts.length ? (
                swapShifts.map((item, i) => {
                  return (
                    <SwapShiftItem
                      setLoaderAppl={setLoaderAppl}
                      setActiveTab={setActiveTab}
                      key={i}
                      reqItem={item}
                    />
                  );
                })
              ) : (
                <NoData />
              )}
              <div style={styles.reqPagination}>
                <Pagination
                  onChange={changePage}
                  current={page}
                  total={totalSwapShifts}
                  position={['bottomRight']}
                  hideOnSinglePage={true}
                />
              </div>
            </Row>
          )}
        </TabPane>
        <TabPane tab="Open Shift Application" key="2">
          {loaderAppl ? (
            <div
              className="justify-content-center align-items-center d-flex "
              style={{ height: '200px' }}
            >
              <Spin spinning={loader} />
            </div>
          ) : (
            <>
              <Row className=" mx-4 g-3">
                {postedSwaps.length ? (
                  postedSwaps.map((item, i) => {
                    return (
                      <UserCard
                        openAppliedModal={openAppliedModal}
                        userApplied={userApplied}
                        forAppied={true}
                        refreshData={refreshData}
                        shiftItem={item}
                        key={i}
                      />
                    );
                  })
                ) : (
                  <NoData />
                )}
              </Row>
              <div
                style={{
                  textAlign: 'left',
                  margin: '50px',
                }}
              >
                <Pagination
                  onChange={changePageAppl}
                  current={pageAppl}
                  total={totalPostedSwaps}
                  position={['bottomRight']}
                  hideOnSinglePage={true}
                />
              </div>
            </>
          )}
        </TabPane>
      </Tabs>
      <ApproveSwap
        show={approveModal}
        setClose={() => {
          setApproveModal(false);
        }}
        appliedUsers={true}
        reqItem={appliedItem}
      />
    </>
  );
}

export default memo(SwapShift);
