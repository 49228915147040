import * as constants from './constants';
import _ from 'lodash';
let initState = {
  tasks: {},
  selfTasks: [],
  initial: true,
  questions: [],
  allTasks: [],
  memberTask: [],
  allTasks_export: [],
  dashboardDisplay: [],
  myTaskDisplay: [],
  allTaskDisplay: [],
  ongoingTaskDisplay: [],
  singleTask: {},
};
function tasksReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_TASKS:
      return {
        ...state,
        tasks: payload,
        initial: false,
      };
    case constants.GET_RAW_TASKS:
      return {
        ...state,
        tasks: payload,
        initial: false,
      };
    case constants.GET_ALL_TASKS:
      return {
        ...state,
        allTasks: payload.allTasks,
        memberTask: payload.tasks,
        initial: false,
      };
    case constants.DASHBOARD_FILTER:
      return {
        ...state,
        dashboardDisplay: payload,
      };
    case constants.MY_FILTER:
      return {
        ...state,
        allTaskDisplay: payload,
      };
    case constants.ALL_FILTER:
      return {
        ...state,
        dashboardDisplay: payload,
      };
    case constants.GET_ALL_TASKS_EXPORT:
      return {
        ...state,
        allTasks_export: payload,
        initial: false,
      };

    case constants.DELETE_TASKS: {
      let obj = {
        ...state.tasks,
        [payload.user_id.id]: [...state.tasks[payload.user_id.id]].filter(
          (task) => task.id !== payload.id
        ),
      };
      return {
        ...state,
        tasks: obj,
      };
    }

    case constants.UPDATE_TASK: {
      let obj = {
        ...state.tasks,
        [payload.user_id.id]: [...state.tasks[payload.user_id.id]].map((task) =>
          task.id == payload.id ? { ...task, status: payload.status } : task
        ),
      };
      return {
        ...state,
        tasks: obj,
      };
    }

    case constants.UPDATE_TASK_SELF: {
      return {
        ...state,
        selfTasks: [...state.selfTasks].map((item) =>
          item.id == payload.id ? { ...item, status: payload.status } : item
        ),
      };
    }

    case constants.TOGGLE_TASK: {
      return {
        ...state,
        selfTasks: [...state.selfTasks].map((item) =>
          item.id == payload.id ? payload : item
        ),
      };
    }

    case constants.GET_ONE_TASK: {
      return {
        ...state,
        singleTask: payload,
      };
    }

    case constants.GET_MY_TASKS: {
      return {
        ...state,
        selfTasks: payload,
      };
    }

    case constants.TASK_QUESTIONS: {
      return {
        ...state,
        questions: payload,
      };
    }

    default:
      return state;
  }
}

export default tasksReducer;
