import React, { useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import AuthLayout from '../layouts/authlayout';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Image } from 'antd';
import { styles } from './style';
import { addVerification, SkipImage } from '../../redux/clockinout/action';
import { logoutUser } from '../../redux/auth/action';
import Verification from '../../assets/images/image_verification.png';
import Camera from './addVerification';

const { Text } = Typography;
function Index({ history }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [camera, setCamera] = useState(false);
  const [loader, setLoader] = useState(false);
  const [oloader, setOLoader] = useState(false);
  const [img, setImg] = useState(null);

  const getImage = (result) => {
    setImg(result);
  };

  const closeCamera = () => {
    setCamera(false);
  };

  const openCamera = () => {
    setCamera(true);
  };

  const stopLoader = () => {
    setLoader(false);
    history.push('/app/dashboard/analytics');
  };
  const submitImage = () => {
    const faceData = {
      image: img,
      id: user?.id,
      company: user?.company_id?.id ? user?.company_id?.id : user?.company_id,
    };
    setLoader(true);
    dispatch(addVerification(faceData, img, stopLoader));
  };

  const skipImage = () => {
    dispatch(SkipImage(stopLoader));
  };

  const logout = () => {
    setOLoader(true);
    dispatch(
      logoutUser(() => {
        setOLoader(false);
        history.push('/auth/login');
      })
    );
  };

  return (
    <AuthLayout
      left={
        <div>
          <Image
            src={Verification}
            preview={false}
            className="text-center img-responsive mt-auto mb-auto"
          />
        </div>
      }
      right={
        <Row className="w-100 justify-content-center">
          <Col
            lg={7}
            xl={8}
            xxl={9}
            sm={12}
            className="d-flex flex-column align-items-center"
          >
            <h3 className="text-center">Facial Recognition Setup</h3>
            <Text type="secondary" className="pb-5 pt-1 d-block text-center">
              Please upload a clear photo of yourself that can be used for
              better verification
            </Text>
            {img ? (
              <Image
                src={img}
                width={300}
                preview={false}
                onClick={openCamera}
                className="c-pointer"
              />
            ) : (
              <div
                style={styles.verifyButton}
                className="d-flex align-items-center justify-content-center text-center c-pointer"
                onClick={openCamera}
              >
                <i className="fas fa-camera" style={styles.cameraIcon}></i>
              </div>
            )}
            <button
              className="btn btn-primary w-100 mt-5"
              onClick={submitImage}
              disabled={img == null || loader}
            >
              {loader ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                'Submit'
              )}
            </button>
            {user.role == 'root' && (
              <button
                className="btn btn-secondary w-100 mt-5"
                onClick={skipImage}
                disabled={loader}
              >
                {loader ? (
                  <>
                    <Spinner animation="border" size="sm" variant="light" />{' '}
                    Skip
                  </>
                ) : (
                  'Skip'
                )}
              </button>
            )}

            <button className="btn btn-primary w-100 mt-2" onClick={logout}>
              {oloader ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                'LogOut'
              )}
            </button>
          </Col>
          <Camera
            getImage={getImage}
            isShow={camera}
            closeModal={closeCamera}
          />
        </Row>
      }
    />
  );
}

export default Index;
