import React, { useEffect, useState } from 'react';
import { Typography, Image, Form, Input, Select } from 'antd';
import { Spinner, Col, Row } from 'react-bootstrap';
import { Slide } from 'react-awesome-reveal';
import { RightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { styles } from './style';
import Organization from '../../assets/images/auth/createOrg.png';
import Payroll from '../../assets/images/auth/payroll.png';
import Time from '../../assets/images/auth/time.png';
import Member from '../../assets/images/auth/member.png';
import Tick from '../../assets/images/auth/tick.png';
import Stepper from './stepper';
import { createCompany, logoutUser } from '../../redux/auth/action';
import { list } from './data';

const { Text } = Typography;
const { Option } = Select;

function LeftOrgMore({ steps }) {
  return (
    <div>
      <Image
        src={Organization}
        preview={false}
        className="text-center img-responsive mt-auto mb-auto"
      />
      <Stepper steps={steps} />
    </div>
  );
}

function RightOrgMore({ goDashboard, setSteps }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [radio, setRadios] = useState([]);
  const [loader, setLoader] = useState(false);
  const [preDetails, setPre] = useState({});
  const handleRadio = (value) => {
    if (radio.includes(value)) {
      // delete item
      setRadios([...radio].filter((item) => item !== value));
    } else {
      // add item
      setRadios([...radio, value]);
    }
  };

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      // go to dashboard
      goDashboard();
      localStorage.clear('companyData');
    }
  };

  useEffect(() => {
    const savedValues = localStorage.getItem('companyData');
    if (Object.values(savedValues || {}).length) {
      form.setFieldsValue(JSON.parse(savedValues));
      setPre(JSON.parse(savedValues));
    }
  }, []);

  const createOrganization = (values) => {
    const { industry, team_size } = values;
    const { phone, invite_email, name } = preDetails;
    const attendance = radio.includes('attendance');
    const payroll = radio.includes('payroll');
    const performance_management = radio.includes('performance_management');
    const comData = {
      industry,
      team_size,
      attendance,
      payroll,
      name,
      phone,
      performance_management,
      ...(invite_email && invite_email.trim().length && { invite_email }),
    };

    // call redux action

    setLoader(true);
    dispatch(createCompany(comData, stopLoader));
  };
  const saveLogout = (e) => {
    e.preventDefault();
    const savedValues = localStorage.getItem('companyData');
    const parsedValues = JSON.parse(savedValues);

    const values = form.getFieldsValue();
    const formValues = {
      ...parsedValues,
      ...preDetails,
      ...values,
    };
    localStorage.setItem('companyData', JSON.stringify(formValues));
    dispatch(logoutUser());
  };

  return (
    <Slide direction="right">
      <div>
        <button style={styles.backBtn} onClick={() => setSteps(2)}>
          <i className="feather icon-arrow-left" style={styles.backIcon} />
        </button>
      </div>
      <div>
        <h3 className="font-weight-bold text-center pt-4 text-capitalize mb-5">
          tell us more about {preDetails?.name}
        </h3>

        <Form form={form} layout="vertical" onFinish={createOrganization}>
          <Form.Item
            name="team_size"
            className="mb-3 mt-4 font-weight-bold text-uppercase"
            shouldUpdate={(prevValues, curValues) => curValues !== '-'}
            label="Team size"
            required={false}
            rules={[
              {
                required: true,
                message: 'Team Size is required!',
              },
              {
                pattern: new RegExp('^[0-9]*[1-9][0-9]*'),
                message: 'Invalid Number',
              },
            ]}
          >
            <Input
              type="number"
              style={styles.input}
              placeholder="Type Your Team size here"
              min="1"
            />
          </Form.Item>
          <Form.Item
            name="industry"
            className="mb-3 mt-4"
            required={false}
            rules={[
              {
                required: true,
                message: 'Industry is required!',
              },
            ]}
            label={
              <Text strong className="text-uppercase">
                Industry
              </Text>
            }
          >
            <Select
              size={'large'}
              placeholder="Select"
              className="custom-selector"
              showSearch
            >
              {list.map((item, i) => {
                return (
                  <Option key={i} value={item.Description}>
                    {item.Description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            className="mb-3 mt-4 font-weight-bold text-uppercase"
            label="What Brings you to Gigsasa?"
          >
            <Row className="w-100 pt-3 justify-content-center flex-wrap">
              <Col lg={6} sm={12} xl={4}>
                <div
                  style={styles.boxPicker}
                  className="c-pointer"
                  onClick={() => handleRadio('attendance')}
                >
                  {radio.includes('attendance') && (
                    <div style={styles.radio}>
                      <Image
                        src={Tick}
                        preview={false}
                        className="img-responsive"
                        style={styles.tick}
                      />
                    </div>
                  )}
                  <Image
                    src={Time}
                    preview={false}
                    className="img-responsive"
                    style={styles.featureImage}
                  />
                  <Text className="d-block pt-3" style={styles.radioTitle}>
                    Time Tracking
                  </Text>
                </div>
              </Col>
              <Col lg={6} sm={12} xl={4}>
                <div
                  style={styles.boxPicker}
                  className="c-pointer mt-3 mt-lg-0"
                  onClick={() => handleRadio('performance_management')}
                >
                  {radio.includes('performance_management') && (
                    <div style={styles.radio}>
                      <Image
                        src={Tick}
                        preview={false}
                        className="img-responsive"
                        style={styles.tick}
                      />
                    </div>
                  )}
                  <Image
                    src={Payroll}
                    preview={false}
                    className="img-responsive"
                    style={styles.featureImage}
                  />
                  <Text className="d-block pt-3" style={styles.radioTitle}>
                    Performance
                  </Text>
                </div>
              </Col>
              <Col lg={6} sm={12} xl={4}>
                <div
                  style={styles.boxPicker}
                  className="mt-3 mt-lg-0 c-pointer"
                  onClick={() => handleRadio('payroll')}
                >
                  {radio.includes('payroll') && (
                    <div style={styles.radio}>
                      <Image
                        src={Tick}
                        preview={false}
                        className="img-responsive"
                        style={styles.tick}
                      />
                    </div>
                  )}
                  <Image
                    src={Member}
                    preview={false}
                    className="img-responsive"
                    style={styles.featureImage}
                  />
                  <Text className="d-block pt-3" style={styles.radioTitle}>
                    People Analytics
                  </Text>
                </div>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <button
              className="btn btn-primary w-100 mt-5 d-flex align-items-center justify-content-between"
              style={styles.buttonContinue}
              htmlType="submit"
            >
              {loader ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'CONTINUE'
              )}
              <RightOutlined />
            </button>
          </Form.Item>

          <Form.Item>
            <button
              className="w-100 mt-2 d-flex align-items-center justify-content-center"
              style={styles.buttonLogout}
              onClick={saveLogout}
            >
              Save for later and Logout
            </button>
          </Form.Item>
        </Form>
      </div>
    </Slide>
  );
}

export { LeftOrgMore, RightOrgMore };
