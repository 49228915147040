import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Form,
  Button,
  DatePicker,
  Select,
  TimePicker,
  Input,
  Radio,
  Switch,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getLeaveTypes, createLeave } from '../../redux/leaveManagement/action';

import { styles } from './style';

import { getAllEmployees } from '../../redux/shifts/action';
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;

function GetTimePicker({ name, label, placeholder }) {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: 'Missing Time' }]}
    >
      <TimePicker
        className="w-100"
        placeholder={placeholder}
        size="large"
        format="HH:mm"
      />
    </Form.Item>
  );
}

function GetDatePicker({
  name,
  label,
  placeholder,
  isFullDay,
  onChange,
  ...props
}) {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: 'Missing Start Time' }]}
    >
      <DatePicker
        className="w-100"
        format={isFullDay ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm'}
        showTime={
          !isFullDay && {
            defaultValue: moment('00:00', 'HH:mm'),
          }
        }
        placeholder={placeholder}
        size="large"
        onChange={onChange}
        disabled={props.isEndDate && !props.clockIn}
        disabledDate={(current) => {
          return current?.isBefore?.(props.clockIn, 'day');
        }}
      />
    </Form.Item>
  );
}

function AddAbsence({ visible, onClose, dept, daily = false, refreshData }) {
  const dispatch = useDispatch();
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { company } = useSelector((state) => state.auth);
  const { types } = useSelector((state) => state.leaveManagement);

  const [eLoader, setELoader] = useState(false);
  const [typeLoader, setTypeLoader] = useState(false);
  const [leaveLoader, setLeaveLoader] = useState(false);
  const [isFullDay, setFullDay] = useState(true);

  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);

  const [form] = Form.useForm();

  const stopLoaderEmp = () => {
    setELoader(false);
  };

  const stopTypeLoader = () => {
    setTypeLoader(false);
  };

  useEffect(() => {
    if (visible) {
      setELoader(true);
      dispatch(getAllEmployees(stopLoaderEmp, dept));
      setTypeLoader(true);
      dispatch(getLeaveTypes({ company: company.id }, stopTypeLoader));
    }
  }, [dept, visible]);

  const leaveRes = (isSuccess) => {
    setLeaveLoader(false);

    if (isSuccess) {
      refreshData();
      onClose(false);
    }
  };

  const onFinish = (values) => {
    const absentObj = {
      ...values,
      start: values.start?.toDate?.() || new Date(),
      end: values.end?.toDate?.() || new Date(),
      paid: values.paid === 1,
      status: 'approved',

      leavePeriod: isFullDay ? 'fullDay' : 'halfDay',
    };

    setLeaveLoader(true);
    dispatch(createLeave(absentObj, leaveRes));
  };

  const onChangeFullDay = (val) => {
    setFullDay(val);
  };

  const setClockTimes = (time, type) => {
    if (type === 'clockIn') {
      setClockIn(time);
    } else {
      setClockOut(time);
    }
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={() => onClose(false)}
      visible={visible}
      width={340}
      zIndex={1050}
      bodyStyle={styles.sideBar}
    >
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        form={form}
        style={{
          width: '100%',
          marginTop: 40,
        }}
      >
        <Form.Item
          label="Employee"
          className="mb-3"
          name="user"
          rules={[
            {
              required: true,
              message: 'Please Select Employee',
            },
          ]}
        >
          <Select
            size={'large'}
            placeholder="select Employee"
            style={{ width: '100%' }}
            loading={eLoader}
            disabled={eLoader}
          >
            {allEmployees.map((item) => {
              return (
                <Option
                  key={item.id}
                >{`${item.name} (${item?.jobType})`}</Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Leave Type"
          className="mb-3"
          name="type"
          rules={[
            {
              required: true,
              message: 'Please Select Leave Type',
            },
          ]}
        >
          <Select
            size={'large'}
            placeholder="select Type"
            style={{ width: '100%' }}
            loading={typeLoader}
            disabled={typeLoader}
          >
            {types.map((item, i) => {
              return (
                <Option key={i} value={item.name}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {/* description */}
        <Form.Item label="Description" className="mb-3" name="description">
          <TextArea rows={4} style={{ width: '100%' }} />
        </Form.Item>

        {/* is  Full day leave */}

        <Form.Item
          labelAlign="right"
          labelCol="flex"
          name="leavePeriod"
          label="Full Day Leave"
          className="fullDaySwitch"
          style={styles.switchCont}
        >
          <Switch checked={isFullDay} onChange={onChangeFullDay} />
        </Form.Item>

        {daily && !isFullDay ? (
          <>
            <GetTimePicker
              name="start"
              label="Start Time"
              placeholder="Select Start Time"
            />
            <GetTimePicker
              name="end"
              label="End Time"
              placeholder="Select End Time"
            />
          </>
        ) : null}

        {!daily ? (
          <>
            <GetDatePicker
              name="start"
              label="Start Time"
              placeholder="Select Start Time"
              isFullDay={isFullDay}
              onChange={(time) => setClockTimes(time, 'clockIn')}
            />
            <GetDatePicker
              name="end"
              label="End Time"
              placeholder="Select End Time"
              isFullDay={isFullDay}
              isEndDate={true}
              onChange={(time) => setClockTimes(time, 'clockOut')}
              clockIn={clockIn}
            />
          </>
        ) : null}

        <Form.Item name="paid" defaultValue={2} label="Is Paid Leave?">
          <Radio.Group defaultValue={2}>
            <Radio value={1}>Paid</Radio>
            <Radio value={2}>Unpaid</Radio>
          </Radio.Group>
        </Form.Item>

        <Button
          type="primary"
          className="bg-primary border-primary d-block mt-5 ml-auto"
          loading={leaveLoader}
          htmlType="submit"
        >
          Add Absent
        </Button>
      </Form>
    </Drawer>
  );
}

export default AddAbsence;
