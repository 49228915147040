import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Avatar, Space, Typography, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import socketHandler from '../../config/socket';
import { getSafetyEmergencies } from '../../redux/activities/action';
import { styles } from './style';
import UserDetailsModal from '../../components/userDetailsModal';
import SafetyDetails from './safetyDetails';
const { Text } = Typography;

function Item({ item, onRowAvatar }) {
  let [visible, setVisible] = useState(false);

  let onClose = () => {
    setVisible(false);
  };

  let openDetails = () => {
    setVisible(true);
  };
  return (
    <>
      <div
        style={{
          ...styles.listItem,
          ...styles.safe,
        }}
        onClick={openDetails}
        className="c-pointer"
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Space align="center" className="c-pointer">
            <Badge dot color={'green'} offset={[0, 30]}>
              <Avatar
                src={item?.user?.profilePic}
                onClick={(e) => onRowAvatar(e, item?.user)}
              />
            </Badge>

            <div className="pl-1">
              <Text strong className="d-block">
                {item?.user?.name?.split(' ')[0]}
              </Text>
              <Text
                type="secondary"
                className="d-block"
                style={styles.roleText}
              >
                {item?.user?.role == 'user' ? 'worker' : item?.user?.role}
              </Text>
            </div>
          </Space>
          <div>
            <Text className="d-block" style={styles.date}>
              {moment(item.createdAt).format('DD-MM-YYYY')}{' '}
            </Text>
            <Text type="secondary" className="d-block" style={styles.time}>
              {moment(item.createdAt).format('hh:mm:ss a')}
            </Text>
          </div>
        </div>
        <Text style={styles.reason} className="d-block mt-2">
          {item?.status ? item?.status : item?.text}
        </Text>
      </div>
      <SafetyDetails visible={visible} onClose={onClose} item={item} />
    </>
  );
}

function Index({ selectedDpt }) {
  const dispatch = useDispatch();
  const { sos, totalSos } = useSelector((state) => state.activitiesReducer);
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);

  const [loader, setLoader] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const [page, setPage] = useState(1);

  const pRef = useRef(1);
  const depRef = useRef(null);
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getSafetyEmergencies(page, selectedDpt, setLoader));
    depRef.current = selectedDpt;
  }, [selectedDpt]);

  const changePage = (page) => {
    setPage(Number(page));
    pRef.current = Number(page);
    setLoader(true);
    dispatch(getSafetyEmergencies(Number(page), selectedDpt, stopLoader));
  };

  const renderTitle = (title) => {
    return <h5>{title}</h5>;
  };

  const onRowAvatar = (event, record) => {
    event.stopPropagation();
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };
    selectUser(userRecord);
    setDetailModal(true);
  };

  const fetchAlert = useCallback(() => {
    dispatch(getSafetyEmergencies(pRef?.current, depRef?.current, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('alert', fetchAlert);
    return () => {
      socketHandler.on('alert', fetchAlert);
    };
  }, []);

  const columns = [
    {
      title: renderTitle('Health and Safety'),
      className: 'bg-transparent pt-0 border-0',
      dataIndex: 'sos',
      render: (item, index) => (
        <Item item={item} key={index} onRowAvatar={onRowAvatar} />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        className="bg-white"
        dataSource={sos?.map((item) => {
          return {
            key: item.id,
            sos: item,
          };
        })}
        size="small"
        loading={loader}
        pagination={{
          onChange: changePage,
          current: page,
          total: totalSos,
          hideOnSinglePage: true,
          pageSize: 10,
        }}
      />
      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
    </>
  );
}

export default Index;
