import * as constants from './constants';
import _ from 'lodash';

let initState = {
  types: [],
  allLeave: [],
  pendingleaves: [],
  approvedleaves: [],
  rejectedleaves: [],
  userleaves: [],
  leaveTotals: { total: 0, pending: 0, approved: 0, rejected: 0 },
  totalLeaves: 0,
  initial: true,
};
export default function (state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_LEAVE_TYPE:
      return {
        ...state,
        types: payload,
      };
    case constants.ADD_LEAVE_TYPE:
      return {
        ...state,
        types: [payload, ...state.types],
      };
    case constants.UPDATE_LEAVE_TYPE:
      return {
        ...state,
        leaves: state.leaves.map((item) =>
          item?.id == payload?.id ? { ...item, status: payload.status } : item
        ),
      };
    case constants.GET_REQUEST:
      return {
        ...state,
        allLeave: payload,
        pendingleaves: payload.filter((_) => _.status == 'pending'),
        approvedleaves: payload.filter((_) => _.status == 'approved'),
        rejectedleaves: payload.filter((_) => _.status == 'rejected'),
        leaveTotals: {
          total: payload.length,
          pending: payload.filter((_) => _.status == 'pending').length,
          approved: payload.filter((_) => _.status == 'approved').length,
          rejected: payload.filter((_) => _.status == 'rejected').length,
        },
      };
    case constants.REMOVE_LEAVE_TYPE:
      return {
        ...state,
        types: state.types.filter((type) => type.id !== payload),
      };

    default:
      return state;
  }
}
