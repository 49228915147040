import { INSTANCE } from '../../config/networkInstance';

export default class SettingsApi {
  createSchedular = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/schedular',
        data: data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateSchedular = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `/schedular/${id}`,
        data: data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteSchedular = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: '/schedular',
        data: { id },
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSchedular = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/schedular',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
