import React from 'react';
import { Modal, Avatar, Tabs, Badge, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'react-bootstrap';

const { TabPane } = Tabs;

function UserDetails({ isVisible, title, handleModal, selectedUser }) {
  return (
    <Modal
      title={title}
      centered
      visible={isVisible}
      onOk={() => handleModal?.(false)}
      onCancel={() => handleModal?.(false)}
      footer={false}
      className="userDetailsModal"
    >
      <Row>
        <Col md={5}>
          <div className="avatarCont">
            <div className="avaInner">
              {/* avatar */}

              <Avatar
                src={selectedUser?.profilePic}
                className="uAvatar"
                icon={<UserOutlined />}
              />
              <p className="detailModalName">{selectedUser?.name}</p>
            </div>
          </div>
        </Col>
        <Col md={7}>
          <div className="userDetailCont">
            <Row>
              <Tabs defaultActiveKey="user">
                <TabPane
                  tab={
                    <div className="uLabelCont">
                      <p className="uDetailHead">User Details</p>
                    </div>
                  }
                  key="user"
                >
                  <Row className="uDetailsMargin">
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Email</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">{selectedUser?.email}</p>
                    </div>
                  </Row>
                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Phone Number</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.phone || '------'}
                      </p>
                    </div>
                  </Row>
                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Role</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">{selectedUser?.role}</p>
                    </div>
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <div className="uLabelCont">
                      <p className="uDetailHead">Company</p>
                    </div>
                  }
                  key="company"
                >
                  <Row className="uDetailsMargin">
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Company Name</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">{selectedUser?.company}</p>
                    </div>
                  </Row>
                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Department</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.organization}
                      </p>
                    </div>
                  </Row>
                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Job</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">{selectedUser?.jobType}</p>
                    </div>
                  </Row>
                </TabPane>

                <TabPane
                  tab={
                    <div className="uLabelCont">
                      <p className="uDetailHead">Personal</p>
                    </div>
                  }
                  key="personal"
                >
                  <Row className="uDetailsMargin">
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Address 1</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.address1 ?? '---'}
                      </p>
                    </div>
                  </Row>
                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Address 2</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.address2 ?? '---'}
                      </p>
                    </div>
                  </Row>
                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Country</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.country ?? '---'}
                      </p>
                    </div>
                  </Row>

                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">State</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.state ?? '---'}
                      </p>
                    </div>
                  </Row>

                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">City</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        {selectedUser?.city ?? '---'}
                      </p>
                    </div>
                  </Row>

                  <Row>
                    <div className="uLabelCont labelMrg">
                      <p className="UDetailLabel">Skills</p>
                    </div>

                    <div>
                      <p className="UDetailsItem">
                        <Space>
                          {selectedUser?.skills?.map((item, index) => (
                            <Badge
                              key={index}
                              className="site-badge-count-109"
                              count={item}
                              style={{ backgroundColor: '#52c41a' }}
                            />
                          ))}
                        </Space>
                      </p>
                    </div>
                  </Row>
                </TabPane>
              </Tabs>
            </Row>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

export default UserDetails;
