import React, { useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { RightOutlined } from '@ant-design/icons';
import { Typography, Checkbox, Image, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AuthLayout from '../layouts/authlayout';
import { adminSignup } from '../../redux/auth/action';
import { styles } from './style';
import Register from '../../assets/images/auth/register.png';

const { Text } = Typography;

function Index({ history }) {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const stopLoader = (success, user = null) => {
    setLoader(false);
    if (success) {
      return history.push('/');
    }
  };

  const registerUser = (values) => {
    const { other_names, first_name, password } = values;
    setLoader(true);
    dispatch(
      adminSignup(
        {
          name: `${first_name} ${other_names}`,
          password,
          isEmailVerified: true,
          token,
        },
        stopLoader
      )
    );
  };

  return (
    <AuthLayout
      left={
        <div>
          <Image
            src={Register}
            preview={false}
            className="text-center img-responsive mt-auto mb-auto"
          />
        </div>
      }
      right={
        <Row className="w-100 justify-content-center ">
          <Col lg={7} xl={8} xxl={9}>
            <Form form={form} layout="vertical" onFinish={registerUser}>
              <h3 className="font-weight-bold text-center">Sign Up</h3>
              <Text
                className="d-block text-center text-secondary"
                style={styles.desc}
              >
                Fill in your details below
              </Text>

              <Row className="mt-4">
                <Col sm={12} lg={6}>
                  <Form.Item
                    name="first_name"
                    className="mb-3 font-weight-bold"
                    label="FIRST NAME"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: 'First Name is required',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Type Your First Name"
                      style={styles.input}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} lg={6}>
                  <Form.Item
                    name="other_names"
                    className="mb-3 font-weight-bold"
                    label="LAST NAME"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: 'Last Name is required',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Type Your Last Name"
                      style={styles.input}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Form.Item
                    name="password"
                    label="YOUR PASSWORD"
                    className="mb-3 font-weight-bold"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: 'Password is required',
                      },
                      {
                        min: 8,
                        message: 'Min 8 characters',
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Type Your Password"
                      size="large"
                      style={styles.input}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} lg={6}>
                  <Form.Item
                    name="confirm"
                    label="REPEATED PASSWORD"
                    className="font-weight-bold"
                    dependencies={['password']}
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: 'Confirm Password is required',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Passwords does not match')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Type Confirm Password"
                      size="large"
                      style={styles.input}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="text-left mb-1 d-flex">
                <Form.Item
                  name="terms"
                  valuePropName="checked"
                  className="font-weight-bold"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error('Should accept Terms')),
                    },
                  ]}
                >
                  <Checkbox>
                    <label>
                      I accept the the{' '}
                      <a
                        rel="noreferrer"
                        href="https://www.privacypolicies.com/live/a137ab9a-3d9b-48ed-a2a5-4bb78279bdf2"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>{' '}
                      of Gigsasa
                    </label>
                  </Checkbox>
                </Form.Item>
              </div>

              <Form.Item className="mb-1">
                <button
                  className="btn btn-primary mt-5 d-flex align-items-center justify-content-between"
                  style={styles.loginButton}
                  htmlType="submit"
                >
                  {loader ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    'Continue'
                  )}
                  <RightOutlined />
                </button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      }
    />
  );
}

export default Index;
