export const GET_SHIFTS = 'GET_SHIFTS';
export const GET_MAIN_SHIFTS = 'GET_MAIN_SHIFTS';
export const DELETE_MAIN_SHIFT = 'DELETE_MAIN_SHIFT';

export const GET_CLOCKED_IN = 'GET_CLOCKED_IN';
export const GET_CLOCKED_OUT = 'GET_CLOCKED_OUT';
export const GET_BREAK_IN = 'GET_BREAK_IN';
export const SHIFTS_STATS = 'SHIFTS_STATS';
export const ALL_EMPLOYEES = 'ALL_EMPLOYEES';
export const ALL_LOCATIONS_SHIFT = 'ALL_LOCATIONS_SHIFT';
export const NEW_EMPLOYEE_SHIFT = 'NEW_EMPLOYEE_SHIFT';
export const SHIFT_PATTERNS = 'SHIFT_PATTERNS';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const CLOCK_IN = 'CLOCK_IN';
export const CLOCK_OUT = 'CLOCK_OUT';
export const RESET_INITIAL_DASHBOARD = 'RESET_INITIAL_DASHBOARD';
