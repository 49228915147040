import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import {
  Form,
  Select,
  DatePicker,
  Input,
  Radio,
  Checkbox,
  TimePicker,
  Typography,
  Drawer,
} from 'antd';
import { eachDayOfInterval } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  getAllEmployees,
  getAllLocations,
  createShift,
  updateShift,
} from '../../redux/shifts/action';
import DepartmentSelector from '../../components/departmentFilter';
import ActivitySelector from './activitySelector';
const { Text, Link } = Typography;
const plainOptions = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
const plainValues = {
  Su: 0,
  M: 1,
  T: 2,
  W: 3,
  Th: 4,
  F: 5,
  S: 6,
};
const convert = (n) =>
  `0${(n / 60) ^ 0}`.slice(-2) + ':' + ('0' + (n % 60)).slice(-2);
export default function CreateShifts({
  show,
  setClose,
  refreshData,
  item = null,
}) {
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allEmployees, allLocations } = useSelector(
    (state) => state.shiftsReducer
  );
  const { user } = useSelector((state) => state.auth);
  const [mLoader, setMLoader] = useState(false);
  const [oLoader, setOLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isRepeat, setRepeat] = React.useState(true);
  const [activity, setActivity] = useState(null);
  const [dept, setDpt] = useState(null);

  const stopLoaderEmp = () => {
    setMLoader(false);
  };

  const stopLocationLoader = () => {
    setOLoader(false);
  };

  const getDayNames = (days) => {
    let result = days
      .map((date) => {
        return new Date(date).getDay();
      })
      .map((time) => plainOptions[time]);
    return [...new Set(result)];
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        shift_title: item?.name,
        employee_id: item?.users?.map((user) => user.id),
        time: moment(item?.start),
        shiftDuration: moment(convert(item?.shiftDuration), 'HH:mm'),
        ...(item?.repeatUntil && { repeat_until: moment(item?.repeatUntil) }),
        ...(item?.location && { location_id: item.location.id }),
        ...(item?.days?.length > 0 && {
          days: getDayNames(item?.days),
        }),
      });
      if (item?.customer) {
        setActivity(item?.customer);
      } else {
        setActivity(null);
      }
      setRepeat(item?.isRepeat);
    }
  }, [item]);

  useEffect(() => {
    setMLoader(true);
    setOLoader(true);
    dispatch(getAllEmployees(stopLoaderEmp));
    dispatch(getAllLocations(stopLocationLoader));
  }, []);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form.resetFields();
      setClose();
      refreshData();
    }
  };

  const onChangeRadio = (e) => {
    setRepeat(e.target.value);
  };

  const getDates = (until, time, days) => {
    const result = [];
    const dates = eachDayOfInterval({
      start: moment(time).toDate(),
      end: moment(until).toDate(),
    });
    dates.map((item) => {
      if (days.includes(item.getDay())) {
        result.push(moment(item).toDate());
      }
    });
    return result;
  };

  const createShiftFunc = (values) => {
    const {
      employee_id,
      time,
      shiftDuration,
      location_id,
      shift_title,
      days,
      repeat_until,
    } = values;
    setLoader(true);
    const shiftDurationMin = moment
      .duration(shiftDuration.format('HH:mm'))
      .asMinutes();
    const endTime = moment(time).add(shiftDurationMin, 'minutes');
    const shiftData = {
      name: shift_title,
      users: employee_id,
      start: time?.toDate?.(),
      end: endTime?.toDate?.(),
      ...(location_id && { location: location_id }),
      isRepeat,
      repeatUntil: repeat_until?.toDate?.(),
      shiftDuration: shiftDurationMin,
      ...(activity && {
        customer: activity,
      }),
    };
    if (repeat_until) {
      let dayValues = days.map((day) => plainValues[day]);
      const startDay = new Date(time.toDate?.()).getDay();
      dayValues.push(startDay);
      dayValues = [...new Set(dayValues)];
      const dates = getDates(repeat_until, time, dayValues);
      shiftData.days = dates;
    }
    if (item) {
      dispatch(updateShift(item.id, shiftData, stopLoader));
    } else {
      dispatch(createShift(shiftData, stopLoader));
    }
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    setMLoader(true);
    dispatch(getAllEmployees(stopLoaderEmp, dept));
  };

  const getActivity = (value) => {
    setActivity(value);
  };

  const createEmployee = () => {
    history.push('/app/members');
  };

  const createLocation = () => {
    history.push('/app/locations');
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={setClose}
      visible={show}
      width={window.innerWidth > 900 ? 500 : window.innerWidth - 100}
      zIndex={1050}
    >
      <React.Fragment>
        <Row>
          <Col md={12}>
            <Form layout="vertical" form={form} onFinish={createShiftFunc}>
              <Form.Item
                label="Shift Name"
                tooltip="This is a required field"
                className="mb-3"
                name="shift_title"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Shift Name',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Shift Name" />
              </Form.Item>

              <div className="pb-3">
                <Text className="d-block pb-2">
                  Filter employee by Department
                </Text>
                <DepartmentSelector
                  onSelectDpt={onChangeDept}
                  pickerStyle={{
                    width: '100%',
                    marginBottom: 10,
                  }}
                  size="large"
                />
              </div>
              <Form.Item
                label="Employee (Select/Create employee)"
                tooltip="This is a required field"
                className="mb-1"
                name="employee_id"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Employees',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  size={'large'}
                  placeholder="Please select"
                  style={{ width: '100%' }}
                  loading={mLoader}
                  disabled={mLoader}
                >
                  {allEmployees
                    .filter((item) => item.id !== user.id)
                    .map((item) => {
                      return <Option key={item.id}>{`${item.name}`}</Option>;
                    })}
                </Select>
              </Form.Item>
              <Link className="mb-3 d-block" onClick={createEmployee}>
                Create New Employee?
              </Link>
              <Text className="mb-2 d-block">Assignment (optional)</Text>
              <ActivitySelector
                onChange={getActivity}
                className="w-100 mb-3"
                size="large"
                value={activity}
              />

              <Form.Item
                label="Shift Start Time"
                tooltip="This is a required field"
                name="time"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Shift Start Time',
                  },
                ]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format="DD-MM-YYYY HH:mm"
                  style={{ width: '100%' }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Shift Duration ( hours )"
                tooltip="This is a required field"
                name="shiftDuration"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Duration',
                  },
                ]}
              >
                <TimePicker
                  showTime={{ format: 'HH:mm' }}
                  format="HH:mm"
                  style={{ width: '100%' }}
                  size="large"
                  showNow={false}
                />
              </Form.Item>
              <Form.Item
                name="location_id"
                label="Locations (optional)"
                className="mb-1"
              >
                <Select
                  placeholder="Select Location"
                  optionFilterProp="children"
                  className="w-100"
                  size="large"
                  loading={oLoader}
                  disabled={oLoader}
                >
                  {allLocations.map((item, i) => {
                    return (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {user?.role == 'root' && (
                <Link className="mb-3 d-block" onClick={createLocation}>
                  Create a Location?
                </Link>
              )}
              <Form.Item>
                <Radio.Group onChange={onChangeRadio} value={isRepeat}>
                  <Radio value={true}>Repeat</Radio>
                  <Radio value={false}>No Repeat</Radio>
                </Radio.Group>
              </Form.Item>

              {isRepeat && (
                <>
                  <Row>
                    <Col md={12} lg={12}>
                      <Form.Item
                        name="repeat_until"
                        label="Repeat Until"
                        tooltip="This is a required field"
                        rules={[
                          {
                            required: true,
                            message: 'Please Select Repeat Until Date',
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          size="large"
                          placeholder="Repeat Until"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="days"
                    label="Days"
                    tooltip="This is a required field"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Pattern Days',
                      },
                    ]}
                  >
                    <CheckboxGroup options={plainOptions} />
                  </Form.Item>
                </>
              )}

              <button
                className="btn py-2 btn-primary btn-block w-100 mt-3"
                htmlType="submit"
                disabled={loader}
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : item ? (
                  'Update'
                ) : (
                  'Create'
                )}
              </button>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    </Drawer>
  );
}
