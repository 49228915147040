export const styles = {
  isActive: {
    marginBottom: 0,
    color: 'rgb(51, 153, 51)',
    fontWeight: '500',
  },

  isDisabled: {
    marginBottom: 0,
    color: '#ff8080',
    fontWeight: '500',
  },
};
