import React, { memo, useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { message, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/settings/action';

const Settings = () => {
  const inputHandler = useRef(0);

  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    profilePic: '',
    name: '',
  });

  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [localFileUrl, setUrl] = useState(null);

  useEffect(() => {
    if (user) {
      setState({
        ...state,
        name: user?.name,
        profilePic: user?.profilePic,
      });
    }

    setUrl(user?.profilePic);

    setFileList([
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: user?.profilePic,
        same: true,
      },
    ]);
  }, [user]);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      message.success('Successfully Updated!');
    }
  };

  const updateProfileFunc = () => {
    const file = fileList;
    const { name } = state;
    if (name == '') {
      message.warning('Name is required!');
      return;
    } else setLoader(true);

    const formData = {};
    const isURL = !fileList[0]?.same;
    formData.name = name;
    if (isURL) {
      formData.file = file;
    }

    dispatch(updateProfile(formData, stopLoader, true));
  };

  const getUserFile = (e) => {
    const file = e?.target?.files?.[0];
    setFileList(file);

    if (file?.type === 'image/jpeg' || file?.type === 'image/png') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUrl(reader.result);
      };
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center mt-5">
          <Col md={12} lg={8}>
            <Form layout="vertical" className="pt-3">
              <div>
                <div className="avaContPro">
                  {localFileUrl ? (
                    <div
                      style={{ position: 'relative' }}
                      onClick={() => inputHandler.current.click()}
                    >
                      <img
                        src={localFileUrl}
                        alt="Place Avatar"
                        className="userAvatarPro"
                      />

                      <div className="uploadHover">
                        <i className="feather icon-camera avaIconUp" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="featEmpty"
                      onClick={() => inputHandler.current.click()}
                    >
                      <i className="feather icon-camera emptyAvaIcon" />
                    </div>
                  )}

                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={inputHandler}
                    onChange={getUserFile}
                  />
                </div>
              </div>

              <Form.Item label="Your Name" className="mt-3">
                <Input
                  size="large"
                  placeholder="Your full name"
                  value={state.name}
                  onChange={handleChange}
                  name="name"
                />
              </Form.Item>
            </Form>
            <div className="comSaveCont">
              <button
                onClick={updateProfileFunc}
                className="btn  btn-primary mb-0 py-2 comSaveBtn"
              >
                {loader ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Settings);
