import { message } from 'antd';
import * as constants from './constants';

import ChargeBeePaymentApi from './ChargeBeeApi';
const ChargeBeeApi = new ChargeBeePaymentApi();

export const getChargeBeePlans = (data, cb) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.getPlans(data);
    dispatch({
      type: constants.GET_PLANS,
      payload: res.data,
    });
    cb?.();
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateSubscription = (data, cb) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.updateSubscription(data);

    dispatch({
      type: constants.GET_ACTIVE_PLANE,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getCardUrl = (id, cb) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.getCardUrl({ userId: id });

    cb(res.data);
  } catch (error) {
    cb(error);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getSubscription = (cb) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.getSubscription();

    dispatch({
      type: constants.GET_ACTIVE_PLANE,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb(error);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getPortalUrl = (cb) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.getPortalUrl();

    cb(res.data);
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const cancelSubscription = (cb) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.cancelSubscription();

    dispatch({
      type: constants.CANCEL_SUB,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb(error);
    message.error(error.response?.data.message ?? error.message);
  }
};
export const createPkgSubscription = (data) => async (dispatch) => {
  try {
    const res = await ChargeBeeApi.subscribe(data);
    // dispatch({
    //   type: constants.GET_PLANS,
    //   payload: res.data,
    // });
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getCurrentStatus = () => async (dispatch) => {
  try {
    // get subscription
    const subscription = await ChargeBeeApi.getSubscriptionStatus();
    dispatch({
      type: constants.GET_SUB_STATUS,
      payload: subscription.data,
    });
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getCustomer = (company) => async (dispatch, getState) => {
  try {
    // get subscription
    if (company) {
      const res = await ChargeBeeApi.getCustomer({
        id: company?.paymentDetails?.chargeBeeUser,
      });
      dispatch({
        type: constants.CHARGEBEE_CUSTOMER,
        payload: res?.data,
      });
    }
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};
