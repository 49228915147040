import React, { useState, useEffect, memo, useRef } from 'react';
import {
  Modal,
  Form,
  Input,
  Space,
  message,
  Select,
  DatePicker,
  AutoComplete,
  Checkbox,
  List,
  Typography,
  Switch,
  Button,
} from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DepartmentFilter from '../../components/departmentFilter';

import moment from 'moment';
import {
  createTask,
  updateTasks,
  deleteTasks,
  multiCreateTask,
  getTasksExport,
} from '../../redux/tasks/action';
import ActivityFilter from '../timeSheets/activityFilter';
import TaskItem from './taskItem';
//import DatalistInput from 'react-datalist-input';
import { getAllLocations } from '../../redux/shifts/action';
import { getAllDepartments } from '../../redux/members/action';
import ExportPref from './exportPreferences';

import excelJs from 'exceljs';
import saveAs from 'file-saver';

const { Option } = Select;
var _location_ = '';

const { Link, Text } = Typography;

export default memo(function Index({ show, handleOk, setClose, activeTasks }) {
  const dispatch = useDispatch();
  const { allLocations } = useSelector((state) => state.shiftsReducer);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [form] = Form.useForm();

  const [loader, setLoader] = useState(false);
  const [activity, setActivity] = useState(null);
  const [locLoader, setLocLoader] = useState(false);
  const [item, setItem] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [oLoader, setOLoader] = useState(false);
  const [_location, _setLocation] = useState('');
  const [dept, setDept] = useState(null);

  const [range, setRange] = useState(null);
  const [raw, setRaw] = useState(false);
  const [side, setSide] = useState(false);

  const exportExcel = (_range, _dept) => {
    if (_range) {
      setLoader(true);
      dispatch(
        getTasksExport(
          { start: _range[0], end: _range[1], organization: dept },
          customTimeSheet
        )
      );
    }
  };
  const rawExport = (_range, _dept) => {
    if (_range) {
      setLoader(true);
      dispatch(
        getTasksExport(
          { start: _range[0], end: _range[1], organization: dept },
          customRow
        )
      );
    }
  };

  const customTimeSheet = (result) => {
    var final = result.map((_) => {
      var _task = {
        ID: _.id,
        Date: moment(_.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
        Title: _.title,
        User: _.user_id.name,
        Status: _.status,
        'Start Date': _?.startTime
          ? moment(_.startTime).format('MMMM Do YYYY, h:mm:ss a')
          : '',
        'Deadline Date': _?.endTime
          ? moment(_.endTime).format('MMMM Do YYYY, h:mm:ss a')
          : '',
        'Intended Location': _?.location
          ? _.location.coordinates.latitude +
            ' , ' +
            _.location.coordinates.longitude
          : '',
        'End Location': _?.endLocation
          ? _.endLocation.latitude + ' , ' + _.endLocation.longitude
          : '',
        Peoject: _?.project ? _?.project : '',
      };

      return _task;
    });
    _generateExcelFile(final);
    setLoader(false);
  };

  const customRow = (result) => {
    var _list = [];

    var final = [];

    var maps = result.map((_) => {
      var list = [];
      _.times.forEach((element) => {
        var _v1 = {
          ID: _.id,
          Date: moment(_.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
          Title: _.title,
          User: _.user_id.name,
          Status: _.status,
          'Start Date': _?.startTime
            ? moment(_.startTime).format('MMMM Do YYYY, h:mm:ss a')
            : '',
          'Deadline Date': _?.endTime
            ? moment(_.endTime).format('MMMM Do YYYY, h:mm:ss a')
            : '',
          'Intended Location': _?.location
            ? _.location.latitude + ' , ' + _.location.longitude
            : '',
          'End Location': _?.endLocation
            ? _.endLocation.latitude + ' , ' + _.endLocation.longitude
            : '',
          Peoject: _?.project ? _?.project : '',
          'Start Date': moment(element.in).format('MMMM Do YYYY, h:mm:ss a'),
          'Start Location': element?.locationIn
            ? element.locationIn.latitude + ' , ' + element.locationIn.longitude
            : '',
          'Stop Date': moment(element.out).format('MMMM Do YYYY, h:mm:ss a'),
          'Stop Location': element?.locationOut
            ? element.locationOut.latitude +
              ' , ' +
              element.locationOut.longitude
            : '',

          AnswerIn: element.answersIn ? element.answersIn : [],
          AnswerOut: element.answersOut ? element.answersOut : [],
        };

        list = [...list, _v1];
      });
      return list;
    });

    maps.forEach((element) => {
      element.forEach((element1) => {
        _list = [..._list, element1];
      });
    });

    _list.forEach((element) => {
      if (element.AnswerIn.length > element.AnswerOut.length) {
        for (let index = 0; index < element.AnswerIn.length; index++) {
          var _ans = {
            ...element,
            'Start Question': element.AnswerIn[index]?.question.question
              ? element.AnswerIn[index].question.question
              : '',
            'Start Answer': element.AnswerIn[index]?.answer
              ? element.AnswerIn[index].answer
              : '',
            'Stop Question': element.AnswerOut[index]?.answer
              ? element.AnswerOut[index].answer
              : '',
            'Stop Answer': element.AnswerOut[index]?.question.question
              ? element.AnswerOut[index].question.question
              : '',
          };

          delete _ans.AnswerIn;
          delete _ans.AnswerOut;

          final = [...final, _ans];
        }
      } else if (element.AnswerIn.length < element.AnswerOut.length) {
        for (let index = 0; index < element.AnswerOut.length; index++) {
          var _ans = {
            ...element,
            'Start Question': element.AnswerIn[index]?.question.question
              ? element.AnswerIn[index].question.question
              : '',
            'Start Answer': element.AnswerIn[index]?.answer
              ? element.AnswerIn[index].answer
              : '',
            'Stop Question': element.AnswerOut[index]?.answer
              ? element.AnswerOut[index].answer
              : '',
            'Stop Answer': element.AnswerOut[index]?.question.question
              ? element.AnswerOut[index].question.question
              : '',
          };

          delete _ans.AnswerIn;
          delete _ans.AnswerOut;

          final = [...final, _ans];
        }
      } else if (
        element.AnswerIn.length == element.AnswerOut.length &&
        element.AnswerOut.length != 0
      ) {
        for (let index = 0; index < element.AnswerIn.length; index++) {
          var _ans = {
            ...element,
            'Start Question': element.AnswerIn[index]?.question.question
              ? element.AnswerIn[index].question.question
              : '',
            'Start Answer': element.AnswerIn[index]?.answer
              ? element.AnswerIn[index].answer
              : '',
            'Stop Question': element.AnswerOut[index]?.answer
              ? element.AnswerOut[index].answer
              : '',
            'Stop Answer': element.AnswerOut[index]?.question.question
              ? element.AnswerOut[index].question.question
              : '',
          };

          delete _ans.AnswerIn;
          delete _ans.AnswerOut;

          final = [...final, _ans];
        }
      } else {
        var _ans = {
          ...element,
          'Start Question': '',
          'Start Answer': '',
          'Stop Question': '',
          'Stop Answer': '',
        };

        delete _ans.AnswerIn;
        delete _ans.AnswerOut;

        final = [...final, _ans];
      }
    });

    // const data = parseExportData(timeSheet, exportSchema);
    _generateExcelFile(final);
    setLoader(false);
  };

  const _generateExcelFile = (parseResult) => {
    let start = moment(range[0]).format('DD-MM-YYYY');
    let end = moment(range[1]).format('DD-MM-YYYY');
    const workbook = new excelJs.Workbook();

    const headers = Object.keys(parseResult?.[0] || {});

    const sheet = workbook.addWorksheet('Tasks');
    sheet.mergeCells(1, 1, 1, headers.length || 5);

    sheet.getRow(2).values = headers;

    sheet.columns = headers.map((item) => ({
      header: item,
      key: item,
      width: 30,
      style: {
        font: { name: 'Arial Black' },
        alignment: {
          vertical: 'center',
          horizontal: item === 'Members' ? 'left' : 'center',
        },
      },
    }));

    sheet.addRows(parseResult);
    sheet.getCell('A1').value = `Gigsasa Task Manager (${start} to ${end})`;
    sheet.getCell('A1').style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0099ff' },
      },
      font: {
        size: 16,
        bold: true,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };

    sheet.getRow(2).style = {
      font: {
        size: 11,
        bold: true,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };

    sheet.getCell('A2').style = {
      font: {
        size: 11,
        bold: true,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'left',
      },
    };

    sheet.getRow(1).height = 40;
    sheet.getRow(2).height = 20;

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'gigsasaTasks.xlsx'
      );
    });
  };

  const locRef = useRef(null);
  const { RangePicker } = DatePicker;

  const { allDept } = useSelector((state) => state.membersReducer);
  useEffect(() => {
    if (allDept.length === 0) {
      setLocLoader(true);
      dispatch(getAllDepartments(() => setLocLoader(false)));
    }
  }, []);

  useEffect(() => {
    if (activeTasks) {
      setTasks(activeTasks);
    } else setTasks(null);
  }, [activeTasks]);

  useEffect(() => {
    if (allLocations.length == 0) {
      setLocLoader(true);
      dispatch(getAllLocations(() => setLocLoader(false)));
    }
  }, []);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      message.success(item ? 'Successfully Updated!' : 'Successfully Created!');
      setItem(null);
      handleOk();
    }
  };

  const openSide = () => {
    setSide(true);
  };

  const closeSide = () => {
    setSide(false);
  };

  const createTaskFunc = (values) => {
    if (dept) {
      let _employees = allEmployees.filter((a) => {
        if (a.organization == values.department_id) {
          return a;
        }
      });

      const tt = allLocations.find((o) => o.name === location)?.id;
      var _tasks = _employees.map((_employee) => {
        if (tt) {
          return {
            title: values.title,
            startTime: values.startTime,
            endTime: values.endTime,
            user_id: _employee.id,
            location: tt,
            organization: values.department_id,
            company: currentUser?.company_id?.id,
          };
        } else {
          return {
            title: values.title,
            startTime: values.startTime,
            endTime: values.endTime,
            user_id: _employee.id,
            organization: values.department_id,
            company: currentUser?.company_id?.id,
          };
        }
      });

      setLoader(true);
      dispatch(multiCreateTask(_tasks, stopLoader));
    } else {
      setLoader(true);
      if (item) {
        let { user_id, title, startTime, endTime, location } = values;
        let filterArr = allEmployees.filter((user) => user?.id == user_id);
        let obj = {
          title,
          startTime,
          endTime,
          location: find(location),
          user_id: currentUser?.role == 'user' ? currentUser?.id : user_id,
          ...(filterArr?.length > 0 &&
            currentUser?.role !== 'user' && {
              organization: filterArr?.[0]?.organization,
            }),
          ...(currentUser?.role == 'user' && {
            organization: currentUser?.organization?.id,
          }),
        };
        const tt = allLocations.find((o) => o.name === location)?.id;

        if (tt) {
          obj.location = tt;
        }

        let params = { taskId: item.id };
        dispatch(updateTasks(params, obj, stopLoader));
      } else {
        var _users = [];
        const tt = allLocations.find((o) => o.name === values.location)?.id;

        if (currentUser?.role == 'user') {
          let obj = {
            ...values,
            user_id: currentUser?.id,
            organization: currentUser?.organization?.id,
            company: currentUser?.company_id?.id,
          };
          const tt = allLocations.find((o) => o.name === obj.location)?.id;

          if (tt) {
            obj.location = tt;
          }

          dispatch(createTask(obj, stopLoader));
          return;
        }

        if (tt) {
          values.user_id.forEach((element) => {
            var { title, startTime, endTime } = values;
            _users = [
              ..._users,
              {
                title,
                startTime,
                endTime,
                location: tt,
                ...(currentUser?.role == 'user'
                  ? {
                      user_id: currentUser?.id,
                    }
                  : { user_id: element }),
                ...(currentUser?.role !== 'user'
                  ? {
                      organization: allEmployees.filter(
                        (user) => user?.id == element
                      )?.[0]?.organization,
                    }
                  : { organization: currentUser?.organization?.id }),
                company: currentUser?.company_id?.id,
              },
            ];
          });
        } else {
          values.user_id.forEach((element) => {
            var { title, startTime, endTime } = values;
            _users = [
              ..._users,
              {
                title,
                startTime,
                endTime,
                ...(currentUser?.role == 'user'
                  ? {
                      user_id: currentUser?.id,
                    }
                  : { user_id: element }),
                ...(currentUser?.role !== 'user'
                  ? {
                      organization: allEmployees.filter(
                        (user) => user?.id == element
                      )?.[0]?.organization,
                    }
                  : { organization: currentUser?.organization?.id }),
                company: currentUser?.company_id?.id,
              },
            ];
          });
        }
        dispatch(multiCreateTask(_users, stopLoader));
      }
    }

    //find all members

    return;
  };

  const onChangeDate = (dates, dateStrings) => {
    setRange(dateStrings);
  };

  const onChangeActivity = (value) => {
    setActivity(value);
  };

  const find = (location) => {
    return allLocations.find((o) => o.name === __test).id;
  };
  /**
   * this is when task type is changed
   * @param {event} e
   */
  function onChange(e) {
    setDept(e);
  }

  const handleRaw = (value) => {
    setRaw(value);
  };

  function handleChange(value) {}

  const editTask = (item) => {
    setItem(item);
    form.setFieldsValue({
      title: item?.title,
      startTime: moment(item?.startTime),
      endTime: moment(item?.endTime),
      user_id: item?.user_id?.id,
      location: item?.location,
    });
  };

  const deleteTask = (task, cb) => {
    dispatch(
      deleteTasks(task, () => {
        setTasks([...tasks].filter((obj) => obj.id !== task.id));
        cb();
      })
    );
  };

  const handleClose = () => {
    setItem(null);
    setClose();
    form?.resetFields();
  };
  function Func() {
    var input, filter, a;

    // take user input
    input = document.getElementById('userInput');

    // convert the input to upper case letter for easy comparison
    filter = input.value.toUpperCase();

    div = document.getElementById('Dropdown');

    // get all the options as a list
    a = div.getElementsByTagName('option');

    // iterate through the entire list and output relevant results if found
    for (var i = 0; i < a.length; i++) {
      txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  }

  return (
    <>
      <Modal
        title={'Export Task'}
        visible={show}
        onCancel={handleClose}
        width={600}
        footer={null}
      >
        <DepartmentFilter onSelectDpt={onChange} className="fullWidth" />
        <div className="mt-4" />
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
          }}
          size="medium"
          onChange={onChangeDate}
          className="w-100"
        />
        <div className="d-flex flex-row align-items-center justify-content-between mt-2">
          {/* <div>
                    {!raw && (
                      <Link strong onClick={openSide} className="d-block">
                        Customize your export ?
                      </Link>
                    )}
                  </div> */}
          <Space>
            <Text>Raw Export</Text>
            <Switch onClick={handleRaw} checked={raw} />
          </Space>
        </div>
        <div className="col-12">
          <Button
            type="primary"
            className="bg-primary border-primary ml-auto d-block mt-3"
            loading={loader}
            disabled={!range}
            onClick={() => {
              if (raw) rawExport(range, dept);
              else exportExcel(range, dept);
            }}
          >
            {' '}
            Proceed
          </Button>
        </div>
      </Modal>
      <ExportPref show={side} handleCancel={closeSide} />
    </>
  );
});
