import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, message } from 'antd';
import { Spinner } from 'react-bootstrap';
import authLogoDark from '../../assets/images/auth/auth-logo-dark.png';
import { styles } from './style';
import { updatePasswordAction, logoutUser } from '../../redux/auth/action';

const Index = ({ history }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      message.success('Password Successfully Changed!');
      history.push('/app/dashboard/analytics');
    }
  };
  const changePassword = (values) => {
    setLoader(true);

    const updatePs = {
      password: values.password,
      isInitialPassword: false,
    };

    dispatch(updatePasswordAction(user.id, updatePs, stopLoader));
  };

  const logOutUser = () => {
    dispatch(logoutUser());
    history.push('/auth/login');
  };
  return (
    <React.Fragment>
      <div className="auth-wrapper align-items-stretch aut-bg-img">
        <div className="flex-grow-1">
          <div className="h-100 d-md-flex align-items-center auth-side-img">
            <div className="col-sm-10 auth-content w-auto">
              <h1 className="text-white my-4">Update Password Mandatory!</h1>
              <h4 className="text-white font-weight-normal">
                Update your account password and make your self more securely
                Gigsasa Member
              </h4>
            </div>
          </div>
          <div className="auth-side-form">
            <div className=" auth-content">
              <div className="d-flex justify-content-center">
                <img
                  src={authLogoDark}
                  alt=""
                  className="img-fluid mb-4"
                  style={styles.logo}
                />
                <h2 className="pl-3">Gigsasa</h2>
              </div>
              <h4 className="mb-3 f-w-400">Update your password</h4>
              <Form layout="vertical" form={form} onFinish={changePassword}>
                <Form.Item
                  name="password"
                  label="New Password"
                  required
                  tooltip="This is a required field"
                  rules={[
                    {
                      required: true,
                      message: 'Please input New password!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter your new password"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  dependencies={['password']}
                  label="Confirm Password"
                  required
                  tooltip="This is a required field"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm new password"
                    size="large"
                  />
                </Form.Item>
                <button
                  htmlType="submit"
                  className="btn btn-block btn-primary mb-4"
                >
                  {loader ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    'Update Password'
                  )}
                </button>
              </Form>
              <div className="text-center">
                <div className="saprator my-4">
                  <span>OR</span>
                </div>
                <button
                  className="btn btn-block btn-outline-danger mb-4"
                  onClick={logOutUser}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;
