import React from 'react';
import { Tabs } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import { useSelector } from 'react-redux';
import Custom from './myTask';
import Daily from './allTask';
import Members from './membersTask';
import Dashboard from './dashboard';

import AllTasks from './allTask';
import CompleteTasks from './completeTask';
import OngoingTasks from './ongoingTask';
import PendingTasks from './pendingTask';

const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar color-primary"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

function Index() {
  const { user, company } = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <h3 className="mb-3"> Tasks</h3>
      <StickyContainer>
        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} size="large">
          {user.role !== 'user' && (
            <>
              <TabPane tab="Dashboard " key="1">
                {/* <Members /> */}
                <Dashboard />
              </TabPane>
              <TabPane tab="Members Tasks" key="2">
                <Members />
              </TabPane>
            </>
          )}

          <TabPane tab="My Task" key="3">
            <Custom />
          </TabPane>

          {user.role !== 'user' && (
            <>
              <TabPane tab="All Tasks" key="4">
                <AllTasks />
              </TabPane>
              <TabPane tab="Complete Task" key="5">
                <CompleteTasks />
              </TabPane>
              <TabPane tab="Onging Task" key="6">
                <OngoingTasks />
              </TabPane>
              <TabPane tab="Pending Task" key="7">
                <PendingTasks />
              </TabPane>
            </>
          )}
        </Tabs>
      </StickyContainer>
    </React.Fragment>
  );
}

export default Index;
