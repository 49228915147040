import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Input, Form, message, Typography, Image } from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';

import AuthLayout from '../layouts/authlayout';
import { Redirect, useLocation } from 'react-router-dom';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import authLogoDark from '../../assets/images/auth/auth-logo-dark.png';
import { styles } from './style';
import Login from '../../assets/images/illustrations/otp.png';
import Forgot from '../../assets/images/illustrations/forgot.png';
import Reset from '../../assets/images/illustrations/reset.png';

import {
  resetPasswordAction,
  ValidateOTPCode,
  changePassword2,
} from '../../redux/auth/action';
let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const { Text } = Typography;

const Index = ({ history }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [resend, setResend] = useState(false);
  const [_email, setEmail] = useState(false);
  const [_code, setCode] = useState(false);
  const location = useLocation();

  const [page, setPage] = useState(0);

  const loginUser = (values) => {
    let { code } = values;
    setCode(code);
    setLoader(true);
    dispatch(ValidateOTPCode({ code: code, email: _email }, stopLoader2));
  };
  const resendCode = () => {};

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      setPage(1);

      message.success(
        'An email / sms  with rest Instructions has been sent to you!'
      );
    }
  };

  const stopLoader2 = (success) => {
    setLoader(false);
    if (success) {
      setPage(2);
    }
  };

  const resetPassword = (values) => {
    let { type } = values;
    let email = null;
    let phone = null;
    setEmail(type);

    if (type?.match?.(regexEmail)) {
      email = type;
    } else {
      phone = type;
    }
    setLoader(true);
    dispatch(
      resetPasswordAction(
        {
          ...(email && { email }),
          ...(phone && { phone }),
        },
        stopLoader
      )
    );
  };

  const _changePassword = (values) => {
    let { password } = values;

    setLoader(true);
    dispatch(
      changePassword2({ code: _code, email: _email, password }, (success) => {
        setLoader(false);
        if (success) {
          message.success('Password Successfully Changed! Now you can login');
          history.push('/auth/login');
        }
      })
    );
  };
  return (
    <React.Fragment>
      {page == 0 && (
        <div className="auth-wrapper align-items-stretch aut-bg-img">
          <div className="flex-grow-1">
            <div className="h-100 d-md-flex align-items-center auth-side-img">
              <div className="col-sm-10 auth-content w-auto">
                <h1 className="text-white my-4">Resend Password!</h1>
              </div>
            </div>
            <div className="auth-side-form">
              <div className=" auth-content">
                <div className="d-flex justify-content-center">
                  <img
                    src={authLogoDark}
                    alt=""
                    className="img-fluid mb-4"
                    style={styles.logo}
                  />
                  <h2 className="pl-3">Gigsasa</h2>
                </div>
                <h4 className="mb-3 f-w-400">Reset your password</h4>
                <Form layout="vertical" form={form} onFinish={resetPassword}>
                  <Form.Item
                    name="type"
                    className="mb-3"
                    label="Email/Phone"
                    required
                    tooltip="This is a required field"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Email / Phone!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="large"
                      placeholder="Enter your email / phone"
                    />
                  </Form.Item>
                  <button
                    htmlType="submit"
                    className="btn btn-block btn-primary mb-4"
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      'Reset password'
                    )}
                  </button>
                </Form>
                <div className="text-center">
                  <div className="saprator my-4">
                    <span>OR</span>
                  </div>
                  <p className="mb-0 text-muted">
                    Don’t have an account?{' '}
                    <NavLink to="/auth/signup" className="f-w-400">
                      Signup
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {page == 1 && (
        <AuthLayout
          left={
            <div>
              <Image
                src={Login}
                preview={false}
                className="text-center img-responsive mt-auto mb-auto"
              />
            </div>
          }
          right={
            <>
              <Row className="w-100 justify-content-center">
                <Col lg={7} xl={8} xxl={9} sm={12}>
                  <Form form={form1} layout="vertical" onFinish={loginUser}>
                    <h2 className="font-weight-bold text-primary text-center">
                      Verify Your Account{' '}
                    </h2>
                    <Text
                      className="d-block pt-1 text-center mb-5"
                      style={styles.desc}
                    >
                      Your code has been sent to <b> {_email}</b>
                    </Text>
                    <Text className="d-block  " style={styles.desc}>
                      Enter to access code activate account
                    </Text>

                    <Row className="mt-4">
                      <Col sm={12}>
                        <Form.Item
                          name="code"
                          required={false}
                          className="mb-3  font-weight-bold"
                          label=""
                          rules={[
                            { required: true, message: 'Please enter code' },
                            {
                              min: 5,
                              max: 5,
                              message: 'Code must be  5 characters.',
                            },
                          ]}
                        >
                          <Input
                            type={'text'}
                            maxLength={5}
                            size="large"
                            placeholder="Enter 5 digit code "
                            style={styles.input}
                            suffix={
                              <LockOutlined style={{ color: '#C0C0C0' }} />
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item className="pb-1 mb-0 w-100">
                      <button
                        className="btn btn-secondary mt-4"
                        style={styles.loginButton}
                        htmlType="submit"
                      >
                        {loader ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        ) : (
                          'Verify'
                        )}
                      </button>
                    </Form.Item>
                    {resend ? (
                      <Text className=" text-center">
                        OTP has been sent please check phone/email{' '}
                      </Text>
                    ) : (
                      <Text className=" text-center">
                        Didn’t recieve the Code ?{' '}
                        <Text
                          strong
                          className="text-primary c-pointer"
                          onClick={resendCode}
                        >
                          Resend
                        </Text>
                      </Text>
                    )}
                  </Form>
                </Col>
              </Row>
            </>
          }
        />
      )}

      {page == 2 && (
        <AuthLayout
          left={
            <div>
              <Image
                src={Reset}
                preview={false}
                className="text-center img-responsive mt-auto mb-auto"
              />
            </div>
          }
          right={
            <>
              <Row className="w-100 justify-content-center">
                <Col lg={7} xl={8} xxl={9} sm={12}>
                  <Form
                    form={form2}
                    layout="vertical"
                    onFinish={_changePassword}
                  >
                    <h2 className="font-weight-bold text-primary text-center">
                      Change securely!{' '}
                    </h2>

                    <Text className="d-block  " style={styles.desc}>
                      Change your account password and make your self more
                      securely
                    </Text>

                    <Row className="mt-4">
                      <Col sm={12}>
                        <Form.Item
                          name="password"
                          label="NEW PASSWORD"
                          className="mb-3 font-weight-bold"
                          required={false}
                          rules={[
                            {
                              required: true,
                              message: 'Password is required',
                            },
                            {
                              min: 8,
                              message: 'Min 8 characters',
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="Type Your Password"
                            size="large"
                            style={styles.input}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col sm={12}>
                        <Form.Item
                          name="confirm"
                          label="REPEATED PASSWORD"
                          className="font-weight-bold"
                          dependencies={['password']}
                          required={false}
                          rules={[
                            {
                              required: true,
                              message: 'Confirm Password is required',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('Passwords does not match')
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder="Type Confirm Password"
                            size="large"
                            style={styles.input}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item className="pb-1 mb-0 w-100">
                      <button
                        className="btn btn-secondary mt-4"
                        style={styles.loginButton}
                        htmlType="submit"
                      >
                        {loader ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        ) : (
                          'Change Password'
                        )}
                      </button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </>
          }
        />
      )}
    </React.Fragment>
  );
};

export default Index;
