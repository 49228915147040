import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import AuthLayout from '../layouts/authlayout';
import { useDispatch, useSelector } from 'react-redux';
import { updateSteps } from '../../redux/auth/action';
import { LeftChoose, RightChoose } from './choose';
import { LeftOrg, RightOrg } from './createOrganization';
import { LeftOrgMore, RightOrgMore } from './organizationMore';
import { LeftJoin, RightJoin } from './teamJoin';
import { LeftApp, RightApp } from './downloadApp';

function Index({ history }) {
  const { step, isLogin, isEmailVerified } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const savedValues = localStorage.getItem('companyData');
    if (Object.values(savedValues || {}).length && isLogin && isEmailVerified) {
      setSteps(3);
    }
  }, [isLogin, isEmailVerified]);

  const setSteps = (value) => {
    dispatch(updateSteps(value));
  };

  if (!isLogin || !isEmailVerified) {
    return <Redirect to="/auth/login" />;
  }

  const goDashboard = () => {
    history.push('/app/dashboard/analytics');
  };

  let Left;
  let Right;

  switch (step) {
    case 1:
      Left = LeftChoose;
      Right = RightChoose;
      break;
    case 2:
      Left = LeftOrg;
      Right = RightOrg;
      break;
    case 3:
      Left = LeftOrgMore;
      Right = RightOrgMore;
      break;
    case 'a':
      Left = LeftJoin;
      Right = RightJoin;
      break;
    case 'b':
      Left = LeftApp;
      Right = RightApp;

      break;
    default:
      break;
  }

  return (
    <AuthLayout
      left={<Left steps={step} />}
      right={
        <Row className="w-100 justify-content-center">
          <Col lg={8} xl={8} xxl={9} sm={12}>
            <Right setSteps={setSteps} goDashboard={goDashboard} />
          </Col>
        </Row>
      }
    />
  );
}

export default Index;
