import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Avatar,
  Tag,
  Collapse,
  Tooltip,
  Statistic,
} from 'antd';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { getAllType } from '../../redux/leave/action';
import {
  PlusOutlined,
  ReloadOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  RedoOutlined,
  EditOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import socketHandler from '../../config/socket';
import {
  getLeaveRequests,
  changeLeaveStatus,
  getLeaveTotals,
} from '../../redux/leaveManagement/action';
import { styles } from './style';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import UserDetailsModal from '../../components/userDetailsModal';
import TotalLeaves from './totalLeaves';

const { Text } = Typography;
const { Panel } = Collapse;

const EditableTable = () => {
  const dispatch = useDispatch();
  const { types, initial } = useSelector((state) => state.leave);
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);

  const renderData = () => {
    const data = types.map((item) => {
      return {
        key: item.id,
        title: item?.name,
        from: moment(item?.from).format('DD-MM-YYYY'),
        to: moment(item?.to).format('DD-MM-YYYY'),
        description: item?.description,
        days: item?.days,
        paid: item?.paid ? 'YES' : 'NO',
      };
    });
    return data;
  };
  const [form] = Form.useForm();
  const [data, setData] = useState(types.length > 0 ? renderData() : []);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedDpt, setDpt] = useState(null);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const pRef = useRef(1);
  const depRef = useRef(null);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    dispatch(getAllType(company.id));
  }, []);

  useEffect(() => {
    if (Array.isArray(types)) {
      const data = renderData();
      setData(data);
    }
  }, [types]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getLeaveRequests(1, selectedDpt, stopLoader));
    dispatch(getLeaveTotals(selectedDpt));
  };

  const changePage = (page) => {
    setPage(page);
    pRef.current = Number(page);
    setLoader(true);
    dispatch(getLeaveRequests(page, selectedDpt, stopLoader));
  };
  const onChangeDept = (dept) => {
    setDpt(dept);
    depRef.current = dept;
    setLoader(true);
    dispatch(getLeaveRequests(page, dept, stopLoader));
    dispatch(getLeaveTotals(dept));
  };

  const updateLeave = (status, item) => {
    setLoader(true);
    const formData = {};
    formData.status = status;
    dispatch(changeLeaveStatus(item.key, formData, item, stopUpdateLoader));
  };

  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';

    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const fetchUpdates = useCallback(() => {
    dispatch(getLeaveRequests(pRef?.current, depRef?.current, () => {}));
    dispatch(getLeaveTotals(depRef?.current));
  }, []);

  useEffect(() => {
    socketHandler.on('leaveRequest', fetchUpdates);
    return () => {
      socketHandler.off('leaveRequest', fetchUpdates);
    };
  }, []);

  const _renderUser = (user) => {
    return (
      <Space
        align="center"
        className="c-pointer"
        onClick={() => onRowAvatar(user)}
      >
        <Avatar src={user?.profilePic} size="large" />
        <Text strong className="pl-2 font-weight-bold">
          {user?.name}
        </Text>
      </Space>
    );
  };

  const columns = [
    {
      title: 'Leave Type',
      dataIndex: 'title',
      editable: true,
      align: 'left',
      render: (_) => {
        return _ + ' Leave';
      },
    },

    {
      title: 'Amount of Days',
      dataIndex: 'days',
      editable: true,
      align: 'center',
      render: (_) => {
        return _ + ' Days';
      },
    },
    {
      title: 'Effective From',
      dataIndex: 'from',
      editable: true,
      align: 'center',
    },
    {
      title: 'Effective To',
      dataIndex: 'to',
      editable: true,
      align: 'center',
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      editable: true,
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        return (
          <Space align="center" size="large">
            <Button shape="circle">
              <EditOutlined />
            </Button>
            <Popconfirm title="Sure you want to Delete?" onConfirm={() => {}}>
              <Button shape="circle" type="danger" className=" text-white ">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Table
        className="table-curved"
        columns={columns}
        dataSource={data}
      ></Table>
    </React.Fragment>
  );
};

export default memo(EditableTable);
