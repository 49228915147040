import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Upload,
  message,
  Tooltip,
  Modal,
  Input,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DownloadOutlined,
  ReloadOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Row, Spinner } from 'react-bootstrap';
import CreateModal from './inviteMember';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getMembers,
  searchMembers,
  getAllDepartments,
  updateMembers,
  uploadMembersAction,
  deleteMember,
  resendEmailVerification,
} from '../../redux/members/action';
import EditableCell from './editableCell';
import DepartmentFilter from '../../components/departmentFilter';
import { sample } from './data';
import UserDetailsModal from '../../components/userDetailsModal';
import { styles } from './style';
import ExportDataModal from './exportDataModal';
import { getAllEmployees } from '../../redux/shifts/action';
import { getExportableData } from './util';
import authApi from '../../redux/auth/authApi';
let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const XLSX = require('xlsx');
const { Text } = Typography;
const { Search } = Input;
const Api = new authApi();

const EditableTable = () => {
  const dispatch = useDispatch();
  const { members, allDept, allDeptByIds, totalMembers, initial } = useSelector(
    (state) => state.membersReducer
  );
  const { isMemberHelp } = useSelector((state) => state.tourReducer);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [uloader, setULoader] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [file, setFile] = useState([]);
  const [selectedDpt, setDpt] = useState(null);
  const isEditing = (record) => record.key === editingKey;
  // for editing
  const [dLoader, setDLoader] = useState(false);
  const [rLoader, setRLoader] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [expModal, setExpModal] = useState(false);
  const [expLoader, setExpLoader] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    if (allDept.length == 0) {
      setDLoader(true);
      dispatch(getAllDepartments(() => setDLoader(false)));
    }
  }, []);

  const getJobTypes = (orgId) => {
    const selectedOrg =
      allDeptByIds[orgId] || allDeptByIds[getDepartmentId(orgId)];
    if (selectedOrg) {
      setJobTypes(selectedOrg.jobTypes);
    }
  };

  function capitalize(word) {
    return word?.[0]?.toUpperCase() + word?.slice?.(1)?.toLowerCase();
  }

  // editing end

  const renderData = () => {
    const data = members.map((item) => {
      return {
        key: item?.id,
        name: item?.name,
        email: item?.email ?? item?.phone,
        organization: item?.organization?.name || '',
        jobType: item?.jobType,
        role: item?.role == 'user' ? 'Worker' : capitalize?.(item?.role),
        kiosk: item?.kiosk,
        active: item?.active ? true : false,
        profilePic: item?.profilePic,
        phone: item?.phone,
        data: item,
      };
    });

    return data;
  };

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    if (members.length < 1) {
      setLoader(true);
      dispatch(getMembers(page, selectedDpt, stopLoader));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(members)) {
      const data = renderData();
      setData(data);
    }
  }, [members, allDept.length]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getMembers(1, selectedDpt, stopLoader));
  };

  const onSearch = (value) => {
    setLoader(true);
    dispatch(searchMembers(value, page, selectedDpt, stopLoader));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const resendEmail = (userId) => {
    setRLoader(userId);
    dispatch(resendEmailVerification(userId, () => setRLoader(false)));
  };

  const changePage = (page) => {
    setPage(Number(page));
    setLoader(true);
    if (text.length > 0) {
      dispatch(searchMembers(text, Number(page), selectedDpt, stopLoader));
    } else {
      dispatch(getMembers(Number(page), selectedDpt, stopLoader));
    }
    cancel();
  };

  const getDepartmentId = (name) => {
    if (name) {
      const id = allDept?.filter((item) => item?.name === name)?.[0]?.id || 0;
      getJobTypes(id);
      return id;
    }
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      organization: '',
      jobType: '',
      role: '',
      email: '',
      ...record,
    });
    getDepartmentId(record.organization);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const validatePhone = async (phone) => {
    let res = (await Api.validatePhone(phone)).data;
    return res.valid;
  };

  const updateMember = async (key, data) => {
    let obj = {
      ...data,
    };
    setLoader(true);
    let role =
      obj?.role?.toLowerCase() == 'worker' ? 'user' : obj?.role?.toLowerCase();
    if (!obj?.email?.match?.(regexEmail)) {
      //phone
      delete obj.email;
      obj['phone'] =
        String(data?.email)[0] == '+' ? data?.email : `+${data?.email}`;
      let result = await validatePhone(obj?.phone);
      if (!result) {
        message.warning('Invalid Phone Number!');
        setLoader(false);
        setEditingKey(key);
        return;
      }
    }
    dispatch(updateMembers(key, { ...obj, role }, stopLoader));
  };

  const handleUpload = ({ fileList }) => {
    if (fileList.length > 1) {
      message.warning('One file at a time!');
      return;
    }
    setFile(fileList);
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      // send api request
      const newRow = { ...row };
      newRow.organization = getDepartmentId(newRow.organization);
      updateMember(key, newRow);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const deleteUser = (key) => {
    setLoader(true);
    dispatch(deleteMember(key, stopLoader));
  };

  const downloadDepartments = () => {
    const parseResult = allDept.map((dept) => {
      return {
        Department_Name: dept?.name,
        Department_ID: dept?.id,
      };
    });
    const ws = XLSX.utils.json_to_sheet(parseResult);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    XLSX.writeFile(wb, 'gigsasaDepartments.xlsx');
  };

  const downloadSample = () => {
    const parseResult = sample.map((item) => {
      return item;
    });
    const ws = XLSX.utils.json_to_sheet(parseResult);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    XLSX.writeFile(wb, 'gigsasaSampleDate_for_bulk.xlsx');
  };

  const parseMembers = (file) => {
    const stopLoader = () => {
      setULoader(false);
    };
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const parse = data.slice(1);
        const empData = [];
        parse.forEach((item, index) => {
          if (item.length) {
            if (!item?.[1] && !item?.[3]) {
              message.warning('Email or Phone is required!');
              parse.length = 0;
              return;
            } else if (!item?.[5]) {
              message.warning('Department ID is required!');
              parse.length = 0;
              return;
            } else {
              empData.push({
                name: item[0],
                ...(item[1] && {
                  email: item[1],
                }),
                role:
                  item?.[2]?.toLowerCase() == 'worker'
                    ? 'user'
                    : item?.[2]?.toLowerCase().trim(),

                ...(item?.[3] && {
                  phone:
                    item?.[3]?.toString?.()?.[0] !== '+'
                      ? `+${item?.[3]}`
                      : item?.[3]?.toString?.(),
                }),
                jobType: item?.[4] ?? 'Employee',
                organization: item[5],
                company_id: user?.company_id?.id,
              });
            }
          }
        });

        if (parse.length > 0) {
          setULoader(true);
          dispatch(uploadMembersAction(empData, page, stopLoader));
        }
      } catch (error) {
        setULoader(false);
      }
    };
    if (rABS) reader.readAsBinaryString(file[0].originFileObj);
    else reader.readAsArrayBuffer(file[0].originFileObj);
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    setLoader(true);
    dispatch(getMembers(page, dept, stopLoader));
  };

  const handleText = (text) => {
    setText(text);
    if (text === '') {
      //
      setLoader(true);
      dispatch(getMembers(1, selectedDpt, stopLoader));
    }
  };

  const renderStatus = (value) => {
    return (
      <>
        {value ? (
          <div>
            <p style={styles?.isActive}>active</p>
          </div>
        ) : (
          <div>
            <p style={styles?.isDisabled}>disabled</p>
          </div>
        )}
      </>
    );
  };

  const openUploader = () => {
    setShow(true);
  };

  const cancelShow = () => {
    setShow(false);
  };

  const isAdmin = (record) => {
    return (
      record?.role?.toLowerCase() == 'admin' &&
      user?.organization == record?.data?.organization?.id
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      align: 'left',
    },
    {
      title: 'Email/Phone',
      dataIndex: 'email',
      editable: true,
      align: 'left',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      editable: true,
      align: 'center',
      render: renderStatus,
    },
    {
      title: 'Department',
      dataIndex: 'organization',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Job Title',
      dataIndex: 'jobType',
      editable: true,
      align: 'center',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Kiosk Code',
      dataIndex: 'kiosk',
      editable: false,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },

    {
      title: 'Actions',
      dataIndex: 'operation',
      className: 'd-none d-sm-table-cell',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Change?"
              onConfirm={() => save(record.key)}
            >
              <a className="mr-3">Save</a>
            </Popconfirm>
            <a href="javascript:;" onClick={cancel}>
              Cancel
            </a>
          </span>
        ) : (
          <Space align="center" size="large">
            {['root', 'admin'].includes(user?.role) && (
              <>
                <Typography.Link
                  disabled={
                    editingKey !== '' ||
                    user?.id == record.key ||
                    isAdmin(record)
                  }
                  onClick={() => edit(record)}
                >
                  Edit <EditOutlined />
                </Typography.Link>
                <Popconfirm
                  title="Sure to Delete?"
                  onConfirm={() => deleteUser(record.key)}
                  disabled={
                    editingKey !== '' ||
                    user?.id == record.key ||
                    !['root', 'admin'].includes(user?.role)
                  }
                >
                  <a className="mr-3 text-danger">Delete</a>
                </Popconfirm>
              </>
            )}
            {record?.data?.active !== true && (
              <Popconfirm
                title="Sure to Resend?"
                onConfirm={() => resendEmail(record.key)}
                disabled={
                  !record?.data?.isInitialPassword &&
                  record?.data?.active === true
                }
              >
                <Button size="small" loading={rLoader == record?.key}>
                  Resend Invite
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        getJobTypes: getJobTypes,
        allDept: allDept,
        jobTypes: jobTypes,
        dLoader: dLoader,
        user,
      }),
    };
  });

  const showExportModal = () => {
    setExpModal(true);
  };

  const handleCloseExp = () => {
    setExpModal(false);
  };

  const exportMembers = (dpt) => {
    setExpLoader(true);
    dispatch(getAllEmployees(onGetExportData, dpt));
  };

  const onGetExportData = (data) => {
    setExpLoader(false);
    const exportableData = getExportableData(data, allDeptByIds);
    const ws = XLSX.utils.json_to_sheet(exportableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    XLSX.writeFile(wb, 'gigsasaMembers.xlsx');
  };

  return (
    <React.Fragment>
      <h3>Members</h3>
      <Text type="secondary">{moment().format('MMMM Do YYYY')}~</Text>
      <Divider className="m-0 pb-2" />
      <Row>
        <Tooltip
          title="Click here to invite a new team member."
          color={'#F89722'}
          defaultVisible={isMemberHelp}
        >
          <Button
            type="primary"
            className="bg-primary border-primary"
            icon={<PlusOutlined />}
            onClick={showModal}
            style={styles.buttonUpload}
          >
            Add Member
          </Button>
        </Tooltip>

        <div style={styles.buttonUpload}>
          <DepartmentFilter
            pickerStyle={{
              width: 215,
            }}
            onSelectDpt={onChangeDept}
          />
        </div>
        <Button
          style={styles.buttonUpload}
          icon={<DownloadOutlined />}
          onClick={openUploader}
        >
          Import Members
        </Button>
        <Button
          type="primary"
          className="bg-primary border-primary"
          icon={<ExportOutlined />}
          style={styles.buttonUpload}
          onClick={showExportModal}
        >
          Export
        </Button>
        <Tooltip title="Fetch Latest Data">
          <Button
            className="ml-3"
            onClick={refreshData}
            style={{ marginTop: 10 }}
          >
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Row>

      <Modal
        title="Upload Members"
        visible={show}
        onCancel={cancelShow}
        footer={null}
      >
        <ol>
          <li>
            To start uploading members in bulk, you will first need the
            department IDs. Please download them{' '}
            <Text
              strong
              className="text-primary c-pointer"
              onClick={downloadDepartments}
            >
              here
            </Text>
          </li>
          <li className="mt-3">
            Once you have the department IDs, you will need to download the
            template that will be used to import your team members. Please
            download it{' '}
            <Text
              strong
              className="text-primary c-pointer"
              onClick={downloadSample}
            >
              here
            </Text>
          </li>
          <li className="mt-3">
            The template will have sample data on how to populate it
            successfully.
          </li>
        </ol>
        <Upload
          beforeUpload={() => false}
          fileList={file}
          disabled={file.length > 1}
          onChange={handleUpload}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <Button style={styles.buttonUpload} icon={<DownloadOutlined />}>
            Upload File
          </Button>
        </Upload>
        <div className="text-right">
          <Space className="mt-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={cancelShow}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => parseMembers(file)}
              disabled={uloader || file.length == 0 || !file}
            >
              {uloader ? (
                <Spinner size="sm" animation="border" variant="light" />
              ) : (
                'Submit'
              )}
            </button>
          </Space>
        </div>
      </Modal>

      <Card className="tmb mt-3">
        <Search
          placeholder="Search By Name, Email, Role or Job Title"
          onSearch={onSearch}
          enterButton
          className="w-50 mb-2"
          onChange={(e) => handleText(e.target.value)}
        />

        <Form form={form} component={false}>
          <Table
            className="table-curved"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={[...data]}
            columns={mergedColumns}
            rowClassName="editable-row c-pointer"
            pagination={{
              onChange: changePage,
              current: page,
              total: totalMembers,
              position: ['bottomLeft'],
              pageSize: data.length,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          />

          {/* <Table
            className="table-curved"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={[...data]}
            columns={mergedColumns}
            rowClassName="editable-row c-pointer"
            pagination={{
              onChange: changePage,
              current: page,
              total: totalMembers,
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          /> */}
        </Form>
      </Card>
      <CreateModal
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
      />
      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
      <ExportDataModal
        visible={expModal}
        onClose={handleCloseExp}
        loader={expLoader}
        onExport={exportMembers}
      />
    </React.Fragment>
  );
};

export default EditableTable;
