import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Pagination,
  Card,
  Badge,
  Button,
  Divider,
  List,
  Avatar,
  Skeleton,
  Space,
  Tooltip,
  Empty,
  Progress,
  Checkbox,
  Table,
  Statistic,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  RedoOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import socketHandler from '../../config/socket';
import CreateModal from '../customersAndProjects/addCustomer';
import MyTask from './myTasks';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { getAllTasks } from '../../redux/tasks/action';
import { createTask, updateTasks, deleteTasks } from '../../redux/tasks/action';
import { getCustomers, updateCustomer } from '../../redux/customers/action';

import DepartmentFilter from '../../components/departmentFilter';
import Item from './item';
import TaskCard from './components/projectCard';
import ApprovalCard from './components/approvalCard';
import moment from 'moment';

import { styles } from './style';
const { Link, Text } = Typography;

function Index() {
  const dispatch = useDispatch();
  const { allTasks } = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [show, setShow] = useState(false);
  const [dept, setDpt] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeTasks, setActiveTasks] = useState(null);

  const [taskList, setTaskList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPageIndex, setminPageIndex] = useState(0);
  const [pageSize, setpageSize] = useState(6);
  const [maxPageIndex, setmaxPageIndex] = useState(pageSize);

  const depRef = useRef(null);
  const stopLoader = () => {
    var temp = [];
    setTaskList([]);
    setLoader(false);
  };
  const fetchTasks = () => {
    setLoader(true);
    dispatch(getAllTasks({ organization: dept }, stopLoader));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setminPageIndex((page - 1) * pageSize);
    setmaxPageIndex(page * pageSize);
  };
  useEffect(() => {
    if (allEmployees.length == 0) {
      dispatch(getAllEmployees(() => {}, dept));
    }
    if (allTasks.length == 0) {
      fetchTasks();
    }
  }, []);

  var groupBy = (arr, key) => arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

  const fetchUpdates = useCallback(() => {
    dispatch(getAllTasks({ organization: depRef?.current }, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('taskComplete', fetchUpdates);
    return () => {
      socketHandler.off('taskComplete', fetchUpdates);
    };
  }, []);

  const refreshData = () => {
    dispatch(getAllTasks({ organization: dept }, () => {}));
  };

  /**
   * End Custom
   */

  const refreshPage = () => {
    setLoader(true);
    dispatch(getAllTasks({ organization: dept }, stopLoader));
  };

  const setClose = () => {
    setActiveTasks(null);
    setShow(false);
  };

  const handleOk = () => {
    setClose();
    refreshData();
  };

  const openTask = () => {
    setShow(true);
  };

  const onChangeDept = (dept) => {
    depRef.current = dept;
    setDpt(dept);
    setLoader(true);
    dispatch(getAllEmployees(() => {}, dept));
    dispatch(getAllTasks({ organization: dept }, stopLoader));
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <div className="row float-right mx-1">
            <Button
              type="primary"
              className="bg-primary border-primary"
              icon={<PlusOutlined />}
              onClick={openTask}
              style={styles.buttonUpload}
            >
              Add Assigment
            </Button>
            <Tooltip title="Fetch Latest Data">
              <Button onClick={refreshPage} style={styles.buttonUpload}>
                <ReloadOutlined />
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>

      <Row className="row mt-3">
        <Col md={12}>
          <Col md={12}>
            <h4>Projects and Customers</h4>
          </Col>
          <Row>
            <Col md={12}>
              <div className="row">
                {allTasks.map(
                  (data, index) =>
                    index >= minPageIndex &&
                    index < maxPageIndex && (
                      <div className="col-md-6">
                        <TaskCard
                          key={index}
                          task={data}
                          employee={groupBy(allEmployees, 'id')}
                        ></TaskCard>
                      </div>
                    )
                )}
              </div>

              <Pagination
                pageSize={pageSize}
                current={currentPage}
                onChange={handlePageChange}
                total={allTasks.length}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CreateModal show={show} setClose={setClose} handleOk={handleOk} />
    </React.Fragment>
  );
}

export default Index;
