import { INSTANCE } from '../../config/networkInstance';

export default class MembersApi {
  createUser = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllUsers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'users',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  countAllUsers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'users/count',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllMember = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'users',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  searchMembers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'users/search',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateUsers = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `users/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteMember = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: `users/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getUser = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `users/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteUser = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: `users/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  uploadMultipleUsers = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/upload-many',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  resendEmailVerification = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'users/resend-verification',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
