import React, { useState } from 'react';
import { SwapUserCard } from '../components';
import ApproveSwap from './approveSwap';
import NewPost from './newPost';

function SwapShiftItem(props) {
  const [approveModal, setAppModal] = useState(false);
  const [newPostModal, setNewModal] = useState(false);

  const onApproveHandler = () => {
    setAppModal(true);
  };

  const newPostHandler = () => {
    setNewModal(true);
  };
  return (
    <>
      <SwapUserCard
        borderStatic
        swapShift={onApproveHandler}
        showPopover
        leaveStatus
        reqItem={props.reqItem}
      />

      <ApproveSwap
        show={approveModal}
        setClose={() => {
          setAppModal(false);
        }}
        openFormModal={newPostHandler}
        reqItem={props.reqItem}
      />

      <NewPost
        setLoaderAppl={props.setLoaderAppl}
        setActiveTab={props.setActiveTab}
        reqItem={props.reqItem}
        show={newPostModal}
        setClose={() => {
          setNewModal(false);
        }}
      />
    </>
  );
}

export default SwapShiftItem;
