import { combineReducers } from 'redux';
import auth from './auth/reducer';
import activitiesReducer from './activities/reducer';
import shiftsReducer from './shifts/reducer';
import timesheetsReducer from './timesheets/reducer';
import pqReducer from './performanceQ/reducer';
import membersReducer from './members/reducer';
import organizationsReducer from './organizations/reducer';
import locationsReducer from './locations/reducer';
import leaveManagement from './leaveManagement/reducer';
import tourReducer from './tour/reducer';
import schedular from './schedular/reducer';
import clockReducer from './clockinout/reducer';
import analyticsReducer from './analytics/reducer';
import customersReducer from './customers/reducer';
import typesReducer from './types/reducer';
import swapShiftReducer from './swapShift/reducer';
import chargeBeeReducer from './chargeBee/reducer';
import tasks from './tasks/reducer';
import leave from './leave/reducer';

const createReducer = combineReducers({
  auth: auth,
  activitiesReducer,
  shiftsReducer,
  timesheetsReducer,
  pqReducer,
  membersReducer,
  organizationsReducer,
  locationsReducer,
  leaveManagement,
  leave,
  tourReducer,
  schedular,
  clockReducer,
  analyticsReducer,
  customersReducer,
  types: typesReducer,
  swapShifts: swapShiftReducer,
  chargeBee: chargeBeeReducer,
  tasks,
});

export default createReducer;
