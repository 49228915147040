import React, { useState, memo } from 'react';
import { Drawer, Image, Typography, Divider, Modal, Tabs } from 'antd';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import StaticMap from '../location/staticMap';

import { styles } from './style';
import { adminClockIn, adminClockOut } from '../../redux/shifts/action';
import SwapRequestModel from './newSwapRequest';
// import { ConfirmModal } from './Modals';
import ConfirmModal from '../../components/confirmationModal';

const rowItemClass = 'd-flex flex-row justify-content-between pt-2';
const defaultImage =
  'https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg';
const Index = ({ visible, onClose, item, fetchUpdates }) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const status = item?.status?.toLowerCase?.();
  const { coords, user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [reqSwapModal, setReqSwapModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const openRequestModal = () => {
    setShowConfirm(false);
    onClose?.();
    setReqSwapModal(true);
  };
  const openConfirmModal = () => {
    setShowConfirm(true);
  };
  const closeReqModal = (isSuccess) => {
    setReqSwapModal(false);

    if (isSuccess) {
      fetchUpdates?.();
    }
  };
  const clockInCallback = () => {
    setLoader(false);
  };

  const clockInOut = () => {
    if (status === 'allocation') {
      const clockInObj = {
        allocation: item.id,
        clockin: new Date(),
        clockinCoord: coords,
        clockInByAdmin: user.id,
      };

      setLoader(true);
      dispatch(adminClockIn(clockInObj, item?.uid, clockInCallback));
    } else {
      // clock out

      const clockOutObj = {
        allocation: item.id,
        clockout: new Date(),
        clockoutCoord: coords,
        clockOutByAdmin: user.id,
        tzOffset: new Date().getTimezoneOffset(),
      };

      setLoader(true);
      dispatch(adminClockOut(clockOutObj, item?.uid, clockInCallback));
    }
  };

  const renderName = () => {
    if (item?.type == 'shift') {
      return item?.shift?.name;
    } else if (item?.type == 'schedule') {
      return item?.schedule?.name == 'default'
        ? 'Regular Shift'
        : item?.schedule?.name;
    } else {
      return 'Shift';
    }
  };

  const renderTime = () => {
    if (item?.type == 'shift') {
      return `${moment(item?.shift?.start).format('HH:mm')} -
      ${moment(item?.shift?.end).format('HH:mm')}`;
    } else if (item?.type == 'schedule') {
      return `${item?.schedule?.start} - ${item?.schedule?.end}`;
    } else {
      if (item?.clockout) {
        return `${moment(item?.clockin).format('HH:mm')}-${moment(
          item?.clockout
        ).format('HH:mm')}`;
      }
      return `${moment(item?.updatedAt).format('HH:mm')}`;
    }
  };

  const renderDate = () => {
    return `${moment(item?.date).format('DD-MM-YYYY')}`;
  };

  let timeToEnd = new Date(
    `${moment(item?.date).format('DD-MM-YYYY')}}-${moment(
      item?.shift?.start
    ).format('HH:mm')}`
  );

  let timeToStart = new Date(
    `${moment(item?.date).format('DD-MM-YYYY')}}-${moment(
      item?.shift?.end
    ).format('HH:mm')}`
  );

  return (
    <>
      <Drawer
        title={renderName()}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={340}
        zIndex={1050}
        bodyStyle={styles.sideBar}
      >
        <div className="position-relative">
          <Image
            width={280}
            src={item?.user?.profilePic ?? defaultImage}
            placeholder={
              <Image
                preview={false}
                src={`${
                  item?.user?.profilePic ?? defaultImage
                }?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                width={280}
              />
            }
          />
          <Text strong style={styles.nameText}>
            {item?.user?.name}
          </Text>
        </div>
        <Divider />
        <div className="w-100">
          <Text strong underline>
            Shift Details
          </Text>
          <div>
            <div className={rowItemClass}>
              <Text strong>Name</Text>
              <Text className="pl-3">{renderName()}</Text>
            </div>
            <div className={rowItemClass}>
              <Text strong>Date</Text>
              <Text>{renderDate()}</Text>
            </div>
            <div className={rowItemClass}>
              <Text strong>Time</Text>
              <Text>{renderTime()}</Text>
            </div>
            {item?.type == 'shift' && item?.shift?.location && (
              <React.Fragment>
                <div className={rowItemClass}>
                  <Text strong>location Name</Text>
                  <Text className="pl-3">{item?.shift?.location?.name}</Text>
                </div>
                {item?.shift?.location?.radius !== 0 && (
                  <div className={rowItemClass}>
                    <Text strong>location radius</Text>
                    <Text>{item?.shift?.location?.radius} meters</Text>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>

        {status !== 'completed' && (
          <>
            {status === 'allocation' ? (
              new Date() >= timeToStart &&
              new Date() <= timeToEnd && (
                <>
                  <button
                    className="btn btn-block btn-primary mt-5"
                    onClick={clockInOut}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      'Clock In'
                    )}
                  </button>
                  {user?.role === 'user' && (
                    <>
                      <button
                        style={{ fontWeight: 'bold' }}
                        className="btn btn-block mt-3 text-primary"
                        onClick={openConfirmModal}
                      >
                        Can&apos;t Do this shift
                      </button>
                    </>
                  )}
                </>
              )
            ) : (
              <button
                className="btn btn-block btn-primary mt-5"
                onClick={clockInOut}
              >
                {loader ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  'Clock Out'
                )}
              </button>
            )}
          </>
        )}

        <Divider />
        {item?.type == 'shift' && item?.shift?.location && (
          <div className="position-relative">
            <a
              href={`https://www.google.com/maps/?q=${item?.shift?.location?.coordinates?.latitude},${item?.shift?.location?.coordinates?.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <StaticMap
                lat={item?.shift?.location?.coordinates?.latitude}
                lng={item?.shift?.location?.coordinates?.longitude}
                radius={100}
                width={280}
              />
            </a>
            <Text className="p-2 d-block">
              {item?.shift?.location?.address}
            </Text>
          </div>
        )}
        <Divider />
        <ConfirmModal
          isModalVisible={showConfirm}
          onClose={() => setShowConfirm(false)}
          openReqModal={openRequestModal}
          closeDrawer={onClose}
          msg={'Are You Unavailable For This Shift?'}
        />
      </Drawer>
      <SwapRequestModel
        item={item}
        show={reqSwapModal}
        onClose={closeReqModal}
      />
    </>
  );
};

export default memo(Index);
