import * as constants from './constants';
import _ from 'lodash';
let initState = {
  locations: [],
  totalLocations: 0,
  initial: true,
};
function locationReducer(state = initState, action) {
  let { payload } = action;

  switch (action.type) {
    case constants.GET_LOCATIONS:
      return {
        ...state,
        locations: payload.results,
        totalLocations: payload.totalResults,

        initial: false,
      };

    case constants.ADD_LOCATION:
      return {
        ...state,
        locations: [payload, ...state.locations],
      };
    case constants.EDIT_LOCATION:
      let xLocation = [...state.locations];
      let obj = convertArrayToObject(xLocation, 'id');
      obj[payload.id] = payload;
      xLocation = Object.values(obj);
      return {
        ...state,
        locations: xLocation,
        //locations: [payload, ...state.locations],
      };

    case constants.DELETE_LOCATION:
      let nLocation = [...state.locations];
      nLocation.splice(
        _.indexOf(nLocation, _.find(nLocation, { id: payload })),
        1
      );
      return {
        ...state,
        locations: nLocation,
      };
    default:
      return state;
  }
}

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export default locationReducer;
