import { INSTANCE } from '../../config/networkInstance';

export default class ShiftApi {
  createTimesheet = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'timesheet',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createWeeklySheet = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'timesheet/weeklyCustom',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTimeSheets = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'timesheet',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTimeSheetExport = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'timesheet/export',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTimeSheetsForExport = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'timesheet/getExportData',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getDailyTimeSheets = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'timesheet/daily',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getDateTimeSheets = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'timesheet/attendance',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSpecificTimeSheets = (id, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'timesheet/specific',
        params,
        data: { id },
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTimesheetRequests = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'timesheet/pending',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateTimesheetRequests = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'timesheet/updateStatus',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTimeSheetTotal = (id, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'timesheet/totalTimes',
        params,
        ...(id && { data: { id } }),
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateTimesheet = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'timesheet/editTime',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteTimesheet = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: 'timesheet',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
