import React, { useState, useEffect } from 'react';
import { Button, Spin, Empty, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getSchedular } from '../../redux/schedular/action';
import CreateModal from './createSchedules';
import Card from './item';

function Index() {
  const dispatch = useDispatch();
  const { schedular, totalSchedulers } = useSelector(
    (state) => state.schedular
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getSchedular(page, stopLoader));
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getSchedular(page, stopLoader));
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4 mb-4">Work Schedules</h2>
            <Button
              type="primary"
              className="bg-primary border-primary mb-3"
              icon={<PlusOutlined />}
              onClick={showModal}
            >
              Add Work schedules
            </Button>
          </div>
          {loader ? (
            <div className="m-auto pt-5">
              <Spin />
            </div>
          ) : (
            <React.Fragment>
              {schedular.length == 0 ? (
                <div className="m-auto pt-5">
                  <Empty
                    description={
                      <span className="text-secondary">
                        No schedules available Create One by clicking on the
                        button above
                      </span>
                    }
                  />
                </div>
              ) : (
                schedular?.map((item, i) => {
                  return <Card item={item} key={i} />;
                })
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      {
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Pagination
            onChange={changePage}
            current={page}
            total={totalSchedulers}
            pageSize={10}
            hideOnSinglePage={true}
          />
        </div>
      }
      <CreateModal
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
      />
    </>
  );
}

export default Index;
