import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  Typography,
  Progress,
  Popconfirm,
  Button,
  Badge,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  CommentOutlined,
  EyeOutlined,
  AntDesignOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { updateTaskStatus } from '../../../redux/tasks/action';

const { Text } = Typography;

function Index({ task, employee, editTask, deleteTask }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [dloader, setDLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  let [check, setChecked] = useState(task?.status == 'completed');
  const [membersObject, setMembersObject] = useState({});

  useEffect(() => {
    setChecked(task?.status == 'completed');
  }, [task]);

  const completeTask = () => {
    setLoader(true);
    let params = { taskId: task.id };
    let status = !check == true ? 'completed' : 'pending';
    dispatch(
      updateTaskStatus(params, { status }, { ...task, status }, () => {
        setLoader(false);
        setChecked(!check);
      })
    );
  };

  const confirm = () => {
    setDLoader(true);
    deleteTask(task, () => {
      setDLoader(false);
      setVisible(false);
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const showPopUp = () => {
    setVisible(true);
  };

  const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

  const timeLeft = (endTime, startTime) => {
    var totalTime = moment(endTime).valueOf() - moment(startTime).valueOf();
    var timeTaken = moment().valueOf() - moment(startTime).valueOf();

    var test = (timeTaken / totalTime) * 100;

    if (test > 100) {
      return 100;
    }
    return test;
  };

  const goDetails = () => {
    history.push(`/app/tasks/${task.id}`);
  };

  return (
    <div className="mb-3">
      <Card
        title={
          <>
            <span className="font-weight-900">
              {task?.title}
              <span className="mx-2">
                {task?.status == 'completed' ? (
                  <Badge
                    style={{ backgroundColor: '#52c41a' }}
                    count={task?.status}
                  ></Badge>
                ) : (
                  <Badge
                    style={{ backgroundColor: '#f79425' }}
                    count={task?.status}
                  ></Badge>
                )}
              </span>
            </span>
            <br />
            <span className="text-muted row mx-1">
              <b>Task Owner : </b> {task?.user_id.name}
              <div className="mx-2">
                {/* {moment(task.endTime).valueOf() > moment().valueOf() &&
                  moment(task.startTime).valueOf() < moment().valueOf() && (
                    <Badge
                      style={{ backgroundColor: '#52c41a' }}
                      count={'onging'}
                    ></Badge>
                  )} */}
                {task.status != 'completed' &&
                  moment().valueOf() > moment(task.endTime).valueOf() && (
                    <Badge count={'overdue'}></Badge>
                  )}
                {_.status == 'rejected' && (
                  <Badge
                    style={{ backgroundColor: '#f79425' }}
                    count={'rejected'}
                  ></Badge>
                )}
              </div>
            </span>
          </>
        }
        extra={
          <Button
            onClick={goDetails}
            type="link"
            icon={<EyeOutlined />}
            size={'small'}
          >
            View{' '}
          </Button>
        }
      >
        <div className="row mb-4 mx-2 text-5 font-weight-bold">
          {task?.description}
        </div>
        {task.assignTo.length > 0 && (
          <div className="row ">
            <div className="float-left mx-2">
              <Avatar.Group
                maxCount={3}
                maxPopoverTrigger="click"
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                  cursor: 'pointer',
                }}
              >
                {task.assignTo.map((_, key) => (
                  <Avatar
                    key={key}
                    src={employee[_]?.profilePic ? employee[_]?.profilePic : ''}
                  />
                ))}
              </Avatar.Group>
            </div>
          </div>
        )}

        {/* <div className="row mt-1">
                {timeLeft(task.endTime, task.startTime) <  75 ? (<Progress percent={timeLeft(task.endTime, task.startTime)} size="small" strokeColor={'#2ed8b6'}  size="small" showInfo={false} />) :
                (timeLeft(task.endTime, task.startTime) >  75 && timeLeft(task.endTime, task.startTime) < 100)  ? (<Progress percent={timeLeft(task.endTime, task.startTime)}  size="small" strokeColor={'#f79425'}  showInfo={false}/>) :
                 (<Progress percent={100}  size="small" status="exception" />)
                }
                
              </div> */}

        <div className=" d-flex justify-content-between mt-3">
          <span>
            <span className="mx-1">
              {' '}
              <CommentOutlined /> 0{' '}
            </span>
            <span className="mx-1">
              {' '}
              <CommentOutlined /> 0{' '}
            </span>
          </span>

          {task.priority == 'high' ? (
            <Badge
              className="text-uppercase"
              count={task.priority + ' Priority'}
            ></Badge>
          ) : task.priority == 'medium' ? (
            <Badge
              className="text-uppercase"
              count={task.priority + ' priority'}
              style={{
                backgroundColor: '#f79425',
              }}
            ></Badge>
          ) : (
            <Badge
              className="text-uppercase"
              style={{
                backgroundColor: '#52c41a',
              }}
              count={task.priority + ' priority'}
            ></Badge>
          )}
        </div>
      </Card>
    </div>
  );
}

export default Index;
