export const getDayNow = () => {
  let d = new Date();

  let weekday = new Array(7);

  weekday[0] = 'sunday';

  weekday[1] = 'monday';

  weekday[2] = 'tuesday';

  weekday[3] = 'wednesday';

  weekday[4] = 'thursday';

  weekday[5] = 'friday';

  weekday[6] = 'saturday';
  let n = weekday[d.getDay()];
  return n;
};
