import { MoreOutlined } from '@ant-design/icons';
import { Popover, Spin } from 'antd';
import React, { memo, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import {
  applyForShift,
  getAvailableShifts,
} from '../../../redux/swapShift/action';
import { useDispatch, useSelector } from 'react-redux';

function RequestItem({ shiftItem, forAppied, userApplied, openAppliedModal }) {
  const [showPopOver, setShowPopOver] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const swapShift = () => {
    if (!shiftItem) return;
    setLoader(true);
    dispatch(
      applyForShift({ id: shiftItem?.id }, shiftItem.companyId, getApplyShift)
    );
  };
  const handleVisibleChange = () => {
    setShowPopOver((p) => !p);
  };

  const getApplyShift = () => {
    handleVisibleChange?.();
    dispatch(
      getAvailableShifts({ page: 1, limit: 10 }, () => setLoader(false))
    );
  };
  return (
    <Spin spinning={loader} delay={500}>
      <Col lg={4} className="ml-3 mt-3 customSwapUserCard  d-flex ">
        <Row className="d-flex flex-grow-1 ">
          <Col lg={12}>
            <div className="swapjobtitle">
              <p>Job:</p>
              <div className="swapjobvalue">
                <p>{shiftItem?.shift?.name}</p>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="swapjobtitle">
              <p>Time:</p>
              <div className="swapjobvalue">
                <p style={{ textTransform: 'lowercase' }}>
                  {moment(shiftItem?.shift?.start).format('hh:mm a')} -{' '}
                  {moment(shiftItem?.shift?.end).format('hh:mm a')}
                </p>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="swapjobtitle">
              <p>Date:</p>
              <div className="swapjobvalue">
                <p>
                  {moment(shiftItem?.allocation?.date).format('DD/MM/YYYY')}{' '}
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="cardPopover">
          {shiftItem?.appliedUser.includes(user.id) ? (
            <p className="pr-3 pt-1">Applied</p>
          ) : (
            <Popover
              placement="right"
              trigger="click"
              content={
                forAppied ? (
                  <a
                    className="btn text-primary"
                    onClick={() => {
                      handleVisibleChange?.();
                      userApplied?.(shiftItem);
                      openAppliedModal?.();
                    }}
                  >
                    Applied
                  </a>
                ) : (
                  <a className="btn text-primary" onClick={swapShift}>
                    Apply
                  </a>
                )
              }
              visible={showPopOver}
              onVisibleChange={handleVisibleChange}
            >
              <button className="btn border-0 swapApplyBtn">
                <MoreOutlined size={30} style={{ fontSize: '1.5em' }} />
              </button>
            </Popover>
          )}
        </div>
      </Col>
    </Spin>
  );
}

export default memo(RequestItem);
