export const days = [
  {
    name: 'monday',
  },
  {
    name: 'tuesday',
  },
  {
    name: 'wednesday',
  },
  {
    name: 'thursday',
  },
  {
    name: 'friday',
  },
  {
    name: 'saturday',
  },
  {
    name: 'sunday',
  },
];

export const labels = [
  {
    key: 'monday',
    label: 'M',
  },
  {
    key: 'tuesday',
    label: 'T',
  },
  {
    key: 'wednesday',
    label: 'W',
  },
  {
    key: 'thursday',
    label: 'T',
  },
  {
    key: 'friday',
    label: 'F',
  },
  {
    key: 'saturday',
    label: 'S',
  },
  {
    key: 'sunday',
    label: 'S',
  },
];

export const timesState = {
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};
