import React from 'react';
import { Modal, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import WelComeImg from '../../assets/images/welcomeModal.png';
import circleA from '../../assets/images/modalCircleA.png';
import circleB from '../../assets/images/modalCircleB.png';
import { showTour, updateTourStatus } from '../../redux/tour/action';
import Tour from './Tour';
import ProgressList from './progressList';

function WelComeModel() {
  const { user, welcomeModal, isProgressListVisible } = useSelector(
    (state) => ({
      user: state.auth.user,
      welcomeModal: state.tourReducer.welcomeModal,
      isProgressListVisible: state.tourReducer.isProgressListVisible,
    })
  );
  const dispatch = useDispatch();

  const skipTour = () => {
    dispatch(updateTourStatus('skip', user.id));
  };

  const setTourVisibility = () => {
    dispatch(
      showTour({
        deptHelp: true,
        welcomeModal: false,
        isProgressListVisible: false,
      })
    );
  };

  return (
    <>
      <Modal
        className="welcomeModalCon"
        bodyStyle={{ padding: 0, borderRadius: 20, overflow: 'hidden' }}
        centered
        width={900}
        visible={welcomeModal}
        onCancel={() => {}}
        footer={null}
        closable={false}
      >
        <div>
          <div>
            {/* header */}
            <div className="modalCont">
              {/* header */}
              <div className="modalHeader">
                <p className="headerHeading">Welcome Aboard!</p>
              </div>

              {/* body */}
              <Row className="bodyCont">
                <Col>
                  <div className="welCircleContA">
                    <img src={circleB} className="welCirA" alt="" />
                    <img className="welCirB" src={circleA} alt="" />
                  </div>
                  <p className="helloUser">Hello {user.name}</p>
                  <p className="welcomeTxt">Welcome to gigsasa</p>
                  <p className="welDesc">
                    Where organizations get the best out of their teams with
                    just a few clicks
                  </p>
                  <div>
                    <div>
                      <button
                        className="getStartedCTA"
                        onClick={setTourVisibility}
                      >
                        Get started with gigsasa
                      </button>
                    </div>
                    <div>
                      <button className="tryLaterCta" onClick={skipTour}>
                        Try later
                      </button>
                    </div>
                  </div>
                </Col>
                <Col>
                  <img src={WelComeImg} alt="" className="wlImg" />

                  <div className="welCircleContB">
                    <img src={circleB} alt="" className="welCirA" />
                    <img src={circleA} alt="" className="welCirC" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
      {isProgressListVisible && <ProgressList />}
      <Tour />
    </>
  );
}

export default WelComeModel;
