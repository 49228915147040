import React, { memo } from 'react';
import { Modal } from 'antd';

function ConfirmationModal({ isModalVisible, onClose, openReqModal, msg }) {
  return (
    <Modal
      className="confirmModalCon"
      bodyStyle={{ borderRadius: 10, overflow: 'hidden' }}
      centered
      footer={null}
      visible={isModalVisible}
      onCancel={onClose}
      zIndex={1100}
    >
      <p>{msg}</p>
      <div className="d-flex justify-content-between w-25 mx-auto customModal">
        <button
          className="btn p-0 m-0 text-primary"
          style={{ fontWeight: 'bold', fontSize: 17 }}
          onClick={() => {
            onClose();
            openReqModal();
          }}
        >
          Yes
        </button>
        <button
          style={{ fontWeight: 'bold', fontSize: 17 }}
          className="btn p-0 m-0 text-danger"
          onClick={() => {
            onClose();
          }}
        >
          No
        </button>
      </div>
    </Modal>
  );
}

export default memo(ConfirmationModal);
