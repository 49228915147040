import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Pagination,
  Card,
  Badge,
  Button,
  Divider,
  List,
  Input,
  Skeleton,
  Space,
  Tooltip,
  Empty,
  Progress,
  Checkbox,
  Select,
  Statistic,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  RedoOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import socketHandler from '../../config/socket';
import CreateModal from './addTask';
import MyTask from './myTasks';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { getAllTasks, FilterDashboard } from '../../redux/tasks/action';
import { createTask, updateTasks, deleteTasks } from '../../redux/tasks/action';

import DepartmentFilter from '../../components/departmentFilter';
import Item from './item';
import TaskCard from './components/taskCard';
import ApprovalCard from './components/approvalCard';
import moment from 'moment';

import { styles } from './style';
const { Link, Text } = Typography;
const { Search } = Input;

function Index() {
  const dispatch = useDispatch();
  const { allTasks, dashboardDisplay } = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [show, setShow] = useState(false);
  const [dept, setDpt] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeTasks, setActiveTasks] = useState(null);

  const [taskList, setTaskList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPageIndex, setminPageIndex] = useState(0);
  const [pageSize, setpageSize] = useState(6);
  const [maxPageIndex, setmaxPageIndex] = useState(pageSize);

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('created');
  const [display, setDisplay] = useState('all');

  const FilterTask = () => {
    var filter1 = [];
    if (search) {
      filter1 = allTasks.filter((_) => {
        return _.title.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    } else {
      filter1 = allTasks;
    }

    if (sort == 'created') {
      filter1 = filter1.sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      );
    } else if (sort == 'updated') {
      filter1 = filter1.sort(
        (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()
      );
    }

    if (display == 'all') {
      dispatch(FilterDashboard(filter1));
    } else if (display == 'pending') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'pending')));
    } else if (display == 'complete') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'completed')));
    } else if (display == 'ongoing') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'ongoing')));
    } else if (display == 'rejected') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'rejected')));
    } else if (display == 'review') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'review')));
    } else if (display == 'overdue') {
      dispatch(
        FilterDashboard(
          allTasks.filter(
            (_) =>
              _.status != 'completed' &&
              moment().valueOf() > moment(_.endTime).valueOf()
          )
        )
      );
    } else {
      dispatch(FilterDashboard(allTasks));
    }
    // check for string
  };

  const FilterTask2 = (_search) => {
    setSearch(_search);
    var filter1 = [];

    if (_search) {
      filter1 = allTasks.filter((_) => {
        return _.title
          .toLocaleLowerCase()
          .includes(_search.toLocaleLowerCase());
      });
    } else {
      filter1 = allTasks;
    }

    if (sort == 'created') {
      filter1 = filter1.sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      );
    } else if (sort == 'updated') {
      filter1 = filter1.sort(
        (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()
      );
    }

    if (display == 'all') {
      dispatch(FilterDashboard(filter1));
    } else if (display == 'pending') {
      dispatch(FilterDashboard(filter1));
    } else if (display == 'complete') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'completed')));
    } else if (display == 'ongoing') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'ongoing')));
    } else if (display == 'rejected') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'rejected')));
    } else if (display == 'review') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'review')));
    } else if (display == 'overdue') {
      dispatch(
        FilterDashboard(
          filter1.filter(
            (_) =>
              _.status != 'completed' &&
              moment().valueOf() > moment(_.endTime).valueOf()
          )
        )
      );
    } else {
      dispatch(FilterDashboard(filter1));
    }
    // check for string
  };

  const FilterTaskSort = (_sort) => {
    var filter1 = [];
    if (search) {
      filter1 = allTasks.filter((_) => {
        return _.title.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    } else {
      filter1 = allTasks;
    }
    setSort(_sort);

    if (_sort == 'created') {
      filter1 = filter1.sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      );
    } else if (_sort == 'updated') {
      filter1 = filter1.sort(
        (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()
      );
    }

    if (display == 'all') {
      dispatch(FilterDashboard(filter1));
    } else if (display == 'pending') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'pending')));
    } else if (display == 'complete') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'completed')));
    } else if (display == 'ongoing') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'ongoing')));
    } else if (display == 'rejected') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'rejected')));
    } else if (display == 'review') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'review')));
    } else if (display == 'overdue') {
      dispatch(
        FilterDashboard(
          filter1.filter(
            (_) =>
              _.status != 'completed' &&
              moment().valueOf() > moment(_.endTime).valueOf()
          )
        )
      );
    } else {
      dispatch(FilterDashboard(filter1));
    }
    // check for string
  };

  const FilterTaskDisplay = (_display) => {
    var filter1 = [];
    if (search) {
      filter1 = allTasks.filter((_) => {
        return _.title.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    } else {
      filter1 = allTasks;
    }
    setDisplay(_display);

    if (sort == 'created') {
      filter1 = filter1.sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      );
    } else if (sort == 'updated') {
      filter1 = filter1.sort(
        (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()
      );
    }

    if (_display == 'all') {
      dispatch(FilterDashboard(filter1));
    } else if (_display == 'pending') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'pending')));
    } else if (_display == 'complete') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'completed')));
    } else if (_display == 'ongoing') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'ongoing')));
    } else if (_display == 'rejected') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'rejected')));
    } else if (_display == 'review') {
      dispatch(FilterDashboard(filter1.filter((_) => _.status == 'review')));
    } else if (_display == 'overdue') {
      dispatch(
        FilterDashboard(
          filter1.filter(
            (_) =>
              _.status != 'completed' &&
              moment().valueOf() > moment(_.endTime).valueOf()
          )
        )
      );
    } else {
      dispatch(FilterDashboard(filter1));
    }
    // check for string
  };

  const depRef = useRef(null);
  const stopLoader = () => {
    FilterTask();
    setTaskList([]);
    setLoader(false);
  };
  const fetchTasks = () => {
    setLoader(true);
    dispatch(getAllTasks({ organization: dept }, stopLoader));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setminPageIndex((page - 1) * pageSize);
    setmaxPageIndex(page * pageSize);
  };
  useEffect(() => {
    if (allEmployees.length == 0) {
      dispatch(getAllEmployees(() => {}, dept));
    }
    if (allTasks.length == 0 || dashboardDisplay.length == 0) {
      fetchTasks();
    } else {
      FilterTask();
    }
  }, []);

  var groupBy = (arr, key) => arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

  const fetchUpdates = useCallback(() => {
    dispatch(getAllTasks({ organization: depRef?.current }, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('taskComplete', fetchUpdates);
    return () => {
      socketHandler.off('taskComplete', fetchUpdates);
    };
  }, []);

  const refreshData = () => {
    dispatch(getAllTasks({ organization: dept }, () => {}));
  };

  /**
   * End Custom
   */

  const refreshPage = () => {
    setLoader(true);
    dispatch(getAllTasks({ organization: dept }, stopLoader));
  };

  const setClose = () => {
    setActiveTasks(null);
    setShow(false);
  };

  const handleOk = () => {
    setClose();
    refreshData();
  };

  const openTask = () => {
    setShow(true);
  };

  const onChangeDept = (dept) => {
    depRef.current = dept;
    setDpt(dept);
    setLoader(true);
    dispatch(getAllEmployees(() => {}, dept));
    dispatch(getAllTasks({ organization: dept }, stopLoader));
  };

  const handleTasks = (tasks) => {
    setActiveTasks(tasks);
    openTask();
  };

  return (
    <React.Fragment>
      <Row className="row my-3">
        <Col md={3}>
          <Card>
            <Statistic
              title="OnGing Tasks"
              value={
                allTasks.filter(
                  (_) =>
                    _.status != 'completed' &&
                    moment(_.endTime).valueOf() > moment().valueOf() &&
                    moment(_.startTime).valueOf() < moment().valueOf()
                ).length
              }
              valueStyle={{ color: '#3f8600' }}
              prefix={<CalendarOutlined />}
              suffix="Tasks"
            />
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Statistic
              title="Overdue Task"
              value={
                allTasks.filter(
                  (_) =>
                    _.status != 'completed' &&
                    moment().valueOf() > moment(_.endTime).valueOf()
                ).length
              }
              valueStyle={{ color: 'red' }}
              prefix={<ClockCircleOutlined />}
              suffix="Tasks"
            />
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Statistic
              title="Completed Task"
              value={allTasks.filter((_) => _.status == 'completed').length}
              valueStyle={{ color: '#3f8600' }}
              prefix={<CheckCircleOutlined />}
              suffix="Tasks"
            />
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Statistic
              title="Rejected Task"
              value={allTasks.filter((_) => _.status == 'rejected').length}
              valueStyle={{ color: 'red' }}
              prefix={<RedoOutlined />}
              suffix="Tasks"
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="row float-left ">
            <div style={styles.buttonUpload}>
              <Search
                placeholder="Find Task by Title "
                onSearch={FilterTask2}
                style={{
                  width: 200,
                }}
              />
            </div>

            <div style={styles.buttonUpload}>
              <Select
                style={{
                  width: 150,
                }}
                placeholder="Sort By ..."
                onChange={FilterTaskSort}
                options={[
                  {
                    value: 'created',
                    label: 'Date Created',
                  },
                  {
                    value: 'updated',
                    label: 'Date Update',
                  },
                ]}
              />
            </div>
            <div style={styles.buttonUpload}>
              <Select
                style={{
                  width: 150,
                }}
                placeholder="Display By ..."
                onChange={FilterTaskDisplay}
                options={[
                  {
                    value: 'all',
                    label: 'All Task',
                  },
                  {
                    value: 'pending',
                    label: 'Not Started Task',
                  },
                  {
                    value: 'complete',
                    label: 'Complete Task',
                  },
                  {
                    value: 'ongoing',
                    label: 'Ongoing Task',
                  },
                  {
                    value: 'rejected',
                    label: 'Rejected Task',
                  },
                  {
                    value: 'review',
                    label: 'In Review Task',
                  },
                  {
                    value: 'overdue',
                    label: 'Overdue Task',
                  },
                ]}
              />
            </div>

            {user?.role !== 'user' && (
              <div style={styles.buttonUpload}>
                <DepartmentFilter
                  pickerStyle={{
                    width: 150,
                  }}
                  onSelectDpt={onChangeDept}
                />
              </div>
            )}

            <Button
              type="primary"
              className="bg-primary border-primary"
              icon={<PlusOutlined />}
              onClick={openTask}
              style={styles.buttonUpload}
            >
              Add Task
            </Button>
            <Tooltip title="Fetch Latest Data">
              <Button onClick={refreshPage} style={styles.buttonUpload}>
                <ReloadOutlined />
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>

      <Row className="row mt-3">
        <Col md={12}>
          <div className="row mt-4">
            <Col md={8}>
              {dashboardDisplay.map(
                (data, index) =>
                  index >= minPageIndex &&
                  index < maxPageIndex && (
                    <TaskCard
                      key={index}
                      task={data}
                      employee={groupBy(allEmployees, 'id')}
                    ></TaskCard>
                  )
              )}

              <Pagination
                pageSize={pageSize}
                current={currentPage}
                onChange={handlePageChange}
                total={dashboardDisplay.length}
              />
            </Col>
            <Col md={4}>
              <Col md={12}>
                <h5 className="text-primary text-center">Tasks Approval </h5>
              </Col>
              <List itemLayout="horizontal">
                {/* <ApprovalCard></ApprovalCard>
                <ApprovalCard></ApprovalCard>
                <ApprovalCard></ApprovalCard> */}
              </List>
            </Col>
          </div>
        </Col>
      </Row>

      <CreateModal
        show={show}
        setClose={setClose}
        handleOk={handleOk}
        activeTasks={activeTasks}
      />
    </React.Fragment>
  );
}

export default Index;
