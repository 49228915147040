import React, { useState, memo, useEffect } from 'react';
import { Modal, Form, Input, Radio, Select } from 'antd';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { createSwapShift } from '../../redux/swapShift/action';
import { getLeaveTypes } from '../../redux/leaveManagement/action';
import { styles } from './style';

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

function SwapRequestModal({ item, show, onClose }) {
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { types } = useSelector((state) => state.leaveManagement);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showUsers, setShowUsers] = useState(false);
  const [loader, setLoader] = useState(false);
  const { company } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  useEffect(() => {
    if (allEmployees.length == 0) {
      dispatch(getAllEmployees(() => {}, user?.organization?.id));
    }
    if (types.length == 0) {
      dispatch(getLeaveTypes({ company: company.id }, () => {}));
    }
  }, []);

  const swapHandler = (findUser) => {
    setLoader(true);
    let swapPost = {
      companyId: user?.company_id?.id,
      createdBy: user?.id,
      leaveId: findUser?.LeaveType,
      allocation: item?.id,
      shift: item?.shift?.id,
      leaveReason: findUser?.leaveReason,
      ...(findUser?.type === 'recommend' && {
        recommendedUsers: findUser?.Users,
      }),
    };

    dispatch(
      createSwapShift(swapPost, (isSuccess) => {
        setLoader(false);
        if (isSuccess) {
          onClose(true);
          form.resetFields();
        }
      })
    );
  };

  return (
    <Modal
      className="confirmModalCon"
      bodyStyle={{ borderRadius: 10, overflow: 'hidden' }}
      centered
      width={600}
      footer={null}
      onCancel={onClose}
      visible={show}
    >
      <p style={styles.changeHeading}>Shift Change Request</p>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        validateMessages={validateMessages}
        initialValues={{
          type: 'notRecommend',
          leaveReason: '',
        }}
        onFinish={swapHandler}
        autoComplete="off"
      >
        <Form.Item className="mt-4" name="type" rules={[{ required: true }]}>
          <Radio.Group
            onChange={(e) =>
              setShowUsers(e.target.value === 'recommend' ? true : false)
            }
          >
            <Radio value="recommend">I Have Recommendation</Radio>
            <Radio value="notRecommend">Don`t Have Recommendation</Radio>
          </Radio.Group>
        </Form.Item>
        {showUsers && (
          <Form.Item
            className="mt-4"
            name="Users"
            label="Recommended Users"
            rules={[{ required: showUsers ? true : false }]}
          >
            <Select mode="multiple" placeholder="select users">
              {allEmployees
                .map((item) => ({
                  id: item?.id,
                  name: item?.name,
                }))
                ?.map((val, i) => {
                  return (
                    val.id !== user.id && (
                      <Select.Option key={i} value={val.id}>
                        {val.name}
                      </Select.Option>
                    )
                  );
                })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          className="mt-4"
          name="LeaveType"
          label="Leave Type"
          rules={[{ required: true }]}
        >
          <Select placeholder="select types">
            {types
              .map((item) => ({
                id: item?.id,
                name: item?.name,
              }))
              ?.map((val, i) => (
                <Select.Option key={i} value={val.id}>
                  {val.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="leaveReason"
          rules={[{ required: true }]}
          label="Reasons For Leave"
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <button className="btn btn-block btn-primary rounded " type="submit">
            {loader ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              'Submit Request'
            )}
          </button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default memo(SwapRequestModal);
