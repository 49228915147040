import React, { useState, useEffect } from 'react';
import { Button, TimePicker, Drawer } from 'antd';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyAction } from '../../redux/settings/action';

function Index({ open, close, workSchedule }) {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    if (open) {
      setState({
        ...state,
        start: moment(workSchedule?.start, 'HH:mm'),
        end: moment(workSchedule?.end, 'HH:mm'),
      });
    }
  }, [open]);

  const handleChangeStart = (_, value) => {
    setState({
      ...state,
      start: value,
    });
  };

  const handleChangeEnd = (_, value) => {
    setState({
      ...state,
      end: value,
    });
  };

  const stopLoader = () => {
    setLoader(false);
    close();
  };

  const updateSchedule = () => {
    let { start, end } = state;
    setLoader(true);
    let dataObj = {
      workSchedule: {
        start: moment(moment(start, 'HH:mm')).format('HH:mm'),
        end: moment(moment(end, 'HH:mm')).format('HH:mm'),
      },
    };
    dispatch(updateCompanyAction(company.id, dataObj, stopLoader));
  };

  return (
    <Drawer
      title="Update Schedule"
      visible={open}
      onClose={close}
      width={window.innerWidth > 900 ? 420 : window.innerWidth - 100}
      zIndex={5000}
    >
      <Row>
        <Col sm={12}>
          <TimePicker
            placeholder="Select Start Time"
            size="large"
            className="fullWidth"
            format="HH:mm"
            defaultValue={moment(workSchedule?.start, 'HH:mm')}
            getPopupContainer={(trigger) => trigger.parentElement}
            onChange={handleChangeStart}
            allowClear={false}
          />
          <TimePicker
            placeholder="Select End Time"
            size="large"
            className="fullWidth mt-4"
            format="HH:mm"
            defaultValue={moment(workSchedule?.end, 'HH:mm')}
            getPopupContainer={(trigger) => trigger.parentElement}
            onChange={handleChangeEnd}
            allowClear={false}
          />

          <Button
            type="primary"
            className="bg-primary border-primary mt-4 w-100"
            onClick={updateSchedule}
            loading={loader}
            disabled={!state.start || !state.end}
          >
            Update
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
}

export default Index;
