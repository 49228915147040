import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { Typography, Card, Spin, Avatar, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DateHeader from './dateHeader';
import Item from './shiftlistItem';
import LeaveItem from './leaveListItem';
import { styles } from './style';
import TotalTimes from './totalTimes';
import AddModal from './createTimesheet';
import ActivityFilter from './activityFilter';
import socketHandler from '../../config/socket';
import {
  getSpecificTimeSheets,
  getTimeSheetTotal,
} from '../../redux/timesheets/action';
import { parseUserTimesheets, parseExportDateUser } from './util';
import XLSX from 'xlsx';
const { Text } = Typography;
export default function Index() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const [eLoader, setELoader] = useState(false);
  const [show, setShow] = useState(false);
  const [activity, setActivity] = useState(null);
  const [period, setPeriod] = useState({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
  });
  const [totalTimes, setTotalTimes] = useState(null);

  const periodRef = useRef({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
  });

  const actRef = useRef(null);

  const onChangeTimePeriod = (selection) => {
    const start = moment(selection.startDate).startOf('day').toDate();
    const end = moment(selection.endDate).endOf('day').toDate();
    setPeriod({ start: start, end: end });
    periodRef.current = { start: start, end: end };
  };
  const stopLoader = (success, data) => {
    if (success) {
      const result = parseUserTimesheets(
        data.timesheets.timeSheet,
        data.timesheets.leaves
      );
      setData(result);
      setUser(data.user);
    }
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    dispatch(getSpecificTimeSheets(id, s, e, activity, stopLoader));
    dispatch(
      getTimeSheetTotal({
        id,
        start: s,
        end: e,
        queryType: 'user',
        activity,
        cb: timeSheetTotalCB,
      })
    );
  }, [period]);

  const refreshData = () => {
    setLoader(true);
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    dispatch(getSpecificTimeSheets(id, s, e, activity, stopLoader));
    dispatch(
      getTimeSheetTotal({
        id,
        start: s,
        end: e,
        queryType: 'user',
        activity,
        cb: timeSheetTotalCB,
      })
    );
  };

  const onChangeActivity = (value) => {
    setActivity(value);
    actRef.current = value;
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    setLoader(true);
    dispatch(getSpecificTimeSheets(id, s, e, value, stopLoader));
    dispatch(
      getTimeSheetTotal({
        id,
        start: s,
        end: e,
        queryType: 'user',
        activity: value,
        cb: timeSheetTotalCB,
      })
    );
  };

  const timeSheetTotalCB = (success, data) => {
    if (success) {
      setTotalTimes(data);
    }
  };

  const customTimeSheet = (success, result) => {
    if (success) {
      const parseResult = parseExportDateUser(
        result.timesheets.timeSheet,
        result.timesheets.leaves
      );
      const ws = XLSX.utils.json_to_sheet(parseResult);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
      XLSX.writeFile(wb, 'gigsasaWeeklyTimesheet.xlsx');
      setELoader(false);
    }
  };

  const exportToExcel = (range, dept, activity) => {
    if (range) {
      const start = range[0];
      const end = range[1];
      setELoader(true);
      dispatch(
        getSpecificTimeSheets(id, start, end, activity, customTimeSheet)
      );
    } else {
      const parseResult = parseExportDateUser(data);
      const ws = XLSX.utils.json_to_sheet(parseResult);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
      XLSX.writeFile(wb, 'gigsasaWeeklyTimesheet.xlsx');
    }
  };

  const showModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const fetchUpdates = useCallback((data) => {
    if (data?.user == user?.id) {
      const s = moment(periodRef?.current?.start).toDate();
      const e = moment(periodRef?.current?.end).toDate();
      dispatch(getSpecificTimeSheets(id, s, e, actRef?.current, stopLoader));
      dispatch(
        getTimeSheetTotal({
          id,
          start: s,
          end: e,
          queryType: 'user',
          activity: actRef?.current,
          cb: timeSheetTotalCB,
        })
      );
    }
  }, []);

  useEffect(() => {
    socketHandler.on('timesheet', fetchUpdates);
    return () => {
      socketHandler.off('timesheet', fetchUpdates);
    };
  }, []);

  const renderAvatar = () => {
    if (user) {
      return (
        <div style={styles.avatarCont}>
          <div style={styles.imgArea}>
            <Avatar
              style={styles.userAvatar}
              src={
                user?.profilePic ??
                'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
              }
              size="large"
            />
          </div>
          <Text strong style={styles.userName}>
            {user?.name}
          </Text>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {/* user details */}
      {user && (
        <div>
          {renderAvatar()}
          <div style={styles.totalComCont}>
            <TotalTimes
              totalWorked={totalTimes?.totalWorkedTime || 0}
              totalBreaks={totalTimes?.totalBreakTime || 0}
              totalOvertime={totalTimes?.totalOverTime || 0}
            />
          </div>
        </div>
      )}

      <DateHeader
        onChange={(dates) => {
          onChangeTimePeriod(dates);
        }}
        exportExcel={exportToExcel}
        loader={eLoader}
        single={true}
      />

      <Row>
        <Col md={12}>
          <Spin spinning={loader}>
            <Card className="tmb">
              <Space className="mb-2 flex-wrap float-right">
                <Button
                  type="primary"
                  className="bg-primary border-primary"
                  icon={<PlusOutlined />}
                  onClick={showModal}
                >
                  Add Time Entry
                </Button>
                <ActivityFilter
                  pickerStyle={{
                    width: 215,
                  }}
                  onChange={onChangeActivity}
                />
              </Space>
              <TableRB
                id="albums"
                responsive
                className="table-curved borderless"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th style={styles.centerAlign}>Activity</th>
                    <th style={styles.centerAlign}>Clocked In</th>
                    <th style={styles.centerAlign}>Clocked Out</th>
                    <th style={styles.centerAlign}>Total Hours</th>
                    <th style={styles.centerAlign}>Break Hours</th>
                    <th style={styles.centerAlign}>Over Time</th>
                    <th style={styles.centerAlign}>Hours Worked</th>
                    <th style={styles.centerAlign}>Leaves</th>
                    <th>Edit Times </th>
                  </tr>
                </thead>
                {data?.map((arr, index) => {
                  if (arr[0].renderType === 'leave') {
                    return arr.map((item, index) => {
                      return <LeaveItem item={item} key={index} />;
                    });
                  } else {
                    let obj = {
                      ...arr[0],
                      time: arr.reduce((a, b) => a + b.time, 0),
                      breakTime: arr.reduce((a, b) => a + b.breakTime, 0),
                      overTime: arr.reduce((a, b) => a + b.overTime, 0),
                      totalTime: arr.reduce((a, b) => {
                        a =
                          a +
                          (b.totalTime ? b.totalTime : b.time + b.breakTime);
                        return a;
                      }, 0),
                    };
                    return (
                      <Item
                        item={obj}
                        key={index}
                        sheets={arr}
                        refreshData={refreshData}
                        sentFrom="userList"
                      />
                    );
                  }
                })}
              </TableRB>
            </Card>
          </Spin>
        </Col>
      </Row>
      <AddModal
        show={show}
        handleCancel={closeModal}
        refreshData={refreshData}
        employee={user}
      />
    </React.Fragment>
  );
}
