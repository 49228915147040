import { insertToken } from '../../config/networkInstance';
import * as constants from './constants';
import * as payConstants from '../chargeBee/constants';

import { message } from 'antd';
import authApi from './authApi';
import { showTour } from '../tour/action';
import { resetTimer } from '../clockinout/action';
import { getAllEmployees } from '../shifts/action';
import socketHandler from '../../config/socket';
import { deleteValue } from '../../helpers/deleteValue';
import ChargeBeePaymentApi from '../chargeBee/ChargeBeeApi';

const AuthApi = new authApi();
const ChargeBeeApi = new ChargeBeePaymentApi();
export const signup = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.register(data);
    const userData = res.data;
    insertToken(userData.tokens.access.token);
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: userData,
    });
    message.success('Successfully created Account');
    cb(true, userData.user);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const adminSignup = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.adminRegister(data);
    insertToken(res?.data?.tokens?.access?.token);
    if (res?.data?.user?.company_id) {
      dispatch({
        type: constants.GET_COMPANY,
        payload: res?.data?.user?.company_id,
      });
      const subStatus = await ChargeBeeApi.getSubscriptionStatus();
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: subStatus.data,
      });
    }
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: res.data,
    });
    message.success('Successfully created Account');
    cb(true, res?.data?.user);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const login = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.login(data);
    insertToken(res?.data?.tokens?.access?.token);
    // get subscription
    if (res?.data?.user?.company_id) {
      dispatch({
        type: constants.GET_COMPANY,
        payload: res?.data?.user?.company_id,
      });
    }
    if (!res?.data?.user?.active) {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }

    if (
      res?.data?.user?.company_id
      //&& ['admin', 'root'].includes(res?.data?.user?.role)
    ) {
      const subStatus = await ChargeBeeApi.getSubscriptionStatus();
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: subStatus.data,
      });
    } else {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: res.data,
    });
    cb(true, res.data.user);
    message.success('Successfully Login');
    socketHandler.emit('joinSocket', res?.data?.user);
    dispatch(
      getAllEmployees(() => {
        dispatch(ActiveUsersHandler());
        dispatch(removeActiveHandler());
      })
    );
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getCurrent = (cb) => async (dispatch) => {
  try {
    const res = await AuthApi.getCurrent();
    insertToken(res.data.tokens.access.token);
    if (
      res?.data?.user?.company_id &&
      ['admin', 'root'].includes(res?.data?.user?.role)
    ) {
      const subStatus = await ChargeBeeApi.getSubscriptionStatus();
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: subStatus.data,
      });
    } else {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: res.data,
    });
    cb();
    if (res?.data?.user?.company_id) {
      dispatch({
        type: constants.GET_COMPANY,
        payload: res?.data?.user?.company_id,
      });
    }
    socketHandler.emit('joinSocket', res?.data?.user);
    dispatch(
      getAllEmployees(() => {
        dispatch(ActiveUsersHandler());
        dispatch(removeActiveHandler());
      })
    );
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createCompany = (data, cb) => async (dispatch) => {
  try {
    let res = await AuthApi.createCompany(data);
    dispatch({
      type: constants.CREATE_COMPANY,
      payload: res.data,
    });
    dispatch({
      type: payConstants.GET_SUB_STATUS,
      payload: 'active',
    });
    message.success('Successfully Created Company');
    cb(true);
    dispatch(showTour({ welcomeModal: true, isProgressListVisible: true }));
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const joinTeam = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.joinTeam({ email: data });
    message.success('Successfully Sent Join Request');
    insertToken(res?.data?.tokens?.access?.token);
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateUserLocation = (data) => async (dispatch) => {
  try {
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: data,
    });
  } catch (error) {}
};

export const updateLocation = (data) => {
  try {
    socketHandler.emit('locationUpdate', data);
  } catch (error) {}
};

export const refreshToken = () => (dispatch, getState) => {
  try {
    let { token = {} } = getState().auth;
    insertToken(token.token);
  } catch (error) {}
};

export const ValidateOTP = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.otpValidation({ code: data });

    // get subscription
    if (res?.data?.user?.company_id) {
      dispatch({
        type: constants.GET_COMPANY,
        payload: res?.data?.user?.company_id,
      });
    }
    if (!res?.data?.user?.active) {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }

    if (
      res?.data?.user?.company_id
      //&& ['admin', 'root'].includes(res?.data?.user?.role)
    ) {
      const subStatus = await ChargeBeeApi.getSubscriptionStatus();
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: subStatus.data,
      });
    } else {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: res.data,
    });

    cb(true, res.data.user);
    message.success('Otp verified Successfully');
    socketHandler.emit('joinSocket', res?.data?.user);
    dispatch(
      getAllEmployees(() => {
        dispatch(ActiveUsersHandler());
        dispatch(removeActiveHandler());
      })
    );
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const ValidateOTP2 = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.otpValidation2(data);
    if (res?.data?.user?.company_id) {
      dispatch({
        type: constants.GET_COMPANY,
        payload: res?.data?.user?.company_id,
      });
    }
    if (!res?.data?.user?.active) {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }

    if (
      res?.data?.user?.company_id
      //&& ['admin', 'root'].includes(res?.data?.user?.role)
    ) {
      const subStatus = await ChargeBeeApi.getSubscriptionStatus();
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: subStatus.data,
      });
    } else {
      dispatch({
        type: payConstants.GET_SUB_STATUS,
        payload: 'active',
      });
    }
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: res.data,
    });

    cb(true, res.data.user);
    message.success('Otp verified Successfully');
    socketHandler.emit('joinSocket', res?.data?.user);
    dispatch(
      getAllEmployees(() => {
        dispatch(removeActiveHandler());
      })
    );
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const ValidateOTPCode = (data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.otpValidation2(data);
    cb(true, res.data.user);
    message.success('Otp verified Successfully');
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const logoutUser =
  (cb = () => {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.LOG_OUT,
      });
      dispatch(resetTimer());
      cb?.();
    } catch (error) {
      message.error(error.response?.data.message ?? error.message);
    } finally {
      cb?.();
    }
  };

export const resetPasswordAction = (data, cb) => async (dispatch) => {
  try {
    await AuthApi.forgotPassword(data);
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const changePasswordAction =
  (token, password, cb) => async (dispatch) => {
    try {
      await AuthApi.resetPassword(token, { password });

      cb(true);
    } catch (error) {
      cb(null);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const changePassword2 = (data, cb) => async (dispatch) => {
  try {
    await AuthApi.resetPassword2(data);

    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updatePasswordAction = (id, data, cb) => async (dispatch) => {
  try {
    const res = await AuthApi.updateUsers(id, data);
    dispatch({
      type: constants.UPDATE_PROFILE,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const resendEmail = (cb) => async (dispatch) => {
  try {
    await AuthApi.resentVerificationEmail();
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const ActiveUsersHandler = () => async (dispatch, getState) => {
  try {
    let { allEmployees } = getState().shiftsReducer;
    let { actives } = getState().auth;
    socketHandler.on('locationUpdate', (value) => {
      let isValid =
        allEmployees?.filter((user) => user.id == value.user.id).length > 0;
      if (isValid) {
        let result = deleteValue([...actives], value.user.id);
        dispatch(addActiveUsers([...result, value]));
      }
    });
  } catch (error) {}
};

export const removeActiveHandler = () => async (dispatch) => {
  try {
    socketHandler.on('timesheet', (data) => {
      dispatch(removeActiveUser(data.user));
    });
  } catch (error) {}
};

export const getCoords = (coords) => {
  return {
    type: constants.GET_COORDS,
    payload: coords,
  };
};

export const isHelpDone = () => {
  return {
    type: constants.HELP_DONE,
  };
};

export const showHelp = () => {
  return {
    type: constants.SHOW_HELP,
  };
};

export const updateSteps = (step) => {
  return {
    type: constants.UPDATE_STEPS,
    payload: step,
  };
};

export const addNewOrganization = (orgId) => {
  return {
    type: constants.NEW_ORG_ID,
    payload: orgId,
  };
};
export const addNewEmployee = (empId) => {
  return {
    type: constants.NEW_DESCENDANT,
    payload: empId,
  };
};

export const updateCompany = (company) => {
  return {
    type: constants.UPDATE_COMPANY,
    payload: company,
  };
};
export const updateTourState = (payload) => {
  return {
    type: constants.UPDATE_TOUR,
    payload,
  };
};
export const updatePowerBiData = (payload) => {
  return {
    type: constants.USER_POWER_BI,
    payload,
  };
};

export const addActiveUsers = (data) => {
  return {
    type: constants.SET_ACTIVE_USERS,
    payload: data,
  };
};

export const removeActiveUser = (user) => {
  return {
    type: constants.REMOVE_ACTIVE_USER,
    payload: user,
  };
};
