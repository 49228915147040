import React, { memo } from 'react';
import { Input, InputNumber, Form, Tag, Select, Typography } from 'antd';
import Store from 'devextreme/data/abstract_store';
import { addBusinessDays } from 'date-fns';
const { Option } = Select;
const { Text } = Typography;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  allDept,
  dLoader,
  locLoader,
  allLocations,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' ? (
      <Select
        placeholder="Select Parent Department"
        className="w-100"
        loading={dLoader}
        disabled={dLoader}
      >
        {allDept
          ?.filter((dept) => dept?.id !== record?.key)
          ?.map((item, i) => {
            return (
              <Option key={i} value={item?.id}>
                {item?.name}
              </Option>
            );
          })}
      </Select>
    ) : inputType === 'tag' ? (
      <Select mode="tags" placeholder="Input Job Types">
        {children[1]?.map((item) => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    ) : inputType == 'location' ? (
      <Select
        placeholder="Select Location"
        className="w-100"
        loading={locLoader}
        disabled={locLoader}
      >
        {allLocations?.map((item, i) => {
          return (
            <Option key={i} value={item?.id}>
              {item?.name}
            </Option>
          );
        })}
      </Select>
    ) : (
      <Input />
    );

  const data =
    dataIndex == 'job_types' ? (
      <>
        {children[1].length > 0 ? (
          <>
            {children[1].map((tag) => (
              <Tag color="blue" key={tag}>
                {tag}
              </Tag>
            ))}
          </>
        ) : (
          <Text>No Job Titles</Text>
        )}
      </>
    ) : (
      children
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          className="m-0 p-0"
          rules={[
            {
              required: !(dataIndex == 'job_types' || dataIndex == 'parent'),
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        data
      )}
    </td>
  );
};

export default memo(EditableCell);
