import { INSTANCE } from '../../config/networkInstance';

export default class ShiftApi {
  createShift = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'shift',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateShift = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `shift/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteShift = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: 'shift',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getShifts = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllocations = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/allocation',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getUserAllocations = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/allocation/user',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  clockIn = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'shift/clockin',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  clockOut = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'shift/clockout',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  breakIn = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'shift/breakin',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  breakOut = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'shift/breakout',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getClockInOuts = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/in-outs',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTodaysActive = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/todaysActive',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  todayOnLeave = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/todayOnLeave',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTodaysOnTimes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/todayOnTime',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getWeeklyActivities = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'shift/weeklyActivity',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteAllocation = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: `shift/allocation/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  uploadBulkShifts = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'shift/uploadBulk',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
