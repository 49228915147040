import React from 'react';
import { Typography } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { styles } from './style';
const { Text } = Typography;

function Index({ item, remove, index }) {
  return (
    <div
      className="d-flex flex-row align-items-center justify-content-between px-3 fullWidth  px-2 mt-4"
      style={styles.item}
    >
      <div className="d-flex flex-column align-items-center">
        <Text strong>Clock In</Text>
        <Text>{moment(item?.clockin).format('DD MMM, hh:mm a')}</Text>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Text strong>Clock Out</Text>
        <Text>{moment(item?.clockout).format('DD MMM, hh:mm a')}</Text>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Text strong>Breaks</Text>
        <Text>{item?.breaks?.length ?? 0}</Text>
      </div>

      <MinusCircleOutlined
        className="c-pointer"
        style={styles.removeIcon}
        onClick={() => remove(index)}
      />
    </div>
  );
}

export default Index;
