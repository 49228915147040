import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from 'antd';
import Geocode from 'react-geocode';
import StaticMap from '../../views/location/staticMap';

let { Text } = Typography;

function Index({ title, latitude, longitude, error = false }) {
  let [address, setAddress] = useState('Unknown');
  useEffect(() => {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {}
    );
  }, []);
  if (address == 'Unknown') {
    return <Fragment />;
  }
  return (
    <>
      <div className={'position-relative pt-3'}>
        {title && (
          <Text strong className="pb-2 d-block">
            {title}
          </Text>
        )}
        <a
          href={`https://www.google.com/maps/?q=${latitude},${longitude}`}
          target="_blank"
          rel="noreferrer"
        >
          <StaticMap lat={latitude} lng={longitude} radius={100} width={280} />
        </a>
        <Text className="p-2 d-block">{address}</Text>
      </div>
      {error && (
        <Text strong style={{ fontSize: 15, color: 'red' }}>
          Geo Fencing Failed
        </Text>
      )}
    </>
  );
}

export default Index;
