import { message } from 'antd';
import ApiClass from './api';
import ApiClassPerformance from '../clockinout/api';
import socketHandler from '../../config/socket';
import * as constants from './constants';

let Api = new ApiClass();
let performanceApi = new ApiClassPerformance();

export const createTask = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.createTask(data);
    cb(true);
    socketHandler.emit('task', res?.data);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const FilterDashboard = (data) => async (dispatch) => {
  try {
    dispatch({
      type: constants.DASHBOARD_FILTER,
      payload: data,
    });
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const multiCreateTask = (data, cb) => async (dispatch) => {
  try {
    data.forEach(async (element) => {
      let res = await Api.createTask(element);
      socketHandler.emit('task', res?.element);
    });
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getTasksExport = (params, cb) => async (dispatch) => {
  try {
    let res = await Api.getTasksExport(params);
    dispatch({
      type: constants.GET_ALL_TASKS_EXPORT,
      payload: res?.data?.tasks,
    });
    cb(res?.data?.tasks);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllTasks = (params, cb) => async (dispatch) => {
  try {
    let res = await Api.getAllTasks(params);
    dispatch({
      type: constants.GET_ALL_TASKS,
      payload: res?.data,
    });
    cb(res?.data?.allTasks);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getTasks = (params, cb) => async (dispatch) => {
  try {
    let res = await Api.getTasks(params);
    if (res?.data?.tasks.length > 0) {
      let data = res?.data?.allTasks.reduce(function (results, pro) {
        (results[pro.user_id.id] = results[pro.user_id.id] || []).push(pro);
        return results;
      }, {});
      dispatch({
        type: constants.GET_TASKS,
        payload: data,
      });
    }
    if (res?.data?.selfTasks?.length > 0) {
      dispatch({
        type: constants.GET_MY_TASKS,
        payload: res?.data?.selfTasks,
      });
    }
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getOneTask = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.getOneTask(data);
    if (res?.data) {
      dispatch({
        type: constants.GET_ONE_TASK,
        payload: res?.data,
      });
    }
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getRawTask = (params, cb) => async (dispatch) => {
  try {
    let res = await Api.getTasks(params);
    res.tasks = [...res?.data?.selfTasks, ...res?.data?.tasks];
    dispatch({
      type: constants.GET_RAW_TASKS,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const toggleTimer = (params, data, cb) => async (dispatch) => {
  try {
    let res = await Api.toggleTimer(params, data);
    dispatch({
      type: constants.TOGGLE_TASK,
      payload: res?.data,
    });
    cb(res?.data);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateTasks = (params, data, cb) => async (dispatch) => {
  try {
    let res = await Api.updateTasks(params, data);

    cb(true, res?.data);

    socketHandler.emit('taskUpdate', res?.data);
  } catch (error) {
    cb(false, null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateTaskStatus =
  (params, data, task, cb) => async (dispatch, getState) => {
    try {
      let { user } = getState().auth;
      let res = await Api.updateTasks(params, data);

      if (user?.id == task.user_id.id) {
        dispatch({
          type: constants.UPDATE_TASK_SELF,
          payload: task,
        });
      } else {
        dispatch({
          type: constants.UPDATE_TASK,
          payload: task,
        });
      }
      cb(true);
      socketHandler.emit('taskUpdate', res?.data);
    } catch (error) {
      cb(false);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const deleteTasks = (task, cb) => async (dispatch) => {
  try {
    await Api.deleteTasks({ taskId: task.id });
    dispatch({
      type: constants.DELETE_TASKS,
      payload: task,
    });
    cb(true);
    socketHandler.emit('taskDelete', task);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const fetchTaskPerformance =
  (type, fetchType, cb) => async (dispatch) => {
    try {
      let res = await performanceApi.getPerformance(type, fetchType);
      dispatch({
        type: constants.TASK_QUESTIONS,
        payload: res?.data,
      });
      cb(res?.data);
    } catch (error) {
      cb([]);
      message.error(error.response?.data.message ?? error.message);
    }
  };
