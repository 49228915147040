import React, { useState, useEffect, memo } from 'react';
import { Drawer, Button, DatePicker, Select, Typography, Form } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getSpecificCustomers } from '../../redux/customers/action';
import { updateTimesheet } from '../../redux/timesheets/action';
import { getBreakTypes } from '../../redux/types/action';

const { Text } = Typography;
const { Option, OptGroup } = Select;

const reasons = [
  { name: 'Lunch break' },
  { name: 'Feeling sick' },
  { name: 'Personal errand' },
  { name: 'Travelling' },
  { name: 'Other' },
];

function Index({ show, item, sheets, refreshData, handleCancel }) {
  const [form] = Form.useForm();
  const s = moment(item?.allocation?.clockin).format('DD-MM-YYYY HH:mm:ss');
  const e = moment(item?.allocation?.clockout).format('DD-MM-YYYY HH:mm:ss');
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);
  const { typesBreak: types } = useSelector((state) => state.types);
  const [tasks, setTasks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cloader, setCLoader] = useState(false);
  const [rloader, setRLoader] = useState(false);
  const [activity, setActivity] = useState(null);
  const [state, setState] = useState({
    start: moment(s, 'DD-MM-YYYY HH:mm:ss'),
    end: moment(e, 'DD-MM-YYYY HH:mm:ss'),
  });

  const customersCallback = (result) => {
    setCLoader(false);
    if (result) {
      setTasks(result);
    }
  };

  const stopReasonLoader = () => {
    setRLoader(false);
  };

  useEffect(() => {
    if (show) {
      const organization = item?.user?.organization;
      setRLoader(true);
      setCLoader(true);
      dispatch(getSpecificCustomers({ organization }, customersCallback));
      dispatch(getBreakTypes({ company: company?.id }, stopReasonLoader));
      if (item?.allocation?.breaks?.length > 0) {
        form.setFieldsValue({
          breaks: item?.allocation?.breaks
            ?.filter((item) => item !== null)
            .map((item) => {
              return {
                ...item,
                in: moment(new Date(item?.in), 'DD-MM-YYYY HH:mm:ss'),
                out: moment(new Date(item?.out), 'DD-MM-YYYY HH:mm:ss'),
              };
            }),
        });
      }
    }
  }, [show]);

  const getTasksTypes = (name) => {
    return tasks.filter((item) => item?.type == name);
  };

  const getType = (value) => {
    setActivity(value);
  };

  const handleChange = (name, date) => {
    setState({
      ...state,
      [name]: date,
    });
  };

  const stopLoader = () => {
    setLoader(false);
    refreshData();
    handleCancel();
  };

  const updateTimesheetFunc = (values) => {
    const { start, end } = state;
    const ids = sheets.map((item) => item?.id);
    const data = {
      clockin: new Date(start),
      clockout: new Date(end),
      timesheets: ids,
      allocation: item?.allocation?.id,
      ...(activity && { activity }),
      ...(values?.breaks?.length > 0 && { breaks: values.breaks }),
      tzOffset: new Date().getTimezoneOffset(),
    };

    setLoader(true);
    dispatch(updateTimesheet(data, stopLoader));
  };
  let reasonsTypes = types.length > 0 ? types : reasons;
  return (
    <Drawer
      title="Edit Time"
      visible={show}
      onClose={handleCancel}
      zIndex={1050}
      width={window.innerWidth > 900 ? 520 : window.innerWidth - 100}
    >
      {tasks?.length > 0 && (
        <div>
          <Text className="d-block pb-1">Activity</Text>
          <Select
            size={'large'}
            placeholder="select Assignment"
            className="fullWidth"
            loading={cloader}
            disabled={cloader}
            onChange={getType}
            defaultValue={item?.allocation?.customer?.name}
          >
            {getTasksTypes('Customer').length > 0 && (
              <OptGroup label="Customer">
                {getTasksTypes('Customer').map((obj) => {
                  return <Option key={obj?.id}>{obj?.name}</Option>;
                })}
              </OptGroup>
            )}
            {getTasksTypes('Project').length > 0 && (
              <OptGroup label="Project">
                {getTasksTypes('Project').map((obj) => {
                  return <Option key={obj?.id}>{obj?.name}</Option>;
                })}
              </OptGroup>
            )}
            {getTasksTypes('Other').length > 0 && (
              <OptGroup label="Other">
                {getTasksTypes('Other').map((obj) => {
                  return <Option key={obj?.id}>{obj?.name}</Option>;
                })}
              </OptGroup>
            )}
          </Select>
        </div>
      )}
      <div>
        <Text className="d-block pb-1 mt-4">Clock In</Text>
        <DatePicker
          format="DD-MM-YYYY HH:mm:ss"
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          value={state.start}
          onChange={(date) => handleChange('start', date)}
          className="w-100"
          size="large"
          allowClear={false}
        />
      </div>
      <div className="mt-4">
        <Text className="d-block pb-1">Clock Out</Text>
        <DatePicker
          format="DD-MM-YYYY HH:mm:ss"
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          value={state.end}
          onChange={(date) => handleChange('end', date)}
          className="w-100"
          size="large"
          allowClear={false}
        />
      </div>

      <Form
        name="dynamic_form_nest_item"
        onFinish={updateTimesheetFunc}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        form={form}
        className="mt-4"
      >
        <Form.List name="breaks">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // overflowX: 'hidden',
                  }}
                  className="timeEntryLeave"
                >
                  <Row>
                    <Col sm={12} lg={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'in']}
                        label="Break In"
                        fieldKey={[fieldKey, 'in']}
                        rules={[
                          { required: true, message: 'Missing Break In' },
                        ]}
                      >
                        <DatePicker
                          className="fullWidth"
                          format="DD-MM-YYYY HH:mm"
                          showTime={{
                            defaultValue: moment('00:00', 'HH:mm'),
                          }}
                          placeholder="Select Break In Time"
                          size="large"
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={12} lg={6}>
                      <Form.Item
                        {...restField}
                        label="Break Out"
                        name={[name, 'out']}
                        fieldKey={[fieldKey, 'out']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing Break Out',
                          },
                        ]}
                        className="m-0"
                      >
                        <DatePicker
                          className="fullWidth"
                          format="DD-MM-YYYY HH:mm"
                          showTime={{
                            defaultValue: moment('00:00', 'HH:mm'),
                          }}
                          placeholder="Select Break Out Time"
                          size="large"
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mt-md-0 mt-3">
                    <Col sm={12}>
                      <Form.Item
                        {...restField}
                        label="Break Reason"
                        name={[name, 'reason']}
                        fieldKey={[fieldKey, 'reason']}
                      >
                        <Select
                          className="fullWidth"
                          placeholder="Select Break Reason"
                          size="large"
                          loading={rloader}
                          disabled={rloader}
                        >
                          {reasonsTypes?.map((item) => {
                            return (
                              <Option key={item?.name}>{item?.name}</Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}

              <Row>
                <Col lg={fields.length == 0 ? 12 : 6} className="mb-4">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    className="fullWidth"
                    icon={<PlusOutlined />}
                  >
                    Add Breaks
                  </Button>
                </Col>
                {fields.length > 0 && (
                  <Col lg={6} className="mb-4">
                    <Button
                      type="dashed"
                      className="fullWidth"
                      danger
                      onClick={() => remove(fields[fields.length - 1].name)}
                      icon={<MinusCircleOutlined />}
                    >
                      Remove Breaks
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
        </Form.List>
        <Button
          type="primary"
          className="w-100 bg-primary border-primary d-block mt-4 ml-auto"
          loading={loader}
          htmlType="submit"
        >
          UPDATE
        </Button>
      </Form>
    </Drawer>
  );
}

export default memo(Index);
