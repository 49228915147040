import * as constants from './constants';
import _ from 'lodash';
let initState = {
  PQ: [],
  totalPQ: 0,
  initial: true,
  summery: [],
  user_answer: {},
};
function performanceReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_PQ:
      return {
        ...state,
        PQ: payload.results,
        totalPQ: payload.totalResults,
        initial: false,
      };
    case constants.NEW_QUESTION:
      return {
        ...state,
        PQ: [payload, ...state.PQ],
      };
    case constants.DELETE_QUESTION:
      let newPQ = [...state.PQ];
      newPQ.splice(_.indexOf(newPQ, _.find(newPQ, { id: payload })), 1);
      return {
        ...state,
        PQ: newPQ,
      };
    case constants.PQ_SUMMERY:
      return {
        ...state,
        summery: payload,
      };
    case constants.USER_ANSWERS:
      var test = { ...state.user_answer };
      test[payload.user] = payload.data;
      return {
        ...state,
        user_answer: test,
      };
    default:
      return state;
  }
}

export default performanceReducer;
