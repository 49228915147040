import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import socketHandler from '../../config/socket';

import { getCurrentPosition } from '../../helpers/getCurrentPosition';
import { updateLocation, updateUserLocation } from '../../redux/auth/action';
import { getMembers } from '../../redux/members/action';
import { configConsumerProps } from 'antd/lib/config-provider';

const AuthGuard = ({ children }) => {
  const location = useLocation();

  const { isLogin, user, isEmailVerified } = useSelector((state) => state.auth);

  const [seconds, setSeconds] = useState(0);
  const { isActiveSub, isPlatinumPlan } = useSelector(
    (state) => state.chargeBee
  );
  const dispatches = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      RunLoop();
      //console.log('Test Run');
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const RunLoop = async () => {
    const locationLive = await getCurrentPosition();
    await updateLocation({
      user: user.id,
      company: user.company_id.id,
      location: locationLive,
    });

    //send location
  };
  const fetchUpdates = () => {
    //dispatches(getMembers(1 ));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchUpdates();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const currentRoute = location.pathname;

  if (!isLogin) {
    return <Redirect to="/auth/login" />;
  }

  if (!user?.active) {
    return <Redirect to="/auth/otp" />;
  }

  if (!isEmailVerified) {
    return (
      <Redirect
        to={{
          pathname: '/auth/otp',
          state: { email: user.email },
        }}
      />
    );
  }

  if (!user?.company_id && user?.role === 'root') {
    return <Redirect to="/steps" />;
  }

  if (
    user?.isInitialPassword &&
    ['user', 'supervisor', 'admin'].includes(user.role)
  ) {
    return <Redirect to="/update-password" />;
  }

  if (!user?.profilePic) {
    return <Redirect to="/addVerification" />;
  }

  // check subscription
  if (!isActiveSub) {
    return <Redirect to="/activateSubscription" />;
  }

  if (
    ['app/leaves', '/app/performance-questions'].includes(currentRoute) &&
    isPlatinumPlan
  ) {
    return <Redirect to="/app/subscription" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
