export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOG_OUT = 'LOG_OUT';
export const HELP_DONE = 'HELP_DONE';
export const SHOW_HELP = 'SHOW_HELP';
export const GET_COORDS = 'GET_COORDS';
export const UPDATE_STEPS = 'UPDATE_STEPS';
export const PRE_DETAILS = 'PRE_DETAILS';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const NEW_ORG_ID = 'NEW_ORG_ID';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_TOUR = 'UPDATE_TOUR';
export const NEW_DESCENDANT = 'NEW_DESCENDANT';
export const USER_POWER_BI = 'USER_POWER_BI';

export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';
export const REMOVE_ACTIVE_USER = 'REMOVE_ACTIVE_USER';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
