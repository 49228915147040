import React from 'react';
import { Modal, Row, Col } from 'antd';

function DoneModal(props) {
  return (
    <>
      <Modal
        className="welcomeModalCon"
        bodyStyle={{ padding: 0, borderRadius: 20, overflow: 'hidden' }}
        centered
        width={600}
        visible={props.isVisible}
        onCancel={() => props.onRightCtaClick?.()}
        footer={null}
      >
        <div>
          <div>
            {/* header */}
            <div className="modalCont" style={{ paddingBottom: 20 }}>
              {/* header */}
              <div className="modalHeader">
                <p className="headerHeading">{props.header}</p>
              </div>

              {/* body */}
              <div className="bodyCont">
                <Row justify="center">
                  <p style={{ textAlign: 'center' }}>{props.bodyA}</p>
                </Row>

                <Row justify="center">
                  <p style={{ textAlign: 'center' }}>{props.bodyB}</p>
                </Row>
                {props.bodyC && <Row>{props.bodyC()}</Row>}
              </div>

              <Row justify="space-around">
                <Col>
                  <button
                    className="getStartedCTA ctaMargin"
                    onClick={() => props.onLeftCtaClick?.()}
                  >
                    {props.ctaLeft}
                  </button>
                </Col>
                <Col>
                  <button
                    className="laterCTA ctaMargin"
                    onClick={() => props.onRightCtaClick?.()}
                  >
                    {props.ctaRight}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DoneModal;
