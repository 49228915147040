import moment from 'moment';
export const renderColumn = (
  days,
  renderTitle,
  renderAvatar,
  renderColumnDate,
  renderSchedule
) => {
  const result = [];
  const arr = ['members', ...days];
  arr.map((item) => {
    if (item === 'members') {
      result.push({
        title: renderTitle,
        dataIndex: 'members',
        render: renderAvatar,
        align: 'center',
        width: 150,
      });
    } else {
      result.push({
        title: renderColumnDate(item),
        dataIndex: moment(item).format('DD-MM-YYYY'),
        width: 250,
        align: 'center',
        render: renderSchedule,
      });
    }
  });
  return result;
};

export const parseShifts = (api, days) => {
  if (api.length > 0) {
    const apiData = [...api].map((item) => {
      return {
        ...item,
        userId: item.user.id,
      };
    });

    const result = [];
    const objectMap = apiData.reduce(function (o, i) {
      if (!o[i.userId]) o[i.userId] = [];
      o[i.userId].push(i);
      return o;
    }, {});
    const sortedKeys = Object.keys(objectMap).sort();
    const filterUsers = sortedKeys.reduce(function (d, k) {
      d.push(objectMap[k]);
      return d;
    }, []);
    filterUsers?.map((userArr) => {
      const getValues = (date) => {
        return userArr?.filter((item) => moment(item.date).isSame(date, 'day'));
      };
      const dataObj = {
        members: userArr[0].user,
        [moment(days[0]).format('DD-MM-YYYY')]: getValues(days[0]),
        [moment(days[1]).format('DD-MM-YYYY')]: getValues(days[1]),
        [moment(days[2]).format('DD-MM-YYYY')]: getValues(days[2]),
        [moment(days[3]).format('DD-MM-YYYY')]: getValues(days[3]),
        [moment(days[4]).format('DD-MM-YYYY')]: getValues(days[4]),
        [moment(days[5]).format('DD-MM-YYYY')]: getValues(days[5]),
        [moment(days[6]).format('DD-MM-YYYY')]: getValues(days[6]),
      };
      result.push(dataObj);
    });

    return result;
  } else return [];
};
