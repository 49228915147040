import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd-country-phone-input';
import { renderRoutes } from './routes';
import { routes, routes2, routes3 } from './routesData';
import { Provider } from 'react-redux';
import Geocode from 'react-geocode';
import { store, persistor } from '../src/config/store';
import { PersistGate } from 'redux-persist/integration/react';
import { refreshToken } from './redux/auth/action';
import { BASENAME } from './config/constant';
import en from 'world_countries_lists/data/en/world.json';
import 'antd-country-phone-input/dist/index.css';
import 'react-phone-input-2/lib/style.css';

Geocode.setApiKey('AIzaSyD4bLo0gWQydOneofumWsQxOiy9VhC8PDo');

const App = () => {
  useEffect(() => {
    store.dispatch(refreshToken());
  }, []);
  return (
    <React.Fragment>
      <ConfigProvider locale={en}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router basename={BASENAME}>
              {renderRoutes(routes, routes2, routes3)}
            </Router>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default App;
