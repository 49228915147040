import React, { useState, useEffect, memo } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import { PlusOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import CreateModal from './addPerformaceQuestion';
import ShowAnswers from './showButton';
import { getPQ, updatePQuestions } from '../../redux/performanceQ/action';
import { getAllDepartments } from '../../redux/members/action';
import EditableCell from './editableCell';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import { styles } from './style';

const { Text } = Typography;
const EditableTable = ({ history }) => {
  const dispatch = useDispatch();
  const { PQ, totalPQ, initial } = useSelector((state) => state.pqReducer);
  const { allDept, allDeptByIds } = useSelector(
    (state) => state.membersReducer
  );
  const { isPerformanceHelp } = useSelector((state) => state.tourReducer);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const isEditing = (record) => record.key === editingKey;

  // edit organization
  const [dLoader, setDLoader] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [selectedDpt, setDpt] = useState(null);

  useEffect(() => {
    if (allDept.length === 0) {
      setDLoader(true);
      dispatch(getAllDepartments(() => setDLoader(false)));
    }
  }, []);

  const getJobTypes = (id) => {
    const selectedOrg = allDeptByIds[id] || allDeptByIds[getDepartmentId(id)];
    if (selectedOrg) {
      setJobTypes(selectedOrg.jobTypes);
    }
  };

  // end edit
  const renderData = () => {
    const data = PQ.map((item) => {
      return {
        ...item,
        key: item?.id,
        question: item?.question,
        organization: item?.organization?.name,
        jobType: item?.jobType,
        questionType: item?.questionType,
        active: item?.active,
        options: item?.options,
      };
    });
    return data;
  };

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    if (initial) {
      setLoader(true);
      dispatch(getPQ(page, selectedDpt, stopLoader));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(PQ)) {
      const data = renderData();
      setData(data);
    }
  }, [PQ]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getPQ(1, selectedDpt, stopLoader));
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    setLoader(true);
    dispatch(getPQ(page, dept, stopLoader));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getDepartmentId = (name) => {
    const id = allDept?.filter((item) => item.name === name)[0].id || 0;

    getJobTypes(id);
    return id;
  };

  const edit = (record) => {
    form.setFieldsValue({
      question: '',
      jobType: '',
      organization: '',
      questionType: '',
      ...record,
    });
    getDepartmentId(record.organization);
    setEditingKey(record.key);
  };

  const changePage = (page) => {
    setPage(Number(page));
    setLoader(true);
    dispatch(getPQ(Number(page), selectedDpt, stopLoader));
    cancel();
  };

  const cancel = () => {
    setEditingKey('');
  };

  const updateQuestion = (key, data) => {
    setLoader(true);

    dispatch(updatePQuestions(key, data, stopLoader));
  };

  // const deleteQuestion = (key) => {
  //   setLoader(true);
  //   dispatch(deletePQuestions(key, stopLoader));
  // };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      // let obj = getRowByValue(row);

      const newRow = { ...row };

      newRow.organization = getDepartmentId(newRow.organization);

      updateQuestion(key, newRow);

      // const newRow = obj.values;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(...row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const renderStatus = (value) => {
    return (
      <>
        {value ? (
          <div>
            <p style={styles?.isActive}>active</p>
          </div>
        ) : (
          <div>
            <p style={styles?.isDisabled}>disabled</p>
          </div>
        )}
      </>
    );
  };

  const renderOptions = (options) => {
    if (options?.length > 0) {
      return options?.map((item) => {
        return (
          <Tag color="orange" key={item} className="mt-2">
            {item}
          </Tag>
        );
      });
    } else {
      return <Text>No Options</Text>;
    }
  };

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      editable: true,
      align: 'left',
    },
    {
      title: 'Options',
      dataIndex: 'options',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',

      render: renderOptions,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      editable: true,
      align: 'center',
      render: renderStatus,
      className: 'd-none d-sm-table-cell',
    },

    {
      title: 'Organization',
      dataIndex: 'organization',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Job Title',
      dataIndex: 'jobType',
      editable: true,
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Type',
      dataIndex: 'questionType',
      editable: true,
      align: 'center',
      render: (value) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <p style={{ marginBottom: 0 }}>
              {value === 'in' ? 'Clock In' : 'Clock Out'}
            </p>
          </div>
        );
      },
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Update?"
              onConfirm={() => save(record.key)}
            >
              <a>Save</a>
            </Popconfirm>
            <a
              href="javascript:;"
              onClick={cancel}
              style={{
                marginLeft: 8,
              }}
            >
              Cancel
            </a>
          </span>
        ) : (
          <Space align="center" size="large">
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit <EditOutlined />
            </Typography.Link>
            <ShowAnswers item={record} editingKey={editingKey} />
          </Space>
        );
      },
    },
  ];
  const getInputType = (dataIndex) => {
    if (dataIndex === 'question') {
      return 'text';
    } else {
      return 'select';
    }
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: getInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        getJobTypes: getJobTypes,
        allDept: allDept,
        jobTypes: jobTypes,
        dLoader: dLoader,
      }),
    };
  });

  return (
    <React.Fragment>
      <h3>Performance</h3>
      <Text type="secondary">{moment().format('MMMM Do YYYY')}~</Text>
      <Divider className="m-0 pb-2" />
      <Space>
        <Tooltip
          title="Click here to add performance questions
          for your team members. You will also
          add and edit performance questions
          here in the future."
          color={'#F89722'}
          defaultVisible={isPerformanceHelp}
        >
          <Button
            type="primary"
            className="bg-primary border-primary"
            icon={<PlusOutlined />}
            onClick={showModal}
            style={{ marginTop: 10 }}
          >
            Add Performance
          </Button>
        </Tooltip>

        <DepartmentFilter
          pickerStyle={{
            width: 215,
            marginTop: 10,
          }}
          onSelectDpt={onChangeDept}
        />

        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshData} style={{ marginTop: 10 }}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider className="m-0 mt-2 pb-2" />
      <Card className="tmb">
        <Form form={form} component={false}>
          <Table
            className="table-curved"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: changePage,
              current: page,
              total: totalPQ,
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          />
        </Form>
      </Card>
      <CreateModal
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
        history={history}
      />
    </React.Fragment>
  );
};

export default memo(EditableTable);
