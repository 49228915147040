import React, { useState, useEffect, memo, useRef } from 'react';
import {
  Drawer,
  Form,
  Input,
  Space,
  message,
  Select,
  DatePicker,
  AutoComplete,
  Checkbox,
} from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  createTask,
  updateTasks,
  deleteTasks,
  multiCreateTask,
} from '../../redux/tasks/action';
import ActivityFilter from '../timeSheets/activityFilter';
//import DatalistInput from 'react-datalist-input';
import { getAllLocations } from '../../redux/shifts/action';
import { getAllDepartments } from '../../redux/members/action';
import { getAllType, addLeaveRequest } from '../../redux/leave/action';

const { Option } = Select;
const { TextArea } = Input;

export default memo(function Index({ show, handleOk, setClose, activeTasks }) {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { types, initial } = useSelector((state) => state.leave);
  const { company } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  const [loader, setLoader] = useState(false);
  const [activity, setActivity] = useState(null);
  const [locLoader, setLocLoader] = useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (types.length === 0) {
      dispatch(getAllType(company.id));
    }
  }, []);

  const toObject = (arr, key) =>
    arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      message.success('Successfully Sent!');
      setItem(null);
      handleOk();
    }
  };

  const createTaskFunc = (values) => {
    var _types = toObject(types, 'id');
    var _type = _types[values.type];

    var _days = getDays(values.from, values.days, _type);

    if (_type.gender.isSet) {
      // if (_type.gender.type != emp.gender){
      // 	errors ={error: true, msg:'Rejected due to Wrong Employee Gender'}
      // 	return
      // }
    }

    if (_type.department.isSet && _type.department.type.length > 0) {
      if (!_type.department.type.includes(currentUser.organization.id)) {
        message.error('Department not Included in this policy');
        return;
      }
    }

    if (_type.days < values.days) {
      message.error('Days selected exceed maximum days allowed in policy');
      return;
    }

    if (_type.maximum.isSet && _type.maximum.amount < values.days) {
      message.error(
        `Days selected exceed maximum consecutive (${_type.maximum.amount}) days allowed in policy`
      );
      return;
    }

    if (
      _type.daysPrior.isSet &&
      _type.daysPrior.amount >
        moment(new Date(from))
          .startOf('day')
          .diff(moment().startOf('day'), 'days')
    ) {
      message.error(
        `Leave Type should be applied ${_type.daysPrior.amount} days prior`
      );
      return;
    }

    var temp = {
      days: _days,
      reason: values.reason,
      status: 'pending',
      type: values.type,
      user: currentUser?.id,
      company: currentUser?.company_id?.id,
    };

    setLoader(true);
    dispatch(addLeaveRequest(temp, stopLoader));
  };

  const getDays = (_startDate, _days, _type) => {
    var array = [];
    for (let index = 0; index < _days; index++) {
      var new_date = moment(_startDate).add(index, 'days').valueOf();
      array = [...array, new_date];
    }
    //
    if (_type.weekend) {
      array.forEach((element) => {
        var momentObject = moment(element);
        const weekday = momentObject.format('dddd');
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';

        if (isWeekend) {
          var carIndex = array.indexOf(momentObject);
          array.splice(carIndex, 1);
          var new_date = moment(array[array.length - 1])
            .add(1, 'days')
            .valueOf();
          array = [...array, new_date];
        }
      });
    }

    return array;
  };

  const handleClose = () => {
    setItem(null);
    setClose();
    form?.resetFields();
  };

  return (
    <Drawer
      title={'Request For Leave '}
      visible={show}
      onCancel={handleClose}
      width={window.innerWidth > 900 ? 520 : window.innerWidth}
      footer={null}
      zIndex={1050}
    >
      <Row>
        <Col md={12} lg={12}>
          <Form layout="vertical" form={form} onFinish={createTaskFunc}>
            <Form.Item
              name="type"
              label="Leave Type "
              required
              rules={[
                {
                  required: true,
                  message: 'Please Select Leave Type',
                },
              ]}
            >
              <Select size="large" className="w-100">
                {types.map((_, key) => (
                  <Option key={key} value={_.id}>
                    {_.name + ' Leave'}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="reason"
              label="Reason"
              required
              rules={[
                {
                  required: true,
                  message: 'Please Enter Reason',
                },
              ]}
            >
              <TextArea size="large" placeholder="Reason for Leave" />
            </Form.Item>

            <Form.Item
              name="from"
              label="From Date"
              required
              rules={[
                {
                  required: true,
                  message: 'Please Enter Start Date',
                },
              ]}
            >
              <DatePicker
                size="large"
                format="DD-MM-YYYY "
                placeholder=" From "
                className="w-100"
              />
            </Form.Item>

            <Form.Item name="days" label="Number of Days" required={true}>
              <Input
                size="large"
                type={'number'}
                placeholder="Please Enter number  of days"
              />
            </Form.Item>

            <Space className="mt-3 float-left">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleClose}
              >
                Cancel
              </button>

              <button
                disabled={loader}
                className="btn btn-primary"
                htmlType="submit"
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Send Request'
                )}
              </button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
});
