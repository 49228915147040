import * as constants from './constants';

let initState = {
  token: {},
  departments: [],
  activities: [],
  users: [],
  sheets: [],
  employeesStats: [],
  stats: {
    totalWorkedTime: 0,
    totalBreakTime: 0,
    totalOverTime: 0,
    avgWorkedTime: 0,
    avgBreakTime: 0,
    avgOverTime: 0,
  },
  leaves: {
    sum: 0,
    avg: 0,
  },
  performanceA: {
    questions: [],
    answers: [],
  },
  performanceB: {
    questions: [],
    answers: [],
  },
};
function analyticsReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.POWERBI_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case constants.GET_INITIAL_DATA:
      return {
        ...state,
        departments: payload[0],
        users: payload[1],
        activities: payload[2],
      };

    case constants.GET_STATS_ANALYTICS:
      return {
        ...state,
        sheets: payload.sheets,
        ...(payload.stats && { stats: payload.stats }),
        leaves: payload.leaves,
      };

    case constants.GET_EMPLOYEES_STATS:
      return {
        ...state,
        employeesStats: payload,
      };
    case constants.GET_PERFORMANCE_STATS:
      return {
        ...state,
        performanceA: {
          ...state.performanceA,
          questions: payload.options,
          answers: payload.answersOne,
        },
        performanceB: {
          ...state.performanceB,
          questions: payload.numbers,
          answers: payload.answersTwo,
        },
      };

    case constants.UPDATE_ANSWERS_A:
      return {
        ...state,
        performanceA: {
          ...state.performanceA,
          answers: payload,
        },
      };
    case constants.UPDATE_ANSWERS_B:
      return {
        ...state,
        performanceB: {
          ...state.performanceB,
          answers: payload,
        },
      };

    default:
      return state;
  }
}

export default analyticsReducer;
