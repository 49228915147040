export const styles = {
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameText: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    fontSize: 17,
    color: '#fff',
  },
  failedText: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    fontSize: 16,
    color: 'red',
  },
  failedTextGeo: {
    fontSize: 15,
    color: 'red',
  },
  smallFailed: {
    fontSize: 13,
    color: 'red',
  },
  avatar: { color: '#f56a00', backgroundColor: '#fde3cf' },

  listItem: {
    boxShadow: '-2px 0px 17px 2px #F0F0F0',
    background: '#fff',
    borderRadius: 10,
    padding: 10,
    // border: "1px solid #F0F0F0",
    width: '100%',
  },

  borderBreak: {
    border: '1px solid rgb(243,150,65)',
  },

  borderIn: {
    border: '1px solid rgb(95,206,122)',
  },
  borderOut: {
    border: '1px solid #ff8080',
  },
  signImg: {
    width: '100%',
  },
  signImgCont: {
    padding: 5,
    border: '1px solid #F0F0F0',
    marginTop: 15,
  },

  downloadCont: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  downloadIcon: {
    fontSize: 25,

    paddingLeft: 10,
    paddingRight: 10,
  },
};
