import React, { useState } from 'react';
import {
  Divider,
  DatePicker,
  Button,
  Space,
  Modal,
  Select,
  Typography,
  Switch,
} from 'antd';
import { RightOutlined, LeftOutlined, ExportOutlined } from '@ant-design/icons';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import ActivityFilter from './activityFilter';
import ExportPref from './exportPreferences';
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Link, Text } = Typography;

function Index({ loader, exportExcel, rawExport, single = false, ...props }) {
  const dayStartOfWeek = moment(new Date()).startOf('week').startOf('day');
  const dayEndOfWeek = moment(dayStartOfWeek).endOf('week').endOf('day');
  const dayStartOfMonth = moment().startOf('month');
  const dayEndOfMonth = moment().endOf('month');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState('week');
  const [status, setStatus] = useState('approved');
  const [dept, setDept] = useState(null);
  const [activity, setActivity] = useState(null);
  const [selection, setSelection] = useState({
    startDate: dayStartOfWeek,
    endDate: dayEndOfWeek,
  });
  const [range, setRange] = useState(null);
  const [side, setSide] = useState(false);
  const [raw, setRaw] = useState(false);

  const onMoveWeek = (days) => {
    const nextSelection = {
      startDate: moment.utc(selection.startDate).add(days, 'days').toDate(),
      endDate: moment.utc(selection.endDate).add(days, 'days').toDate(),
    };
    setSelection(nextSelection);
    props.onChange(nextSelection);
  };

  const getValues = (date) => {
    const endDate = moment.utc(date).add(7, 'days').toDate();
    const nextSelection = {
      startDate: date,
      endDate,
    };
    setSelection(nextSelection);
    props.onChange(nextSelection);
  };

  const getRangeValues = (values) => {
    const nextSelection = {
      startDate: values[0],
      endDate: values[1],
    };
    setSelection(nextSelection);
    props.onChange(nextSelection);
  };

  const selectMode = (value) => {
    setStatus(value);
  };
  const handleSelect = (value) => {
    setType(value);
    let dates;
    if (value == 'week') {
      dates = {
        startDate: dayStartOfWeek,
        endDate: dayEndOfWeek,
      };
    } else {
      dates = {
        startDate: dayStartOfMonth,
        endDate: dayEndOfMonth,
      };
    }
    setSelection(dates);
    props.onChange(dates);
  };

  const onChange = (dates, dateStrings) => {
    setRange(dateStrings);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeDept = (value) => {
    setDept(value);
  };

  const onChangeActivity = (value) => {
    setActivity(value);
  };

  const openSide = () => {
    setSide(true);
  };

  const closeSide = () => {
    setSide(false);
  };

  const handleRaw = (value) => {
    setRaw(value);
  };

  return (
    <Row>
      <Col md={12}>
        <Divider className="w-100 mb-2" />
        <Space align="center" className="d-flex flex-wrap">
          {type == 'week' && (
            <React.Fragment>
              <Button
                size="large"
                icon={<LeftOutlined />}
                onClick={() => onMoveWeek(-7)}
              />
              <Button
                size="large"
                icon={<RightOutlined />}
                onClick={() => onMoveWeek(7)}
              />
              <DatePicker
                format={dateFormat}
                size="large"
                picker="week"
                defaultValue={moment(selection.startDate)}
                onChange={getValues}
                value={moment(selection.startDate)}
                allowClear={false}
                clearIcon={false}
              />
              <span>~</span>
              <Button size="large">
                {moment(selection.endDate).format('DD/MM/YYYY')}
              </Button>
            </React.Fragment>
          )}
          {type == 'custom' && (
            <RangePicker
              format={dateFormat}
              size="large"
              onChange={getRangeValues}
              value={[moment(selection.startDate), moment(selection.endDate)]}
              allowClear={false}
              clearIcon={false}
            />
          )}

          <Divider type="vertical" style={{ height: 40 }} />

          <Select
            style={{ width: 180 }}
            defaultValue={type}
            size="large"
            onChange={handleSelect}
          >
            <Option value="week">weekly</Option>
            <Option value="custom">custom</Option>
          </Select>

          <>
            {exportExcel && (
              <Button
                type="primary"
                size="large"
                className="bg-primary border-primary ml-2"
                icon={<ExportOutlined />}
                onClick={showModal}
              >
                Export
              </Button>
            )}

            <Modal
              title="Select Start & End Dates"
              visible={isModalVisible}
              footer={null}
              onCancel={handleCancel}
            >
              {!single && (
                <>
                  <DepartmentFilter
                    onSelectDpt={onChangeDept}
                    className="fullWidth"
                  />
                  <div className="mt-4" />
                </>
              )}
              <ActivityFilter
                className="fullWidth"
                onChange={onChangeActivity}
              />
              <div className="mt-4" />

              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  'This Week': [
                    moment().startOf('week'),
                    moment().endOf('week'),
                  ],
                }}
                size="medium"
                onChange={onChange}
                className="w-100"
              />
              {!raw && (
                <Select
                  className="mt-3 mb-2"
                  style={{ width: '100%' }}
                  defaultValue={status}
                  size="medium"
                  onChange={selectMode}
                >
                  <Option value="approved">Approved time sheets</Option>
                  <Option value="requested">Pending time sheets</Option>
                  <Option value="rejected">Rejected time sheets</Option>
                  <Option value="revision">In-review time sheets</Option>
                </Select>
              )}

              {!single && (
                <div className="d-flex flex-row align-items-center justify-content-between mt-2">
                  <div>
                    {!raw && (
                      <Link strong onClick={openSide} className="d-block">
                        Customize your export ?
                      </Link>
                    )}
                  </div>
                  <Space>
                    <Text>Raw Export</Text>
                    <Switch onClick={handleRaw} checked={raw} />
                  </Space>
                </div>
              )}
              <Button
                type="primary"
                className="bg-primary border-primary ml-auto d-block mt-3"
                loading={loader}
                onClick={() => {
                  if (raw) rawExport(range, dept, activity);
                  else exportExcel(range, dept, activity, status);
                }}
                disabled={!range}
              >
                Proceed
              </Button>
            </Modal>
          </>
        </Space>
        <Divider className="w-100 mt-2" />
      </Col>
      <ExportPref show={side} handleCancel={closeSide} />
    </Row>
  );
}

export default Index;
