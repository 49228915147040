import * as constants from './constants';
import { message } from 'antd';
import membersApi from './membersApi';
import organizationApi from '../organizations/organizationApi';
import { addNewEmployee } from '../auth/action';

const MembersApi = new membersApi();

const OrganizationApi = new organizationApi();

export const getMembers = (page, dept, cb) => async (dispatch) => {
  try {
    const res = await MembersApi.getAllUsers({
      page,
      limit: 10,
      sortBy: '-createdAt',
      ...(dept && { dept }),
    });
    dispatch({
      type: constants.GET_MEMBERS,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const countMembers = (dept, cb) => async (dispatch) => {
  try {
    const res = await MembersApi.countAllUsers({
      ...(dept && { dept }),
    });
    cb(res.data.count);
  } catch (error) {
    cb(0);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllMembers = (dept, cb) => async (dispatch) => {
  try {
    const res = await MembersApi.getAllMember({
      sortBy: '-createdAt',
      ...(dept && { dept }),
    });
    dispatch({
      type: constants.GET_ALL_MEMBERS,
      payload: res.data,
    });
    cb(res.data.result);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const searchMembers = (text, page, dept, cb) => async (dispatch) => {
  try {
    const res = await MembersApi.searchMembers({
      text,
      page,
      limit: 10,
      sortBy: '-createdAt',
      ...(dept && { dept }),
    });
    dispatch({
      type: constants.GET_MEMBERS,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createEmployee = (data, cb) => async (dispatch) => {
  try {
    const res = await MembersApi.createUser(data);
    dispatch({
      type: constants.NEW_EMPLOYEE,
      payload: res.data,
    });
    dispatch(addNewEmployee(res.data?.id));
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllDepartments = (cb) => async (dispatch) => {
  try {
    const res = await OrganizationApi.getOrganizations({ getAll: true });
    dispatch({
      type: constants.ALL_DEPT,
      payload: res.data,
    });
    cb?.(res?.data);
  } catch (error) {
    cb?.([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const UpdateLocation = (data) => async (dispatch) => {
  try {
    dispatch({
      type: constants.UPDATE_LOCATION,
      payload: data,
    });
  } catch (error) {
    // console.error(error);
  }
};

export const updateMembers = (id, data, cb) => async (dispatch) => {
  try {
    await MembersApi.updateUsers(id, data);
    message.success('Updated!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const uploadMembersAction = (data, page, cb) => async (dispatch) => {
  try {
    await MembersApi.uploadMultipleUsers(data);
    dispatch(getMembers(page, null, () => {}));
    message.success('Updated!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteMember = (id, cb) => async (dispatch) => {
  try {
    await MembersApi.deleteMember(id);
    dispatch({
      type: constants.DELETE_MEMBER,
      payload: id,
    });
    message.success('Deleted!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const resendEmailVerification = (userId, cb) => async (dispatch) => {
  try {
    await MembersApi.resendEmailVerification({ userId });
    message.success('Successfully Resend!');
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateAllDept = (dept) => {
  return {
    type: constants.UPDATE_ALL_DEPT,
    payload: dept,
  };
};
