export const getCurrentPosition = async (cb) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      },
      () => {
        resolve({ latitude: 0, longitude: 0 });
      }
    );
  });
};
