import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { Table, Avatar, Space, Typography, Card, Button, Tooltip } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import socketHandler from '../../config/socket';
import DepartmentFilter from '../../components/departmentFilter';
import ActivityFilter from './activityFilter';
import DateHeader from './dateHeader';
import Item from './shiftlistItem';
import LeaveItem from './leaveListItem';
import {
  getTimeSheets,
  getExportData,
  getTimeSheetTotal,
  getRawData,
} from '../../redux/timesheets/action';
import {
  renderColumn,
  parseTimeSheets,
  parseExportData,
  formatTime,
  generateExcelFile,
} from './util';
import { styles } from './style';
import TotalTimes from './totalTimes';
import AddModal from './createTimesheet';
import UserDetailsModal from '../../components/userDetailsModal';
import AddAbsence from './addAbsence';

const { Text } = Typography;

const renderTitle = (title) => {
  return (
    <div>
      <Text strong className="text-light">
        {title}
      </Text>
    </div>
  );
};

const renderTime = (time) => {
  return <Text>{formatTime(time)}</Text>;
};

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { timesheets, leaves, initialC } = useSelector(
    (state) => state.timesheetsReducer
  );
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const { exportSchema } = useSelector((state) => state.timesheetsReducer);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [eLoader, setELoader] = useState(false);
  const [selectedDpt, setDpt] = useState(null);
  const [activity, setActivity] = useState(null);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [period, setPeriod] = useState({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
  });
  const [totalTimes, setTotalTimes] = useState(null);
  const [show, setShow] = useState(false);
  const [absenceModal, setAbsenceModal] = useState(false);

  const periodRef = useRef({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
  });
  const depRef = useRef(null);
  const actRef = useRef(null);

  const goDetails = (user) => {
    history.push(`/app/timesheets/${user.id}`);
  };
  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space align="center" className="c-pointer">
          <Avatar
            onClick={() => onRowAvatar(user)}
            style={styles.avatar}
            src={
              user?.profilePic ??
              'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
            }
            size="medium"
          />
          <Text
            onClick={() => goDetails(user)}
            strong
            className="pl-2 font-weight-bold"
          >
            {user?.name?.split(' ')[0]}
          </Text>
        </Space>
      );
    }
  };

  const stopLoader = () => {
    setLoader(false);
  };

  const fetchData = (start, end) => {
    setLoader(true);
    dispatch(getTimeSheets(start, end, selectedDpt, activity, stopLoader));
    dispatch(
      getTimeSheetTotal({
        start,
        end,
        queryType: 'custom',
        dept: selectedDpt,
        activity,
        cb: timeSheetTotalCB,
      })
    );
  };

  const onChangeTimePeriod = (selection) => {
    const start = moment(selection.startDate).startOf('day').toDate();
    const end = moment(selection.endDate).endOf('day').toDate();
    setPeriod({ start: start, end: end });
    periodRef.current = { start: start, end: end };
    fetchData(start, end);
  };

  useEffect(() => {
    if (initialC) {
      setLoader(true);
      const s = moment(period.start).toDate();
      const e = moment(period.end).toDate();
      dispatch(getTimeSheets(s, e, selectedDpt, activity, stopLoader));
      dispatch(
        getTimeSheetTotal({
          start: s,
          end: e,
          queryType: 'custom',
          dept: selectedDpt,
          activity,
          cb: timeSheetTotalCB,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(timesheets)) {
      const data = parseTimeSheets(timesheets, false, leaves);
      setData(data);
    }
  }, [timesheets, period]);

  const refreshData = () => {
    setLoader(true);
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    dispatch(getTimeSheets(s, e, selectedDpt, activity, stopLoader));
    dispatch(
      getTimeSheetTotal({
        start: s,
        end: e,
        queryType: 'custom',
        dept: selectedDpt,
        activity,
        cb: timeSheetTotalCB,
      })
    );
  };

  const timeSheetTotalCB = (success, data) => {
    if (success) {
      setTotalTimes(data);
    }
  };

  const groupBy = (arr, groupFn) =>
    arr.reduce(
      (grouped, obj) => ({
        ...grouped,
        [groupFn(obj)]: [...(grouped[groupFn(obj)] || []), obj],
      }),
      {}
    );

  const customTimeSheet = (range, result) => {
    let { timeSheet } = result;

    var groups = groupBy(timeSheet, (time) => time.user.id);
    var list = Object.values(groups);

    var finalTime = [];
    list.forEach((item) => {
      var _test = item.sort((a, b) => {
        var nameC = moment(a.allocation?.clockin).isSame(moment(a.date), 'day')
          ? moment(a.allocation?.clockin).valueOf()
          : moment(a.date).startOf().valueOf();
        var nameD = moment(b.allocation?.clockin).isSame(moment(b.date), 'day')
          ? moment(b.allocation?.clockin).valueOf()
          : moment(b.date).startOf().valueOf();
        return nameC - nameD;
      });
      finalTime = [...finalTime, ..._test];
    });

    const data = parseExportData(finalTime, exportSchema);

    generateExcelFile(data, range);
    setELoader(false);
  };

  const exportToExcel = (range, dept, activity, status) => {
    if (range) {
      setELoader(true);
      dispatch(getExportData(range, dept, activity, status, customTimeSheet));
    }
  };

  const getRawExport = (success, data, range) => {
    if (success) {
      // do export here
      generateExcelFile(data, range);
    }
    setELoader(false);
  };

  const rawExport = (range, dept, activity) => {
    if (range) {
      setELoader(true);
      dispatch(getRawData(range, dept, activity, getRawExport));
    }
  };

  const onChangeDept = (dept) => {
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    setDpt(dept);
    depRef.current = dept;
    setLoader(true);
    dispatch(getTimeSheets(s, e, dept, activity, stopLoader));

    dispatch(
      getTimeSheetTotal({
        start: s,
        end: e,
        queryType: 'custom',
        dept,
        activity,
        cb: timeSheetTotalCB,
      })
    );
  };

  const onChangeActivity = (value) => {
    setActivity(value);
    actRef.current = value;
    const s = moment(period.start).toDate();
    const e = moment(period.end).toDate();
    setLoader(true);
    dispatch(getTimeSheets(s, e, selectedDpt, value, stopLoader));

    dispatch(
      getTimeSheetTotal({
        start: s,
        end: e,
        queryType: 'custom',
        dept: selectedDpt,
        activity: value,
        cb: timeSheetTotalCB,
      })
    );
  };

  const showModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const fetchUpdates = useCallback(() => {
    const s = moment(periodRef?.current?.start).toDate();
    const e = moment(periodRef?.current?.end).toDate();
    dispatch(getTimeSheets(s, e, depRef?.current, actRef?.current, () => {}));
    dispatch(
      getTimeSheetTotal({
        start: s,
        end: e,
        queryType: 'custom',
        dept: depRef?.current,
        activity: actRef?.current,
        cb: timeSheetTotalCB,
      })
    );
  }, []);

  useEffect(() => {
    socketHandler.on('timesheet', fetchUpdates);
    return () => {
      socketHandler.off('timesheet', fetchUpdates);
    };
  }, []);

  return (
    <React.Fragment>
      <div style={{ ...styles.totalComCont, marginBottom: 20 }}>
        <TotalTimes
          totalWorked={totalTimes?.totalWorkedTime || 0}
          totalBreaks={totalTimes?.totalBreakTime || 0}
          totalOvertime={totalTimes?.totalOverTime || 0}
        />
      </div>
      <DateHeader
        onChange={(dates) => {
          onChangeTimePeriod(dates);
        }}
        exportExcel={exportToExcel}
        rawExport={rawExport}
        loader={eLoader}
      />
      <Space className="mb-3 flex-wrap">
        <DepartmentFilter
          pickerStyle={{
            width: 215,
          }}
          onSelectDpt={onChangeDept}
        />
        <ActivityFilter
          pickerStyle={{
            width: 215,
          }}
          onChange={onChangeActivity}
        />
        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshData}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>
      <Row>
        <Col md={12}>
          <Card className="tmb">
            <Space className="float-right mb-2">
              <Button
                type="primary"
                className="bg-primary border-primary"
                icon={<PlusOutlined />}
                onClick={showModal}
              >
                Add Time Entry
              </Button>
              <Button
                type="primary"
                className="bg-primary border-primary"
                icon={<PlusOutlined />}
                onClick={() => setAbsenceModal(true)}
              >
                Add Absence
              </Button>
            </Space>
            <Table
              id="sheetjs"
              className="table-curved"
              columns={renderColumn(renderTitle, renderAvatar, renderTime)}
              dataSource={data}
              scroll={data.length > 0 && { x: true }}
              size="medium"
              loading={loader}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <TableRB responsive className="borderless">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Activity
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Clock In
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Clock Out
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Total Hours
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Break Hours
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Overtime
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Hours worked
                          </th>
                          <th
                            style={styles.centerAlign}
                            className="d-none d-sm-table-cell"
                          >
                            Leaves
                          </th>
                          <th>Edit Time</th>
                        </tr>
                      </thead>
                      {record?.shifts?.map((arr, index) => {
                        if (arr[0].renderType === 'leave') {
                          return arr.map((item, index) => {
                            return <LeaveItem item={item} key={index} />;
                          });
                        } else {
                          let obj = {
                            ...arr[0],
                            time: arr.reduce((a, b) => a + b.time, 0),
                            breakTime: arr.reduce((a, b) => a + b.breakTime, 0),
                            overTime: arr.reduce((a, b) => a + b.overTime, 0),
                            totalTime: arr.reduce((a, b) => {
                              a =
                                a +
                                (b.totalTime
                                  ? b.totalTime
                                  : b.time + b.breakTime);
                              return a;
                            }, 0),
                          };
                          return (
                            <Item
                              item={obj}
                              key={index}
                              sheets={arr}
                              refreshData={refreshData}
                              sentFrom="custom"
                            />
                          );
                        }
                      })}
                    </TableRB>
                  );
                },
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
            />
          </Card>
        </Col>
      </Row>
      <AddModal
        show={show}
        handleCancel={closeModal}
        dept={selectedDpt}
        refreshData={refreshData}
      />
      <AddAbsence
        visible={absenceModal}
        onClose={setAbsenceModal}
        dept={selectedDpt}
        refreshData={refreshData}
      />
      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
    </React.Fragment>
  );
}
