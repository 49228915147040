import * as constants from './constants';
import { message } from 'antd';
import performanceApi from './performanceApi';
import socketHandler from '../../config/socket';

const PerformanceApi = new performanceApi();

export const getPQ = (page, dept, cb) => async (dispatch) => {
  try {
    const res = await PerformanceApi.getPerformanceQ({
      page,
      sortBy: { createdAt: 'desc' },
      limit: 10,
      ...(dept && { dept }),
    });

    dispatch({
      type: constants.GET_PQ,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getPA = (id, cb) => async (dispatch) => {
  try {
    const res = await PerformanceApi.getAnswers({ question: id });
    cb(res.data);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createPQuestions = (data, cb) => async (dispatch, getData) => {
  try {
    const res = await PerformanceApi.createPerformanceQ(data);
    dispatch({
      type: constants.NEW_QUESTION,
      payload: res.data,
    });
    message.success('Successfully Created Performance Question!');
    cb(true);
    socketHandler.emit('updatePQ', data);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updatePQuestions = (id, data, cb) => async (dispatch) => {
  try {
    await PerformanceApi.updatePerformanceQ(id, data);
    message.success('Updated!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllocationAnswers = (id, cb) => async () => {
  try {
    const res = await PerformanceApi.getAllocationAnswers({ allocationId: id });
    cb(res.data);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getTaskAnswers = (id, cb) => async () => {
  try {
    const res = await PerformanceApi.getTaskAnswers({ task: id });
    cb(res.data);
  } catch (error) {
    cb([]);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getSummery = (dpt, cb) => async (dispatch) => {
  try {
    var res = await PerformanceApi.getSummery();
    dispatch({
      type: constants.PQ_SUMMERY,
      payload: res.data,
    });
    cb(res.data);
  } catch (error) {
    cb([]);
  }
};

export const getUserAnswers = (user, date, cb) => async (dispatch) => {
  try {
    const res = await PerformanceApi.getUserAnswers({
      user,
      start: date[0],
      end: date[1],
    });
    dispatch({
      type: constants.USER_ANSWERS,
      payload: { data: res.data, user },
    });
    cb(res.data);
  } catch (error) {
    cb([]);
  }
};

export const getUserQuestions = (user, cb) => async (dispatch) => {
  try {
    const res = await PerformanceApi.getUserQuestion({ user });
    cb(res.data);
  } catch (error) {
    cb([]);
  }
};
