import * as constants from './constants';
import _ from 'lodash';
let initState = {
  organizations: [],
  totalOrganizations: 0,
  initial: true,
};
function organizationReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: payload?.results,
        totalOrganizations: payload?.totalResults,
        initial: false,
      };
    case constants.NEW_ORGANIZATION:
      return {
        ...state,
        organizations: [payload, ...state.organizations],
      };
    case constants.DELETE_ORG:
      let nOrg = [...state.organizations];
      nOrg.splice(_.indexOf(nOrg, _.find(nOrg, { id: payload })), 1);
      return {
        ...state,
        organizations: nOrg,
      };
    default:
      return state;
  }
}

export default organizationReducer;
