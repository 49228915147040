import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card, Button, Space, Select, DatePicker } from 'antd';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import LocationModal from '../usersTracking';
import socketHandler from '../../config/socket';
import Donut from '../dashboard/charts/donutChart';
import Bar from '../dashboard/charts/horizontalBar';
import InOutBreak from './inout';
import Safety from './safety';
import Activities from '../activities';
import { styles } from './style';
import {
  getTodaysActive,
  getTodaysOnTimes,
  getWeeklyActivities,
  getTodayOnLeave,
  getAllEmployees,
  resetInitial,
} from '../../redux/shifts/action';

import { UpdateLocation } from '../../redux/members/action';
import DepartmentFilter from '../../components/departmentFilter';
const center = 'd-flex flex-column justify-content-center align-items-center';
const { Option } = Select;

function Index({ history }) {
  const [todayActive, setTodayActive] = useState(0);
  const [todayOnLeave, setOnLeave] = useState(0);
  const [todayOnTime, setTodayOnTime] = useState(0);
  const [weeklyActivity, setWeeklyActivity] = useState({});
  const [selectedDpt, setDpt] = useState(null);
  const [logs, setLogs] = useState(null);
  const [type, setType] = useState(null);
  const [date, setDate] = useState(null);
  const [selectedClockState, setClockState] = useState(null);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const { initial } = useSelector((state) => state.shiftsReducer);
  const { user } = useSelector((state) => state.auth);

  const fetchUpdates = useCallback(() => {
    dispatch(getWeeklyActivities(getWeeklyActivityCb, selectedDpt));
    dispatch(getTodaysActive(getTodaysActiveCb, selectedDpt));
    dispatch(getTodayOnLeave(getTodaysOnLeaveCb, selectedDpt));
    dispatch(getTodaysOnTimes(getTodaysOnTimeCb, selectedDpt));
  }, []);

  useEffect(() => {
    socketHandler.on('activity', fetchUpdates);
    return () => {
      socketHandler.off('activity', fetchUpdates);
    };
  }, []);

  useEffect(() => {
    socketHandler.on('locationUpdate', (data) => {
      dispatch(UpdateLocation(data));
    });
    return () => {
      socketHandler.off('locationUpdate', (data) => {
        dispatch(UpdateLocation(data));
      });
    };
  }, []);

  const updateLocation = (data) => {};

  const getInitial = () => {
    dispatch(getTodaysActive(getTodaysActiveCb));
    dispatch(getTodayOnLeave(getTodaysOnLeaveCb));
    dispatch(getTodaysOnTimes(getTodaysOnTimeCb));
    dispatch(getWeeklyActivities(getWeeklyActivityCb));
    dispatch(getAllEmployees(() => {}));
  };

  useEffect(() => {
    getInitial();
  }, []);

  const onChangeDept = (dept) => {
    setDpt(dept);
    dispatch(getWeeklyActivities(getWeeklyActivityCb, dept));
    dispatch(getTodaysActive(getTodaysActiveCb, dept));
    dispatch(getTodayOnLeave(getTodaysOnLeaveCb, dept));
    dispatch(getTodaysOnTimes(getTodaysOnTimeCb, dept));
  };
  const onChangedSec = (dept) => {
    setDpt(dept);
    dispatch();
  };
  const getTodaysActiveCb = (active) => {
    setTodayActive(active || 0);
  };

  const getTodaysOnTimeCb = (active) => {
    setTodayOnTime(active || 0);
  };
  const getTodaysOnLeaveCb = (onLeave) => {
    setOnLeave(onLeave || 0);
  };

  const getWeeklyActivityCb = (weeklyActivities) => {
    setWeeklyActivity(weeklyActivities);
  };

  const openTracking = () => {
    setVisible(true);
  };

  const handleChange = (value) => {
    setLogs(value);
  };
  const typeChange = (value) => {
    setType(value);
  };
  //parses the attributes
  const clockStateChange = (value) => {
    //listener for the event form placeholder
    setLogs(value);
  };
  const onChangeDate = (date, dateString) => {
    if (date) {
      setDate(new Date(date));
    } else {
      setDate(null);
    }
  };
  //
  // function onclockStatusChange(event){
  //   props.clockStateChange(event.target.clockState)
  // }

  return (
    <div className="tmb">
      <div className="pb-3">
        <Row>
          <Col sm={12} md={6} lg={3}>
            <h3>Tracked hours</h3>
          </Col>

          <Col sm={12} md={6} lg={3}>
            {/* department filter */}
            <div className="orgPickerDashboard">
              <DepartmentFilter
                pickerStyle={styles.pickerStyle}
                onSelectDpt={onChangeDept}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col sm={12} md={12} lg={7}>
          <Card style={{ ...styles.cardRounded, ...styles.firstRow }}>
            <Bar weeklyActivity={weeklyActivity} />
          </Card>
        </Col>
        <Col sm={12} md={12} lg={5}>
          <Card
            className={center}
            style={{ ...styles.cardRounded, ...styles.firstRow }}
          >
            <Donut weeklyActivity={weeklyActivity} />
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col sm={12} lg={4}>
          <Card className={center} style={styles.cardRounded}>
            <h4 style={{ color: '#34B53A' }}>Today’s Active</h4>
            <div style={styles.progress}>
              <CircularProgressbar
                value={isNaN(todayActive) ? 0 : todayActive}
                text={`${isNaN(todayActive) ? 0 : todayActive}%`}
                background
                strokeWidth={5}
                styles={buildStyles({
                  textSize: '16px',
                  pathTransitionDuration: 0.5,
                  pathColor: '#34B53A',
                  textColor: 'black',
                  trailColor: '#E2FBD7',
                  backgroundColor: '#E2FBD7',
                })}
              />
            </div>
          </Card>
        </Col>
        <Col sm={12} lg={4}>
          <Card className={center} style={styles.cardRounded}>
            <h4 style={{ color: '#6666ff' }}>Clock in on time</h4>
            <div style={styles.progress}>
              <CircularProgressbar
                value={isNaN(todayOnTime) ? 0 : todayOnTime}
                text={`${isNaN(todayOnTime) ? 0 : todayOnTime}%`}
                background
                strokeWidth={5}
                styles={buildStyles({
                  textSize: '16px',
                  pathTransitionDuration: 0.5,
                  pathColor: '#6666ff',
                  textColor: 'black',
                  trailColor: '#e6e6ff',
                  backgroundColor: '#e6e6ff',
                })}
              />
            </div>
          </Card>
        </Col>
        <Col sm={12} lg={4}>
          <Card className={center} style={styles.cardRounded}>
            <h4 style={{ color: '#ff6666' }}>Those on leave</h4>
            <div style={styles.progress}>
              <CircularProgressbar
                value={isNaN(todayOnLeave) ? 0 : todayOnLeave}
                text={`${isNaN(todayOnLeave) ? 0 : todayOnLeave}%`}
                background
                strokeWidth={5}
                styles={buildStyles({
                  textSize: '16px',
                  pathTransitionDuration: 0.5,
                  pathColor: '#ff6666',
                  textColor: 'black',
                  trailColor: '#ffe6e6',
                  backgroundColor: '#ffe6e6',
                })}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col sm={12} lg={8}>
          <Card className="p-3" style={styles.cardRounded}>
            <Space className="mb-3 float-right">
              <Button
                type="primary"
                className="bg-primary border-primary"
                onClick={openTracking}
              >
                Users by Location
              </Button>
              <Select
                className="d-none d-sm-table-cell"
                placeholder="Filter Logs"
                style={{ minWidth: 190 }}
                onChange={handleChange}
              >
                <Option value={null}>All</Option>
                <Option value="failedFacial">Failed Facial</Option>
                <Option value="failedGeo">Failed Geofence</Option>
                <Option value="valid">Successful logs</Option>
              </Select>

              <DepartmentFilter onSelectDpt={onChangeDept} />

              <Select
                className="f-none f-sm-table-cell"
                placeholder="Filter by Clock"
                style={{ minWidth: 190 }}
                onChange={typeChange}
              >
                <Option value={null}>All</Option>
                <Option value="clockin">Clocked In</Option>
                <Option value="clockout">Clocked Out</Option>
                <Option value="breakin">Start Break</Option>
                <Option value="breakout">End Break</Option>
              </Select>
              <DatePicker
                placeholder="Filter by date"
                onChange={onChangeDate}
              />
            </Space>
            <Activities
              selectedDpt={selectedDpt}
              type={type}
              logs={logs}
              clockState={selectedClockState}
              date={date}
            />
          </Card>
        </Col>

        <Col sm={12} lg={4}>
          <Row>
            <Col sm={12} className="mb-4">
              <Card style={{ ...styles.cardRounded, ...styles.sideDetails }}>
                <InOutBreak selectedDpt={selectedDpt} />
              </Card>
            </Col>

            <Col sm={12}>
              <Card style={{ ...styles.cardRounded, ...styles.sideDetails }}>
                <Safety selectedDpt={selectedDpt} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <LocationModal visible={visible} handleCancel={() => setVisible(false)} />
    </div>
  );
}

export default Index;
