import { INSTANCE } from '../../config/networkInstance';

export default class Api {
  getLeaveRequests = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/leaves',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getLeaveTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/leaves/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createLeaveTypes = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/leaves/types',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateLeaveTypes = (data, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: '/leaves/types',
        data,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteLeaveTypes = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: '/leaves/types',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  changeLeaveStatus = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: `/leaves/updateStatus?id=${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  createLeave = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/leaves/request',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getLeaveTotals = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/leaves/leaveTotals',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
