import React, { memo, StrictMode, useEffect, useState } from 'react';
import { Switch, Typography, Space, InputNumber } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ScheduleModal from './defaultSchedule';

function Index({
  image_recognition,
  geoFence,
  performance_questions,
  toggleCheckBox,
  leave_management,
  autoClockOut,
  autoClockOutTime,
  timesheetApproval,
  workSchedule,
  newsletter,
  kioskOnly,
  strictMode,
}) {
  const history = useHistory();
  const { Text } = Typography;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      window.Chargebee.init({
        site: 'app-gigsasa',
      });
      window.Chargebee.registerAgain();
    };
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);
  }, []);

  const goToSubscription = () => {
    history.push('/pricing');
  };

  const closeSchedule = () => {
    setShow(false);
  };
  return (
    <>
      <div>
        {/* image recognition */}
        <div className="prefRow">
          <p className="prefRowLabel">Image Recognition</p>
          <div className="prefSwitchCont">
            <Switch
              checked={image_recognition}
              onChange={(value) => toggleCheckBox('image_recognition', value)}
              className="prefSwitchCls"
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disabled the Image verification Process during Shift
          </Text>
        </div>

        {/* geo fence */}

        <div className="prefRow">
          <p className="prefRowLabel">Geo Fence</p>
          <div className="prefSwitchCont">
            <Switch
              checked={geoFence}
              onChange={(value) => toggleCheckBox('geoFence', value)}
              className="prefSwitchCls"
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disable the GeoFencing Process to track time
          </Text>
        </div>

        {/* strict */}

        <div className="prefRow">
          <p className="prefRowLabel">Enable Strict Mode</p>
          <div className="prefSwitchCont">
            <Switch
              checked={strictMode}
              onChange={(value) => toggleCheckBox('strictMode', value)}
              className="prefSwitchCls"
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You will not be able to clock in/out if facial Recognition or Geo
            Fencing fails
          </Text>
        </div>

        {/* geo fence */}

        <div className="prefRow">
          <p className="prefRowLabel">Kiosk Mode Only </p>
          <div className="prefSwitchCont">
            <Switch
              checked={kioskOnly}
              onChange={(value) => toggleCheckBox('kioskOnly', value)}
              className="prefSwitchCls"
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            Regular workers and supervisor can only clock in/out using kiosk
            mode
          </Text>
        </div>

        <div className="prefRow">
          <p className="prefRowLabel">Performance Questions</p>
          <div className="prefSwitchCont">
            <Switch
              checked={performance_questions}
              onChange={(value) =>
                toggleCheckBox('performance_questions', value)
              }
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disabled the Performance Questions that are asked before and
            after shift Process
          </Text>
        </div>

        <div className="prefRow">
          <p className="prefRowLabel">Leave Management</p>
          <div className="prefSwitchCont">
            <Switch
              checked={leave_management}
              onChange={(value) => toggleCheckBox('leave_management', value)}
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disabled the Leave Management during shift
          </Text>
        </div>

        <div className="prefRow">
          <p className="prefRowLabel">Timesheet Approval</p>
          <div className="prefSwitchCont">
            <Switch
              checked={timesheetApproval}
              onChange={(value) => toggleCheckBox('timesheetApproval', value)}
              className="prefSwitchCls"
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disabled the Timesheet Request Approval
          </Text>
        </div>

        <div className="prefRow">
          <p className="prefRowLabel">News Letter</p>
          <div className="prefSwitchCont">
            <Switch
              checked={newsletter}
              onChange={(value) => toggleCheckBox('newsletter', value)}
              className="prefSwitchCls"
            />
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disabled the email reminders about activities of company
          </Text>
        </div>

        <div className="prefRow">
          <p className="prefRowLabel">Auto ClockOut</p>
          <div className="prefSwitchCont">
            <Space>
              <InputNumber
                min={1}
                max={24}
                value={autoClockOutTime}
                onChange={(value) => toggleCheckBox('autoClockOutTime', value)}
              />
              <Switch
                checked={autoClockOut}
                onChange={(value) => toggleCheckBox('autoClockOut', value)}
                className="prefSwitchCls"
              />
            </Space>
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can disabled the auto ClockOut from shift
          </Text>
        </div>

        <div className="prefRow">
          <p className="prefRowLabel">Work Schedule</p>
          <div className="prefSwitchCont">
            <Space size={'large'}>
              <Text>
                {workSchedule?.start} to {workSchedule?.end}
              </Text>
              <button
                onClick={() => setShow(true)}
                className="btn text-primary text-uppercase"
              >
                <EditOutlined style={{ fontSize: 20 }} />
              </button>
            </Space>
          </div>
        </div>
        <div className="prefSecondaryText">
          <Text type="secondary">
            You can set the work schedule for your company
          </Text>
        </div>
      </div>
      <ScheduleModal
        open={show}
        close={closeSchedule}
        workSchedule={workSchedule}
      />
    </>
  );
}

export default memo(Index);
