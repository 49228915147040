import React, { memo, useState, useEffect } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { Upload, message, Input, Form, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, changePassword } from '../../redux/settings/action';

function ChangePasswordComponent() {
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    profilePic: '',
    name: '',
  });

  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    if (user) {
      setState({
        ...state,
        name: user?.name,
        profilePic: user?.profilePic,
      });
    }

    setFileList([
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: user?.profilePic,
        same: true,
      },
    ]);
  }, [user]);

  const stopLoader2 = (success) => {
    setLoader2(false);
    if (success) {
      form.resetFields();
    }
  };
  const changePasswordFunc = (values) => {
    setLoader2(true);
    const formData = {};
    formData.password = values.new_password;
    formData.oldPassword = values.old_password;
    dispatch(changePassword(formData, stopLoader2));
  };

  return (
    <>
      <Container fluid className="p-5">
        <Row className="justify-content-md-center">
          <Col md={12} lg={8}>
            {!user?.social && (
              <Form
                form={form}
                layout="vertical"
                className="pt-3"
                onFinish={changePasswordFunc}
              >
                <Form.Item
                  name="old_password"
                  label="Old Password"
                  required
                  tooltip="This is a required field"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="new_password"
                  label="New Password"
                  required
                  tooltip="This is a required field"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      min: 8,
                      message: 'Min 8 characters',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="cpassword"
                  label="Confirm New Password"
                  required
                  tooltip="This is a required field"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Passwords does not match!')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item>
                  <div className="comSaveCont">
                    <button
                      className="btn btn-primary mt-3 comSaveBtn mb-4"
                      htmlType="submit"
                    >
                      {loader2 ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        'Update Password'
                      )}
                    </button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default memo(ChangePasswordComponent);
