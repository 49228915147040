import * as constants from './constants';

const initialState = {
  activeShift: null,
  inTime: new Date(),
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_ACTIVE: {
      return {
        ...state,
        activeShift: payload,
      };
    }
    case constants.SHIFT_DONE: {
      return {
        ...state,
        activeShift: null,
        inTime: new Date(),
      };
    }
    case constants.UPDATE_SHIFT: {
      return {
        ...state,
        activeShift: payload,
      };
    }
    case constants.TIMER_IN: {
      let time = new Date(payload).setSeconds(
        new Date(payload).getSeconds() + 1
      );
      return {
        ...state,
        inTime: time,
      };
    }
    case constants.START_TIMER: {
      return {
        ...state,
        inTime: new Date(payload.inTime),
      };
    }
    case constants.RESET_TIMER: {
      return {
        ...state,
        inTime: new Date(),
      };
    }

    default: {
      return state;
    }
  }
};
