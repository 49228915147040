import React, { Fragment, useEffect, useState } from 'react';
import { Drawer, Image, Typography, Divider, Timeline } from 'antd';
import MapAddress from '../../components/mapAddress';
import { useDispatch } from 'react-redux';
import { getAllocationAnswers } from '../../redux/performanceQ/action';
import { getAllocationSOS } from '../../redux/activities/action';
import moment from 'moment';
import PerformanceAns from './performanceAns';
import { styles } from './style';

const Index = ({ visible, onClose, item }) => {
  const { Text } = Typography;
  const imgIn = item?.allocation?.clockinImage;
  const imgOut = item?.allocation?.clockoutImage;
  const inLat = item?.allocation?.clockinCoord?.latitude;
  const outLat = item?.allocation?.clockoutCoord?.latitude;
  const inLng = item?.allocation?.clockinCoord?.longitude;
  const outLng = item?.allocation?.clockoutCoord?.longitude;
  const dispatch = useDispatch();
  const [sos, setSOS] = useState(null);
  const [PAns, setPAns] = useState(null);

  useEffect(() => {
    if (visible) {
      dispatch(getAllocationAnswers(item?.allocation?.id, getPQ));
      dispatch(getAllocationSOS(item?.allocation?.id, getSOS));
    }
  }, [visible]);

  const getPQ = (PQ) => {
    setPAns(PQ);
  };

  const getSOS = (data) => {
    setSOS(data);
  };

  const dImg =
    'https://png.pngtree.com/png-vector/20190501/ourmid/pngtree-users-icon-design-png-image_1014936.jpg';

  return (
    <Drawer
      title={`${item?.user?.name}`}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={340}
      zIndex={4234234234234243}
      bodyStyle={styles.sideBar}
    >
      {imgIn && (
        <div className="position-relative">
          <Text strong className="pb-2 d-block">
            Clock In Image
          </Text>
          <Image
            width={280}
            src={imgIn}
            placeholder={
              <Image
                preview={false}
                src={`${imgIn}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                width={280}
              />
            }
          />
          {!item?.allocation?.facialIn && (
            <Text strong style={styles.failedText}>
              Facial Recognition Failed
            </Text>
          )}
        </div>
      )}
      {imgOut && (
        <div className="position-relative mt-3">
          <Text strong className="pb-2 d-block">
            Clock Out Image
          </Text>
          <Image
            width={280}
            src={imgOut}
            placeholder={
              <Image
                preview={false}
                src={`${imgOut}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                width={280}
              />
            }
          />
          {!item?.allocation?.facialOut && (
            <Text strong style={styles.failedText}>
              Facial Recognition Failed
            </Text>
          )}
        </div>
      )}
      {!imgIn && !imgOut && (
        <div className="position-relative">
          <Image
            width={280}
            src={item?.user?.profilePic ?? dImg}
            placeholder={
              <Image
                preview={false}
                src={`${
                  item?.user?.profilePic ?? dImg
                }?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                width={280}
              />
            }
          />
        </div>
      )}
      <Divider />
      <MapAddress
        latitude={inLat}
        longitude={inLng}
        error={!item?.allocation?.geoFenceIn}
      />

      <MapAddress
        latitude={outLat}
        longitude={outLng}
        error={!item?.allocation?.geoFenceOut}
      />
      <Divider />
      <div className="w-100">
        <Text strong>Activities</Text>
        <Timeline className="mt-3">
          <Timeline.Item color="green">
            <div>
              <Text className="d-block">Clocked In</Text>
              <Text type="secondary d-block" style={styles.date}>
                {moment(item?.allocation?.clockin).format('DD-MM-YYYY')}{' '}
                {moment(item?.allocation?.clockin).format('HH:mm:ss')}
              </Text>
            </div>
          </Timeline.Item>
          {item?.allocation?.breaks?.length > 0 &&
            item?.allocation?.breaks?.map((item) => {
              return (
                <>
                  <Timeline.Item color="orange">
                    <div>
                      <Text className="d-block">Break In</Text>
                      <Text type="secondary d-block" style={styles.date}>
                        {moment(item?.in).format('DD-MM-YYYY')}{' '}
                        {moment(item?.in).format('HH:mm:ss')}
                      </Text>
                      <Text className="d-block" style={styles.reason}>
                        Break Reason : {item?.reason}
                      </Text>
                    </div>
                  </Timeline.Item>
                  <Timeline.Item color="orange">
                    <div>
                      <Text className="d-block">Break Out</Text>
                      <Text type="secondary d-block" style={styles.date}>
                        {moment(item?.out).format('DD-MM-YYYY')}{' '}
                        {moment(item?.out).format('HH:mm:ss')}
                      </Text>
                    </div>
                  </Timeline.Item>
                </>
              );
            })}
          {item.allocation?.clockout && (
            <Timeline.Item color="red">
              <div>
                <Text className="d-block">Clocked Out</Text>
                <Text type="secondary d-block" style={styles.date}>
                  {moment(item?.allocation?.clockout).format('DD-MM-YYYY')} -{' '}
                  {moment(item?.allocation?.clockout).format('HH:mm:ss')}
                </Text>
              </div>
            </Timeline.Item>
          )}
        </Timeline>
      </div>
      {PAns?.length > 0 && (
        <Fragment>
          <Divider />
          <div className="w-100">
            <Text strong className="pb-2 d-block">
              Performance
            </Text>
            {PAns?.filter((item) => item?.question?.questionType == 'in')
              .length > 0 && (
              <React.Fragment>
                <Text strong className="d-block">
                  Clock In Questions
                </Text>
                <Timeline mode={'left'} className="mt-4">
                  {PAns?.filter(
                    (item) => item?.question?.questionType == 'in'
                  ).map((PQ, i) => {
                    return <PerformanceAns PQ={PQ} key={i} />;
                  })}
                </Timeline>
              </React.Fragment>
            )}
            <Divider />
            {PAns?.filter((item) => item?.question?.questionType == 'out')
              .length > 0 && (
              <React.Fragment>
                <Text strong className="d-block">
                  Clock Out Questions
                </Text>
                <Timeline mode={'left'} className="mt-4">
                  {PAns?.filter(
                    (item) => item?.question?.questionType == 'out'
                  ).map((PQ, i) => {
                    return <PerformanceAns PQ={PQ} key={i} />;
                  })}
                </Timeline>
                <Divider />
              </React.Fragment>
            )}
          </div>
        </Fragment>
      )}

      {sos && sos.length > 0 && (
        <div className="pt-1">
          <Text strong className="text-danger">
            Health and Safety
          </Text>
          {sos?.map((item, index) => {
            return (
              <Fragment key={index}>
                {item?.file && (
                  <div className="position-relative pt-3">
                    <Text strong className="pb-2 d-block">
                      Attachement
                    </Text>
                    <Image
                      width={280}
                      src={item?.file}
                      placeholder={
                        <Image
                          preview={false}
                          src={`${item?.file}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                          width={280}
                        />
                      }
                    />
                  </div>
                )}

                <MapAddress
                  latitude={item?.location?.latitude}
                  longitude={item?.location?.longitude}
                  title="Location"
                />

                {item?.status && (
                  <div className="w-100 mt-3">
                    <Text className="d-block" strong>
                      Status
                    </Text>
                    <Text>{item?.status}</Text>
                  </div>
                )}

                {item?.text && (
                  <div className="w-100 mt-3">
                    <Text className="d-block" strong>
                      Detail
                    </Text>
                    <Text>{item?.text}</Text>
                  </div>
                )}
                <Divider />
              </Fragment>
            );
          })}
        </div>
      )}
    </Drawer>
  );
};

export default Index;
