import React, { useEffect, useState, memo, useRef } from 'react';
import { Modal, Form, Input, Space, Radio, Upload, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getPerformance } from '../../redux/clockinout/action';
import SignatureCanvas from 'react-signature-canvas';
import { styles } from './style';
import eraserIcon from '../../assets/images/eraser.png';
import { cloudinaryImageUrl } from '../../services/cloudinaryImage';

export default memo(function Index({ show, setClose, IsClockOut, getAnswers }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [aloader, setALoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [qByIds, setQByIds] = useState({});
  const [attachments, setAttachments] = useState({});
  const type = IsClockOut() ? 'out' : 'in';
  const signPadRef = useRef();

  const stopLoader = (data) => {
    setLoader(false);
    if (data.length > 0) {
      setQuestions(data);
      const dataById = {};
      data.forEach((item) => {
        dataById[item?.id] = item;
      });

      setQByIds(dataById);
    } else {
      getAnswers([]);
    }
  };

  useEffect(() => {
    if (show) {
      setLoader(true);
      dispatch(getPerformance(type, stopLoader));
    }
  }, [show]);

  const answersQuestions = async (values) => {
    try {
      setALoader(true);
      const data = await Promise.all(
        Object.keys(values).map(async (key) => {
          let qType = qByIds[key]?.type;

          if (qType === 'signature') {
            const sigCanvas = signPadRef.current
              ?.getTrimmedCanvas?.()
              ?.toDataURL?.('image/png');
            let uploadSign = await cloudinaryImageUrl(sigCanvas);
            return {
              question: key,
              answer: uploadSign?.data?.secure_url,
            };
          } else if (qType === 'attachment' && values[key]?.file) {
            let uploadAtt = await cloudinaryImageUrl(values[key]?.file);
            return {
              question: key,
              answer: uploadAtt?.data?.secure_url,
            };
          }

          return {
            question: key,
            answer: values[key],
          };
        })
      );
      setALoader(false);

      getAnswers(data);
    } catch (error) {
      getAnswers([]);
      setALoader(false);
    }
  };

  const handleAttachment = (info, qId) => {
    const allAtt = { ...attachments, [qId]: info.file };

    setAttachments(allAtt);
  };

  return (
    <>
      <Modal
        title=""
        visible={show}
        onCancel={setClose}
        width={700}
        footer={null}
      >
        {loader ? (
          <Spin />
        ) : (
          <Form
            requiredMark={false}
            layout="vertical"
            form={form}
            onFinish={answersQuestions}
          >
            <Row>
              {questions?.map((item) => {
                if (item?.options.length > 0) {
                  return (
                    <Col md={12} lg={12}>
                      <Form.Item
                        name={item.id}
                        label={item.question}
                        rules={[
                          {
                            required: true,
                            message: 'Please pick an item!',
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            {item?.options?.map((option, i) => {
                              return (
                                <Radio key={i} value={option}>
                                  {option}
                                </Radio>
                              );
                            })}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  );
                } else if (item?.type === 'signature') {
                  return (
                    <Col md={12} lg={12} style={{ marginBottom: 25 }}>
                      <Form.Item name={item.id} label={item?.question}>
                        <div style={styles.padCont}>
                          <SignatureCanvas
                            penColor="#f79425"
                            canvasProps={{
                              className: 'sigCanvas',
                            }}
                            ref={signPadRef}
                          />
                          <div style={styles.eraserIconCont}>
                            <button
                              type="button"
                              style={styles.eraserIcon}
                              onClick={() => signPadRef.current?.clear?.()}
                            >
                              <img
                                src={eraserIcon}
                                width={25}
                                height={25}
                                alt="clear sig"
                              />
                            </button>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  );
                } else if (item?.type === 'attachment') {
                  return (
                    <Col
                      md={12}
                      lg={12}
                      style={{ marginBottom: 25 }}
                      className="uploadAtt"
                    >
                      <Form.Item
                        name={item.id}
                        label={item?.question}
                        rules={[
                          {
                            required: true,
                            message: 'Please Upload a file',
                          },
                        ]}
                      >
                        <Upload
                          name="attachment"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={() => false}
                          onChange={(attInfo) =>
                            handleAttachment(attInfo, item?.id)
                          }
                        >
                          {attachments?.[item.id] ? (
                            <div>
                              <div style={{ marginTop: 8 }}>
                                {attachments?.[item?.id]?.name || ''}
                              </div>
                            </div>
                          ) : (
                            <div style={styles.uploadBtn}>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return (
                    <Col md={12} lg={12}>
                      <Form.Item
                        name={item.id}
                        label={item?.question}
                        rules={[
                          {
                            required: true,
                            message: 'Please Input First Name',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Type Answer here"
                          size="large"
                          type={item.type == 'number' ? 'number' : 'text'}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
              })}
            </Row>
            <Row>
              <Col md={12} lg={12}>
                <Space className="mt-3 float-right">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={setClose}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={aloader}
                  >
                    {aloader ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
});
