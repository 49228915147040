import * as constants from './constants';
import { message } from 'antd';
import moment from 'moment';
import membersApi from '../members/membersApi';
import locationsApi from '../locations/locationApi';
import shiftApi from './shiftApi';
import socketHandler from '../../config/socket';

const MembersApi = new membersApi();
const LocationsApi = new locationsApi();
const ShiftApi = new shiftApi();

export const getShiftsAllocation =
  (start, end, dept, cb) => async (dispatch, getState) => {
    let { user } = getState().auth;

    let res;
    try {
      if (user?.role == 'user') {
        res = await ShiftApi.getUserAllocations({
          start,
          end,
        });
      } else {
        res = await ShiftApi.getAllocations({
          start,
          end,
          ...(dept && { dept }),
        });
      }
      dispatch({
        type: constants.GET_SHIFTS,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getShifts = (params, cb) => async (dispatch) => {
  try {
    let res = await ShiftApi.getShifts(params);
    dispatch({
      type: constants.GET_MAIN_SHIFTS,
      payload: res?.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createShift = (data, cb) => async (dispatch) => {
  try {
    await ShiftApi.createShift(data);
    message.success('Shift Created Successfully!');
    cb(true);
    socketHandler.emit('shift', data);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateShift = (id, data, cb) => async (dispatch) => {
  try {
    await ShiftApi.updateShift(id, data);
    message.success('Shift Updated Successfully!');
    cb(true);
    socketHandler.emit('shift', { ...data, id });
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteMainShift = (id, cb) => async (dispatch) => {
  try {
    await ShiftApi.deleteShift({ id });
    dispatch({
      type: constants.DELETE_MAIN_SHIFT,
      payload: id,
    });
    message.success('Shift Deleted Successfully!');
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteShift = (id, cb) => async (dispatch) => {
  try {
    await ShiftApi.deleteAllocation(id);
    dispatch({
      type: constants.DELETE_SHIFT,
      payload: id,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllClockInAndOut =
  (page, dept = null, cb) =>
  async (dispatch) => {
    try {
      const results = await ShiftApi.getClockInOuts({
        page,
        limit: 10,
        start: new Date(),
        end: new Date(),
        ...(dept && { dept }),
      });
      dispatch({
        type: constants.GET_CLOCKED_OUT,
        payload: results?.data?.[1] || [],
      });
      dispatch({
        type: constants.GET_CLOCKED_IN,
        payload: results?.data?.[0] || [],
      });
      dispatch({
        type: constants.GET_BREAK_IN,
        payload: results?.data?.[2] || [],
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };
export const getAllClockIn =
  (page, dept = 'clockedIn', cb) =>
  async (dispatch) => {
    try {
      const results = await ShiftApi.Clockin({
        page,
        limit: 10,
        start: new Date(),
        end: new Date(),
        ...(dept && { dept }),
      });
      dispatch({
        type: constants.GET_CLOCKED_IN,
        payload: results?.data?.[0] || [],
      });
      dispatch({
        type: constants.GET_BREAK_IN,
        payload: results?.data?.[2] || [],
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };
export const getAllClockOut =
  (page, dept = 'clockedOut', cb) =>
  async (dispatch) => {
    try {
      const results = await ShiftApi.ClockOut({
        page,
        limit: 10,
        start: new Date(),
        end: new Date(),
        ...(dept && { dept }),
      });
      dispatch({
        type: constants.GET_CLOCKED_OUT,
        payload: results?.data?.[1] || [],
      });
      dispatch({
        type: constants.GET_BREAK_IN,
        payload: results?.data?.[2] || [],
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };
export const getAllEmployees =
  (cb, dept = null) =>
  async (dispatch) => {
    try {
      const res = await MembersApi.getAllUsers({
        getAll: true,
        ...(dept && { dept }),
      });
      dispatch({
        type: constants.ALL_EMPLOYEES,
        payload: res.data,
      });
      cb(res.data);
    } catch (error) {
      cb([]);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getAllLocations = (cb) => async (dispatch, getState) => {
  try {
    const { company } = getState().auth;
    const res = await LocationsApi.getLocations({
      getAll: true,
      company: company.id,
    });

    dispatch({
      type: constants.ALL_LOCATIONS_SHIFT,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const adminClockIn = (data, uid, cb) => async (dispatch, getState) => {
  try {
    let { company } = getState().auth;
    let res = await ShiftApi.clockIn(data);
    dispatch({
      type: constants.CLOCK_IN,
      payload: uid,
    });
    cb();
    socketHandler.emit('activity', { ...res?.data, company: company.id });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const adminClockOut = (data, uid, cb) => async (dispatch, getState) => {
  try {
    let { company } = getState().auth;
    let res = await ShiftApi.clockOut(data);
    dispatch({
      type: constants.CLOCK_OUT,
      payload: uid,
    });
    cb();
    socketHandler.emit('activity', { ...res?.data, company: company.id });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getTodaysActive =
  (cb, dept = null) =>
  async (dispatch) => {
    const start = moment().startOf('day').toDate();
    const end = moment(start).endOf('day').toDate();
    try {
      const res = await ShiftApi.getTodaysActive({
        ...(dept && {
          dept,
        }),
        start,
        end,
      });
      //console.log(res);
      cb(res.data?.activeUsers);
    } catch (error) {
      cb(0);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getTodayOnLeave =
  (cb, dept = null) =>
  async (dispatch) => {
    const start = moment().startOf('day').toDate();
    const end = moment(start).endOf('day').toDate();
    try {
      const res = await ShiftApi.todayOnLeave({
        ...(dept && {
          dept,
        }),
        start,
        end,
      });

      cb(res.data?.todayLeaves);
    } catch (error) {
      cb(0);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getTodaysOnTimes =
  (cb, dept = null) =>
  async (dispatch) => {
    try {
      const start = moment().startOf('day').toDate();
      const end = moment(start).endOf('day').toDate();
      const res = await ShiftApi.getTodaysOnTimes({
        ...(dept && {
          dept,
        }),
        start,
        end,
        tzOffset: new Date().getTimezoneOffset(),
      });
      cb(res.data?.onTimeUsers);
    } catch (error) {
      cb(0);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getWeeklyActivities =
  (cb, dept = null) =>
  async (dispatch) => {
    try {
      const res = await ShiftApi.getWeeklyActivities(
        dept && {
          dept,
        }
      );
      cb(res.data);
    } catch (error) {
      cb(0);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const uploadBulkShifts = (data, cb) => async (dispatch) => {
  try {
    let res = await ShiftApi.uploadBulkShifts(data);
    cb(true);
  } catch (error) {
    cb(0);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const resetInitial = () => {
  return {
    type: constants.RESET_INITIAL_DASHBOARD,
  };
};
