import * as constants from './constants';
import { message } from 'antd';
import ApiClass from './api';
import socketHandler from '../../config/socket';

const Api = new ApiClass();

export const getLeaveRequests = (page, dept, cb) => async (dispatch) => {
  try {
    let res = await Api.getLeaveRequests({ page, ...(dept && { dept }) });
    dispatch({
      type: constants.GET_LEAVES_REQUESTS,
      payload: res?.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getLeaveTotals = (dept, cb) => async (dispatch) => {
  try {
    let res = await Api.getLeaveTotals({ ...(dept && { dept }) });
    dispatch({
      type: constants.GET_LEAVE_TOTALS,
      payload: res?.data,
    });
    cb?.();
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getLeaveTypes = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.getLeaveTypes(data);
    dispatch({
      type: constants.GET_TYPES,
      payload: res?.data,
    });
    cb(res?.data);
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createLeaveTypes = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.createLeaveTypes(data);
    dispatch({
      type: constants.CREATE_TYPE,
      payload: res?.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateLeaveTypes = (data, params, cb) => async (dispatch) => {
  try {
    await Api.updateLeaveTypes(data, params);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteLeaveTypes = (params, cb) => async (dispatch) => {
  try {
    await Api.deleteLeaveTypes(params);
    dispatch({
      type: constants.DELETE_TYPE,
      payload: params.typeId,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const changeLeaveStatus = (id, data, item, cb) => async (dispatch) => {
  try {
    let res = await Api.changeLeaveStatus(id, data);
    dispatch({
      type: constants.UPDATE_REQUEST,
      payload: {
        id,
        status: data?.status,
      },
    });
    message.success('Successfully updated request');
    cb();
    socketHandler.emit('leaveStatus', { ...item, ...data });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createLeave = (data, cb) => async (dispatch) => {
  try {
    await Api.createLeave(data);
    cb?.(true);
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};
