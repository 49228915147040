export const sample = [
  {
    'Shift Name': 'Night Shift',
    'Employees List(Email/Phone)':
      'omar213@gmail.com,+923138760987,bsaif281@gmail.com',
    'Shift Date (MM-DD-YYYY)': '10-02-2022',
    'Shift Time (HH:mm)': '09:00',
    'Shift Duration (In Hours)': '8',
  },
  {
    'Shift Name': 'Day Shift',
    'Employees List(Email/Phone)':
      'omar213@gmail.com,sohaiba213@gmail.com,+9234578654',
    'Shift Date (MM-DD-YYYY)': '10-02-2022',
    'Shift Time (HH:mm)': '09:00',
    'Shift Duration (In Hours)': '8',
  },
  {
    'Shift Name': 'Noon Shift',
    'Employees List(Email/Phone)':
      'omar213@gmail.com,sohaiba213@gmail.com,+92334534534',
    'Shift Date (MM-DD-YYYY)': '10-02-2022',
    'Shift Time (HH:mm)': '09:00',
    'Shift Duration (In Hours)': '8',
  },
  {
    'Shift Name': 'Mid Night Shift',
    'Employees List(Email/Phone)':
      '+9223423443,sohaiba213@gmail.com,bsaif281@gmail.com',
    'Shift Date (MM-DD-YYYY)': '10-02-2022',
    'Shift Time (HH:mm)': '09:00',
    'Shift Duration (In Hours)': '8',
  },
];
