import React, { useState, memo, Fragment, useEffect, useRef } from 'react';
import {
  Modal,
  Form,
  Input,
  Space,
  message,
  Select,
  DatePicker,
  AutoComplete,
  Button,
  Popover,
  Popconfirm,
  Checkbox,
  Badge,
  Spin,
  Typography,
} from 'antd';
import { Row, Col, Spinner, Table as TableRB } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ViewTask from './taskDetails';
import { getCurrentPosition } from '../../helpers/getCurrentPosition';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { getAllLocations } from '../../redux/shifts/action';
import moment from 'moment';
import { updateTaskStatus, updateTasks } from '../../redux/tasks/action';
import ActivityFilter from '../timeSheets/activityFilter';
const { Option } = Select;
import { useHistory } from 'react-router-dom';

import { formatTime } from './util';
import { styles } from './style';
const { Text } = Typography;

function Index({ item, refreshData, deleteTask }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { allLocations } = useSelector((state) => state.shiftsReducer);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [form] = Form.useForm();
  const [activity, setActivity] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dloader, setDLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [details, setDetails] = useState(false);
  const [locLoader, setLocLoader] = useState(false);
  let [check, setChecked] = useState(item?.status == 'completed');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTasks, setActiveTasks] = useState(null);

  useEffect(() => {
    if (allLocations.length == 0) {
      setLocLoader(true);
      dispatch(getAllLocations(() => setLocLoader(false)));
    }
  }, []);
  const setClose = () => {
    setActiveTasks(null);
    setIsModalVisible(false);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  const onChangeActivity = (value) => {
    setActivity(value);
  };

  const createTaskFunc = (values) => {
    setLoader(true);
    let { user_id, title, startTime, endTime, location } = values;
    let filterArr = allEmployees.filter((user) => user?.id == user_id);
    let obj = {
      title,
      startTime,
      endTime,
      location: find(location),
      user_id: currentUser?.role == 'user' ? currentUser?.id : user_id,
      ...(filterArr?.length > 0 &&
        currentUser?.role !== 'user' && {
          organization: filterArr?.[0]?.organization,
        }),
      ...(currentUser?.role == 'user' && {
        organization: currentUser?.organization?.id,
      }),
    };
    const tt = allLocations.find((o) => o.name === location)?.id;

    if (tt) {
      obj.location = tt;
    }

    let params = { taskId: item.id };
    dispatch(updateTasks(params, obj, stopLoader));
  };
  const handleOk = () => {
    setClose();
    refreshData();
  };

  const goDetails = (_task) => {
    history.push(`/app/tasks/${_task.id}`);
  };

  const showUpdateModal = () => {
    form.setFieldsValue({
      title: item?.title,
      startTime: moment(item?.startTime),
      endTime: moment(item?.endTime),
      user_id: item?.user_id?.id,
    });
    setIsModalVisible(true);
  };

  const handleUpdateCancel = () => {
    setIsModalVisible(false);
    form?.resetFields();
  };

  useEffect(() => {
    setChecked(item?.status == 'completed');
  }, [item]);

  const getCoords = (value) => {
    if (value) {
      //coordsRef.current = value;
    }
  };

  const completeTask = async () => {
    let _location = await getCurrentPosition();
    setLoader(true);
    let params = { taskId: item.id };
    let status = !check == true ? 'completed' : 'pending';
    if (_location) {
      dispatch(
        updateTaskStatus(
          params,
          { status, endLocation: _location },
          { ...item, status },
          () => {
            setLoader(false);
            setChecked(!check);
          }
        )
      );
    } else {
      dispatch(
        updateTaskStatus(params, { status }, { ...item, status }, () => {
          setLoader(false);
          setChecked(!check);
        })
      );
    }
  };

  const confirm = () => {
    setDLoader(true);
    deleteTask(item, () => {
      setDLoader(false);
      setVisible(false);
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const showPopUp = () => {
    setVisible(true);
  };

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const onClickTaskDetails = () => {
    if (details) {
      setDetails(false);
      setVisibleDrawer(true);
    } else {
      setDetails(true);
      setVisibleDrawer(true);
    }
  };

  return (
    <>
      <tr>
        <td className="d-none d-sm-table-cell">
          {loader ? (
            <Spin size="small" />
          ) : (
            <Popconfirm
              title="Are you sure to Complete this task?"
              onConfirm={completeTask}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Checkbox checked={check}>
                {check ? (
                  <Badge
                    className="site-badge-count-109"
                    count={'completed'}
                    style={{ backgroundColor: '#52c41a' }}
                  />
                ) : (
                  <Badge
                    className="site-badge-count-109"
                    count={
                      item?.status == 'pending' ? 'in pending' : 'in progress'
                    }
                    style={{ backgroundColor: '#52c41a' }}
                  />
                )}
              </Checkbox>
            </Popconfirm>
          )}
        </td>
        <td style={styles.centerAlign}>
          {check ? (
            <Text delete italic>
              <div
                style={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item?.title}
              </div>
            </Text>
          ) : (
            <Text>
              <div
                style={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {' '}
                {item?.title}
              </div>
            </Text>
          )}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {item.startTime ? (
            <>
              <small>
                <b>{days[moment(item?.startTime).day()]}</b>
              </small>
              <br></br>
              {moment(item?.startTime).format('HH:mm') + 'HRS'}
            </>
          ) : (
            '--'
          )}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {item?.endTime ? (
            <>
              <small>
                <b>{days[moment(item?.endTime).day()]}</b>
              </small>
              <br></br>
              {moment(item?.endTime).format('HH:mm') + 'HRS'}
            </>
          ) : (
            '--'
          )}
        </td>
        <td style={styles.centerAlign}>
          {moment(item?.endTime).diff(moment(item?.startTime), 'hours')}
        </td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {moment().diff(moment(item?.startTime), 'hours')}
        </td>
        <td style={styles.centerAlign}>{item?.status}</td>
        <td style={styles.centerAlign} className="d-none d-sm-table-cell">
          {moment(item?.startTime).diff(moment(), 'hours')}
        </td>

        <td className="d-none d-sm-table-cell">
          <Button
            type="warning"
            className="px-2 mx-1"
            shape="circle"
            onClick={() => goDetails(item)}
            {...(details ? 'Hide Details' : 'View Details')}
          >
            <EyeOutlined />
          </Button>

          <div className="d-inline" onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={confirm}
              visible={visible}
              shape="circle"
              onCancel={handleCancel}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: dloader }}
            >
              <Button
                type="danger"
                className="px-2 mx-1"
                shape="circle"
                onClick={showPopUp}
                disabled={check === true}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </td>
      </tr>

      {/* {!item?.creator && (
        <TimeDetails visible={visible} onClose={onClose} item={item} />
      )} */}
      <Modal
        title={'Edit Task '}
        visible={isModalVisible}
        onCancel={handleUpdateCancel}
        width={600}
        footer={null}
      >
        <Row>
          <Col md={12} lg={12}>
            <Form layout="vertical" form={form} onFinish={createTaskFunc}>
              <Form.Item
                name="title"
                label="Task Title"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input Title',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="ie Pick up the cafe after closing"
                />
              </Form.Item>

              {currentUser?.role !== 'user' && (
                <Form.Item
                  name="user_id"
                  label="Assign To"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select User',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select User to be assigned"
                    size="large"
                    className="w-100"
                  >
                    {[{ ...currentUser }, ...allEmployees]?.map((item, i) => {
                      return (
                        <Option key={i} value={item?.id}>
                          {item?.name == currentUser?.name ? 'Me' : item?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name="startTime"
                label="Start Date"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input Start Date',
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  placeholder="ie Task Start Time"
                  className="w-100"
                />
              </Form.Item>

              <Form.Item
                name="endTime"
                label="End Date"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input End Date',
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  placeholder="ie Task Due Date"
                  className="w-100"
                />
              </Form.Item>

              <Form.Item
                name="location"
                label="Location (optional)"
                required={false}
              >
                <AutoComplete
                  size="large"
                  filterOption={(inputValue, option) => {
                    return (
                      option.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  placeholder="input here"
                >
                  {allLocations.map((location) => (
                    <Option key={location.id} value={location.name}>
                      {location.name}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>

              <Form.Item label="Project (optional)" required={false}>
                <ActivityFilter
                  pickerStyle={{
                    width: '100%',
                  }}
                  onChange={onChangeActivity}
                  size="large"
                />
              </Form.Item>

              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleUpdateCancel}
                >
                  Cancel
                </button>

                <button
                  disabled={loader}
                  className="btn btn-primary"
                  htmlType="submit"
                >
                  {loader ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Update'
                  )}
                </button>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
      <ViewTask
        item={item}
        visible={visibleDrawer}
        onClose={closeDrawer}
      ></ViewTask>
    </>
  );
}

export default memo(Index);
