//axios configurations
import axios from 'axios';
import moment from 'moment';
import { baseURL } from './endPoint';
import { store } from './store';
import authApi from '../redux/auth/authApi';
import { UPDATE_TOKEN } from '../redux/auth/constants';

export const INSTANCE = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

INSTANCE.interceptors.request.use((request) => {
  if (request.url === 'auth/refresh-tokens') {
    return request;
  }
  return checkExpireToken(request);
});
INSTANCE.interceptors.response.use((response) => {
  return response;
});

export const insertToken = async (token) => {
  INSTANCE.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const checkExpireToken = (config) => {
  const tokenExpiryDate = store.getState().auth?.token?.expires;
  const needsRefresh = moment(new Date(tokenExpiryDate)).isSameOrBefore(
    new Date(),
    'milliseconds'
  );
  if (false) {
    return executeSilentRefresh(config);
  }
  return config;
};

const executeSilentRefresh = async (config) => {
  function handleError(err) {
    // fail safe: delete auth header
    delete config.headers.Authorization;
    return config;
  }
  try {
    const AuthApi = new authApi();
    const refreshToken = store.getState().auth?.refreshToken?.token;
    const res = await AuthApi.getRefreshToken(refreshToken);
    if (res && res.status === 200) {
      const { access, refresh } = res.data;

      insertToken(access.token);
      // update tokens to redux store
      config.headers.Authorization = `Bearer ${access.token}`;

      store.dispatch({
        type: UPDATE_TOKEN,
        payload: { access, refresh },
      });

      return config;
    }
    // else
    return handleError(`Error: [${res.status}] ${res.statusText}`);
  } catch (error) {
    return handleError(
      `Error: [${error.response.status || 500}] ${
        error.response.data.msg || 'SERVER_ERROR'
      }`
    );
  }
};
