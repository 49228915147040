import * as constants from './constants';
import { message } from 'antd';
import moment from 'moment';
import activitiesApi from './activitiesApi';
const ActivitiesApi = new activitiesApi();

export const getActivities =
  (page, dept, logs, type, date, cb) => async (dispatch) => {
    try {
      let start = date
        ? moment(new Date(date)).startOf('day').toDate()
        : moment().startOf('week').toDate();
      let end = date
        ? moment(new Date(date)).endOf('day').toDate()
        : moment().endOf('week').toDate();
      const res = await ActivitiesApi.getActivities({
        page,
        limit: 10,
        ...(dept && { dept }),
        ...(logs && { logs }),
        start,
        end,
        ...(type && { type }),
      });

      dispatch({
        type: constants.GET_ACTIVITIES,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.message ? error.message : error.message);
    }
  };

export const getSafetyEmergencies =
  (page, dept = null, cb) =>
  async (dispatch) => {
    try {
      const res = await ActivitiesApi.getSafetyEmergencies({
        page,
        limit: 10,
        ...(dept && { dept }),
      });
      dispatch({
        type: constants.GET_SOS,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.message ? error.message : error.message);
    }
  };

export const getAllocationSOS = (allocation, cb) => async (dispatch) => {
  try {
    const res = await ActivitiesApi.getAllocationSOS({ allocation });
    cb(res?.data);
  } catch (error) {
    cb();
    message.error(error.message ? error.message : error.message);
  }
};
