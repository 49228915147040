import { message } from 'antd';
import { cloudinaryImageUrl } from '../../services/cloudinaryImage';
import { updateCompany } from '../auth/action';
import settingsApi from './settingsApi';
import socketHandler from '../../config/socket';
import * as authConstants from '../auth/constants';
const SettingsApi = new settingsApi();

export const updateCompanyAction = (key, data, cb) => async (dispatch) => {
  try {
    const res = await SettingsApi.updateCompany(key, data);
    dispatch(updateCompany(res.data));
    cb(true);
    socketHandler.emit('preferences', { company: key });
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const sendComplainAction = (data, cb) => async (dispatch) => {
  try {
    await SettingsApi.sendComplain(data);
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateProfile = (data, cb) => async (dispatch) => {
  try {
    let profilePic;
    if (data.file) {
      const upload = await cloudinaryImageUrl(data.file);
      profilePic = upload.data.secure_url;
      dispatch({
        type: authConstants.UPDATE_IMAGE,
        payload: profilePic,
      });
    }
    await SettingsApi.updateProfile({
      name: data.name,
      ...(data.file && { profilePic }),
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const changePassword = (data, cb) => async (dispatch) => {
  try {
    await SettingsApi.changePassword(data);
    message.success('Successfully updated password!');
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const sendOtp = async (phone, cb) => {
  try {
    await SettingsApi.sendOtp({
      phone,
    });
    cb(true);
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const verifyOtp = async (otp, cb) => {
  try {
    const res = await SettingsApi.verityOtp({ otp });
    if (res.data === 'matched') {
      cb?.(true);
    } else {
      cb?.(false);
      message.error(res.data);
    }
  } catch (error) {
    cb?.(false);
    message.error(error.response?.data.message ?? error.message);
  }
};
