import * as constants from './constants';
import { message } from 'antd';
import moment from 'moment';
import timeSheetApi from './timeSheetApi';
import socketHandler from '../../config/socket';
const TimeSheetApi = new timeSheetApi();

export const createTimesheet = (data, cb) => async (dispatch) => {
  try {
    let promises = [];
    data.map((item) => {
      promises.push(TimeSheetApi.createTimesheet(item));
    });
    await Promise.all([...promises]);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createWeeklySheet = (data, cb) => async (dispatch) => {
  try {
    await TimeSheetApi.createWeeklySheet(data);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getTimeSheets =
  (first, last, dept, activity, cb) => async (dispatch) => {
    try {
      let start = moment(first).startOf('day').toDate();
      let end = moment(last).endOf('day').toDate();
      const res = await TimeSheetApi.getTimeSheets({
        start,
        end,
        dept,
        activity,
      });
      dispatch({
        type: constants.GET_SHEETS,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getDailyTimeSheets = (dept, activity, cb) => async (dispatch) => {
  try {
    const start = moment().startOf('day').toDate();
    const end = moment(start).endOf('day').toDate();
    const res = await TimeSheetApi.getDailyTimeSheets({
      start,
      end,
      dept,
      activity,
    });
    dispatch({
      type: constants.GET_DAILY_SHEETS,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getDateTimeSheets =
  (dept, activity, date, status, cb) => async (dispatch) => {
    try {
      const start = moment(date).startOf('day').toDate();
      const end = moment(date).endOf('day').toDate();
      const res = await TimeSheetApi.getDateTimeSheets({
        start,
        end,
        dept,
        activity,
        status,
      });
      dispatch({
        type: constants.GET_DAILY_ATTENDANCE,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getDatesTimeSheets =
  (dept, activity, _start, _end, status, cb) => async (dispatch) => {
    try {
      const start = moment(_start).startOf('day').toDate();
      const end = moment(_end).endOf('day').toDate();
      const res = await TimeSheetApi.getDateTimeSheets({
        start,
        end,
        dept,
        activity,
        status,
      });
      dispatch({
        type: constants.GET_DAILY_ATTENDANCES,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getSpecificTimeSheets =
  (id, start, end, activity, cb) => async (dispatch) => {
    try {
      const res = await TimeSheetApi.getSpecificTimeSheets(id, {
        start,
        end,
        activity,
      });
      cb(true, res.data);
    } catch (error) {
      cb(false);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getTimesheetRequests =
  (first, last, dept, activity, cb) => async (dispatch) => {
    try {
      let start = moment(first).startOf('day').toDate();
      let end = moment(last).endOf('day').toDate();
      const res = await TimeSheetApi.getTimesheetRequests({
        start,
        end,
        dept,
        activity,
      });

      dispatch({
        type: constants.GET_TIMESHEET_REQUESTS,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const updateTimesheetRequests = (data, user, cb) => async (dispatch) => {
  try {
    await TimeSheetApi.updateTimesheetRequests(data);
    cb();
    socketHandler.emit('timesheetStatus', {
      ...data,
      user,
    });
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getTimeSheetTotal =
  ({ id, start, end, queryType, activity, dept, cb }) =>
  async () => {
    try {
      const res = await TimeSheetApi.getTimeSheetTotal(id, {
        start,
        end,
        queryType,
        dept,
        activity,
      });

      cb(true, res.data);
    } catch (error) {
      cb(false);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getExportData =
  (range, dept, activity, status, cb) => async (dispatch) => {
    try {
      let start = moment(range[0]).startOf('day').toDate();
      let end = moment(range[1]).endOf('day').toDate();

      const res = await TimeSheetApi.getTimeSheetExport({
        start,
        end,
        dept,
        activity,
        status,
      });
      cb(range, res.data.results);
    } catch (error) {
      cb(range, []);
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getRawData = (range, dept, activity, cb) => async (dispatch) => {
  try {
    let [start, end] = range;
    const res = await TimeSheetApi.getTimeSheetsForExport({
      start,
      end,
      dept,
      activity,
    });
    cb(true, res.data, range);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateTimesheet = (data, cb) => async (dispatch) => {
  try {
    await TimeSheetApi.updateTimesheet(data);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteTimesheet = (data, cb) => async (dispatch) => {
  try {
    await TimeSheetApi.deleteTimesheet(data);
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateExportSchema = (obj) => {
  return {
    type: constants.UPDATE_EXPORT_SCHEMA,
    payload: obj,
  };
};

export const resetExportSchema = () => {
  return {
    type: constants.RESET_EXPORT_SCHEMA,
  };
};
