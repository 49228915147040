import * as constants from './constants';
let initState = {
  activities: [],
  totalActivities: 0,
  sos: [],
  totalSos: 0,
};
export default function (state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_ACTIVITIES:
      return {
        ...state,
        activities: payload.results,
        totalActivities: payload.totalResults,
      };
    case constants.GET_SOS:
      return {
        ...state,
        sos: payload.results,
        totalSos: payload.totalResults,
      };
    default:
      return state;
  }
}
