import * as constants from './constants';
import { message } from 'antd';
import organizationApi from './organizationApi';
import { addNewOrganization } from '../auth/action';
import { updateAllDept } from '../members/action';

const OrganizationApi = new organizationApi();

export const getOrganizations =
  (page, cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await OrganizationApi.getOrganizations({
        page,
        limit: 10,
        sortBy: '-createdAt',
      });

      dispatch({
        type: constants.GET_ORGANIZATIONS,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const searchOrganizations =
  (text, page, cb = () => {}) =>
  async (dispatch) => {
    try {
      const res = await OrganizationApi.searchOrganizations({
        text,
        page,
        limit: 10,
        sortBy: '-createdAt',
      });
      dispatch({
        type: constants.GET_ORGANIZATIONS,
        payload: res.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const createOrganization = (data, cb) => async (dispatch) => {
  try {
    const res = await OrganizationApi.createOrganization(data);
    dispatch({
      type: constants.NEW_ORGANIZATION,
      payload: res.data,
    });
    cb(true);
    dispatch(addNewOrganization(res.data.id));
    dispatch(updateAllDept(res?.data));
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateOrganization = (id, data, cb) => async (dispatch) => {
  try {
    await OrganizationApi.updateOrganization(id, data);
    message.success('Updated!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteOrganization = (id, cb) => async (dispatch) => {
  try {
    var res = await OrganizationApi.deleteOrganization(id);
    dispatch({
      type: constants.DELETE_ORG,
      payload: res.data.id,
    });
    message.success('Deleted!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};
