import * as constants from './constants';
import { message } from 'antd';
import ApiClass from './api';
import socketHandler from '../../config/socket';

const Api = new ApiClass();

export const addType = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.createTypes(data);
    dispatch({
      type: constants.ADD_LEAVE_TYPE,
      payload: res?.data,
    });
    cb(true);
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllType = (company) => async (dispatch) => {
  try {
    let res = await Api.getAllType(company);
    dispatch({
      type: constants.GET_LEAVE_TYPE,
      payload: res?.data,
    });
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const removeType = (id) => async (dispatch) => {
  try {
    let res = await Api.removeType();
    dispatch({
      type: constants.REMOVE_LEAVE_TYPE,
      payload: res?.data,
    });
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateType = (data) => async (dispatch) => {
  try {
    let res = await Api.updateType();
    dispatch({
      type: constants.UPDATE_LEAVE_TYPE,
      payload: res?.data,
    });
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAllRequest =
  (cb = () => {}) =>
  async (dispatch) => {
    try {
      let res = await Api.getAllRequest();
      dispatch({
        type: constants.GET_REQUEST,
        payload: res?.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const addLeaveRequest = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.addLeaveRequest(data);
    dispatch({
      type: constants.ADD_LEAVE_REQUEST,
      payload: res?.data,
    });

    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const approveRequest = (id, cb) => async (dispatch) => {
  try {
    let res = await Api.approveRequest(id);
    dispatch({
      type: constants.APPROVE_REQUEST,
      payload: res?.data,
    });
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const rejectRequest = (id, cb) => async (dispatch) => {
  try {
    let res = await Api.rejectRequest(id);
    dispatch({
      type: constants.REJECT_REQUEST,
      payload: res?.data,
    });
    cb(true);
  } catch (error) {
    cb(false);
    message.error(error.response?.data.message ?? error.message);
  }
};
