import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Avatar,
  Tag,
  Collapse,
  Tooltip,
  Statistic,
} from 'antd';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import {
  PlusOutlined,
  ReloadOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import socketHandler from '../../config/socket';
import {
  getLeaveRequests,
  changeLeaveStatus,
  getLeaveTotals,
} from '../../redux/leaveManagement/action';
import { styles } from './style';
import moment from 'moment';
import CreateModal from './addRequest';

import DepartmentFilter from '../../components/departmentFilter';
import UserDetailsModal from '../../components/userDetailsModal';
import {
  getAllRequest,
  approveRequest,
  rejectRequest,
} from '../../redux/leave/action';

const { Text } = Typography;
const { Panel } = Collapse;

const EditableTable = () => {
  const dispatch = useDispatch();
  const { pendingleaves, leaveTotals, initial } = useSelector(
    (state) => state.leave
  );
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);

  const renderData = () => {
    const data = pendingleaves.map((item) => {
      return {
        key: item.id,
        user: item?.user,
        email: item?.user?.email,
        type: item?.type?.name,
        from: moment(item?.days[0]).format('DD-MM-YYYY'),
        to: moment(item?.days[item?.days.length - 1]).format('DD-MM-YYYY'),
        days:
          item?.days.length == 1
            ? item?.days.length + ' Day'
            : item?.days.length + ' Days',
        operation: item?.status,
        description: item?.description,
        paid: item?.paid ? 'YES' : 'NO',
      };
    });
    return data;
  };
  const [form] = Form.useForm();
  const [data, setData] = useState(
    pendingleaves.length > 0 ? renderData() : []
  );
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedDpt, setDpt] = useState(null);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const pRef = useRef(1);
  const depRef = useRef(null);

  const stopLoader = () => {
    setLoader(false);
  };

  const openRequest = () => {
    setShow(true);
  };

  const stopUpdateLoader = () => {
    setLoader(false);
    dispatch(getAllRequest(stopLoader));
  };

  useEffect(() => {
    if (pendingleaves.length == 0) {
      setLoader(true);
      dispatch(getAllRequest(stopLoader));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(pendingleaves)) {
      const data = renderData();
      setData(data);
    }
  }, [pendingleaves]);

  const refreshData = () => {
    setLoader(true);
    dispatch(getAllRequest(() => setLoader(false)));
  };

  const setClose = () => {
    setShow(false);
  };

  const handleOk = () => {
    setClose();
    refreshData();
  };

  const changePage = (page) => {
    setPage(page);
    pRef.current = Number(page);
    setLoader(true);
    dispatch(getLeaveRequests(page, selectedDpt, stopLoader));
  };
  const onChangeDept = (dept) => {
    setDpt(dept);
    depRef.current = dept;
    setLoader(true);
    dispatch(getLeaveRequests(page, dept, stopLoader));
    dispatch(getLeaveTotals(dept));
  };

  const ApproveLeave = (item) => {
    setLoader(true);

    dispatch(approveRequest(item.key, stopUpdateLoader));
  };

  const RejectLeave = (item) => {
    setLoader(true);
    dispatch(rejectRequest(item.key, stopUpdateLoader));
  };

  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';

    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const fetchUpdates = useCallback(() => {
    dispatch(getLeaveRequests(pRef?.current, depRef?.current, () => {}));
    dispatch(getLeaveTotals(depRef?.current));
  }, []);

  useEffect(() => {
    socketHandler.on('leaveRequest', fetchUpdates);
    return () => {
      socketHandler.off('leaveRequest', fetchUpdates);
    };
  }, []);

  const _renderUser = (user) => {
    return (
      <Space
        align="center"
        className="c-pointer"
        onClick={() => onRowAvatar(user)}
      >
        <Avatar src={user?.profilePic} size="large" />
        <Text strong className="pl-2 font-weight-bold">
          {user?.name}
        </Text>
      </Space>
    );
  };

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'user',
      align: 'left',
      render: _renderUser,
    },

    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: 'Date From',
      dataIndex: 'from',
      editable: true,
      align: 'center',
    },
    {
      title: 'Date To',
      dataIndex: 'to',
      editable: true,
      align: 'center',
    },
    {
      title: 'Total Days',
      dataIndex: 'days',
      align: 'center',
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      editable: true,
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      // align: "center",
      render: (_, record) => {
        return (
          <Space align="center" size="large">
            <Popconfirm
              title="Sure to Approved?"
              onConfirm={() => ApproveLeave(record)}
            >
              <Button className="bg-primary text-white border-primary">
                Approve
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Sure to Reject?"
              onConfirm={() => RejectLeave(record)}
            >
              <Button>Reject</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <div className="row float-right mx-1">
            <DepartmentFilter
              pickerStyle={styles.pickerStyle}
              onSelectDpt={onChangeDept}
            />

            <Button
              type="primary"
              onClick={openRequest}
              className="bg-primary border-primary mx-2"
              icon={<PlusOutlined />}
              style={styles.buttonUpload}
            >
              Request For Leave
            </Button>
            <Tooltip title="Fetch Latest Data">
              <Button onClick={refreshData} style={{ height: 40 }}>
                <ReloadOutlined />
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row className="row mt-3 mb-3">
        <Col md={3}>
          <Card>
            <Statistic
              title="Total Number of leave"
              value={leaveTotals.total}
              valueStyle={{ color: '#3f8600' }}
              prefix={<CalendarOutlined />}
              suffix="Request"
            />
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Statistic
              title="Pending Leave "
              value={leaveTotals.pending}
              valueStyle={{ color: '#f79425' }}
              prefix={<QuestionCircleOutlined />}
              suffix="Request"
            />
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Statistic
              title="Approved Leave"
              value={leaveTotals.approved}
              valueStyle={{ color: '#3f8600' }}
              prefix={<CheckCircleOutlined />}
              suffix="Request"
            />
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Statistic
              title="Rejected Task"
              value={leaveTotals.rejected}
              valueStyle={{ color: 'red' }}
              prefix={<CloseCircleOutlined />}
              suffix="Request"
            />
          </Card>
        </Col>
      </Row>
      <Collapse bordered={false} ghost expandIconPosition={'end'}>
        <Panel header={<span className="h4">Pending Request</span>} key="1">
          <Form form={form} component={false}>
            <Table
              className="table-curved"
              dataSource={data}
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <p style={{ margin: 0 }}>
                    <Text strong>Reason: </Text>
                    {record.description}
                  </p>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
              pagination={{
                onChange: changePage,
                current: page,
                total: pendingleaves.length,
                position: ['bottomLeft'],
                pageSize: 10,
                hideOnSinglePage: true,
              }}
              scroll={data.length > 0 && { x: true }}
              loading={loader}
            />
          </Form>
        </Panel>
      </Collapse>

      {/* <Collapse bordered={false} ghost expandIconPosition='end'>
      <Panel header={(<span className='h4'>Ongoing Leave</span>)} key="1">
      <Form form={form} component={false}>
          <Table
            className="table-curved"
            dataSource={data}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{ margin: 0 }}>
                  <Text strong>Reason: </Text>
                  {record.description}
                </p>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            pagination={{
              onChange: changePage,
              current: page,
              total: totalLeaves,
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          />
        </Form>

      </Panel>
      

      </Collapse> */}

      <CreateModal show={show} setClose={setClose} handleOk={handleOk} />

      <UserDetailsModal
        isVisible={userDetailModal && selectedUser}
        title=""
        handleModal={(value) => setDetailModal(value)}
        selectedUser={selectedUser}
      />
    </React.Fragment>
  );
};

export default memo(EditableTable);
