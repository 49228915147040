import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Spin, Space } from 'antd';

import {
  cancelSubscription,
  getCardUrl,
  getPortalUrl,
  getSubscription,
  getCurrentStatus,
} from '../../redux/chargeBee/action';

import ConfirmModal from '../../components/confirmationModal';
import SubListModal from './SubListModal';

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';

function Index() {
  const { activePlan, isActiveSub, customer, card } = useSelector(
    (state) => state.chargeBee
  );
  const [loader, serLoader] = useState(false);

  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);

  const [CardUrl, setCardUrl] = useState(null);
  const [portalUrl, setPortalUrl] = useState(null);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showList, setShowList] = useState(false);

  const cancelSub = () => {
    dispatch(cancelSubscription(() => {}));
  };

  const onShowList = () => {
    setShowList(true);
  };
  const onCloseList = () => {
    setShowList(false);
  };

  const onShowConfirm = () => {
    setShowConfirm(true);
  };

  useEffect(() => {
    // company
    dispatch(
      getCardUrl(company?.paymentDetails?.chargeBeeUser, (res) => {
        setCardUrl(res?.hosted_page?.url);
      })
    );

    serLoader(true);
    dispatch(getSubscription(() => serLoader(false)));
    dispatch(
      getPortalUrl((res) => {
        setPortalUrl(res?.portal_session?.access_url);
      })
    );

    if (!isActiveSub) {
      dispatch(getCurrentStatus());
    }
  }, []);

  if (loader) {
    return (
      <Space
        size="large"
        align="center"
        style={{
          display: 'flex',
          flex: 1,
          height: 500,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <>
      <Container className="mt-5 p-5">
        <Row>
          <Col lg={8}>
            <h1 className="text-capitalize">
              {activePlan?.chargeItem?.external_name}
            </h1>

            {activePlan?.subscription?.status === 'in_trial' && (
              <>
                <p>
                  Your first bill for $
                  {activePlan?.chargeItem?.price
                    ? (activePlan?.chargeItem?.price / 100) * activePlan.users
                    : activePlan?.chargeItem?.price}{' '}
                  is due on{' '}
                  {moment(activePlan?.subscription?.trial_end * 1000).format(
                    'DD-MM-YYYY'
                  )}
                </p>
                <p className="text-danger">
                  Free trial expires in:{' '}
                  {moment
                    .duration(
                      moment(activePlan?.subscription?.trial_end * 1000).diff(
                        moment()
                      )
                    )
                    .asDays()
                    .toFixed(0)}{' '}
                  days
                </p>
              </>
            )}

            <div
              className="prefSwitchCont"
              style={{ marginTop: 15, marginBottom: 60 }}
            >
              {card && customer?.payment_method ? null : (
                <a
                  href={CardUrl}
                  className="btn border p-2  border-primary text-white bg-primary mr-1 text-uppercase mt-3"
                >
                  Add Payment Method
                </a>
              )}

              <button
                onClick={onShowList}
                className="btn border p-2 border-primary text-primary text-uppercase mt-3"
              >
                {isActiveSub ? 'Manage Plans' : 'Upgrade Plan'}
              </button>
            </div>
          </Col>
        </Row>
        <Container className="mt-3">
          <Row className="border border-right-0 border-left-0">
            <Col className="d-flex align-items-center">
              <p className=" mb-0">Billing cycle</p>
            </Col>
            <Col className="d-flex align-items-center">
              <p className=" mb-0 py-2 text-capitalize">
                {activePlan?.subscription?.billing_period_unit}
              </p>
            </Col>
          </Row>
          <Row className="border border-right-0  border-top-0 border-left-0">
            <Col className="d-flex align-items-center">
              <p className=" mb-0">Price</p>
            </Col>
            <Col className="d-flex align-items-center">
              <p className=" mb-0 py-2">
                $
                {activePlan?.chargeItem?.price
                  ? activePlan?.chargeItem?.price / 100
                  : activePlan?.chargeItem?.price}{' '}
                per user/{activePlan?.subscription?.billing_period_unit}
              </p>
            </Col>
          </Row>
          <Row className="border border-right-0  border-top-0 border-left-0">
            <Col className="d-flex align-items-center">
              <p className=" mb-0">Monthly active users</p>
            </Col>
            <Col className="d-flex align-items-center">
              <p className=" mb-0 py-2">{activePlan?.users}</p>
            </Col>
          </Row>
          <Row className="border border-right-0  border-top-0 border-left-0">
            <Col className="d-flex align-items-center">
              <p className=" mb-0">Payment method</p>
            </Col>
            <Col className="d-flex align-items-center">
              <p className=" mb-0 py-2 text-secondary">
                {activePlan?.customer?.payment_method?.type
                  ? activePlan?.customer?.payment_method?.type ===
                    'paypal_express_checkout'
                    ? 'Paypal'
                    : 'Card'
                  : 'No payment method set up yet'}
              </p>
            </Col>
          </Row>
        </Container>
        <div
          className="d-flex flex-column justify-content-start align-items-start"
          style={{ marginTop: 35 }}
        >
          <a
            href={portalUrl}
            target="_blank"
            className="btn text-primary"
            rel="noreferrer"
          >
            Payment & Billing Details
          </a>

          {activePlan?.subscription?.cancelled_at ? (
            <p className="text-danger" style={{ paddingLeft: 15.2 }}>
              Subscription will be cancelled at{' '}
              {moment(activePlan?.subscription?.cancelled_at * 1000).format(
                'DD-MM-YYYY'
              )}
            </p>
          ) : (
            <button onClick={onShowConfirm} className="btn text-primary">
              Cancel Subscription
            </button>
          )}
        </div>
      </Container>
      <SubListModal show={showList} onClose={onCloseList} CardUrl={CardUrl} />

      <ConfirmModal
        isModalVisible={showConfirm}
        onClose={() => setShowConfirm(false)}
        openReqModal={cancelSub}
        msg={'Are You Want To Cancel Subscription?'}
      />
    </>
  );
}

export default Index;
