import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import {
  Table,
  Avatar,
  Space,
  Typography,
  Card,
  Button,
  Tooltip,
  Statistic,
  DatePicker,
  Select,
  Input,
} from 'antd';
import {
  UserAddOutlined,
  ReloadOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import socketHandler from '../../config/socket';
import DepartmentFilter from '../../components/departmentFilter';
import ActivityFilter from './activityFilter';
import AddModal from './createTimesheet';
import {
  getDailyTimeSheets,
  getDatesTimeSheets,
} from '../../redux/timesheets/action';

import { styles } from './style';
import { countMembers } from '../../redux/members/action';
import {
  renderColumnAt2,
  parseTimeSheets,
  formatTime,
  generateExcelFile,
} from './util';

const { Text } = Typography;
const { Option } = Select;
const renderTitle = (title) => {
  return (
    <div>
      <Text strong className="d-block text-light">
        {title}
      </Text>
    </div>
  );
};

const renderTime = (time) => {
  return <Text>{formatTime(time)}</Text>;
};
const { RangePicker } = DatePicker;
const { Search } = Input;

const renderDate = (date) => {
  if (!date) {
    return <Text>-----</Text>;
  }
  return <Text>{moment(date).format('HH:mm:ss')}</Text>;
};

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dailyLeaves, initialD, dailyAttendances } = useSelector(
    (state) => state.timesheetsReducer
  );
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [totalTimes, setTotalTimes] = useState(null);
  const [selectedDpt, setDpt] = useState(null);
  const [activity, setActivity] = useState(null);

  const [endDate, setEndDate] = useState(moment().subtract(30, 'days'));
  const [startDate, setStartDate] = useState(moment());

  const [absenceModal, setAbsenceModal] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [total, SetTotal] = useState(0);
  const depRef = useRef(null);
  const actRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const statusRef = useRef(null);
  const [status, setStatus] = useState('approved');

  const stopLoader = () => {
    setLoader(false);
  };
  const stopLoaderCount = (_total) => {
    SetTotal(_total);
  };
  useEffect(() => {
    if (initialD) {
      setLoader(true);
      dispatch(
        getDatesTimeSheets(
          selectedDpt,
          activity,
          moment().subtract(30, 'day'),
          moment().subtract(1, 'day'),
          status,
          stopLoader
        )
      );
      dispatch(countMembers(selectedDpt, stopLoaderCount));
    }
  }, []);

  const timeSheetTotalCB = (success, data) => {
    if (success) {
      setTotalTimes(data);
    }
  };

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const selectMode = (value) => {
    setStatus(value);
    statusRef.current = value;
    setLoader(true);
    dispatch(
      getDatesTimeSheets(
        selectedDpt,
        activity,
        startDate,
        endDate,
        value,
        stopLoader
      )
    );
  };

  useEffect(() => {
    if (Array.isArray(dailyAttendances)) {
      const _data = parseTimeSheets(dailyAttendances, true, []);

      setData(_data);
    }
  }, [dailyAttendances]);

  const refreshData = () => {
    setLoader(true);
    dispatch(
      getDatesTimeSheets(
        selectedDpt,
        activity,
        startDate,
        endDate,
        status,
        stopLoader
      )
    );
    dispatch(countMembers(selectedDpt, stopLoaderCount));
  };

  const goDetails = (user) => {
    history.push(`/app/timesheets/${user.id}`);
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    depRef.current = dept;
    setLoader(true);
    dispatch(
      getDatesTimeSheets(dept, activity, startDate, endDate, status, stopLoader)
    );
    dispatch(countMembers(selectedDpt, stopLoaderCount));
  };

  const exportToExcel = () => {
    setLoader(true);
    const _data = data.map((x) => {
      return {
        'Employee Name': x.members.name,
        Department: allDeptByIds[x.members?.organization]?.name
          ? allDeptByIds[x.members?.organization]?.name
          : '',
        'Job Title': x.jobType,

        '# of Days': x.days,
      };
    });
    generateExcelFile(_data, [startDate, endDate]);
    setLoader(false);
  };

  const onChangeDate = (_date) => {
    if (_date == null) return;
    setStartDate(_date[0]);
    setEndDate(_date[1]);

    startDateRef.current = _date[0];
    endDateRef.current = _date[1];
    setLoader(true);
    dispatch(
      getDatesTimeSheets(
        selectedDpt,
        activity,
        _date[0],
        _date[0],
        status,
        stopLoader
      )
    );
  };

  const onChangeActivity = (value) => {
    setActivity(value);
    actRef.current = value;
    setLoader(true);
    dispatch(
      getDatesTimeSheets(
        selectedDpt,
        value,
        startDate,
        endDate,
        status,
        stopLoader
      )
    );
  };

  const showModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };
  const onSearch = (value) => {};
  const onRowAvatar = (record) => {
    const deptName = allDeptByIds?.[record?.organization]?.name || '';
    const userRecord = {
      ...record,
      company: company?.name,
      organization: deptName,
    };

    selectUser(userRecord);
    setDetailModal(true);
  };

  const fetchUpdates = useCallback((data) => {
    dispatch(
      getDatesTimeSheets(
        depRef?.current,
        actRef?.current,
        dateRef.current,
        statusRef.current,
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    socketHandler.on('timesheet', fetchUpdates);
    return () => {
      socketHandler.off('timesheet', fetchUpdates);
    };
  }, []);

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space align="center" className="c-pointer">
          <Avatar
            onClick={() => onRowAvatar(user)}
            style={styles.avatar}
            src={
              user?.profilePic ??
              'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
            }
            size="medium"
          />
          <Text
            onClick={() => goDetails(user)}
            strong
            className="pl-2 font-weight-bold"
          >
            {user?.name?.split(' ')[0]}
          </Text>
        </Space>
      );
    }
  };

  const renderDepartment = (dpt) => {
    return (
      <Text>{allDeptByIds[dpt]?.name ? allDeptByIds[dpt]?.name : ''}</Text>
    );
  };

  return (
    <React.Fragment>
      <Space className="mb-3 flex-wrap">
        <DepartmentFilter
          pickerStyle={{
            width: 215,
          }}
          onSelectDpt={onChangeDept}
        />

        <Search placeholder=" search by Name" onSearch={onSearch} enterButton />
        <RangePicker
          onChange={onChangeDate}
          defaultValue={[startDate, endDate]}
        />
        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshData}>
            <ReloadOutlined />
          </Button>
        </Tooltip>

        <Button
          type="primary"
          className="bg-primary border-primary "
          loading={loader}
          onClick={() => {
            exportToExcel();
          }}
        >
          Export to Excel
        </Button>
      </Space>

      <Row>
        <Col md={12}>
          <Table
            className="table-curved"
            columns={renderColumnAt2(
              renderTitle,
              renderAvatar,
              renderDepartment
            )}
            dataSource={data}
            scroll={data.length > 0 && { x: true }}
            size="medium"
            pagination={true}
            loading={loader}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
