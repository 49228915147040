import React, { useState } from 'react';
import { Divider, DatePicker, Button, Space } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
const dateFormat = 'DD/MM/YYYY';

function Index({ startDate, endDate, ...props }) {
  const [selection, setSelection] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  const onMoveWeek = (days) => {
    const nextSelection = {
      startDate: moment.utc(selection.startDate).add(days, 'days').toDate(),
      endDate: moment.utc(selection.endDate).add(days, 'days').toDate(),
    };
    setSelection(nextSelection);
    props.onChange(nextSelection);
  };

  const getValues = (date) => {
    const endDate = moment.utc(date).add(7, 'days').toDate();
    const nextSelection = {
      startDate: date,
      endDate,
    };
    setSelection(nextSelection);
    props.onChange(nextSelection);
  };

  return (
    <Row>
      <Col md={12}>
        <Divider className="w-100 mb-2" />
        <Space align="center" className="d-flex flex-wrap">
          <Button
            size="large"
            icon={<LeftOutlined />}
            onClick={() => onMoveWeek(-7)}
          />
          <Button
            size="large"
            icon={<RightOutlined />}
            onClick={() => onMoveWeek(7)}
          />
          <DatePicker
            format={dateFormat}
            size="large"
            picker="week"
            defaultValue={moment(selection.startDate)}
            onChange={getValues}
            value={moment(selection.startDate)}
            allowClear={false}
            clearIcon={false}
          />
          <span>~</span>
          <Button size="large">
            {moment(selection.endDate).format('DD/MM/YYYY')}
          </Button>
        </Space>
        <Divider className="w-100 mt-2" />
      </Col>
    </Row>
  );
}

export default Index;
