export const styles = {
  mapContainer: {
    height: '95vh',
    width: '100%',
  },
  searchBar: {
    position: 'absolute',
    top: '4rem',
    zIndex: 2342444,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  label: {
    color: '#fff',
    padding: '5px 10px',
    display: 'flex',
    transform: 'translate(-5%, -130%)',
    fontWeight: 'bolder',
    minWidth: 100,
    borderRadius: 20,
    border: '2px solid #fff',
  },
  refresh: {
    position: 'absolute',
    top: '1rem',
    right: '3rem',
    zIndex: 3453454,
  },
};
