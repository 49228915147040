import React, { useState, memo, useEffect } from 'react';
import { Modal, Form, Input, Space, message, Select, Tooltip } from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createOrganization } from '../../redux/organizations/action';
import { getAllDepartments } from '../../redux/members/action';
import { getAllLocations } from '../../redux/shifts/action';
import DoneModal from '../tourView/doneModal';
import { showTour, updateTourStatus } from '../../redux/tour/action';

export default memo(function Index({ show, handleOk, setClose }) {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { allDept } = useSelector((state) => state.membersReducer);
  const { isDeptHelp, dptDoneModal } = useSelector(
    (state) => state.tourReducer
  );
  const { allLocations } = useSelector((state) => state.shiftsReducer);
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);
  const [oLoader, setOLoader] = useState(false);

  const getAllDepartmentsFun = () => {
    if (allDept.length == 0) {
      setDLoader(true);
      dispatch(getAllDepartments(() => setDLoader(false)));
    }
  };

  const stopLocationLoader = () => {
    setOLoader(false);
  };

  useEffect(() => {
    getAllDepartmentsFun();
    setOLoader(true);
    dispatch(getAllLocations(stopLocationLoader));
  }, []);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      message.success('Successfully Created Organization!');
      getAllDepartmentsFun();
      handleOk();
      if (isDeptHelp) {
        dispatch(
          showTour({
            dptDoneModal: true,
            isProgressListVisible: true,
          })
        );
      }
    }
  };
  const createOrgFunc = (values) => {
    const { name, parent, location } = values;
    setLoader(true);
    const orgDetails = {
      name: name,
      companyId: user?.company_id?.id,
      parent,
      location,
    };

    dispatch(createOrganization(orgDetails, stopLoader));
  };

  const doneDeptHelper = () => {
    // setDoneModal(false);
    dispatch(
      showTour({
        isDeptHelp: false,
        memberHelp: true,
        dptDoneModal: false,
        isProgressListVisible: false,
      })
    );
  };

  const finishHelper = () => {
    dispatch(updateTourStatus('skipAtDpt', user.id));
  };

  return (
    <>
      <Modal
        title="Create New Department"
        visible={show}
        onCancel={setClose}
        width={700}
        footer={null}
      >
        <Row>
          <Col md={12} lg={12}>
            <Form layout="vertical" form={form} onFinish={createOrgFunc}>
              <Form.Item
                name="name"
                label="Department Name"
                required
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Name',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter a Name" />
              </Form.Item>
              {allDept?.length > 0 && (
                <Form.Item name="parent" label="Parent Department (Optional)">
                  <Select
                    size="large"
                    className="w-100"
                    placeholder="Select a Parent Department"
                    loading={dLoader}
                    disabled={dLoader}
                  >
                    {allDept?.map((item, i) => {
                      return (
                        <Option key={i} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name="location"
                label="Locations (optional)"
                className="mb-1"
              >
                <Select
                  placeholder="Select Location"
                  optionFilterProp="children"
                  className="w-100"
                  size="large"
                  loading={oLoader}
                  disabled={oLoader}
                >
                  {allLocations.map((item, i) => {
                    return (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={setClose}
                >
                  Cancel
                </button>
                <Tooltip
                  title="Once you are done, click here to complete creating your department"
                  color={'#F89722'}
                  defaultVisible={isDeptHelp}
                >
                  <button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={loader}
                  >
                    {loader ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      'Create'
                    )}
                  </button>
                </Tooltip>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>

      <DoneModal
        isVisible={dptDoneModal}
        header="Well done!"
        bodyA="You have now created your first department. You can always edit and add new departments on the departments page"
        bodyB="Let’s now invite the team members for your department"
        ctaLeft="Let's Invite my team"
        onLeftCtaClick={doneDeptHelper}
        ctaRight="I'll do this later"
        onRightCtaClick={finishHelper}
      />
    </>
  );
});
