import * as constants from './constants';
import _ from 'lodash';

let initState = {
  leaves: [],
  types: [],
  leaveTotals: {},
  totalLeaves: 0,
  initial: true,
};
export default function (state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_LEAVES_REQUESTS:
      return {
        ...state,
        leaves: payload.results,
        totalLeaves: payload.totalResults,
        initial: false,
      };
    case constants.GET_TYPES:
      return {
        ...state,
        types: payload,
      };
    case constants.CREATE_TYPE:
      return {
        ...state,
        types: [payload, ...state.types],
      };
    case constants.UPDATE_REQUEST:
      return {
        ...state,
        leaves: state.leaves.map((item) =>
          item?.id == payload?.id ? { ...item, status: payload.status } : item
        ),
      };
    case constants.DELETE_TYPE:
      return {
        ...state,
        types: state.types.filter((type) => type.id !== payload),
      };

    case constants.GET_LEAVE_TOTALS:
      return {
        ...state,
        leaveTotals: payload,
      };

    default:
      return state;
  }
}
