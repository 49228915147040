import { INSTANCE } from '../../config/networkInstance';

export default class LocationApi {
  createLocation = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'location',
        data: params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getLocations = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'location',
        params: { ...params },
      })
        .then(resolve)
        .catch(reject);
    });
  };

  searchLocations = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'location/search',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateLocation = (id, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `location/${id}`,
        data: params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteLocation = (id) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: `location/${id}`,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  uploadBulkLocations = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'location/import-locations',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
