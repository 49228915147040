export const styles = {
  scheduleDiv: {
    // background: "#d9f7be",
    height: '90%',
    width: '100%',
  },
  avatar: {
    color: '#f56a00',
    backgroundColor: '#fde3cf',
    width: 45,
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mT: {
    marginTop: 10,
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameText: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    fontSize: 17,
    color: '#fff',
  },
  deptFilterCont: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
  },
  buttonUpload: {
    marginLeft: 20,
    marginTop: 10,
  },
};
