import React, { useState } from 'react';
import { Menu } from 'antd';
import Profile from './profile';
import Preferences from './index';
import ChangePassword from './changePassword';
import BreakTypes from './breakTypes';
import HealthTypes from './healthTypes';
import LeaveTypes from '../leaveManagement/types';
import Subscriptions from '../subscriptions/index';
const { SubMenu } = Menu;

function Index() {
  let [key, setKey] = useState('1');

  const handleChange = ({ _, key }) => {
    setKey(key);
  };

  const renderComp = () => {
    switch (key) {
      case '1':
        return <Profile />;
      case '2':
        return <Preferences />;
      case '3':
        return <ChangePassword />;
      case '4':
        return <Subscriptions />;
      case '5':
        return <HealthTypes />;
      case '6':
        return <BreakTypes />;
      case '7':
        return <LeaveTypes />;
    }
  };

  return (
    <div className="d-flex flex-row">
      <Menu
        style={{ width: 256 }}
        mode={'inline'}
        theme={'light'}
        onClick={handleChange}
        selectedKeys={[key]}
      >
        <Menu.Item key={'1'}>Profile</Menu.Item>
        <Menu.Item key={'2'}>Preferences</Menu.Item>
        <Menu.Item key={'3'}>Change Password</Menu.Item>
        <Menu.Item key={'4'}>Subscriptions</Menu.Item>

        <SubMenu key="sub1" title="Types Management">
          <Menu.Item key={'5'}>Health</Menu.Item>
          <Menu.Item key={'6'}>Break</Menu.Item>
          <Menu.Item key={'7'}>Leave</Menu.Item>
        </SubMenu>
      </Menu>
      {renderComp()}
    </div>
  );
}

export default Index;
