/* eslint-disable react/display-name */
import React from 'react';
import Tour from 'reactour';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from '../../hooks/useWindowSize';
import TourOrg from '../../assets/images/tourOrg.png';
import tourProductivity from '../../assets/images/tourProductivity.png';
import tourTeam from '../../assets/images/tourTeam.png';
import { finishTour } from '../../redux/tour/action';

const steps = {
  dept: {
    selector: '.organization',
    content: () => (
      <div className="tipCont">
        <div>
          <p className="tipHeading">Create organizations</p>
          <p className="tipDesc">
            Click here to create your first department. You will also manage
            your departments here in the future
          </p>
        </div>
        <div className="tipIconCont">
          <img src={TourOrg} alt="" />
        </div>
      </div>
    ),
  },
  member: {
    selector: '.members',
    content: () => (
      <div className="tipCont">
        <div>
          <p className="tipHeading">Create teams</p>
          <p className="tipDesc">
            Click here to invite your team members. You will also add and edit
            team members here in the future.
          </p>
        </div>
        <div className="tipIconCont">
          <img src={tourTeam} alt="" />
        </div>
      </div>
    ),
  },
  performance: {
    selector: '.pq',
    content: () => (
      <div className="tipCont">
        <div>
          <p className="tipHeading">Create performance questions</p>
          <p className="tipDesc">
            Click here to add performance questions for your team members. You
            will also add and edit performance questions here in the future.
          </p>
        </div>
        <div className="tipIconCont">
          <img src={tourProductivity} alt="" />
        </div>
      </div>
    ),
  },
  // settings: {
  //   selector: '.settings',
  //   content: () => (
  //     <div className="tipCont">
  //       <div>
  //         <p className="tipHeading">Set Preferences</p>
  //         <p className="tipDesc">
  //           Click here to set Preferences for Performance, Leave Management and
  //           Image Recognition
  //         </p>
  //       </div>
  //       <div className="tipIconCont">
  //         <img src={tourProductivity} alt="" />
  //       </div>
  //     </div>
  //   ),
  // },
};

const stepsMobile = {
  dept: {
    content: () => (
      <div className="tipCont">
        <div>
          <p className="tipHeading">Create organizations</p>
          <p className="tipDesc">
            Click here to create your first department. You will also manage
            your departments here in the future
          </p>
        </div>
        <div className="tipIconCont">
          <img src={TourOrg} alt="" />
        </div>
      </div>
    ),
  },
  member: {
    content: () => (
      <div className="tipCont">
        <div>
          <p className="tipHeading">Create teams</p>
          <p className="tipDesc">
            Click here to invite your team members. You will also add and edit
            team members here in the future.
          </p>
        </div>
        <div className="tipIconCont">
          <img src={tourTeam} alt="" />
        </div>
      </div>
    ),
  },
  performance: {
    content: () => (
      <div className="tipCont">
        <div>
          <p className="tipHeading">Create performance questions</p>
          <p className="tipDesc">
            Click here to add performance questions for your team members. You
            will also add and edit performance questions here in the future.
          </p>
        </div>
        <div className="tipIconCont">
          <img src={tourProductivity} alt="" />
        </div>
      </div>
    ),
  },
};

function TourView(props) {
  const { deptHelp, memberHelp, performanceHelp } = useSelector(
    (state) => state.tourReducer
  );

  const dispatch = useDispatch();

  const windowSize = useWindowSize();

  const doneHelp = () => {
    dispatch(finishTour());
  };

  const selectHelperObj = deptHelp
    ? 'dept'
    : memberHelp
    ? 'member'
    : 'performance';

  return (
    <Tour
      steps={
        windowSize.width <= 760
          ? [stepsMobile[selectHelperObj]]
          : [steps[selectHelperObj]]
      }
      isOpen={deptHelp || memberHelp || performanceHelp}
      className="helper"
      rounded={5}
      accentColor={'#F8A94F'}
      onRequestClose={doneHelp}
      showButtons={false}
      showCloseButton={true}
      showNavigation={false}
      showNumber={false}
      closeWithMask={false}
    />
  );
}

export default TourView;
