import io from 'socket.io-client';
import { socketEndPoint } from './endPoint';

const userSocket = io(socketEndPoint, {
  path: '/userIO',
  transports: ['websocket'],
  upgrade: false,
});

export default userSocket;
