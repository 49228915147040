export const styles = {
  stepperContainer: { height: '100vh', background: '#ffff', marginTop: 60 },
  container: {
    height: '100vh',
    width: '100vw',
    background: '#ffff',
  },
  mapContainer: {
    height: 400,
    width: '100%',
    position: 'relative',
  },
  mapValues: {
    background: 'rgba(255,255,255,0.5',
    padding: '0.8rem',
    border: '1px solid #ccc',
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    zIndex: 2342444,
    width: '25%',
  },
  desc: {
    fontSize: 18,
  },
  button: {
    height: 63,
    width: 200,
    padding: 10,
  },
  stepperIcon: {
    height: 40,
    width: 40,
  },
  line: {
    height: 1,
    width: '20%',
    background: '#121F49',
  },
  input: {
    height: 50,
    borderRadius: 5,
    borderWidth: 2,
  },
  btnSm: {
    height: 50,
    borderRadius: 5,
    width: 200,
  },
  select: {
    height: 60,
  },
  stepsText: {
    fontSize: 18,
  },
  boxPicker: {
    height: 127,
    width: 155,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 5,
    position: 'relative',
    boxShadow: '-2px 0px 17px 2px #F0F0F0',
    background: '#fff',
    borderRadius: 10,
  },
  radioTitle: {
    fontSize: 10,
    color: '#F79642',
    fontWeight: 'bold',
  },
  buttonContinue: {
    height: 50,
    borderRadius: 5,
  },
  featureImage: {
    height: 60,
    width: 60,
  },
  tick: {
    height: 20,
    width: 20,
  },
  radio: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  backBtn: {
    backgroundColor: 'transparent',
    width: 60,
    height: 40,
    border: 'none',
    marginLeft: -50,
  },

  backIcon: {
    fontSize: 30,
  },

  buttonLogout: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#F79642',
    fontWeight: 'bold',
    fontSize: 16,
  },
  smallText: {
    fontSize: 12,
  },
};
