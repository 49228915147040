import { Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChargeBeePlans } from '../../redux/chargeBee/action';
import PaymentCard from './paymentCard';
import { isSubscribed } from '../../helpers/isSubscribed';

export default function SubListModal({ show, onClose, onShow, CardUrl }) {
  const { user } = useSelector((state) => state.auth);
  const { monthlyPlans, yearlyPlans } = useSelector((state) => state.chargeBee);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);

  const changeCharge = () => {
    setCheck(!check);
  };

  useEffect(() => {
    if (!check && monthlyPlans) {
      setPlans(monthlyPlans);
    }

    if (check && yearlyPlans) {
      setPlans(yearlyPlans);
    }
  }, [monthlyPlans, yearlyPlans, check]);

  useEffect(() => {
    dispatch(getChargeBeePlans());
  }, []);

  return (
    <Modal
      visible={show}
      footer={null}
      width={1200}
      onCancel={onClose}
      style={{ backgroundColor: 'transparent' }}
      bodyStyle={{ padding: 0, backgroundColor: 'transparent' }}
    >
      <section className="pricing pt-5">
        {!isSubscribed(user) && (
          <>
            <h3 className="text-center font-weight-bold pt-4">
              Your 14 day trial has ended
            </h3>
            <p className="text-center">
              Upgrade to one of our paid plans below and continue using Gigsasa
              to stay on top of your teams attendance and productivity.
            </p>
          </>
        )}
        <div className="container">
          <div className="row pb-3 justify-content-center">
            <Switch
              checkedChildren="YEARLY"
              unCheckedChildren="MONTHLY"
              onClick={changeCharge}
            />
          </div>
          <div className="row justify-content-center">
            {plans?.map((plan, index) => (
              <PaymentCard
                onShow={onShow}
                plan={plan.item_price}
                key={index}
                CardUrl={CardUrl}
              />
            ))}
          </div>
        </div>
      </section>
    </Modal>
  );
}
