export const sample = [
  {
    name: 'Omar',
    email: 'omar213@gmail.com',
    role: 'admin',
    phone: '+923233232334',
    jobType: 'Job type of Selected Department',
    organization: 'Paste any department ID from sample',
  },
  {
    name: 'Arhus',
    email: 'arhus123@gmail.com',
    role: 'admin',
    phone: '+923233232365',
    jobType: 'Job type of Selected Department',
    organization: 'Paste any department ID from sample',
  },
  {
    name: 'John don',
    email: 'jhondon213@gmail.com',
    role: 'admin',
    phone: '+923233232378',
    jobType: 'Job type of Selected Department',
    organization: 'Paste any department ID from sample',
  },
  {
    name: 'David warner',
    email: 'warner213@gmail.com',
    role: 'admin',
    phone: '+923233232398',
    jobType: 'Job type of Selected Department',
    organization: 'Paste any department ID from sample',
  },
];
