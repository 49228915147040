export const stringData = [
  {
    name: 'None',
    value: 'none',
  },
  {
    name: 'Upper Case',
    value: 'uppercase',
  },
  {
    name: 'Lower Case',
    value: 'lowercase',
  },
  {
    name: 'No Spaces(trim)',
    value: 'trim',
  },
];

export const dateFormats = [
  {
    name: 'mm/dd/yy  10/28/90',
    value: 'mm/dd/yy',
  },
  {
    name: 'dd/mm/yyyy  28/10/1990',
    value: 'dd/mm/yyyy',
  },
  {
    name: 'mm/dd/yyyy  10/28/1990',
    value: 'mm/dd/yyyy',
  },
  {
    name: 'dd/mmm/yyyy  28/Oct/1990',
    value: 'dd/mmm/yyyy',
  },
  {
    name: 'dd/mmm/yy  28/Oct/90',
    value: 'dd/mmm/yy',
  },
  {
    name: 'yyyy/mm/dd  1990/10/28',
    value: 'yyyy/mm/dd',
  },
  {
    name: 'dd-mm-yy  28-10-90',
    value: 'dd-mm-yy',
  },
  {
    name: 'mm-dd-yy  10-28-90',
    value: 'mm-dd-yy',
  },
  {
    name: 'dd-mm-yyyy  28-10-1990',
    value: 'dd-mm-yyyy',
  },
  {
    name: 'mm-dd-yyyy  10-28-1990',
    value: 'mm-dd-yyyy',
  },
  {
    name: 'dd-mmm-yyyy  28-Oct-1990',
    value: 'dd-mmm-yyyy',
  },
  {
    name: 'dd-mmm-yy  28-Oct-90',
    value: 'dd-mmm-yy',
  },
  {
    name: 'yyyy-mm-dd  1990-10-28',
    value: 'yyyy-mm-dd',
  },
  {
    name: 'dd.mm.yy  28.10.90',
    value: 'dd.mm.yy',
  },
  {
    name: 'mm.dd.yy  10.28.90',
    value: 'mm.dd.yy',
  },
  {
    name: 'dd.mm.yyyy  28.10.1990',
    value: 'dd.mm.yyyy',
  },
  {
    name: 'mm.dd.yyyy  10.28.1990',
    value: 'mm.dd.yyyy',
  },
  {
    name: 'dd.mmm.yyyy  28.Oct.1990',
    value: 'dd.mmm.yyyy',
  },
  {
    name: 'dd.mmm.yy  28.Oct.90',
    value: 'dd.mmm.yy',
  },
  {
    name: 'yyyy.mm.dd  1990.10.28',
    value: 'yyyy.mm.dd',
  },
  {
    name: 'ddmmyy  281090',
    value: 'ddmmyy',
  },
  {
    name: 'mmddyy  102890',
    value: 'mmddyy',
  },
  {
    name: 'ddmmyyyy 28101990',
    value: 'ddmmyyyy',
  },
  {
    name: 'mmddyyyy 10281990',
    value: 'mmddyyyy',
  },
  {
    name: 'ddmmmyyyy 28Oct1990',
    value: 'ddmmmyyyy',
  },
  {
    name: 'ddmmmyy 28Oct90',
    value: 'ddmmmyy',
  },
  {
    name: 'yyyymmdd 19901028',
    value: 'yyyymmdd',
  },
];

export const timesFormat = [
  {
    name: 'HH:mm:ss   22:43:05',
    value: 'HH:mm:ss',
  },
  {
    name: 'hh:mm:ss   10:43:05',
    value: 'hh:mm:ss',
  },
  {
    name: 'hh:mm:ss A   10:43:05 PM',
    value: 'hh:mm:ss A',
  },
  {
    name: 'HH:mm   22:43',
    value: 'HH:mm',
  },
  {
    name: 'hh:mm   10:43',
    value: 'hh:mm',
  },
  {
    name: 'hh:mm A   10:43 PM',
    value: 'hh:mm A',
  },
];

export const numericFormat = [
  {
    name: 'Round (0 decimal)  43.0',
    value: '0',
  },
  {
    name: 'Round (1 decimal)  43.1',
    value: '1',
  },
  {
    name: 'Round (2 decimal)  43.12',
    value: '2',
  },
  {
    name: 'Round (3 decimal)  43.123',
    value: '3',
  },
  {
    name: 'Round (4 decimal)  43.1234',
    value: '4',
  },
  {
    name: 'HH:mm   23:59',
    value: 'HH:mm',
  },
  {
    name: 'Hours  24',
    value: 'HH',
  },
  {
    name: 'Minutes 1440',
    value: 'min',
  },
  {
    name: 'Seconds 86400',
    value: 'sec',
  },
  {
    name: 'Milliseconds 8.64e+7',
    value: 'msec',
  },
];
