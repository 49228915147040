import React, { useState, useEffect, memo, useRef, useCallback } from 'react';
import { Typography, Badge, Button, Space, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Camera from './camera';
import Timer from './counter';
import BreakModal from './break';
import PQModal from './performance';
import AssignmentModal from './assignment';
import {
  getActiveShift,
  clockIn,
  clockOut,
  breakIn,
  breakOut,
} from '../../redux/clockinout/action';
import socketHandler from '../../config/socket';
import { CoffeeOutlined } from '@ant-design/icons';
import { autoClockOut } from '../../helpers/autoClockOut';
import { isUnderLocation } from '../../helpers/isUnderLocation';
import { getCurrentPosition } from '../../helpers/getCurrentPosition';
const { Text } = Typography;

const Index = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const {
    coords: coordinates,
    company,
    user,
  } = useSelector((state) => state.auth);
  const { activeShift } = useSelector((state) => state.clockReducer);
  const [camera, setCamera] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [show, setShow] = useState(false);
  const [color, setColor] = useState('primary');
  const [text, setText] = useState('Clock In');
  const imgRcg = company?.image_recognition;
  const PQ = company?.performance_management;
  const coordsRef = useRef(coordinates);
  const answersRef = useRef(null);
  const imgRef = useRef(null);
  const projectRef = useRef(null);
  const geoErrorRef = useRef(null);
  const facialErrorRef = useRef(null);

  const stopLoader = () => {
    setLoader(false);
    answersRef.current = null;
    imgRef.current = null;
    facialErrorRef.current = null;
    geoErrorRef.current = null;
  };

  useEffect(() => {
    let timer = setInterval(() => {
      if (
        !company?.autoClockOut ||
        !activeShift ||
        activeShift?.status == 'allocation'
      ) {
        clearInterval(timer);
      }
      autoClockOut(company, activeShift, dispatch, coordinates);
    }, 60 * 1500);

    return () => {
      clearInterval(timer);
    };
  }, [activeShift]);

  useEffect(() => {
    setLoader(true);
    dispatch(getActiveShift(stopLoader));
    autoClockOut(company, activeShift, dispatch, coordinates);
  }, []);

  useEffect(() => {
    changeStatues();
  }, [activeShift]);

  const fetchUpdates = useCallback((data) => {
    dispatch(getActiveShift(() => {}));
    if (data.id) {
      message.warning(
        `Your shift ${data?.name} has been updated starting at ${moment(
          data?.start
        ).format('DD-MMM, HH:mm')}`
      );
    } else {
      message.warning(
        `You have upcoming shift ${data?.name} starting at ${moment(
          data?.start
        ).format('DD-MMM, HH:mm')}`
      );
    }
  }, []);

  const fetchActive = useCallback(() => {
    dispatch(getActiveShift(() => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('shift', fetchUpdates);
    socketHandler.on('webUpdate', fetchActive);
    return () => {
      socketHandler.off('shift', fetchUpdates);
      socketHandler.on('webUpdate', fetchActive);
    };
  }, []);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const closeCamera = () => {
    setCamera(false);
  };

  const openCamera = () => {
    setCamera(true);
  };

  const openPQ = () => {
    setPerformance(true);
  };

  const closePQ = () => {
    setPerformance(false);
  };

  const closeAssignment = () => {
    setAssignment(false);
    actionProcess();
  };

  const openAssignment = () => {
    setAssignment(true);
  };

  const IsClockOut = () => {
    return (
      activeShift &&
      (activeShift?.status === 'going' || activeShift?.status === 'break')
    );
  };

  const IsNoBreak = () => {
    return activeShift && activeShift?.status === 'going';
  };

  const getImage = (image, error = false) => {
    imgRef.current = image;
    if (error) {
      facialErrorRef.current = true;
    }
    closeCamera();
    if (PQ == 1) {
      openPQ();
    } else {
      // clock in or out
      if (IsClockOut()) {
        clockOutUser();
      } else {
        clockInUser();
      }
    }
  };

  const getAnswers = (data) => {
    answersRef.current = data;
    closePQ();
    // clock in or out
    if (IsClockOut()) {
      clockOutUser();
    } else {
      clockInUser();
    }
  };

  const getProject = (value) => {
    projectRef.current = value;
    closeAssignment();
    actionProcess();
  };

  const getHoursBetween = (start, end) => {
    let timeStart = new Date('01/01/2007 ' + start).getHours();
    let timeEnd = new Date('01/01/2007 ' + end).getHours();
    let hourDiff = timeEnd - timeStart;
    return hourDiff;
  };

  const clockInUser = () => {
    setLoader(true);
    const formData = {};
    let time = new Date();
    if (imgRef.current) {
      formData.clockinImage = imgRef.current;
    }
    if (answersRef.current) {
      const answers = answersRef.current;
      formData.answers = answers;
    }
    if (activeShift && activeShift?.id) {
      formData.allocation = activeShift?.id;
    }
    if (projectRef.current) {
      formData.customer = projectRef.current;
    }
    if (geoErrorRef.current) {
      formData['geoFenceIn'] = false;
    }
    if (facialErrorRef.current) {
      formData['facialIn'] = false;
    }
    if (activeShift?.type == 'schedule') {
      let obj = { ...activeShift?.schedule };
      formData.schedule = obj;
      if (company?.autoClockOut) {
        let hrs = getHoursBetween(obj?.start, obj?.end);
        formData['autoClockOut'] = new Date(
          new Date().setTime(time.getTime() + hrs * 60 * 60 * 1000)
        );
      }
    }
    if (activeShift?.type == 'shift' && company?.autoClockOut) {
      formData['autoClockOut'] = new Date(
        new Date().setTime(
          time.getTime() + activeShift?.shift?.shiftDuration * 60 * 1000
        )
      );
    }
    if (company?.autoClockOut && !activeShift) {
      formData['autoClockOut'] = new Date(
        moment(time).add(company?.autoClockOutTime ?? 8, 'hours')
      );
    }
    formData.clockin = time;
    formData.clockinCoord = coordsRef?.current;
    dispatch(clockIn(formData, stopLoader));
  };

  const clockOutUser = () => {
    setLoader(true);
    const formData = {};
    if (imgRef.current) {
      formData.clockoutImage = imgRef.current;
    }
    if (answersRef.current) {
      const answers = answersRef.current;
      formData.answers = answers;
    }

    if (geoErrorRef.current) {
      formData['geoFenceOut'] = false;
    }
    if (facialErrorRef.current) {
      formData['facialOut'] = false;
    }

    formData.clockout = new Date();
    formData.clockoutCoord = coordsRef?.current;
    formData.allocation = activeShift?.id;
    dispatch(clockOut(formData, stopLoader));
  };

  const breakLoader = (success) => {
    setLoader(false);
  };

  const getReason = async (reason) => {
    closeModal();
    setLoader(true);
    let location = await getCurrentPosition();
    getCoords(location);
    const formData = {};
    formData.allocation = activeShift?.id;
    formData.in = new Date();
    formData.inCoord = location;
    formData.reason = reason;
    dispatch(breakIn(formData, breakLoader));
  };

  const clockInProcess = () => {
    if (imgRcg == 1) {
      openCamera();
    } else {
      if (PQ == 1 && user.role !== 'root') {
        openPQ();
      } else {
        // quick clock In
        clockInUser();
      }
    }
  };

  const clockOutProcess = () => {
    if (imgRcg == 1) {
      openCamera();
    } else {
      if (PQ == 1 && user.role !== 'root') {
        openPQ();
      } else {
        // quick clock In
        clockOutUser();
      }
    }
  };

  const resumeShift = () => {
    setLoader(true);
    const formData = {};
    formData.allocation = activeShift?.id;
    formData.out = new Date();
    formData.outCoord = coordsRef?.current;
    dispatch(breakOut(formData, stopLoader));
  };

  const actionProcess = () => {
    if (!activeShift || activeShift?.status == 'allocation') {
      // clock in user
      clockInProcess();
    } else if (activeShift?.status == 'break') {
      // resume the shift
      resumeShift();
    } else {
      // clock out user
      clockOutProcess();
    }
  };

  const getCoords = (value) => {
    if (value) {
      coordsRef.current = value;
    }
  };

  const handlePress = async () => {
    let location = await getCurrentPosition();
    getCoords(location);
    if (activeShift && activeShift?.shift?.location?.radius) {
      let end = activeShift?.shift?.location?.coordinates;
      let radius = activeShift?.shift?.location?.radius;
      if (!isUnderLocation(location, end, radius)) {
        message.warning('You are Not Under Company location');
        geoErrorRef.current = true;
      }
    } else if (company?.geoFence && user?.organization?.location?.radius) {
      let end = user?.organization?.location?.coordinates;
      let radius = user?.organization?.location?.radius;
      if (!isUnderLocation(location, end, radius)) {
        message.warning('You are Not Under Company location');
        geoErrorRef.current = true;
      }
    }

    if (IsClockOut() || activeShift?.customer) {
      actionProcess();
    } else {
      openAssignment();
    }
  };

  const renderText = () => {
    if (activeShift?.type == 'schedule') {
      return `${
        activeShift?.schedule?.name == 'default'
          ? 'Regular Shift'
          : activeShift?.schedule?.name
      } (
             ${moment(activeShift?.date).format('MMM Do')}
             ${activeShift?.schedule?.start} )`;
    } else {
      return `${activeShift?.shift?.name ?? ''} (
            ${moment(activeShift?.date).format('MMM Do')}
            ${moment(activeShift?.shift?.start).format('HH:mm')} )`;
    }
  };

  const changeStatues = () => {
    if (!activeShift || activeShift?.status == 'allocation') {
      setColor('#3399ff');
      setText('Clock In');
    } else if (activeShift?.status == 'break') {
      setColor('#ff9900');
      setText('Break Out');
    } else {
      setColor('#00cc99');
      setText('Clock Out');
    }
  };

  return (
    <>
      <Space className="pr-5">
        {IsClockOut() && <Timer />}
        {activeShift ? (
          <Badge
            status="processing"
            text={<Text>{renderText()}</Text>}
            className="pl-2"
            color="green"
          />
        ) : (
          <Text>No shift Today</Text>
        )}
        <Button
          onClick={handlePress}
          loading={loader}
          disabled={loader}
          style={{
            background: color,
            borderColor: color,
            color: '#fff',
          }}
        >
          {text}
        </Button>
        {IsNoBreak() && (
          <Button icon={<CoffeeOutlined />} onClick={openModal}>
            Take a Break
          </Button>
        )}
      </Space>
      <BreakModal show={show} setClose={closeModal} getReason={getReason} />
      <AssignmentModal
        getProject={getProject}
        show={assignment}
        setClose={closeAssignment}
      />
      <PQModal
        getAnswers={getAnswers}
        IsClockOut={IsClockOut}
        show={performance}
        setClose={closePQ}
      />
      <Camera getImage={getImage} isShow={camera} closeModal={closeCamera} />
    </>
  );
};

export default memo(Index);
