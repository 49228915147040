import React, { useState, useEffect, memo, useRef } from 'react';
import {
  Modal,
  Drawer,
  Form,
  Input,
  Space,
  message,
  Select,
  DatePicker,
  AutoComplete,
  Checkbox,
  List,
} from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  createTask,
  updateTasks,
  deleteTasks,
  multiCreateTask,
} from '../../redux/tasks/action';
import ActivityFilter from '../timeSheets/activityFilter';
import TaskItem from './taskItem';
//import DatalistInput from 'react-datalist-input';
import { getAllLocations } from '../../redux/shifts/action';
import { getAllDepartments } from '../../redux/members/action';

const { Option } = Select;
const { TextArea } = Input;
var _location_ = '';

export default memo(function Index({ show, handleOk, setClose, activeTasks }) {
  const dispatch = useDispatch();
  const { allLocations } = useSelector((state) => state.shiftsReducer);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [form] = Form.useForm();

  const [loader, setLoader] = useState(false);
  const [activity, setActivity] = useState(null);
  const [locLoader, setLocLoader] = useState(false);
  const [item, setItem] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [oLoader, setOLoader] = useState(false);
  const [_location, _setLocation] = useState('');
  const [dept, setDept] = useState(false);
  const [approvall, settApprovall] = useState(false);
  const [selectNo, setSelectNo] = useState(0);

  const locRef = useRef(null);
  const { allDept } = useSelector((state) => state.membersReducer);
  useEffect(() => {
    if (allDept.length === 0) {
      setLocLoader(true);
      dispatch(getAllDepartments(() => setLocLoader(false)));
    }
  }, []);

  useEffect(() => {
    if (activeTasks) {
      setTasks(activeTasks);
    } else setTasks(null);
  }, [activeTasks]);

  useEffect(() => {
    if (allLocations.length == 0) {
      setLocLoader(true);
      dispatch(getAllLocations(() => setLocLoader(false)));
    }
  }, []);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      message.success(item ? 'Successfully Updated!' : 'Successfully Created!');
      setItem(null);
      handleOk();
    }
  };

  const createTaskFunc = (values) => {
    if (dept) {
      let _employees = allEmployees.filter((a) => {
        if (a.organization == values.department_id) {
          return a;
        }
      });
      const tt = allLocations.find((o) => o.name === location)?.id;

      var temp = {
        title: values.title,
        description: values.description,
        startTime: values.startTime,
        endTime: values.endTime,
        createdBy: currentUser.id,
        assignTo: _employees.map((_) => _.id),
        user_id: values.user_id,
        isUpdated: true,
        priority: values.priority,
        isVerifiable: approvall,
        organization: values.department_id,
        company: currentUser?.company_id?.id,
      };
      if (tt) {
        temp.location = tt;
      }

      if (values.project) {
        temp.project;
      }
      setLoader(true);
      dispatch(createTask(temp, stopLoader));
    } else {
      setLoader(true);
      var _users = [];
      const tt = allLocations.find((o) => o.name === values.location)?.id;
      if (currentUser?.role == 'user') {
        let obj = {
          ...values,
          user_id: currentUser?.id,
          createdBy: currentUser.id,
          assignTo: [currentUser?.id],
          isVerifiable: false,
          organization: currentUser?.organization?.id,
          company: currentUser?.company_id?.id,
        };
        const tt = allLocations.find((o) => o.name === obj.location)?.id;

        if (tt) {
          obj.location = tt;
        }

        if (values.project) {
          temp.project;
        }

        dispatch(createTask(obj, stopLoader));
        return;
      }

      var temp = {
        title: values.title,
        description: values.description,
        startTime: values.startTime,
        endTime: values.endTime,
        createdBy: currentUser.id,
        assignTo: values.assignTo,
        user_id: values.user_id ? values.user_id : values.assignTo[0],
        isUpdated: true,
        priority: values.priority,
        isVerifiable: approvall,
        organization: values.department_id,
        company: currentUser?.company_id?.id,
      };

      if (tt) {
        temp.location = tt;
      }

      if (values.project) {
        temp.project;
      }

      dispatch(createTask(temp, stopLoader));
    }

    //find all members

    return;
  };

  const onChangeActivity = (value) => {
    setActivity(value);
  };

  const find = (location) => {
    return allLocations.find((o) => o.name === __test).id;
  };
  /**
   * this is when task type is changed
   * @param {event} e
   */
  function onChange(e) {
    setDept(e.target.checked);
  }

  function onChangeApproval(e) {
    settApprovall(e.target.checked);
  }

  function handleChange(value) {}

  const editTask = (item) => {
    setItem(item);
    form.setFieldsValue({
      title: item?.title,
      startTime: moment(item?.startTime),
      endTime: moment(item?.endTime),
      user_id: item?.user_id?.id,
      location: item?.location,
    });
  };

  const deleteTask = (task, cb) => {
    dispatch(
      deleteTasks(task, () => {
        setTasks([...tasks].filter((obj) => obj.id !== task.id));
        cb();
      })
    );
  };

  const handleClose = () => {
    setItem(null);
    setClose();
    form?.resetFields();
  };
  function Func() {
    var input, filter, a;

    // take user input
    input = document.getElementById('userInput');

    // convert the input to upper case letter for easy comparison
    filter = input.value.toUpperCase();

    div = document.getElementById('Dropdown');

    // get all the options as a list
    a = div.getElementsByTagName('option');

    // iterate through the entire list and output relevant results if found
    for (var i = 0; i < a.length; i++) {
      txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  }

  return (
    <Drawer
      title={'Task Management'}
      visible={show}
      onCancel={handleClose}
      closable={false}
      width={window.innerWidth > 900 ? 520 : window.innerWidth}
      footer={null}
      zIndex={1050}
    >
      {tasks && !item ? (
        <List>
          {tasks?.map((task, i) => {
            return (
              <TaskItem
                task={task}
                key={i}
                editTask={editTask}
                deleteTask={deleteTask}
              />
            );
          })}
        </List>
      ) : (
        <Row>
          <Col md={12} lg={12}>
            <Form layout="vertical" form={form} onFinish={createTaskFunc}>
              <Form.Item
                name="title"
                label="Task Title"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input Title',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="ie Pick up the cafe after closing"
                />
              </Form.Item>

              <Form.Item
                name="description"
                label="Task Description"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input Title',
                  },
                ]}
              >
                <TextArea size="large" placeholder="Desprition of the task" />
              </Form.Item>

              <Checkbox className="mb-3" onChange={onChange}>
                Is task departmental
              </Checkbox>

              {currentUser.role !== 'user' && (
                <Checkbox className="mb-3" onChange={onChangeApproval}>
                  Task requires Approval
                </Checkbox>
              )}

              <Form.Item name="priority" label="Priority" defaultValue="medium">
                <Select size="large" className="w-100">
                  <Option value="high">High</Option>
                  <Option value="medium">Medium</Option>
                  <Option value="low">Low</Option>
                </Select>
              </Form.Item>

              {dept && currentUser?.role !== 'user' && (
                <Form.Item
                  name="department_id"
                  label="Assign To Department"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select a Department',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Department to be assigned"
                    size="large"
                    className="w-100"
                  >
                    {allDept.map((item, i) => {
                      return (
                        <Option key={i} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              {!dept && currentUser?.role !== 'user' && (
                <Form.Item
                  name="assignTo"
                  label="Assign To"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select User',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select User to be assigned"
                    size="large"
                    className="w-100"
                    mode="tags"
                    onChange={(value) => {
                      setSelectNo(value.length);
                    }}
                  >
                    {[{ ...currentUser }, ...allEmployees]?.map((item, i) => {
                      return (
                        <Option key={i} value={item?.id}>
                          {item?.name == currentUser?.name ? 'Me' : item?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              {selectNo > 1 && (
                <Form.Item name="user_id" label="Task Lead (optional)">
                  <Select
                    placeholder="Select User to be assigned"
                    size="large"
                    className="w-100"
                  >
                    {[{ ...currentUser }, ...allEmployees]?.map((item, i) => {
                      return (
                        <Option key={i} value={item?.id}>
                          {item?.name == currentUser?.name ? 'Me' : item?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              {dept && (
                <Form.Item name="user_id" label="Task Lead (optional)">
                  <Select
                    placeholder="Select User to be assigned"
                    size="large"
                    className="w-100"
                    onChange={(value) => {}}
                  >
                    {[{ ...currentUser }, ...allEmployees]?.map((item, i) => {
                      return (
                        <Option key={i} value={item?.id}>
                          {item?.name == currentUser?.name ? 'Me' : item?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name="startTime"
                label="Start Date"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input Start Date',
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  format="DD-MM-YYYY HH:mm"
                  placeholder="Task Start Time"
                  className="w-100"
                  showTime={{
                    defaultValue: moment('00:00', 'HH:mm'),
                  }}
                />
              </Form.Item>

              <Form.Item
                name="endTime"
                label="End Date"
                required
                rules={[
                  {
                    required: true,
                    message: 'Please Input End Date',
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  format="DD-MM-YYYY HH:mm"
                  placeholder="End Start Time"
                  className="w-100"
                  showTime={{
                    defaultValue: moment('00:00', 'HH:mm'),
                  }}
                />
              </Form.Item>

              <Form.Item
                name="location"
                label="Location (optional)"
                required={false}
              >
                <AutoComplete
                  size="large"
                  options={allLocations.map((item) => {
                    return { value: item.name, label: item.name };
                  })}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  placeholder="input here"
                ></AutoComplete>
              </Form.Item>

              <Form.Item
                name="project"
                label="Assignment (optional)"
                required={false}
              >
                <ActivityFilter
                  pickerStyle={{
                    width: '100%',
                  }}
                  onChange={onChangeActivity}
                  size="large"
                />
              </Form.Item>

              <Space className="mt-3 float-left">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Cancel
                </button>

                <button
                  disabled={loader}
                  className="btn btn-primary"
                  htmlType="submit"
                >
                  {loader ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : item ? (
                    'Update'
                  ) : (
                    'Add'
                  )}
                </button>
              </Space>
            </Form>
          </Col>
        </Row>
      )}
    </Drawer>
  );
});
