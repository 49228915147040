import * as constants from './constants';

const initialState = {
  customers: [],
  allCustomers: [],
  totalResults: 0,
  customerTypes: ['Customer', 'Project', 'Other'],
  initial: true,
};

function customersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_CUSTOMERS: {
      return {
        ...state,
        customers: payload.results,
        totalResults: payload.totalResults,
        initial: false,
      };
    }

    case constants.GET_CUSTOMERS_ALL: {
      return {
        ...state,
        allCustomers: payload,
      };
    }
    case constants.ADD_CUSTOMER: {
      return {
        ...state,
        customers: [payload, ...state.customers],
      };
    }

    default: {
      return state;
    }
  }
}

export default customersReducer;
