import React, { useState, useEffect } from 'react';
import {
  Space,
  List,
  Checkbox,
  Typography,
  Spin,
  Popconfirm,
  Button,
  Badge,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ViewTask from './taskDetails';
import { updateTaskStatus } from '../../redux/tasks/action';
import { styles } from './style';
const { Text } = Typography;

function Index({ task, editTask, deleteTask }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [dloader, setDLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  let [check, setChecked] = useState(task?.status == 'completed');

  useEffect(() => {
    setChecked(task?.status == 'completed');
  }, [task]);

  const completeTask = () => {
    setLoader(true);
    let params = { taskId: task.id };
    let status = !check == true ? 'completed' : 'pending';
    dispatch(
      updateTaskStatus(params, { status }, { ...task, status }, () => {
        setLoader(false);
        setChecked(!check);
      })
    );
  };

  const confirm = () => {
    setDLoader(true);
    deleteTask(task, () => {
      setDLoader(false);
      setVisible(false);
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const showPopUp = () => {
    setVisible(true);
  };
  return (
    <List.Item size="small">
      <List.Item.Meta
        description={
          check ? (
            <Text delete italic>
              {task?.title}
            </Text>
          ) : (
            <Text>{task?.title}</Text>
          )
        }
      />
      <Space>
        <div>
          {loader ? (
            <Spin size="small" />
          ) : (
            <Popconfirm
              title="Are you sure to Complete this task?"
              onConfirm={completeTask}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Checkbox checked={check}>
                {check ? (
                  <Badge
                    className="site-badge-count-109"
                    count={'completed'}
                    style={{ backgroundColor: '#52c41a' }}
                  />
                ) : (
                  <Badge
                    className="site-badge-count-109"
                    count={
                      task?.status == 'pending' ? 'in pending' : 'in progress'
                    }
                    style={{ backgroundColor: '#52c41a' }}
                  />
                )}
              </Checkbox>
            </Popconfirm>
          )}
        </div>
        <Text style={styles.date}>
          {moment(task?.endTime).format('MMM DD, YYYY')}
        </Text>

        <Button
          type="primary"
          className="px-2"
          onClick={() => editTask(task)}
          disabled={check === true}
        >
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Are you sure to delete this task?"
          onConfirm={confirm}
          visible={visible}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ loading: dloader }}
        >
          <DeleteOutlined onClick={showPopUp} />
        </Popconfirm>
      </Space>
    </List.Item>
  );
}

export default Index;
