import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getAllDepartments } from '../../redux/members/action';
const { Option } = Select;

function DepartmentFilter(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { allDept } = useSelector((state) => state.membersReducer);

  useEffect(() => {
    if (allDept.length === 0) {
      setLoading(true);
      dispatch(getAllDepartments(() => setLoading(false)));
    }
  }, []);

  return (
    <Select
      defaultValue="Select Department"
      style={props.pickerStyle}
      loading={loading}
      size={props.size ? props.size : 'medium'}
      onChange={(value) => {
        props.onSelectDpt?.(value);
      }}
      className={props.className}
      dropdownStyle={{ zIndex: 234234234 }}
      getPopupContainer={(triggerNode) => {
        return triggerNode.parentNode;
      }}
    >
      <Option value={null}>All</Option>
      {allDept.map((item, i) => {
        return (
          <Option key={i} value={item.id}>
            {item.name}
          </Option>
        );
      })}
    </Select>
  );
}

export default DepartmentFilter;
