import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Card,
  Button,
  Divider,
  List,
  Avatar,
  Skeleton,
  Space,
  Tooltip,
  Empty,
  Checkbox,
  Table,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import socketHandler from '../../config/socket';
import CreateModal from './addTask';
import ExportModal from './exportTask';

import MyTask from './myTasks';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../redux/shifts/action';
import { getTasks, getAllTasks } from '../../redux/tasks/action';
import { createTask, updateTasks, deleteTasks } from '../../redux/tasks/action';

import DepartmentFilter from '../../components/departmentFilter';
import Item from './item';
import { styles } from './style';
const { Link, Text } = Typography;

function Index() {
  const dispatch = useDispatch();
  const { tasks, initial, selfTasks, allTasks } = useSelector(
    (state) => state.tasks
  );
  const { user } = useSelector((state) => state.auth);
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const [show, setShow] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [dept, setDpt] = useState(null);
  const [loader, setLoader] = useState(false);

  const [activeTasks, setActiveTasks] = useState(null);
  const [mine, setMine] = useState(false);
  const depRef = useRef(null);
  const stopLoader = () => {
    setLoader(false);
  };
  const fetchTasks = () => {
    setLoader(true);
    dispatch(getTasks({ organization: dept }, stopLoader));
  };
  useEffect(() => {
    if (allEmployees.length == 0) {
      dispatch(getAllEmployees(() => {}, dept));
    }
    if (initial) {
      fetchTasks();
    }
  }, []);

  const fetchUpdates = useCallback(() => {
    dispatch(getTasks({ organization: depRef?.current }, () => {}));
  }, []);

  useEffect(() => {
    socketHandler.on('taskComplete', fetchUpdates);
    return () => {
      socketHandler.off('taskComplete', fetchUpdates);
    };
  }, []);

  const refreshData = () => {
    dispatch(getTasks({ organization: dept }, () => {}));
  };
  /**
   * Custom
   */
  const renderColumn4 = (renderTitle, renderAvatar) => {
    const result = [
      {
        title: 'Members',
        dataIndex: 'members',
        render: renderAvatar,
        align: 'left',
        fixed: true,
        width: 200,
      },
      {
        title: renderTitle('Complete'),
        dataIndex: 'complete',
        align: 'center',
      },
      {
        title: renderTitle('Ongoing'),
        dataIndex: 'worked',
        align: 'center',
      },
      {
        title: renderTitle('Pending'),
        dataIndex: 'break',
        align: 'center',
      },
    ];
    return result;
  };
  const renderTitle = (title) => {
    return (
      <div>
        <Text strong className="d-block text-light">
          {title}
        </Text>
      </div>
    );
  };
  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space align="center" className="c-pointer">
          <Avatar
            style={styles.avatar}
            src={
              user?.profilePic ??
              'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
            }
            size="medium"
          />
          <Text strong className="pl-2 font-weight-bold">
            {user?.name}
          </Text>
        </Space>
      );
    }
  };

  const editTask = (item) => {
    setItem(item);
    form.setFieldsValue({
      title: item?.title,
      startTime: moment(item?.startTime),
      endTime: moment(item?.endTime),
      user_id: item?.user_id?.id,
    });
  };

  const deleteTask = (item, cb) => {
    dispatch(
      deleteTasks(item, () => {
        fetchTasks();
        cb();
      })
    );
  };

  /**
   * End Custom
   */

  const refreshPage = () => {
    setLoader(true);
    dispatch(getTasks({ organization: dept }, stopLoader));
  };

  const setClose = () => {
    setActiveTasks(null);
    setShow(false);
  };

  const handleOk = () => {
    setClose();
    refreshData();
  };

  const openTask = () => {
    setShow(true);
  };

  const setExportClose = () => {
    setShowExport(false);
  };

  const handleOkExport = () => {
    setClose();
  };

  const openTaskExport = () => {
    setShowExport(true);
  };

  const onChangeDept = (dept) => {
    depRef.current = dept;
    setDpt(dept);
    setLoader(true);
    dispatch(getAllEmployees(() => {}, dept));
    dispatch(getTasks({ organization: dept }, stopLoader));
  };

  const handleTasks = (tasks) => {
    setActiveTasks(tasks);
    openTask();
  };

  return (
    <React.Fragment>
      <Row>
        {user?.role !== 'user' && (
          <div style={styles.buttonUpload}>
            <DepartmentFilter
              pickerStyle={{
                width: 215,
              }}
              onSelectDpt={onChangeDept}
            />
          </div>
        )}
        <Button
          type="primary"
          className="bg-primary border-primary"
          icon={<PlusOutlined />}
          onClick={openTask}
          style={styles.buttonUpload}
        >
          Add Task
        </Button>
        <Button
          type="primary"
          className="bg-primary border-primary"
          icon={<ExportOutlined />}
          onClick={openTaskExport}
          style={styles.buttonUpload}
        >
          Export Task
        </Button>
        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshPage} style={styles.buttonUpload}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Row>
      <TableRB responsive className="mt-4 table-curved">
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell"></th>
            <th style={styles.centerAlign}>Task</th>
            <th style={styles.centerAlign} className="d-none d-sm-table-cell">
              Start In
            </th>
            <th style={styles.centerAlign} className="d-none d-sm-table-cell">
              End Out
            </th>
            <th style={styles.centerAlign}>Total </th>
            <th style={styles.centerAlign} className="d-none d-sm-table-cell">
              Time Used
            </th>
            <th style={styles.centerAlign}>Status </th>
            <th style={styles.centerAlign} className="d-none d-sm-table-cell">
              Time Left
            </th>

            <th className="d-none d-sm-table-cell">Edit Time</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(tasks)
            .reduce((a, b) => {
              return [...Object.values(a), ...Object.values(b)];
            }, [])
            .map((arr, index) => {
              return (
                <Item
                  item={arr}
                  key={index}
                  refreshData={refreshData}
                  deleteTask={deleteTask}
                />
              );
            })}
        </tbody>
      </TableRB>

      {/* <Card className="tmb mt-3">
        
          <List loading={loader}>
            {Object.keys(tasks).length > 0 ? (
              Object.keys(tasks).map((key) => {



                return (
                  <List.Item key={key}>
                    <Skeleton avatar title={false} loading={loader} active>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={tasks?.[key]?.[0]?.user_id?.profilePic}
                          />
                        }
                        title={<a>{tasks?.[key]?.[0]?.user_id?.name}</a>}
                        description={
                          <Space>
                            <Text type="secondary" style={styles.jobTitle}>
                              {tasks?.[key]?.[0]?.user_id?.jobType ??
                                'Root User'}
                            </Text>
                            <Link onClick={() => handleTasks(tasks?.[key])}>
                              Total Tasks {tasks?.[key]?.length}
                            </Link>
                          </Space>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                );
              })
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
                description={<span>No Tasks Created Yet!</span>}
              >
                <Button type="primary" onClick={openTask}>
                  Create Now
                </Button>
              </Empty>
            )}
          </List>
      
      </Card> */}
      <CreateModal
        show={show}
        setClose={setClose}
        handleOk={handleOk}
        activeTasks={activeTasks}
      />

      <ExportModal
        show={showExport}
        setClose={setExportClose}
        handleOk={handleOkExport}
      />
    </React.Fragment>
  );
}

export default Index;
