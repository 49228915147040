import moment from 'moment';
export const isSubscribed = (user) => {
  let start = moment(user?.createdAt);
  let end = moment();
  let days = 14 - end.diff(start, 'days');
  if ((days == 0 || days < 0) && !user.subscription_plan) {
    return false;
  } else {
    return true;
  }
};
