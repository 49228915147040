import React, { memo } from 'react';
import { Button, Form, Select, Space, Collapse, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'react-bootstrap';
import PickerTime from 'react-time-picker';
import moment from 'moment';

let { Panel } = Collapse;
let { Text } = Typography;
const { Option, OptGroup } = Select;

function Index({ item, tasks, weeks, setWeeks }) {
  const getTasksTypes = (name) => {
    return tasks.filter((item) => item?.type == name);
  };

  const add = () => {
    setWeeks({
      ...weeks,
      [item?.date]: [
        ...weeks[item?.date],
        {
          totalTime: '',
          breakTime: '',
          overTime: '',
          customer: '',
        },
      ],
    });
  };

  const remove = () => {
    let updateItems = {
      ...weeks,
      [item?.date]: [
        ...weeks[item?.date].filter(
          (_, index) => index !== weeks[item?.date].length - 1
        ),
      ],
    };
    setWeeks(updateItems);
  };

  const handleInput = (value, name, index) => {
    let nItems = {
      ...weeks,
    }[item?.date];
    nItems[index][name] = value;
    setWeeks({
      ...weeks,
      [item?.date]: nItems,
    });
  };

  return (
    <Col sm={12} className="mt-3">
      <Collapse expandIconPosition={'left'}>
        <Panel
          header={
            <Text>
              {item?.name} ({moment(item?.date).format('DD-MM-YYYY')})
            </Text>
          }
          key="1"
        >
          {weeks?.[item?.date]?.map((data, index) => {
            return (
              <Space wrap key={index}>
                <Form.Item label="Activity">
                  <Select
                    size={'large'}
                    placeholder="Select Activity"
                    className="fullWidth"
                    onChange={(value) => handleInput(value, 'customer', index)}
                  >
                    {getTasksTypes('Customer').length > 0 && (
                      <OptGroup label="Customer">
                        {getTasksTypes('Customer').map((obj) => {
                          return <Option key={obj?.id}>{obj?.name}</Option>;
                        })}
                      </OptGroup>
                    )}
                    {getTasksTypes('Project').length > 0 && (
                      <OptGroup label="Project">
                        {getTasksTypes('Project').map((obj) => {
                          return <Option key={obj?.id}>{obj?.name}</Option>;
                        })}
                      </OptGroup>
                    )}
                    {getTasksTypes('Other').length > 0 && (
                      <OptGroup label="Other">
                        {getTasksTypes('Other').map((obj) => {
                          return <Option key={obj?.id}>{obj?.name}</Option>;
                        })}
                      </OptGroup>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item label="Work Hours">
                  <PickerTime
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                    format="H:mm"
                    minutePlaceholder="mm"
                    hourPlaceholder="HH"
                    onChange={(value) => handleInput(value, 'totalTime', index)}
                  />
                </Form.Item>
                <Form.Item label="Break Hours">
                  <PickerTime
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                    format="H:mm"
                    minutePlaceholder="mm"
                    hourPlaceholder="HH"
                    onChange={(value) => handleInput(value, 'breakTime', index)}
                  />
                </Form.Item>
                <Form.Item label="Over Time">
                  <PickerTime
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                    format="H:mm"
                    minutePlaceholder="mm"
                    hourPlaceholder="HH"
                    onChange={(value) => handleInput(value, 'overTime', index)}
                  />
                </Form.Item>
              </Space>
            );
          })}
          <Row>
            <Col lg={weeks?.[item?.date]?.length == 0 ? 12 : 6}>
              <Button
                type="dashed"
                onClick={add}
                className="fullWidth"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </Col>
            {weeks?.[item?.date]?.length > 0 && (
              <Col lg={6}>
                <Button
                  type="dashed"
                  className="fullWidth"
                  danger
                  onClick={remove}
                  icon={<MinusCircleOutlined />}
                >
                  Remove
                </Button>
              </Col>
            )}
          </Row>
        </Panel>
      </Collapse>
    </Col>
  );
}

export default memo(Index);
