export const styles = {
  buttonUpload: {
    marginLeft: 20,
    marginTop: 10,
  },
  underline: {
    textDecoration: 'underline',
  },
  date: {
    fontSize: 12,
    color: 'gray',
  },
  jobTitle: {
    fontSize: 11,
    color: 'gray',
  },

  item: {
    borderLeft: '5px solid #F79642',
    boxShadow: '-2px 2px 13px 2px rgba(207,207,207,0.75)',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    paddingTop: 12,
    paddingBottom: 12,
  },
  removeIcon: {
    color: '#F79642',
    fontSize: 25,
  },

  avatar: { color: '#f56a00', backgroundColor: '#fde3cf' },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameText: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    fontSize: 17,
    color: '#fff',
  },
  date: {
    fontSize: 12,
  },
  reason: {
    fontSize: 13,
  },
  userAvatar: {
    width: 71,
    height: 71,
  },
  userName: {
    fontWeight: '500',
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
  },
  avatarCont: {
    width: 90,
    height: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalCont: {
    marginLeft: 20,
    borderRadius: 8,
    marginTop: 10,
    width: 200,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '-2px 2px 13px 2px rgba(207,207,207,0.75)',
  },
  totalValue: {
    textAlign: 'center',
    fontSize: 34,
    fontWeight: 500,
    color: '#ffff',
    marginBottom: 0,
    fontFamily: 'Roboto',
  },
  valueType: {
    marginBottom: 0,
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontWeight: 500,
  },

  totalComCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 25,
  },
  switchCont: {
    display: 'flex',
    flexGrow: 'unset',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    borderLeft: '5px solid #F79642',
    boxShadow: '-2px 2px 13px 2px rgba(207,207,207,0.75)',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    paddingTop: 12,
    paddingBottom: 12,
  },
  removeIcon: {
    color: '#F79642',
    fontSize: 25,
  },

  centerAlign: {
    textAlign: 'center',
    backgroundColor: 'red',
  },

  signImg: {
    width: '100%',
  },
  signImgCont: {
    padding: 5,
    border: '1px solid #F0F0F0',
    marginTop: 15,
  },

  downloadCont: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  downloadIcon: {
    fontSize: 25,

    paddingLeft: 10,
    paddingRight: 10,
  },
  failedText: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    fontSize: 16,
    color: 'red',
  },
  failedTextGeo: {
    fontSize: 15,
    color: 'red',
  },
};
