import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { getPQ } from '../../redux/tour/action';

const checklist = [
  {
    name: 'Creating My First Department',
    value: 'department',
  },
  {
    name: 'Invite My Team Members and Supervisors',
    value: 'member',
  },
  {
    name: 'Creating my performance questions',
    value: 'performance',
  },
  {
    name: 'Setting my Preferences for Performance and Image Recognition',
    value: 'preference',
  },

  // {
  //   name: 'All set!',
  //   value: 'all',
  // },
];

function ProgressList() {
  const { user } = useSelector((state) => state.auth);
  const [perf, setPerf] = useState(0);

  useEffect(() => {
    getPQ((docs) => {
      setPerf(docs);
    });
  }, []);

  const getCheckStatus = (id) => {
    if (id === 'department') {
      return user?.descendants_depts?.length || false;
    }
    if (id === 'member') {
      return user?.descendants_users?.length || false;
    }
    if (id === 'performance') {
      return perf || false;
    }

    return false;
  };

  return (
    <div className="checkCont">
      <div>
        <p className="checkHeading">Get started with gigsasa</p>
        <p className="checkSubHeading">
          In few clicks you will be a gigsasa pro
        </p>
      </div>

      <div>
        {checklist.map((item, i) => {
          return (
            <div key={i} className="tourCheckBox">
              <Checkbox checked={getCheckStatus(item.value)} value={item.value}>
                {item.name}
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProgressList;
