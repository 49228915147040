export const styles = {
  stepperContainer: { height: '100vh', background: '#ffff', marginTop: 60 },
  logo: {
    height: 50,
    width: 50,
  },
  container: {
    background: '#ffff',
  },
  input: {
    height: 50,
    borderRadius: 5,
    borderWidth: 2,
  },
  button: {
    height: 50,
    borderRadius: 5,
  },
  desc: {
    fontSize: 18,
  },
  fullHeight: {
    height: '100vh',
  },
  smallIcon: {
    height: 50,
    width: 50,
    boxShadow: '-2px 5px 27px 9px #F0F0F0',
    borderRadius: 50 / 2,
  },

  line: {
    height: 2,
    background: '#d3d3d3',
    width: '40%',
  },
  loginButton: {
    height: 50,
    borderRadius: 5,
    width: '100%',
  },
  orText: {
    fontSize: 18,
  },
  verifyButton: {
    height: 300,
    width: 300,
    borderRadius: 10,
    border: '3px dashed #f79425',
  },
  cameraIcon: {
    fontSize: 70,
    color: '#f79425',
  },
};
