import React, { useState, memo, useEffect } from 'react';
import { Modal, Form, Input, Space, message, Select } from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer, getCustomers } from '../../redux/customers/action';
import { getAllDepartments } from '../../redux/members/action';

export default memo(function Index({ show, handleOk, setClose }) {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { allDept } = useSelector((state) => state.membersReducer);
  const { customerTypes } = useSelector((state) => state.customersReducer);

  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);

  const getAllDepartmentsFun = () => {
    setDLoader(true);
    dispatch(getAllDepartments(() => setDLoader(false)));
  };

  useEffect(() => {
    getAllDepartmentsFun();
  }, []);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      message.success('Successfully Created!');
      handleOk();
      dispatch(getCustomers());
    }
  };
  const createCustomerFunc = (values) => {
    const { name, type, departments } = values;
    setLoader(true);
    const customerDetails = {
      name,
      type,
      departments,
      createdBy: user.id,
      company: user?.company_id?.id,
    };

    dispatch(addCustomer(customerDetails, stopLoader));
  };

  return (
    <Modal
      title="Create New Customer or Project"
      visible={show}
      onCancel={setClose}
      width={700}
      footer={null}
    >
      <Row>
        <Col md={12} lg={12}>
          <Form layout="vertical" form={form} onFinish={createCustomerFunc}>
            <Form.Item
              name="name"
              label="Name"
              required
              tooltip="This is a required field"
              rules={[
                {
                  required: true,
                  message: 'Please Input Name',
                },
              ]}
            >
              <Input size="large" placeholder="Enter a Name" />
            </Form.Item>

            <Form.Item
              name="type"
              label="Type"
              tooltip="This is a required field"
              rules={[
                {
                  required: true,
                  message: 'Please Select Type',
                },
              ]}
            >
              <Select
                placeholder="Select Type"
                size="large"
                className="w-100"
                loading={dLoader}
                disabled={dLoader}
              >
                {customerTypes?.map((item, i) => {
                  return (
                    <Option key={i} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {allDept?.length > 0 && (
              <Form.Item name="departments" label="Departments">
                <Select
                  mode="multiple"
                  size="large"
                  className="w-100"
                  placeholder="Select Departments"
                  loading={dLoader}
                  disabled={dLoader}
                >
                  {allDept?.map((item, i) => {
                    return (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Space className="mt-3 float-right">
              <button
                type="button"
                className="btn btn-light"
                onClick={setClose}
              >
                Cancel
              </button>

              <button
                className="btn btn-primary"
                htmlType="submit"
                disabled={loader}
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Create'
                )}
              </button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
});
