import React, { useState, useEffect } from 'react';
import { Card, Typography, Avatar, Tooltip, Popconfirm } from 'antd';
import {
  CalendarOutlined,
  FieldTimeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import CreateModal from './createSchedules';
import { useDispatch } from 'react-redux';
import { deleteSchedular } from '../../redux/schedular/action';
import { styles } from './style';
import { labels } from './data';
const { Text } = Typography;

function Index({ item }) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const getTime = ({ start, end }) => {
    const timeStart = new Date('01/01/2007 ' + start).getHours();
    const timeEnd = new Date('01/01/2007 ' + end).getHours();
    const hourDiff = timeEnd - timeStart;
    return hourDiff;
  };

  useEffect(() => {
    setData(item);
  }, [item]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getTotalHours = () => {
    let total = 0;
    labels?.map((day) => {
      if (item.hasOwnProperty(day.key)) {
        total = total + getTime(item[day.key]);
      }
    });
    return total;
  };

  const stopLoader = () => {
    setLoader(false);
  };

  const confirm = () => {
    setLoader(true);
    dispatch(deleteSchedular(item.id, stopLoader));
  };
  return (
    <div className="col-md-6 col-lg-3" style={{ minWidth: 350 }}>
      <Card className="rounded border border-primary mb-4">
        <div className="pb-3 d-flex flex-row align-items-center justify-content-between">
          <Text>{item?.name}</Text>
          <div>
            <EditOutlined
              style={styles.icon}
              className="mr-2 cursor-pointer"
              onClick={showModal}
            />

            <Popconfirm
              title="Are you sure to delete this schedule?"
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loader }}
            >
              <DeleteOutlined style={styles.icon} />
            </Popconfirm>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <CalendarOutlined style={styles.icon} className="mr-2" />

          {labels.map((day, i) => {
            return (
              <Tooltip
                key={i}
                title={
                  item.hasOwnProperty(day.key)
                    ? `
              ${item[day.key].start} to  ${item[day.key].end}
              `
                    : 'No work'
                }
                placement="top"
              >
                <Avatar
                  className="ml-1 c-pointer"
                  style={{
                    backgroundColor: item.hasOwnProperty(day.key)
                      ? '#1890ff'
                      : '#d3d3d3',
                  }}
                >
                  {day.label}
                </Avatar>
              </Tooltip>
            );
          })}
        </div>
        <div className="d-flex flex-row align-items-center pt-4 justify-content-between">
          <div>
            <FieldTimeOutlined style={styles.icon} />
            <h3 className="d-inline text-secondary pl-2">
              {getTotalHours()} Hrs
            </h3>
          </div>
          {/* <Avatar.Group size="small">
            {item?.users?.slice(0, 5)?.map((user) => {
              if (user?.profilePic) {
                return (
                  <Tooltip title={`${user?.name} (${user?.jobType})`}>
                    <Avatar src={user?.profilePic} />
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={`${user?.name} (${user?.jobType})`}>
                    <Avatar
                      style={{ backgroundColor: "#f56a00" }}
                      className="text-capitalize"
                    >
                      {user?.name[0]}
                    </Avatar>
                  </Tooltip>
                );
              }
            })}
          </Avatar.Group> */}
        </div>
      </Card>
      <CreateModal
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
        edit={data}
      />
    </div>
  );
}

export default Index;
