import React, { useState } from 'react';

import { Divider, DatePicker, Button, Space, Modal, Select } from 'antd';
import DepartmentFilter from '../../components/departmentFilter';

function ExportDataModal(props) {
  const [dept, setDept] = useState(null);

  const onChangeDept = (value) => {
    setDept(value);
  };

  return (
    <Modal
      title="Select Department"
      visible={props.visible}
      footer={null}
      onCancel={() => props.onClose?.()}
    >
      <DepartmentFilter onSelectDpt={onChangeDept} className="fullWidth" />
      <div className="mt-4" />
      <Button
        type="primary"
        className="bg-primary border-primary d-block mt-3 ml-auto"
        loading={props.loader}
        // onClick={() => exportExcel(range, dept, activity)}
        onClick={() => props.onExport?.(dept)}
      >
        PROCEED
      </Button>
    </Modal>
  );
}

export default ExportDataModal;
