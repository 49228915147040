import React from 'react';
import SocialLogin from 'react-social-login';
import { Image } from 'antd';
import Google from '../../assets/images/auth/google.png';
import { styles } from './style';

function Index({ children, triggerLogin, ...props }) {
  return (
    <Image
      {...props}
      src={Google}
      preview={false}
      style={styles.smallIcon}
      className="c-pointer"
      onClick={() => triggerLogin()}
    />
  );
}

export default SocialLogin(Index);
