import React from 'react';

import moment from 'moment';

function Index({ item }) {
  const getLeavePeriod = () => {
    if (item?.leavePeriod === 'halfDay') {
      return `${moment(item.start).format('hh:mm')} - ${moment(item.end).format(
        'hh:mm'
      )}`;
    }

    return 'Full Day Leave';
  };

  return (
    <>
      <tr className={'c-pointer'}>
        <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
        <td>
          {item?.type} ({item?.paid ? 'paid' : 'unpaid'})
        </td>
        <td>----</td>
        <td>----</td>
        <td>----</td>
        <td>----</td>
        <td>----</td>
        <td>----</td>
        <td>{getLeavePeriod()}</td>
        <td></td>
      </tr>
    </>
  );
}

export default Index;
