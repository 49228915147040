import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  Button,
  Divider,
  Table,
  Tag,
  Popconfirm,
  Space,
  Modal,
  Upload,
  Tooltip,
  Input,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Spinner } from 'react-bootstrap';

import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Location from './createLocation';
import EditLocation from './editLocation';
import { styles } from './style';

import StaticMap from './staticMap';
import {
  getLocations,
  searchLocations,
  deleteLocation,
  updateLocation,
  uploadBulkLocations,
} from '../../redux/locations/action';

import * as XLSX from 'xlsx';
import { sample } from './data';

const { Text } = Typography;
const { Search } = Input;

const Index = () => {
  const dispatch = useDispatch();
  const { locations, totalLocations, initial } = useSelector(
    (state) => state.locationsReducer
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateVisible, setUpdateVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [uloader, setULoader] = useState(false);

  const [show, setShow] = useState(false);
  const [file, setFile] = useState([]);
  const [text, setText] = useState('');

  const [selectedLocation, setLcn] = useState(null);

  const [location, setLocation] = useState({
    key: '',
    image: {
      lat: 0,
      lng: 0,
      radius: 0,
    },
    name: '',
    address: '',
    radius: '',
    organizations: [],
  });

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (initial) {
      setLoader(true);
      dispatch(getLocations(page, stopLoader));
    }
  }, []);

  const refreshData = () => {
    setLoader(true);
    dispatch(getLocations(1, stopLoader));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showUpdateModal = (data) => {
    setUpdateVisible(true);
    setLocation(data);
  };

  const updateLocationFunc = (locationObj) => {
    setLoader(true); //
    dispatch(updateLocation(location.key, locationObj, stopLoader));
    setUpdateVisible(false);
    refreshData();
  };

  const handleUpdateOk = () => {
    setUpdateVisible(false);
    setLocation({
      key: '',
      image: {
        lat: 0,
        lng: 0,
        radius: 0,
      },
      name: '',
      address: '',
      radius: '',
      organizations: [],
    });
  };

  const handleUpdateCancel = () => {
    setUpdateVisible(false);
    setLocation({
      key: '',
      image: {
        lat: 0,
        lng: 0,
        radius: 0,
      },
      name: '',
      address: '',
      radius: '',
      organizations: [],
    });
  };

  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getLocations(page, stopLoader));
  };

  const deleteLocationFunc = (key) => {
    setLoader(true);
    dispatch(deleteLocation(key, stopLoader));
  };
  const openUploader = () => {
    setShow(true);
  };

  const cancelShow = () => {
    setShow(false);
  };

  const onSearch = (value) => {
    setLoader(true);
    dispatch(searchLocations(value, page, stopLoader));
  };
  const downloadSample = () => {
    const parseResult = sample.map((item) => {
      return item;
    });
    const ws = XLSX.utils.json_to_sheet(parseResult);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Locations');
    XLSX.writeFile(wb, 'bulk_import_locations_sample.xlsx');
  };

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  };
  const parseLocations = (file) => {
    const stopLoader = () => {
      setULoader(false);
    };
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const parse = data.slice(1);
        const locationsData = [];
        parse.forEach((item, index) => {
          if (item.length) {
            if (!item?.[0] && !item?.[1]) {
              message.warning('Name and Address are required!');
              parse.length = 0;
              return;
            } else if (!item?.[2] && !item?.[3]) {
              message.warning('Longitude and Latitude are required!');
              parse.length = 0;
              return;
            } else if (!isNumber(item?.[2]) && !isNumber(item?.[3])) {
              message.warning('Longitude and Latitude are invalid!');
              parse.length = 0;
              return;
            } else {
              locationsData.push({
                name: item[0].toLowerCase(),
                address: item[1].toLowerCase(),
                latitude: item[2],
                longitude: item[3],
                radius: item[4] ? item[4] : 0,
              });
            }
          }
        });
        // console.log(locationsData);

        if (parse.length > 0) {
          setULoader(true);
          dispatch(uploadBulkLocations(locationsData, page, stopLoader));
          setShow(false);
        }
      } catch (error) {
        setULoader(false);
      }
    };
    if (rABS) reader.readAsBinaryString(file[0].originFileObj);
    else reader.readAsArrayBuffer(file[0].originFileObj);
  };

  const handleText = (_text) => {
    setText(_text);
    if (_text === '') {
      setLoader(true);
      dispatch(searchLocations(text, 1, stopLoader));
    }
  };

  const handleUpload = ({ fileList }) => {
    if (fileList.length > 1) {
      message.warning('One file at a time!');
      return;
    }
    setFile(fileList);
  };

  const renderData = () => {
    const data = locations.map((item) => {
      return {
        key: item?.id,
        image: {
          lat: item?.coordinates?.latitude,
          lng: item?.coordinates?.longitude,
          radius: item?.radius,
        },
        name: item?.name,
        address: item?.address,
        radius: item?.radius ? `${item?.radius} meters` : 'No Radius',
        organizations: item?.organizations ? item?.organizations : [],
      };
    });
    return data;
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      render: (image) => (
        <StaticMap lat={image?.lat} lng={image?.lng} radius={image?.radius} />
      ),
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Radius',
      dataIndex: 'radius',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Departments',
      dataIndex: 'organizations',
      render: (organizations) => (
        <>
          {organizations?.length > 0 ? (
            <Space>
              {organizations.map((item) => (
                <Tag color="blue" key={item?.id}>
                  {item?.name}
                </Tag>
              ))}
            </Space>
          ) : (
            <Text>Not Data</Text>
          )}
        </>
      ),
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button
            className="mr-2"
            info
            size="small"
            onClick={() => showUpdateModal(record)}
          >
            Edit
          </Button>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteLocationFunc(record.key)}
            disabled={record?.shifts?.length > 0}
          >
            <Button danger size="small" disabled={record?.shifts?.length > 0}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <h3>Locations</h3>
      <Text type="secondary">{moment().format('MMMM Do YYYY')}~</Text>
      <Divider className="m-0 pb-2" />
      <Button
        type="primary"
        className="bg-primary border-primary"
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        Add Location
      </Button>

      <Button
        type="primary"
        className="bg-primary border-primary ml-3"
        icon={<DownloadOutlined />}
        onClick={openUploader}
      >
        Import Locations
      </Button>
      <Tooltip title="Fetch Latest Data" className="d-none d-sm-table-cell">
        <Button onClick={refreshData} className="ml-3">
          <ReloadOutlined />
        </Button>
      </Tooltip>

      <Search
        placeholder="Search locations"
        onSearch={onSearch}
        enterButton
        className=" d-none d-sm-table-cell w-50 mb-2 ml-5 flex "
        onChange={(e) => handleText(e.target.value)}
      />

      <Divider className="m-0 mt-2 pb-2" />
      <Card className="tmb">
        <Table
          className="table-curved"
          columns={columns}
          dataSource={renderData()}
          loading={loader}
          scroll={renderData().length > 0 && { x: true }}
          pagination={{
            onChange: changePage,
            current: page,
            total: totalLocations,
            position: ['bottomLeft'],
            pageSize: 10,
            hideOnSinglePage: true,
          }}
        />
      </Card>

      <Modal
        title="Upload Locations"
        visible={show}
        onCancel={cancelShow}
        footer={null}
      >
        <ol>
          <li className="mt-3">
            Please download the sample below to match the format needed on
            importing locations.{' '}
            <Text
              strong
              className="text-primary c-pointer"
              onClick={downloadSample}
            >
              here
            </Text>
          </li>
          <li className="mt-3">
            The template will have sample data on how to populate it
            successfully.
          </li>
        </ol>
        <Upload
          beforeUpload={() => false}
          fileList={file}
          disabled={file.length > 1}
          onChange={handleUpload}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <Button style={styles.buttonUpload} icon={<UploadOutlined />}>
            Upload File
          </Button>
        </Upload>
        <div className="text-right">
          <Space className="mt-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={cancelShow}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => parseLocations(file)}
              disabled={uloader || file.length == 0 || !file}
            >
              {uloader ? (
                <Spinner size="sm" animation="border" variant="light" />
              ) : (
                'Submit'
              )}
            </button>
          </Space>
        </div>
      </Modal>
      <Location
        show={isModalVisible}
        setClose={handleCancel}
        handleOk={handleOk}
      />
      <EditLocation
        show={isUpdateVisible}
        setClose={handleUpdateCancel}
        handleOk={handleUpdateOk}
        updateLocation={updateLocationFunc}
        location={location}
      />
    </React.Fragment>
  );
};

export default Index;
