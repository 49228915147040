import { TOUR_VISIBLE, FINISH_TOUR, CONTINUE_TOUR } from './constant';

let initState = {
  welcomeModal: false,
  deptHelp: false,
  isDeptHelp: false,
  dptDoneModal: false,
  memberHelp: false,
  isMemberHelp: false,
  memDoneModal: false,
  performanceHelp: false,
  isPerformanceHelp: false,
  perfDoneModal: false,
  isProgressListVisible: false,
};

function tourReducer(state = initState, action) {
  let { payload } = action;

  switch (action.type) {
    case TOUR_VISIBLE:
      return {
        ...state,
        ...payload,
      };
    case FINISH_TOUR:
      return {
        ...initState,
      };
    case CONTINUE_TOUR:
      if (payload === 'skip') {
        return {
          ...state,
          welcomeModal: true,
          isProgressListVisible: true,
        };
      } else if (payload === 'skipAtDpt') {
        return {
          ...state,
          memberHelp: true,
          isMemberHelp: true,
        };
      } else if (payload === 'skipAtMember') {
        return {
          ...state,
          performanceHelp: true,
          isPerformanceHelp: true,
        };
      }
      return state;

    default:
      return state;
  }
}

export default tourReducer;
