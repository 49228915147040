import { INSTANCE } from '../../config/networkInstance';

export default class Api {
  createTask = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/tasks',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getOneTask = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: '/tasks/get',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTasks = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/tasks',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllTasks = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: '/tasks/getAll',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateTasks = (params, data, _location) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: '/tasks',
        params,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteTasks = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'DELETE',
        url: '/tasks',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  toggleTimer = (params, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: '/tasks/toggle',
        params,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
