import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GuestGuard = ({ children }) => {
  const { isLogin, user } = useSelector((state) => state.auth);

  if (isLogin) {
    if (user.role === 'user') {
      return <Redirect to="/schedules/shifts" />;
    } else return <Redirect to="/app/dashboard/analytics" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
