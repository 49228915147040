import React, { useState, useEffect } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Select,
  TimePicker,
  Drawer,
  Space,
  Switch,
  Typography,
  message,
} from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTimesheet,
  createWeeklySheet,
} from '../../redux/timesheets/action';
import { getAllEmployees } from '../../redux/shifts/action';
import { getSpecificCustomers } from '../../redux/customers/action';
import { getBreakTypes } from '../../redux/types/action';
import { daysDTA, transformWeekObject } from './util';
import Item from './itemAdded';
import WeeklyItem from './weeklyItem';
import moment from 'moment';
const { Option, OptGroup } = Select;
const { Text } = Typography;

const reasons = [
  'Lunch break',
  'Feeling sick',
  'Personal errand',
  'Travelling',
  'Other',
];

function Index({
  show,
  handleCancel,
  dept,
  refreshData,
  daily = false,
  employee = false,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { company } = useSelector((state) => state.auth);
  const { typesBreak: types } = useSelector((state) => state.types);
  const [loader, setLoader] = useState(false);
  const [eLoader, setELoader] = useState(false);
  const [cLoader, setCLoader] = useState(false);
  const [rloader, setRLoader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [items, setItems] = useState([]);
  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);
  const [weekly, setWeekly] = useState(false);
  const [range, setRange] = useState({
    start: new Date(),
    end: moment().add(7, 'days').toDate(),
  });
  const [weeks, setWeeks] = useState(
    transformWeekObject(daysDTA(range.start, range.end))
  );

  const stopLoaderEmp = () => {
    setELoader(false);
  };

  const stopReasonLoader = () => {
    setRLoader(false);
  };

  useEffect(() => {
    if (show) {
      setELoader(true);
      dispatch(getAllEmployees(stopLoaderEmp, dept));
      dispatch(getBreakTypes({ company: company?.id }, stopReasonLoader));
    }
  }, [dept, show]);

  const getActivities = (organization) => {
    const customersCallback = (result) => {
      setCLoader(false);
      if (result) {
        setTasks(result);
      }
    };
    setCLoader(true);
    dispatch(getSpecificCustomers({ organization }, customersCallback));
  };

  useEffect(() => {
    if (employee) {
      getActivities(employee?.organization);
    }
  }, [employee]);

  const stopLoader = () => {
    setLoader(false);
    refreshData();
    handleCancel();
    setItems([]);
    setTasks([]);
    if (weekly) {
      setWeeks(transformWeekObject(daysDTA(range.start, range.end)));
    }
  };

  const removeEntry = (index) => {
    const nItems = [...items].filter((_, i) => i !== index);
    setItems(nItems);
  };

  const getTasksTypes = (name) => {
    return tasks.filter((item) => item?.type == name);
  };

  const handleEmployee = (value) => {
    let employee = JSON.parse(value);
    getActivities(employee?.organization);
  };

  const getMinutes = (date) => {
    if (date) {
      let [hours, minutes] = String(date).split(':');
      return +hours * 60 + +minutes;
    } else {
      return 0;
    }
  };

  const onFinish = (values) => {
    if (weekly) {
      if (Object.values(weeks).filter((item) => item.length == 0).length == 7) {
        message.warning('You need to create at least a day record!');
        return;
      }
      let data = [];
      Object.keys(weeks).map((key) => {
        weeks[key].map((item) => {
          if (item.totalTime) {
            if (item?.customer) {
              data.push({
                date: new Date(key),
                totalTime: getMinutes(item?.totalTime),
                breakTime: getMinutes(item?.breakTime),
                overTime: getMinutes(item?.overTime),
                time: getMinutes(item?.totalTime) - getMinutes(item?.breakTime),
                customer: item?.customer,
                user: JSON.parse(values?.user)?.id,
              });
            } else {
              data.push({
                date: new Date(key),
                totalTime: getMinutes(item?.totalTime),
                breakTime: getMinutes(item?.breakTime),
                overTime: getMinutes(item?.overTime),
                time: getMinutes(item?.totalTime) - getMinutes(item?.breakTime),
                user: JSON.parse(values?.user)?.id,
              });
            }
          }
        });
      });
      setLoader(true);
      dispatch(createWeeklySheet(data, stopLoader));
    } else {
      const data = {
        ...values,
        ...(!employee && { user: JSON.parse(values?.user)?.id }),
        date: values.clockout,
        status: 'completed',
        tzOffset: new Date().getTimezoneOffset(),
        ...(employee && { user: employee?.id }),
      };
      setItems([...items, data]);
    }
    form.resetFields();
  };

  const createSheets = () => {
    setLoader(true);
    dispatch(createTimesheet(items, stopLoader));
  };
  let reasonsTypes = types.length > 0 ? types : reasons;
  const setClockTimes = (time, type) => {
    if (type === 'clockIn') {
      setClockIn(time);
    } else {
      setClockOut(time);
    }
  };

  const handleSwitch = (checked) => {
    setWeekly(checked);
  };

  const handleCurrent = (date) => {
    let start = date;
    let end = moment(date).add(7, 'days').toDate();
    setRange({ start, end });
    setWeeks(transformWeekObject(daysDTA(start, end)));
  };

  return (
    <>
      <Drawer
        title="Time Entry"
        visible={show}
        onClose={handleCancel}
        width={window.innerWidth > 900 ? 520 : window.innerWidth}
        zIndex={1050}
        footer={
          !weekly && (
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                type="primary"
                className="bg-primary border-primary"
                onClick={createSheets}
                loading={loader}
                disabled={items.length == 0}
              >
                Submit
              </Button>
            </Space>
          )
        }
      >
        <div>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            form={form}
          >
            <Row>
              <Col sm={12}>
                {!employee && (
                  <Form.Item
                    label="Employee"
                    className="mb-3"
                    name="user"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Employee',
                      },
                    ]}
                  >
                    <Select
                      size={'large'}
                      placeholder="select Employee"
                      className="fullWidth"
                      loading={eLoader}
                      disabled={eLoader}
                      onChange={handleEmployee}
                    >
                      {allEmployees.map((item) => {
                        return (
                          <Option
                            key={JSON.stringify(item)}
                          >{`${item?.name} (${item?.jobType})`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
              </Col>

              {tasks?.length > 0 && !weekly && (
                <Col sm={12}>
                  <Form.Item name="customer" label="Activity">
                    <Select
                      size={'large'}
                      placeholder="Select Activity"
                      className="fullWidth"
                      loading={cLoader}
                      disabled={cLoader}
                    >
                      {getTasksTypes('Customer').length > 0 && (
                        <OptGroup label="Customer">
                          {getTasksTypes('Customer').map((obj) => {
                            return <Option key={obj?.id}>{obj?.name}</Option>;
                          })}
                        </OptGroup>
                      )}
                      {getTasksTypes('Project').length > 0 && (
                        <OptGroup label="Project">
                          {getTasksTypes('Project').map((obj) => {
                            return <Option key={obj?.id}>{obj?.name}</Option>;
                          })}
                        </OptGroup>
                      )}
                      {getTasksTypes('Other').length > 0 && (
                        <OptGroup label="Other">
                          {getTasksTypes('Other').map((obj) => {
                            return <Option key={obj?.id}>{obj?.name}</Option>;
                          })}
                        </OptGroup>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Space className="pl-3 pb-3">
                <Text>Create Weekly Sheet</Text>
                <Switch onChange={handleSwitch} />
              </Space>

              {!weekly ? (
                <>
                  <Col sm={12}>
                    <Form.Item
                      name="clockin"
                      label="Clock In"
                      rules={[{ required: true, message: 'Missing Clock In' }]}
                    >
                      {daily ? (
                        <TimePicker
                          className="fullWidth"
                          placeholder="Select Clock In Time"
                          size="large"
                          format="HH:mm"
                          onChange={(date) => setClockTimes(date, 'clockIn')}
                        />
                      ) : (
                        <DatePicker
                          className="fullWidth"
                          format="DD-MM-YYYY HH:mm"
                          showTime={{
                            defaultValue: moment('00:00', 'HH:mm'),
                          }}
                          placeholder="Select Clock In Time"
                          size="large"
                          onChange={(date) => setClockTimes(date, 'clockIn')}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      name="clockout"
                      label="Clock Out"
                      rules={[{ required: true, message: 'Missing Clock Out' }]}
                    >
                      {daily ? (
                        <TimePicker
                          className="fullWidth"
                          placeholder="Select Clock Out Time"
                          size="large"
                          format="HH:mm"
                          onChange={(date) => setClockTimes(date, 'clockOut')}
                        />
                      ) : (
                        <DatePicker
                          className="fullWidth"
                          format="DD-MM-YYYY HH:mm"
                          showTime={{
                            defaultValue: moment('00:00', 'HH:mm'),
                          }}
                          placeholder="Select Clock Out Time"
                          size="large"
                          onChange={(date) => setClockTimes(date, 'clockOut')}
                          disabled={!clockIn}
                          disabledDate={(current) => {
                            return current?.isBefore?.(clockIn, 'day');
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Form.List name="breaks">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, i) => (
                            <div
                              key={i}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // overflowX: 'hidden',
                              }}
                              className="timeEntryLeave"
                            >
                              <Row className="px-3">
                                <Col sm={12} lg={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'in']}
                                    label="Break In"
                                    fieldKey={[fieldKey, 'in']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing Break In',
                                      },
                                    ]}
                                  >
                                    {daily ? (
                                      <TimePicker
                                        placeholder="Select Break In Time"
                                        size="large"
                                        className="fullWidth"
                                        format="HH:mm"
                                        disabled={!clockIn || !clockOut}
                                      />
                                    ) : (
                                      <DatePicker
                                        className="fullWidth"
                                        format="DD-MM-YYYY HH:mm"
                                        showTime={{
                                          defaultValue: moment(
                                            '00:00',
                                            'HH:mm'
                                          ),
                                        }}
                                        placeholder="Select Break In Time"
                                        size="large"
                                        disabled={!clockIn || !clockOut}
                                        disabledDate={(current) => {
                                          return (
                                            current?.isBefore?.(
                                              clockIn,
                                              'day'
                                            ) ||
                                            current?.isAfter?.(clockOut, 'day')
                                          );
                                        }}
                                        showNow={false}
                                      />
                                    )}
                                  </Form.Item>
                                </Col>

                                <Col sm={12} lg={6}>
                                  <Form.Item
                                    {...restField}
                                    label="Break Out"
                                    name={[name, 'out']}
                                    fieldKey={[fieldKey, 'out']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing Break Out',
                                      },
                                    ]}
                                    className="m-0"
                                  >
                                    {daily ? (
                                      <TimePicker
                                        placeholder="Select Break Out Time"
                                        size="large"
                                        className="fullWidth"
                                        format="HH:mm"
                                        disabled={!clockIn || !clockOut}
                                      />
                                    ) : (
                                      <DatePicker
                                        className="fullWidth"
                                        format="DD-MM-YYYY HH:mm"
                                        showTime={{
                                          defaultValue: moment(
                                            '00:00',
                                            'HH:mm'
                                          ),
                                        }}
                                        placeholder="Select Break Out Time"
                                        size="large"
                                        disabledDate={(current) => {
                                          return (
                                            current?.isBefore?.(
                                              clockIn,
                                              'day'
                                            ) ||
                                            current?.isAfter?.(clockOut, 'day')
                                          );
                                        }}
                                        disabled={!clockIn || !clockOut}
                                        showNow={false}
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Col sm={12} className="mt-md-0 mt-3">
                                <Form.Item
                                  {...restField}
                                  label="Break Reason"
                                  name={[name, 'reason']}
                                  fieldKey={[fieldKey, 'reason']}
                                >
                                  <Select
                                    className="fullWidth"
                                    placeholder="Select Break Reason"
                                    size="large"
                                    loading={rloader}
                                  >
                                    {reasonsTypes.map((item) => {
                                      return (
                                        <Option key={item?.name}>
                                          {item?.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </div>
                          )
                        )}

                        <Col lg={fields.length == 0 ? 12 : 6} className="mb-4">
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            className="fullWidth"
                            icon={<PlusOutlined />}
                          >
                            Add Breaks
                          </Button>
                        </Col>
                        {fields.length > 0 && (
                          <Col lg={6} className="mb-4">
                            <Button
                              type="dashed"
                              className="fullWidth"
                              danger
                              onClick={() =>
                                remove(fields[fields.length - 1].name)
                              }
                              icon={<MinusCircleOutlined />}
                            >
                              Remove Breaks
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                  </Form.List>
                  <Button
                    type="primary"
                    className="bg-primary border-primary fullWidth mx-3  d-block"
                    htmlType="submit"
                    icon={<PlusOutlined />}
                  >
                    Add Entry
                  </Button>
                </>
              ) : (
                <>
                  <Col sm={12} className="mt-3">
                    <Form.Item label="Select Week">
                      <DatePicker
                        onChange={handleCurrent}
                        picker="week"
                        size="large"
                        placeholder="Select Week"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>

                  {daysDTA(range.start, range.end).map((item, index) => {
                    return (
                      <WeeklyItem
                        item={item}
                        tasks={tasks}
                        weeks={weeks}
                        setWeeks={setWeeks}
                        key={index}
                      />
                    );
                  })}

                  <Button
                    type="primary"
                    className="bg-primary border-primary fullWidth mx-3 mt-3  d-block"
                    loading={loader}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </>
              )}
            </Row>
          </Form>
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <Item
                  item={item}
                  key={index}
                  remove={removeEntry}
                  index={index}
                />
              );
            })}
        </div>
      </Drawer>
    </>
  );
}

export default Index;
