const menuItems = {
  items: [
    {
      id: 'System',
      title: 'System',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-home',
          url: '/app/dashboard/analytics',
        },
        {
          id: 'Inbox',
          title: 'Inbox',
          type: 'item',
          icon: 'feather icon-inbox',
          url: '/app/inbox',
        },

        {
          id: 'Analytics',
          title: 'Analytics',
          type: 'collapse',
          icon: 'feather icon-bar-chart-2',
          classes: 'tasks',
          children: [
            {
              id: 'main',
              title: 'Daily Attendance',
              type: 'item',
              url: '/app/analytics/dailyAttendance',
            },
            {
              id: 'summery',
              title: 'Attendance Summery',
              type: 'item',
              url: '/app/analytics/attendanceSummery',
            },
            {
              id: 'incident',
              title: 'incident Reports',
              type: 'item',
              url: '/app/analytics/incidentReports',
            },
            {
              id: 'performance',
              title: 'Performance Reports',
              type: 'item',
              url: '/app/analytics/performanceReports',
            },
          ],
        },
        // {
        //   id: 'Attendance',
        //   title: 'Attendance',
        //   type: 'item',
        //   icon: 'feather icon-user-check',
        //   url: '/app/attendance',
        // },
        {
          id: 'Timesheets',
          title: 'Timesheets',
          type: 'item',
          icon: 'feather icon-clock',
          url: '/app/timesheets',
        },

        {
          id: 'Members',
          title: 'Members',
          type: 'item',
          icon: 'feather icon-users',
          url: '/app/members',
          classes: 'members',
        },
        // {
        //   id: 'Tasks',
        //   title: 'Tasks and Projects',
        //   type: 'collapse',
        //   icon: 'feather icon-calendar',
        //   classes: 'tasks',
        //   children: [
        //     {
        //       id: 'main',
        //       title: 'Task',
        //       type: 'item',
        //       url: '/app/tasks',
        //     },
        //     {
        //       id: 'project',
        //       title: 'Projects',
        //       type: 'item',
        //       url: '/app/task/project',
        //     },
        //   ],
        // },
        {
          id: 'Tasks',
          title: 'Tasks',
          type: 'item',
          icon: 'feather icon-calendar',
          url: '/app/tasks',
        },
        {
          id: 'Schedules',
          title: 'Schedules',
          type: 'collapse',
          icon: 'feather icon-clipboard',
          classes: 'shifts',
          children: [
            {
              id: 'Shifts',
              title: 'Shifts',
              type: 'item',
              url: '/schedules/shifts',
            },
            {
              id: 'main',
              title: 'Management',
              type: 'item',
              url: '/schedules/main',
            },
            {
              id: 'swapShift',
              title: 'Shift Requests',
              type: 'item',
              url: '/schedules/swapShift',
            },

            {
              id: 'WorkSchedules',
              title: 'Work Schedules',
              type: 'item',
              url: '/schedules/workSchedules',
            },
          ],
        },
        {
          id: 'Organizations',
          title: 'Departments',
          type: 'item',
          icon: 'feather icon-server',
          url: '/app/organizations',
          classes: 'organization',
        },
        {
          id: 'CustomerAndProjects',
          title: 'Assignments',
          type: 'item',
          icon: 'feather icon-briefcase',
          url: '/app/customers',
          classes: 'customers',
        },
        {
          id: 'PerformanceQuestions',
          title: 'Performance',
          type: 'item',
          icon: 'feather icon-trending-up',
          url: '/app/performance-questions',
          classes: 'pq',
        },
        {
          id: 'Locations',
          title: 'Locations',
          type: 'item',
          icon: 'feather icon-map-pin',
          url: '/app/locations',
          classes: 'location',
        },
        {
          id: 'Leave',
          title: 'Leave Management',
          icon: 'feather icon-user-minus',
          type: 'item',
          url: '/app/leaves',
          classes: 'leaves',
        },
        {
          id: 'Settings',
          title: 'Settings',
          type: 'item',
          icon: 'feather icon-settings',
          classes: 'settings',
          url: '/app/settings',
        },
        // {
        //   id: 'Settings',
        //   title: 'Power BI',
        //   type: 'item',
        //   icon: 'feather icon-settings',
        //   classes: 'settings',
        //   url: '/app/powerBI',
        // },
      ],
    },
  ],
};

const menuItems2 = {
  items: [
    {
      id: 'System',
      title: 'System',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-home',
          url: '/app/dashboard/analytics',
        },
        {
          id: 'Inbox',
          title: 'Inbox',
          type: 'item',
          icon: 'feather icon-inbox',
          url: '/app/inbox',
        },
        {
          id: 'Attendance',
          title: 'Attendance',
          type: 'item',
          icon: 'feather icon-user-check',
          url: '/app/attendance',
        },
        {
          id: 'Timesheets',
          title: 'Timesheets',
          type: 'item',
          icon: 'feather icon-clock',
          url: '/app/timesheets',
        },
        {
          id: 'Members',
          title: 'Members',
          type: 'item',
          icon: 'feather icon-users',
          url: '/app/members',
          classes: 'members',
        },
        {
          id: 'Schedules',
          title: 'Schedules',
          type: 'collapse',
          icon: 'feather icon-clipboard',
          url: '/hospital/hosp-pharmacist',
          classes: 'shifts',
          children: [
            {
              id: 'Shifts',
              title: 'Shifts',
              type: 'item',
              url: '/schedules/shifts',
            },
            {
              id: 'main',
              title: 'Management',
              type: 'item',
              url: '/schedules/main',
            },
            {
              id: 'WorkSchedules',
              title: 'Work Schedules',
              type: 'item',
              url: '/schedules/workSchedules',
            },
          ],
        },
        {
          id: 'Tasks',
          title: 'Tasks',
          type: 'item',
          icon: 'feather icon-calendar',
          url: '/app/tasks',
        },

        {
          id: 'CustomerAndProjects',
          title: 'Assignments',
          type: 'item',
          icon: 'feather icon-briefcase',
          url: '/app/customers',
          classes: 'customers',
        },
        {
          id: 'CustomerAndProjects',
          title: 'Assignments',
          type: 'item',
          icon: 'feather icon-briefcase',
          url: '/app/customers',
          classes: 'customers',
        },
        {
          id: 'Leave',
          title: 'Leave Management',
          icon: 'feather icon-user-minus',
          type: 'item',
          url: '/app/leaves',
          classes: 'leaves',
        },
      ],
    },
    {
      id: 'Settings',
      title: 'Settings',
      type: 'group',
      icon: 'feather icon-settings',
      children: [
        {
          id: 'profile',
          title: 'Profile',
          type: 'item',
          icon: 'feather icon-user',
          url: '/app/profile',
        },
        {
          id: 'changePassword',
          title: 'Change Password',
          type: 'item',
          icon: 'feather icon-lock',
          url: '/app/changePassword',
        },
      ],
    },
  ],
};

const menuItems3 = {
  items: [
    {
      id: 'System',
      title: 'System',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'Shifts',
          title: 'Shifts',
          type: 'item',
          icon: 'feather icon-clipboard',
          url: '/schedules/shifts',
        },
        {
          id: 'Inbox',
          title: 'Inbox',
          type: 'item',
          icon: 'feather icon-inbox',
          url: '/app/inbox',
        },
        {
          id: 'Tasks',
          title: 'Tasks',
          type: 'item',
          icon: 'feather icon-calendar',
          url: '/app/tasks',
        },
        {
          id: 'Leave',
          title: 'Leave Management',
          icon: 'feather icon-user-minus',
          type: 'item',
          url: '/app/leaves',
          classes: 'leaves',
        },

        {
          id: 'availableShifts',
          title: 'Change Requests',
          type: 'item',
          icon: 'feather icon-copy',
          url: '/schedules/availableShifts',
        },
      ],
    },

    {
      id: 'Settings',
      title: 'Settings',
      type: 'group',
      icon: 'feather icon-settings',
      children: [
        {
          id: 'profile',
          title: 'Profile',
          type: 'item',
          icon: 'feather icon-user',
          url: '/app/profile',
        },
        {
          id: 'changePassword',
          title: 'Change Password',
          type: 'item',
          icon: 'feather icon-lock',
          url: '/app/changePassword',
        },
      ],
    },
  ],
};

export { menuItems, menuItems2, menuItems3 };
