import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCustomers } from '../../redux/customers/action';

const { Option, OptGroup } = Select;

function Index(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { allCustomers } = useSelector((state) => state.customersReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCustomers(() => setLoading(false)));
  }, []);

  const getTasksTypes = (name) => {
    return allCustomers.filter((item) => item?.type == name);
  };

  return (
    <Select
      placeholder="Select Activity"
      style={props.pickerStyle}
      loading={loading}
      disabled={loading}
      size={props.size ? props.size : 'medium'}
      onChange={(value) => {
        props.onChange?.(value);
      }}
      className={props.className}
      value={props?.value}
    >
      {getTasksTypes('Customer').length > 0 && (
        <OptGroup label="Customer">
          {getTasksTypes('Customer').map((obj) => {
            return <Option key={obj?.id}>{obj?.name}</Option>;
          })}
        </OptGroup>
      )}
      {getTasksTypes('Project').length > 0 && (
        <OptGroup label="Project">
          {getTasksTypes('Project').map((obj) => {
            return <Option key={obj?.id}>{obj?.name}</Option>;
          })}
        </OptGroup>
      )}
      {getTasksTypes('Other').length > 0 && (
        <OptGroup label="Other">
          {getTasksTypes('Other').map((obj) => {
            return <Option key={obj?.id}>{obj?.name}</Option>;
          })}
        </OptGroup>
      )}
    </Select>
  );
}

export default Index;
