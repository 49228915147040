export const styles = {
  modalCont: {
    paddingTop: 40,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 50,
  },

  otpCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  },

  modalHeading: {
    fontSize: 22,
    fontWeight: '500',
  },

  otpContStyle: {
    width: '70%',
    height: 100,
    justifyContent: 'center',
  },

  otpInput: {
    width: 50,
    height: 50,
    borderRadius: 5,
    borderWidth: 1,
    boxShadow: '-2px 5px 27px 9px #F0F0F0',
  },

  otpDivider: {
    marginLeft: 5,
    marginRight: 5,
  },
  ctaVerify: {
    width: 150,
    height: 45,
    backgroundColor: '#f89722',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },

  resendOtp: {
    color: '#f89722',
    height: 45,
    marginRight: 10,
  },
};
