import { INSTANCE } from '../../config/networkInstance';

export default class PerformanceApi {
  createPerformanceQ = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'performance',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getUserQuestion = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'answers/userQuestions',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getUserAnswers = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'answers/userAnswers',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getPerformanceQ = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'performance',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updatePerformanceQ = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `performance/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAnswers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'answers',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAllocationAnswers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'answers/allocation-answer',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTaskAnswers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'answers/task-answer',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSummery = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'answers/summery',
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
