import React, { useContext, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import moment from 'moment';
import Navigation from './Navigation';
import NavBar from './NavBar';
import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ConfigContext } from '../../contexts/ConfigContext';
import * as actionType from '../../store/actions';
import { getCoords, getCurrent } from '../../redux/auth/action';
import { getInitialData } from '../../redux/analytics/action';
import { useDispatch, useSelector } from 'react-redux';
import TourView from '../../views/tourView';
import { continueTour } from '../../redux/tour/action';
import { getPQ } from '../../redux/tour/action';

const AdminLayout = ({ children }) => {
  const { user, company } = useSelector((state) => state.auth);
  const { activePlan } = useSelector((state) => state.chargeBee);
  const [loader, setLoader] = useState(false);
  const [warn, setWarn] = useState(true);
  const [perf, setPerf] = useState(0);
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);
  const dispatches = useDispatch();

  const { collapseMenu, layout, subLayout, headerFixedLayout } =
    configContext.state;
  const { dispatch } = configContext;

  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  });

  useEffect(() => {
    if (
      windowSize.width > 992 &&
      windowSize.width <= 1024 &&
      layout !== 'horizontal'
    ) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }

    if (layout === 'horizontal' && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: 'vertical' });
    }
  }, [dispatch, layout, windowSize]);

  const stopLoader = () => {
    setLoader(false);
    $crisp.push(['set', 'user:nickname', [user?.name]]);
    $crisp.push(['set', 'user:email', [user?.email]]);
    $crisp.push(['set', 'user:avatar', [user?.profilePic]]);
    $crisp.push([
      'set',
      'user:company',
      [
        {
          employment: [
            user?.role == 'user' ? 'Worker' : user?.role,
            user?.jobType,
          ],
        },
        company?.name,
      ],
    ]);
    if (
      user?.role === 'root' &&
      company?.phone &&
      company?.phone?.code &&
      company?.phone?.phone
    ) {
      $crisp.push([
        'set',
        'user:phone',
        [`+${company?.phone?.code}${company?.phone?.phone}`],
      ]);
    } else {
      $crisp.push(['set', 'user:phone', [`${user?.phone}`]]);
    }
  };

  const closeWarn = () => {
    setWarn(false);
  };

  useEffect(() => {
    getPosition();
    setLoader(true);
    dispatches(getCurrent(stopLoader));
    dispatches(getInitialData());
    getPQ((docs) => {
      setPerf(docs);
    });
  }, []);

  const getPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatches(
        getCoords({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      );
    });
  };

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  const continueTourHandler = (tourState) => {
    dispatches(continueTour(tourState));
  };

  let mainClass = ['pcoded-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    mainClass = [...mainClass, 'container'];
  }

  let common = (
    <React.Fragment>
      <Navigation />
      <NavBar continueTour={continueTourHandler} perf={perf} />
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div className={outSideClass.join(' ')} ref={ref}>
        {common}
      </div>
    );
  }

  if (loader) {
    return (
      <div className="authSpinCont">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <React.Fragment>
      {warn &&
        moment
          .duration(
            moment(activePlan?.subscription?.trial_end * 1000).diff(moment())
          )
          .asDays()
          .toFixed(0) > 0 && (
          <div className="notification-top-bar">
            <p>
              Your Free Trial is about to End in{' '}
              {moment
                .duration(
                  moment(activePlan?.subscription?.trial_end * 1000).diff(
                    moment()
                  )
                )
                .asDays()
                .toFixed(0)}{' '}
              days{' '}
              <small>
                <a href="#" onClick={closeWarn}>
                  OK
                </a>
              </small>
            </p>
          </div>
        )}
      {common}
      <div
        className="pcoded-main-container"
        onClick={() => mobileOutClickHandler}
        style={{ background: '#fff' }}
      >
        <div className={mainClass.join(' ')}>
          <div className="pcoded-content">
            <div className="pcoded-inner-content">{children}</div>
          </div>
        </div>
      </div>
      <TourView />
    </React.Fragment>
  );
};

export default AdminLayout;
