import React, { useState } from 'react';
import { Button, Badge, Space, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import TimeDetails from './timeDetails';
import { updateTimesheetRequests } from '../../redux/timesheets/action';
import EditTime from './editTime';
import moment from 'moment';
import { truncateToDecimals } from './util';

function Index({ item, showAction, sheets: timesheets, refreshData, user }) {
  const supervisor = item?.editor ? item?.editor : item?.creator;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [minSheet, setMiniSheet] = useState(timesheets.reverse());
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [details, setDetails] = useState(false);

  const onClose = () => {
    setVisible(false);
  };
  const openDrawer = () => {
    if (item?.allocation) {
      setVisible(true);
    }
  };
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const stopLoader = () => {
    setLoader(false);
    refreshData();
  };

  const updateStatus = (status) => {
    setLoader(status);
    dispatch(
      updateTimesheetRequests(
        {
          timesheets: timesheets.reverse().map((i) => i.id),
          status,
        },
        user,
        stopLoader
      )
    );
  };

  const showModal = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'revision':
        return (
          <Badge count="In Revision" style={{ backgroundColor: '#4da6ff' }} />
        );
      case 'requested':
        return (
          <Badge count="Requested" style={{ backgroundColor: '#ff751a' }} />
        );
    }
  };
  const onClickDetails = () => {
    if (details) {
      setDetails(false);
    } else {
      setDetails(true);
    }
  };

  return (
    <>
      {showAction && (
        <>
          <tr
            onClick={openDrawer}
            className={!supervisor && item?.allocation ? 'c-pointer' : ''}
            disabled={supervisor}
          >
            <td>{moment(item?.allocation?.clockin).format('DD-MM-YYYY')}</td>
            <td>
              {item?.allocation?.clockin ? (
                <>
                  <small>
                    <b>{days[moment(item?.allocation?.clockin).day()]}</b>
                  </small>
                  <br></br>
                  {moment(item?.allocation?.clockin).format('HH:mm') + 'HRS'}
                </>
              ) : (
                '--'
              )}
            </td>
            <td>
              {item?.allocation?.clockout ? (
                <>
                  <small>
                    <b>{days[moment(item?.allocation?.clockout).day()]}</b>
                  </small>
                  <br></br>
                  {moment(item?.allocation?.clockout).format('HH:mm') + 'HRS'}
                </>
              ) : (
                '--'
              )}
            </td>
            <td className="d-none d-sm-table-cell"></td>
            <td className="d-none d-sm-table-cell"></td>
            <td className="d-none d-sm-table-cell">
              {renderStatus(item?.status)}{' '}
              {!item?.allocation && (
                <Tooltip title="Created Manually">
                  <QuestionCircleOutlined className="ml-2" />
                </Tooltip>
              )}
            </td>

            <td align="center">
              <Space>
                <Button
                  type="primary"
                  className="bg-primary border-primary"
                  onClick={() => updateStatus('approved')}
                  loading={loader == 'approved'}
                >
                  Approve
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => updateStatus('rejected')}
                  loading={loader == 'rejected'}
                >
                  Reject
                </Button>
                <Button
                  type="primary"
                  className="d-none d-sm-table-cell"
                  onClick={onClickDetails}
                >
                  {details ? 'Hide Details' : 'View Details'}
                </Button>
                <Button
                  type="primary"
                  className="d-none d-sm-table-cell"
                  onClick={showModal}
                >
                  Edit
                </Button>
              </Space>
            </td>
          </tr>
          {!item?.creator && (
            <TimeDetails visible={visible} onClose={onClose} item={item} />
          )}

          <EditTime
            show={isModalVisible}
            handleCancel={handleCancel}
            item={item}
            sheets={timesheets.reverse()}
            refreshData={refreshData}
          />
        </>
      )}
      {details && showAction ? (
        <>
          {minSheet.map((_item) => {
            return (
              <tr key={_item}>
                <td>
                  <small>
                    <b>{days[moment(_item?.date).day()]}</b>
                  </small>
                  <br></br>
                  {moment(_item?.date).format('DD-MM-YYYY')}
                </td>
                <td>
                  {item?.allocation?.clockin &&
                  moment(_item?.allocation?.clockin).isSame(_item?.date, 'day')
                    ? moment(_item?.allocation?.clockin).format('HH:mm') + 'HRS'
                    : '--'}
                </td>
                <td>
                  {item?.allocation?.clockout &&
                  moment(_item?.allocation?.clockout).isSame(_item?.date, 'day')
                    ? moment(_item?.allocation?.clockout).format('HH:mm') +
                      'HRS'
                    : '--'}
                </td>
                <td>
                  {truncateToDecimals(_item?.time / 60) +
                    'HRS ' +
                    truncateToDecimals(_item?.time % 60) +
                    'MIN '}
                </td>
                <td>
                  {truncateToDecimals(_item?.breakTime / 60) +
                    'HRS ' +
                    truncateToDecimals(_item?.breakTime % 60) +
                    'MIN '}
                </td>
                <td>
                  {renderStatus(_item?.status)}{' '}
                  {!_item?.allocation && (
                    <Tooltip title="Created Manually">
                      <QuestionCircleOutlined className="ml-2" />
                    </Tooltip>
                  )}
                </td>

                <td align="center"></td>
              </tr>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Index;
