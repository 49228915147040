export const styles = {
  buttonLoader: {
    height: 70,
    width: 70,
    borderRadius: 70 / 2,
    position: 'absolute',
    top: '50%',
    left: '47%',
    zIndex: 324234234,
  },
  labelRadio: {
    fontSize: 11,
  },
  padCont: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  eraserIcon: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 10,
  },

  eraserIconCont: {
    position: 'relative',
    height: 200,
    marginLeft: -50,
  },

  uploadBtn: {},
};
