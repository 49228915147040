import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavLeft from './NavLeft';
import { ConfigContext } from '../../../contexts/ConfigContext';
import logo from '../../../assets/images/logo.png';
import * as actionType from '../../../store/actions';
import NavRight from './NavRight';

const NavBar = ({ continueTour, perf, simple = false }) => {
  const { user } = useSelector((state) => state.auth);
  const [moreToggle, setMoreToggle] = useState(false);
  const configContext = useContext(ConfigContext);
  const {
    collapseMenu,
    headerBackColor,
    headerFixedLayout,
    layout,
    subLayout,
  } = configContext.state;
  const { dispatch } = configContext;

  let headerClass = [
    'navbar',
    'pcoded-header',
    'navbar-expand-lg',
    headerBackColor,
  ];
  if (headerFixedLayout && layout === 'vertical') {
    headerClass = [...headerClass, 'headerpos-fixed'];
  }

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  const navToggleHandler = () => {
    dispatch({ type: actionType.COLLAPSE_MENU });
  };

  let moreClass = ['mob-toggler'];
  let collapseClass = ['collapse navbar-collapse'];
  if (moreToggle) {
    moreClass = [...moreClass, 'on'];
    collapseClass = [...collapseClass, 'd-block'];
  }

  let navBar = (
    <React.Fragment>
      <div className="m-header">
        {!simple && (
          <Link
            to="#"
            className={toggleClass.join(' ')}
            id="mobile-collapse"
            onClick={navToggleHandler}
          >
            <span />
          </Link>
        )}
        <Link to="#" className="b-brand">
          <img
            id="main-logo"
            src={logo}
            alt=""
            className="logo"
            height={30}
            width={30}
          />
          <p
            className="pl-1 mt-3"
            style={{
              fontStyle: 'inherit',
              color: '#121F49',
            }}
          >
            AIM-UP
          </p>
        </Link>
        <Link
          to="#"
          className={moreClass.join(' ')}
          onClick={() => setMoreToggle(!moreToggle)}
        >
          <i className="feather icon-more-vertical text-primary" />
        </Link>
      </div>
      <div className={collapseClass.join(' ')}>
        {!simple && <NavLeft user={user} />}
        <NavRight continueTour={continueTour} perf={perf} />
      </div>
    </React.Fragment>
  );

  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    navBar = <div className="container">{navBar}</div>;
  }

  return (
    <React.Fragment>
      <header className={headerClass.join(' ')}>{navBar}</header>
    </React.Fragment>
  );
};

export default NavBar;
