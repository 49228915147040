import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { Table, Avatar, Space, Typography, Card, Tooltip, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DateHeader from './dateHeader';
import socketHandler from '../../config/socket';
import DepartmentFilter from '../../components/departmentFilter';
import ActivityFilter from './activityFilter';
import { getTimesheetRequests } from '../../redux/timesheets/action';
import { renderColumn4, parseTimeSheets } from './util';
import Item from './requestItem';
import { styles } from './style';
const { Text } = Typography;
const renderTitle = (title) => {
  return (
    <div>
      <Text strong className="d-block text-light">
        {title}
      </Text>
    </div>
  );
};
const renderTime = (time) => {
  return <Text>{(time / 60).toFixed(3)}</Text>;
};
const renderDate = (date) => {
  return <Text>{moment(date).format('HH:mm:ss')}</Text>;
};
export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { requests, initialR } = useSelector(
    (state) => state.timesheetsReducer
  );
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedDpt, setDpt] = useState(null);
  const [activity, setActivity] = useState(null);
  const [period, setPeriod] = useState({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
  });
  const depRef = useRef(null);
  const actRef = useRef(null);
  const periodRef = useRef({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
  });

  const stopLoader = () => {
    setLoader(false);
  };

  const fetchData = (start, end) => {
    setLoader(true);
    dispatch(
      getTimesheetRequests(start, end, selectedDpt, activity, stopLoader)
    );
  };
  useEffect(() => {
    if (initialR) {
      const start = moment(period.start).toDate();
      const end = moment(period.end).toDate();
      setLoader(true);
      dispatch(
        getTimesheetRequests(start, end, selectedDpt, activity, stopLoader)
      );
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(requests)) {
      const data = parseTimeSheets(requests, true);
      setData(data);
    }
  }, [requests]);

  const refreshData = () => {
    const start = moment(period.start).toDate();
    const end = moment(period.end).toDate();
    setLoader(true);
    dispatch(
      getTimesheetRequests(start, end, selectedDpt, activity, stopLoader)
    );
  };

  const onChangeTimePeriod = (selection) => {
    const start = moment(selection.startDate).startOf('day').toDate();
    const end = moment(selection.endDate).endOf('day').toDate();
    setPeriod({ start: start, end: end });
    periodRef.current = { start: start, end: end };
    fetchData(start, end);
  };

  const goDetails = (user) => {
    history.push(`/app/timesheets/${user.id}`);
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    depRef.current = dept;
    const start = moment(period.start).toDate();
    const end = moment(period.end).toDate();
    setLoader(true);
    dispatch(getTimesheetRequests(start, end, dept, activity, stopLoader));
  };

  const onChangeActivity = (value) => {
    setActivity(value);
    actRef.current = value;
    const start = moment(period.start).toDate();
    const end = moment(period.end).toDate();
    setLoader(true);
    dispatch(getTimesheetRequests(start, end, selectedDpt, value, stopLoader));
  };

  const fetchUpdates = useCallback((data) => {
    const start = moment(periodRef?.current?.start).toDate();
    const end = moment(periodRef?.current?.end).toDate();
    dispatch(
      getTimesheetRequests(
        start,
        end,
        depRef?.current,
        actRef?.current,
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    socketHandler.on('timesheetRequest', fetchUpdates);
    return () => {
      socketHandler.off('timesheetRequest', fetchUpdates);
    };
  }, []);

  const renderAvatar = (user) => {
    if (user) {
      return (
        <Space
          align="center"
          className="c-pointer"
          onClick={() => goDetails(user)}
        >
          <Avatar
            style={styles.avatar}
            src={
              user?.profilePic ??
              'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
            }
            size="medium"
          />
          <Text strong className="pl-2 font-weight-bold">
            {user?.name?.split(' ')[0]}
          </Text>
        </Space>
      );
    }
  };

  return (
    <React.Fragment>
      <DateHeader
        onChange={(dates) => {
          onChangeTimePeriod(dates);
        }}
        exportExcel={false}
        single={true}
      />
      <Space className="flex-wrap">
        <DepartmentFilter
          pickerStyle={{
            width: 215,
          }}
          onSelectDpt={onChangeDept}
        />
        <ActivityFilter
          pickerStyle={{
            width: 215,
          }}
          onChange={onChangeActivity}
        />
        <Tooltip title="Fetch Latest Data">
          <Button onClick={refreshData}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>
      <Row className="mt-3">
        <Col md={12}>
          <Card className="mb-5">
            <Table
              className="table-curved"
              columns={renderColumn4(
                renderTitle,
                renderAvatar,
                renderTime,
                renderDate
              )}
              dataSource={data}
              scroll={data.length > 0 && { x: true }}
              pagination={false}
              size="medium"
              loading={loader}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <TableRB responsive="md">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Clock In</th>
                          <th>Clock Out</th>
                          <th className="d-none d-sm-table-cell">
                            Hours Worked
                          </th>
                          <th className="d-none d-sm-table-cell">
                            Break Hours
                          </th>
                          <th className="d-none d-sm-table-cell">Status</th>
                          <th align="center">Actions</th>
                        </tr>
                      </thead>

                      {record?.shifts?.map((arr, i) => {
                        return arr
                          .sort(function (a, b) {
                            return new Date(b.date) - new Date(a.date);
                          })
                          .map((item, index) => {
                            return (
                              <Item
                                item={item}
                                key={index}
                                showAction={index == 0}
                                sheets={arr}
                                refreshData={refreshData}
                                user={record.members}
                              />
                            );
                          });
                      })}
                    </TableRB>
                  );
                },
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
