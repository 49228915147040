export const exportSchema = {
  name: { label: 'Display Name', format: 'lowercase', active: true },
  fname: { label: 'First Name', format: 'lowercase', active: true },
  lname: { label: 'Last Name', format: 'lowercase', active: true },

  jobType: { label: 'Job Title', format: 'lowercase', active: true },
  role: { label: 'Role', format: 'lowercase', active: true },

  organization: {
    label: 'Department',
    format: 'lowercase',
    active: true,
  },
  date: { label: 'TimeSheet Date', format: 'DD-MM-YYYY', active: true },
  customer: { label: 'Activity', format: 'lowercase', active: true },
  clockin: { label: 'Start Time', format: 'HH:mm:ss', active: true },
  clockout: { label: 'End Time', format: 'HH:mm:ss', active: true },
  totalTime: { label: 'Work Duration', format: '1', active: true },
  breakTime: { label: 'Break Duration', format: '1', active: true },
  overTime: { label: 'OverTime Duration', format: '1', active: true },
  time: {
    label: 'Work Without Break Duration',
    format: '1',
    active: true,
  },
  // location: { label: 'Location', format: 'lowercase', active: true },
};
