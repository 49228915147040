import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Form, Select, Typography, Modal, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Talk from 'talkjs';
import { getAllEmployees } from '../../redux/shifts/action';
import DepartmentSelector from '../../components/departmentFilter';
const { Text } = Typography;
let avatar =
  'https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg';

export default function Index({ show, setClose }) {
  let chatRef = useRef();
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { user } = useSelector((state) => state.auth);
  const [mLoader, setMLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dept, setDpt] = useState(null);

  const stopLoaderEmp = () => {
    setMLoader(false);
  };

  useEffect(() => {
    setMLoader(true);
    dispatch(getAllEmployees(stopLoaderEmp));
  }, []);

  const addNewMember = (values) => {
    let { employee } = values;
    startConversation(JSON.parse(employee));
  };

  const onChangeDept = (dept) => {
    setDpt(dept);
    setMLoader(true);
    dispatch(getAllEmployees(stopLoaderEmp, dept));
  };

  const startConversation = async (other) => {
    try {
      setLoader(true);
      await Talk.ready;
      var me = new Talk.User({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        photoUrl: user?.profilePic ?? avatar,
        role: 'default',
      });
      var other = new Talk.User({
        id: other?.id,
        name: other?.name,
        email: other?.email,
        photoUrl: other?.profilePic ?? avatar,
        role: 'default',
      });
      if (!window.talkSession) {
        window.talkSession = new Talk.Session({
          appId: 'tLInDPcW',
          me: me,
        });
      }
      let conversationId = Talk.oneOnOneId(me, other);
      let conversation =
        window.talkSession.getOrCreateConversation(conversationId);
      conversation.setParticipant(me);
      conversation.setParticipant(other);
      let inbox = window.talkSession.createInbox({
        selected: conversation,
      });
      inbox.mount(chatRef?.current);
      await conversation.sendMessage('Hello');
      inbox.destroy();
      setLoader(false);
      setClose();
    } catch (error) {
      setLoader(false);
      message.warning('Try Again!');
    }
  };

  return (
    <Modal
      onCancel={setClose}
      title="Start New Conversation"
      visible={show}
      footer={null}
    >
      <React.Fragment>
        <div
          style={{
            height: 0,
            width: 0,
          }}
          ref={chatRef}
        />
        <Row>
          <Col md={12}>
            <Form layout="vertical" form={form} onFinish={addNewMember}>
              {user?.role !== 'user' && (
                <div className="pb-3">
                  <Text className="d-block pb-2">
                    Filter employee by Department
                  </Text>
                  <DepartmentSelector
                    onSelectDpt={onChangeDept}
                    pickerStyle={{
                      width: '100%',
                      marginBottom: 10,
                    }}
                    size="large"
                  />
                </div>
              )}
              <Form.Item
                label="Employee"
                tooltip="This is a required field"
                className="mb-1"
                name="employee"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Employees',
                  },
                ]}
              >
                <Select
                  size={'large'}
                  placeholder="Please select"
                  style={{ width: '100%' }}
                  loading={mLoader}
                  disabled={mLoader}
                >
                  {allEmployees
                    .filter((item) => item.id !== user.id)
                    .map((item) => {
                      return (
                        <Option
                          key={JSON.stringify(item)}
                        >{`${item.name}`}</Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <button
                className="btn py-2 btn-primary btn-block w-100 mt-3"
                htmlType="submit"
                disabled={loader}
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Add'
                )}
              </button>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    </Modal>
  );
}
