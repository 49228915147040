import React, { useState, useEffect } from 'react';
import { NavLink, Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Input, Form, message } from 'antd';
import { Spinner } from 'react-bootstrap';
import authLogoDark from '../../assets/images/auth/auth-logo-dark.png';
import { styles } from './style';
import { changePasswordAction } from '../../redux/auth/action';

const Index = ({ history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      message.success('Password Successfully Changed! Now you can login');
      history.push('/auth/login');
    }
  };
  const changePassword = (values) => {
    setLoader(true);

    dispatch(changePasswordAction(id, values?.password, stopLoader));
  };

  if (!id) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <React.Fragment>
      <div className="auth-wrapper align-items-stretch aut-bg-img">
        <div className="flex-grow-1">
          <div className="h-100 d-md-flex align-items-center auth-side-img">
            <div className="col-sm-10 auth-content w-auto">
              <h1 className="text-white my-4">Change securely!</h1>
              <h4 className="text-white font-weight-normal">
                Change your account password and make your self more securely
                Gigsasa Member
              </h4>
            </div>
          </div>
          <div className="auth-side-form">
            <div className=" auth-content">
              <div className="d-flex justify-content-center">
                <img
                  src={authLogoDark}
                  alt=""
                  className="img-fluid mb-4"
                  style={styles.logo}
                />
                <h2 className="pl-3">Gigsasa</h2>
              </div>
              <h4 className="mb-3 f-w-400">Change your password</h4>
              <Form layout="vertical" form={form} onFinish={changePassword}>
                <Form.Item
                  name="password"
                  label="New Password"
                  required
                  tooltip="This is a required field"
                  rules={[
                    {
                      required: true,
                      message: 'Please input New password!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter your new password"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  dependencies={['password']}
                  label="Confirm Password"
                  required
                  tooltip="This is a required field"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm new password"
                    size="large"
                  />
                </Form.Item>
                <button
                  htmlType="submit"
                  className="btn btn-block btn-primary mb-4"
                >
                  {loader ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    'Change Password'
                  )}
                </button>
              </Form>
              <div className="text-center">
                <div className="saprator my-4">
                  <span>OR</span>
                </div>
                <p className="mb-0 text-muted">
                  Don’t have an account?{' '}
                  <NavLink to="/auth/signup" className="f-w-400">
                    Sign up
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;
