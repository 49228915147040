import React from 'react';
import { Typography, Image } from 'antd';
import { Fade, Slide } from 'react-awesome-reveal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styles } from './style';
import EmailVerify from '../../assets/images/auth/verifyEmail.png';
import { logoutUser } from '../../redux/auth/action';
const { Text } = Typography;

function LeftApp() {
  return (
    <Fade>
      <Image src={EmailVerify} className="img-responsive" preview={false} />
    </Fade>
  );
}

function RightApp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const openApp = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.gigsasatimetrackerrn',
      '_blank'
    );
    dispatch(logoutUser());
    history.push('/auth/login');
  };
  return (
    <Slide direction="right">
      <div>
        <h3 className="font-weight-bold  pt-4">Request Sent</h3>
        <Text className="d-block" style={styles.desc}>
          Your request to join has been sent to the
        </Text>
        <Text className="d-block" style={styles.desc}>
          organization owner. A notification will be sent
        </Text>
        <Text className="d-block" style={styles.desc}>
          to you once it has been approved.
        </Text>
        <div className="pt-4" />
        <Text className="d-block" style={styles.desc}>
          In the meantime download our app so that you are
        </Text>
        <Text className="d-block" style={styles.desc}>
          ready once you are approved.
        </Text>
        <button
          className="btn btn-primary mb-4 mt-5"
          style={styles.button}
          onClick={openApp}
        >
          Download App
        </button>
      </div>
    </Slide>
  );
}

export { LeftApp, RightApp };
