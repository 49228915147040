import React from 'react';
import { Drawer, Image, Typography, Divider } from 'antd';
import MapAddress from '../../components/mapAddress';
import { styles } from './style';

const Index = ({ visible, onClose, item }) => {
  const { Text } = Typography;
  const { file, location } = item;

  return (
    <Drawer
      title={`${item?.user?.name}`}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={340}
      zIndex={4234234234234243}
      bodyStyle={styles.sideDetails}
    >
      {file && (
        <div className="position-relative">
          <Text strong className="pb-2 d-block">
            Attachment
          </Text>
          <Image
            width={280}
            src={file}
            placeholder={
              <Image
                preview={false}
                src={`${file}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                width={280}
              />
            }
          />
        </div>
      )}

      <>
        <Divider />
        <MapAddress
          latitude={location?.latitude}
          longitude={location?.longitude}
          title="Location"
        />
      </>

      {item?.text && (
        <div className="w-100 mt-3">
          <Text className="d-block" strong>
            Details
          </Text>
          <Text>{item?.text}</Text>
        </div>
      )}
    </Drawer>
  );
};

export default Index;
