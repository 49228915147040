import { MoreOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Spin } from 'antd';
import React, { memo, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { styles } from './style';
import { useDispatch } from 'react-redux';
import { declineRequest } from '../../../redux/swapShift/action';

function ShiftRequestItem({
  swapShift,
  leaveStatus,
  showPopover,
  reqItem,
  borderStatic,
  index,
  active,
  setActiveHandle,
  user,
}) {
  const [showPopOver, setShowPopOver] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const approvedModal = (item) => {
    setShowPopOver(false);
    swapShift(item);
  };

  const handleVisibleChange = () => {
    setShowPopOver((p) => !p);
  };
  const stopLoader = () => {
    setLoader(false);
    setShowPopOver(false);
  };
  const toggleDesc = () => {
    setShowDesc(!showDesc);
  };
  const declineReq = () => {
    setLoader(true);
    dispatch(
      declineRequest(
        { reqId: reqItem.id, allocation: reqItem.allocation.id },
        reqItem.createdBy?.id,
        stopLoader
      )
    );
  };

  return (
    <Col
      style={{ cursor: 'pointer' }}
      lg={12}
      onClick={() => {
        setActiveHandle?.(user, index);
      }}
    >
      <Spin style={{ width: '100%' }} spinning={loader} delay={500}>
        <Row
          className={`mt-4 p-2 superUserCard d-flex align-items-center hoverSuper  ${
            borderStatic || active === index ? 'border-primary' : 'border'
          }  w-100 mr-4 d-flex border `}
        >
          <Col
            lg={3}
            md={6}
            sm={12}
            className="d-flex h-100   align-items-start"
          >
            <div className="d-flex flex-column justify-content-center align-items-center ">
              {user ? (
                <>
                  <Avatar
                    size={50}
                    src={user?.profilePic}
                    icon={<UserOutlined />}
                  />
                  <p style={styles.swUserName}>{user?.name}</p>
                </>
              ) : (
                <>
                  <Avatar
                    size={50}
                    src={reqItem?.createdBy?.profilePic}
                    icon={<UserOutlined />}
                  />
                  <p style={styles.swUserName}>{reqItem?.createdBy?.name}</p>
                </>
              )}
            </div>
          </Col>
          {leaveStatus && (
            <Col
              lg={3}
              md={6}
              sm={12}
              className="leavedes  d-flex justify-content-start   c-pointer"
              onClick={toggleDesc}
            >
              <p
                style={{ ...styles.noMB }}
                className={`${showDesc ? '' : 'leaveDesTrun'} `}
              >
                {reqItem?.leaveReason}
              </p>
            </Col>
          )}

          <Col lg={3} md={6} className="d-flex justify-content-start">
            <div>
              <p style={{ ...styles.noMB, ...styles.shiftTime }}>
                {moment(reqItem?.shift?.start).format('hh:mm a')} -{' '}
                {moment(reqItem?.shift?.end).format('hh:mm a')}
              </p>
              <p style={{ color: '#757575', ...styles.noMB }}>
                {moment(reqItem?.allocation?.date).format('DD/MM/YYYY')}
              </p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} className="d-flex justify-content-start">
            <p style={{ ...styles.noMB, ...styles.swapName }}>
              {reqItem?.shift?.name}
            </p>
          </Col>
        </Row>
        {showPopover && (
          <div className="superPopover ">
            <Popover
              placement="right"
              trigger="click"
              content={
                <div className="d-flex flex-column">
                  <a
                    className="btn text-primary"
                    onClick={() => approvedModal(reqItem)}
                  >
                    Approve
                  </a>
                  <a className="btn text-primary" onClick={declineReq}>
                    Decline
                  </a>
                </div>
              }
              visible={showPopOver}
              onVisibleChange={handleVisibleChange}
            >
              <button
                style={styles.menuBtn}
                className="btn border-0 outline-0 swapMenuBtn"
              >
                <MoreOutlined size={30} style={{ fontSize: '1.5em' }} />
              </button>
            </Popover>
          </div>
        )}
      </Spin>
    </Col>
  );
}

export default ShiftRequestItem;
