import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
const { Text } = Typography;

const Index = (props) => {
  const { inTime } = useSelector((state) => state.clockReducer);
  const [counter, setCounter] = useState('0:0:0');
  useEffect(() => {
    const timer = setTimeout(() => {
      getCounterSeconds();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  const getCounterSeconds = () => {
    const result = new Date() - new Date(inTime);
    let duration = moment.duration(result, 'milliseconds');
    const interval = 1000;
    duration = moment.duration(duration + interval, 'milliseconds');
    setCounter(
      Math.floor(duration.asHours()) +
        ':' +
        duration.minutes() +
        ':' +
        duration.seconds()
    );
  };

  return <Text strong>{counter}</Text>;
};

export default Index;
