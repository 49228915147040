import React, { useState, useEffect, memo } from 'react';
import {
  Modal,
  Form,
  Input,
  Typography,
  Slider,
  List,
  Space,
  message,
  Checkbox,
} from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { debounce } from 'lodash';
import { createLocation } from '../../redux/locations/action';
import { styles } from './style';
const { Text, Link } = Typography;
let markerRef = null;
let circleRef = null;

export default memo(function Index({ show, handleOk, setClose }) {
  const dispatch = useDispatch();
  const { coords, user, company } = useSelector((state) => state.auth);
  const [mapRef, setMapRef] = useState(null);
  const [mapsRef, setMapsRef] = useState(null);
  const [name, setName] = useState('');
  const [latLng, setLatLng] = useState({
    lat: coords.latitude,
    lng: coords.longitude,
  });
  const [address, setAddress] = useState('');
  const [radiusValue, setRadius] = useState(100);
  const [noRad, setNoRad] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    markByLatLng(latLng, radiusValue);
  }, [mapRef, mapsRef, latLng, radiusValue]);

  const markByLatLng = debounce((center, radius) => {
    if (mapRef && mapsRef) {
      if (markerRef !== null && circleRef !== null) {
        markerRef.setMap(null);
        circleRef.setMap(null);
      }
      markerRef = new mapsRef.Marker({
        position: center,
        map: mapRef,
        title: 'Location of the site',
        draggable: true,
      });
      circleRef = new mapsRef.Circle({
        strokeColor: '#f69425',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#f69425',
        fillOpacity: 0.3,
        map: mapRef,
        center: center,
        radius: radius && parseFloat(radius),
      });
      circleRef.bindTo('center', markerRef, 'position');

      mapsRef.event.addListener(mapRef, 'dblclick', (e) => {
        setLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        getAddressByCoords(e.latLng.lat(), e.latLng.lng());
      });
      mapsRef.event.addListener(markerRef, 'dragend', () => {
        const latLng = {
          lat: markerRef.position.lat(),
          lng: markerRef.position.lng(),
        };
        getAddressByCoords(latLng.lat, latLng.lng);
        setLatLng(latLng);
      });
    }
  }, 500);

  const getAddressByCoords = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {}
    );
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      getAddressByCoords(lat, lng);
      setLatLng({ lat, lng });
    });
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    setMapRef(map);
    setMapsRef(maps);
  };

  const changeRadius = (value) => {
    setRadius(value);
  };

  const handleAddress = (value) => {
    setAddress(value);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      setAddress(results[0].formatted_address);
      const { lat, lng } = await getLatLng(results[0]);
      setLatLng({ lat, lng });
    } catch (err) {}
  };

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      message.success('Successfully Created Location!');
      handleOk();
    }
  };

  const onChangeCheck = (e) => {
    if (e.target.checked) {
      setRadius(0);
    }
    setNoRad(e.target.checked);
  };

  const createLocationFunc = () => {
    if (name === '') {
      message.warning('Location Name is required!');
    } else if (address === '') {
      message.warning('Location Address is required!');
    } else {
      setLoader(true);

      const locationObj = {
        name: name,
        address: address,
        coordinates: {
          latitude: latLng.lat,
          longitude: latLng.lng,
        },
        user: user.id,
        company: company.id,
      };

      if (!noRad) {
        locationObj.radius = radiusValue;
      }
      dispatch(createLocation(locationObj, stopLoader));
    }
  };

  return (
    <Modal
      title="Create Location"
      visible={show}
      onCancel={setClose}
      width={1000}
      footer={null}
    >
      <Row>
        <Col md={12} lg={12}>
          <Form layout="vertical">
            <Form.Item
              name="locName"
              label="Name of the site"
              tooltip="This is a required field"
            >
              <Input
                placeholder="Name of the location"
                size="large"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Text type="secondary">
                choose a name for your location, You can easily remember one
              </Text>
            </Form.Item>
            <Form.Item
              label="Address of the site"
              tooltip="This is a required field"
            >
              <PlacesAutocomplete
                value={address}
                onChange={handleAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className: 'location-search-input',
                      })}
                      size="large"
                    />
                    <Text type="secondary">
                      pick a nearby point of interest or the exact location{' '}
                    </Text>
                    <Link type="link" onClick={getCurrentLocation}>
                      Pick Current Location
                    </Link>
                    <div className="autocomplete-dropdown-container mt-2">
                      {loading && <div>.....</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? {
                              backgroundColor: '#fafafa',
                              cursor: 'pointer',
                            }
                          : {
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                            };
                        return (
                          <List
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <List.Item className="p-2">
                              {suggestion.description}
                            </List.Item>
                          </List>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Form.Item>
            <Checkbox onChange={onChangeCheck} className="mb-4">
              Disable Radius
            </Checkbox>

            {!noRad && (
              <Form.Item label="Select Radius in meters for geofencing">
                <Slider
                  defaultValue={radiusValue}
                  onChange={changeRadius}
                  min={12}
                  max={500}
                />
              </Form.Item>
            )}

            <div style={styles.mapContainer}>
              <div style={styles.mapValues}>
                <p>
                  <small>
                    Latitude: {latLng.lat} <br />
                    Longitude: {latLng.lng} <br />
                    Radius: {radiusValue && radiusValue} meters
                  </small>
                </p>
              </div>
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onGoogleApiLoaded}
                options={{
                  disableDoubleClickZoom: true,
                }}
                bootstrapURLKeys={{
                  key: 'AIzaSyD4bLo0gWQydOneofumWsQxOiy9VhC8PDo',
                }}
                center={latLng}
                defaultZoom={15}
              ></GoogleMapReact>
            </div>
            <Space className="mt-3 float-right">
              <button
                type="button"
                className="btn btn-light"
                onClick={setClose}
              >
                Cancel
              </button>
              <button
                disabled={loader}
                className="btn btn-primary"
                onClick={createLocationFunc}
              >
                {loader ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Create'
                )}
              </button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
});
