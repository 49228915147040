import { INSTANCE } from '../../config/networkInstance';

export default class SwapeShiftApi {
  createSwapShift = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'swapShift',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getSwapShifts = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'swapShift',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getPostedSwapShifts = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'swapShift/postedSwapShifts',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateSwap = (data, params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: 'swapShift',
        data,
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getAvailableShifts = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'swapShift/getAvailable',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  applyForShift = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'swapShift/applyForShift',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  assignShift = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'swapShift/assignShift',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  declineSwapReq = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'swapShift/declineRequest',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getUserSwaps = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'swapShift/getUserRequest',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
