/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import {
  Table,
  Popconfirm,
  Form,
  Checkbox,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  message,
  Avatar,
  Tag,
  Tooltip,
  Select,
  DatePicker,
} from 'antd';
import { ReloadOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import socketHandler from '../../config/socket';
import {
  getLeaveRequests,
  changeLeaveStatus,
  getLeaveTotals,
} from '../../redux/leaveManagement/action';
import { addType } from '../../redux/leave/action';
import { getAllDepartments } from '../../redux/members/action';
import { styles } from './style';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import UserDetailsModal from '../../components/userDetailsModal';
import TotalLeaves from './totalLeaves';
import { Tabs } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';

const { TabPane } = Tabs;
import { Input } from 'antd';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
var count = 0;
var mycount = true;

const Addpolicy = () => {
  const [mystate, setMysState] = useState(0);
  const [selectedDpt, setDpt] = useState(null);

  //start of my  name hooks
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [weekend, setWeekend] = useState(false);
  const [gender, setGender] = useState(false);
  const [advance, setAdvance] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const [deptLimit, setDeptLimit] = useState(false);
  const [consecutive, setConsecutive] = useState(false);
  const [overdraft, setOverdraft] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [daysprior, setDaysPrior] = useState(false);
  const [accrual, setAccrual] = useState(false);
  const [loader, setLoader] = useState(false);

  const [formData1, setformData1] = useState({});
  const [formData2, setformData2] = useState(null);
  const [level, setLevel] = useState([]);

  const stopLoader = (success) => {
    setLoader(false);
    if (success) {
      form?.resetFields();
      form1?.resetFields();
      message.success('Successfully Created!');
      setMysState(0);
    }
  };

  const dispatch = useDispatch();

  const { allDept } = useSelector((state) => state.membersReducer);
  useEffect(() => {
    if (allDept.length === 0) {
      setLoading(true);
      dispatch(getAllDepartments(() => setLoading(false)));
    }
  }, []);

  const sumitForm1 = (values) => {
    var obj = {
      name: values.name,
      code: values.code,
      description: values.description,
      paid: values.payment,
      unit: 'daily',
      accrual: { isSet: accrual, amount: values.accrual },
      days: values.days,
      month: values.month,
      day: values.day,
      from: values.effective[0],
      to: values.effective[1],
    };
    setformData1(obj);

    setMysState(1);
  };

  const addLevel = () => {
    setLevel([
      {
        department: null,
        role: null,
        id: new Date().valueOf(),
        pos: level.length,
      },
      ...level,
    ]);
  };

  const removeLevel = (index) => {
    setLevel([...level.filter((_) => _.id != index)]);
  };

  const updateRole = (index, role) => {
    setLevel(
      level.map((_) => {
        return _.id != index ? _ : { ..._, role };
      })
    );
  };

  const updateDept = (index, department) => {
    setLevel(
      level.map((_) => {
        return _.id != index ? _ : { ..._, department };
      })
    );
  };

  const sumitForm2 = (values) => {
    var obj = {
      ...formData1,
      gender: { type: values.gender, isSet: gender },
      department: { type: values.deptLimit, isSet: deptLimit },
      weekend: weekend,
      maximum: {
        isSet: consecutive,
        amount: values.consecutive ? values.consecutive : 0,
      },
      daysPrior: {
        isSet: daysprior,
        amount: values.daysprior ? values.consecutive : 0,
      },
      attachment: attachment,
      overdraft,
      company: currentUser?.company_id?.id,
    };

    dispatch(addType(obj, stopLoader));
  };
  const handleChange = () => {
    if ((mycount = false)) {
      mycount = true;
    } else {
      mycount = false;
    }
    // if (isSubscribed == false) {
    //   setIsSubscribed((isSubscribed = true));
    // } else {
    //   setIsSubscribed((isSubscribed = false));
    // }
  };
  const { Option } = Select;

  const handlepolicy = () => {};

  const handleAccrual = (event) => {};

  return (
    <React.Fragment>
      <>
        <div id="content" className="py-4 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {mystate == 0 ? (
                  <Form layout="vertical" form={form} onFinish={sumitForm1}>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="name"
                          label="Name"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <Input size="large" placeholder="Leave Policy Name" />
                        </Form.Item>
                      </div>
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="code"
                          label="Code"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <Input size="large" placeholder="Leave Policy Code" />
                        </Form.Item>
                      </div>

                      <div className="form-group col-md-4">
                        <Form.Item name="unit" label="Unit">
                          <Select size="large" disabled defaultValue="daily">
                            <Option value="daily">Daily</Option>
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="form-group col-xl-8 col-md-12">
                        <Form.Item
                          name="description"
                          label="Description"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <TextArea
                            type="text"
                            placeholder="description"
                            size="large"
                            rows={4}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="payment"
                          label="Payment"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <Select size="large">
                            <Option value={true}>Paid</Option>
                            <Option value={false}>Non-paid</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="effective"
                          label="Effective from"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <RangePicker
                            size="large"
                            renderExtraFooter={() => 'extra footer'}
                            showTime
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="days"
                          label="Amount of Days"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <Input
                            type="number"
                            size="large"
                            placeholder="Amount of Days"
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-row col-12">
                      <div className="form-group text-3 col-md-2">
                        <Form.Item name="days">
                          <Checkbox
                            onChange={(e) => {
                              setAccrual(e.target.checked);
                            }}
                          >
                            Accrual
                          </Checkbox>
                        </Form.Item>
                      </div>

                      <div className="form-group col-md-4">
                        {accrual && (
                          <Form.Item
                            name="accrual"
                            label="Days Carried Forward"
                            required
                            rules={[
                              { required: true, message: 'Please Enter Name ' },
                            ]}
                          >
                            <Input
                              type="number"
                              size="large"
                              placeholder="Amount of Days"
                            />
                          </Form.Item>
                        )}
                      </div>
                    </div>

                    <div className="form-row col-12">
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="month"
                          label="Reset Month"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Name ' },
                          ]}
                        >
                          <Select size="large">
                            <Option value="January">January</Option>
                            <Option value="February">February</Option>
                            <Option value="March">March</Option>
                            <Option value="April">April</Option>
                            <Option value="May">May</Option>
                            <Option value="June">June</Option>
                            <Option value="July">July</Option>
                            <Option value="August">August</Option>
                            <Option value="Sepetember">Sepetember</Option>
                            <Option value="October">October</Option>
                            <Option value="November">November</Option>
                            <Option value="Decemenber">December</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="form-group col-md-4">
                        <Form.Item
                          name="day"
                          label="Reset Day"
                          required
                          rules={[
                            { required: true, message: 'Please Enter Day ' },
                          ]}
                        >
                          <Select size="large">
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                            <Option value="7">7</Option>
                            <Option value="8">8</Option>
                            <Option value="9">9</Option>
                            <Option value="10">10</Option>
                            <Option value="11">11</Option>
                            <Option value="12">12</Option>
                            <Option value="13">13</Option>
                            <Option value="14">14</Option>
                            <Option value="15">15</Option>
                            <Option value="16">16</Option>
                            <Option value="17">17</Option>
                            <Option value="18">18</Option>
                            <Option value="19">19</Option>
                            <Option value="20">20</Option>
                            <Option value="21">21</Option>
                            <Option value="22">22</Option>
                            <Option value="23">23</Option>
                            <Option value="24">24</Option>
                            <Option value="25">25</Option>
                            <Option value="26">26</Option>
                            <Option value="27">27</Option>
                            <Option value="28">28</Option>
                            <Option value="29">29</Option>
                            <Option value="30">30</Option>
                            <Option value="31">31</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-sm-12 text-right">
                      <button
                        htmlType="submit"
                        className="btn btn-primary btn-sm pr-2 pl-2 text-right"
                      >
                        Next Step
                        <i className="bx bx-right-arrow-alt ml-3 nav_icon" />
                      </button>
                    </div>
                  </Form>
                ) : (
                  <>
                    {/* <Form layout="vertical" >
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setAdvance(e.target.checked);
                            }}
                          >
                            Advance Permissions
                          </Checkbox>
                        </div>
                        <div className="form-group col-md-8">
                          {advance && (
                            <>
                              <div className="d-flex justify-content-between">
                                {' '}
                                <h5>Permissions Level</h5>{' '}
                                <button
                                  onClick={addLevel}
                                  className="btn btn-primary mx-2 px-2"
                                >
                                  {' '}
                                  Add Permissions Level{' '}
                                </button>
                              </div>

                              {level.map((_, index) => (
                                <div className="row mt-3" key={_.id}>
                                  <div className="col-5">
                                    <Select
                                      required
                                      onChange={(value) => {
                                        updateDept(_.id, value);
                                      }}
                                      style={{ width: '100%' }}
                                      size="large"
                                      placeholder="Department"
                                    >
                                      {allDept.map((_) => (
                                        <Option value={_.id}>{_.name}</Option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="col-5">
                                    <Select
                                      required
                                      onChange={(value) => {
                                        updateRole(_.id, value);
                                      }}
                                      style={{ width: '100%' }}
                                      size="large"
                                      placeholder="Role"
                                    >
                                      <Option value={'supervisor'}>
                                        supervisor
                                      </Option>
                                      <Option value={'admin'}>Admin</Option>
                                    </Select>
                                  </div>
                                  <div
                                    onClick={() => {
                                      removeLevel(_.id);
                                    }}
                                    className="col-2"
                                  >
                                    <Button
                                      shape="circle"
                                      type="danger"
                                      className=" mx-2"
                                    >
                                      {' '}
                                      <CloseOutlined />{' '}
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </Form> */}

                    <Form layout="vertical" onFinish={sumitForm2}>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setGender(e.target.checked);
                            }}
                          >
                            Gender
                          </Checkbox>
                          {gender && (
                            <Form.Item
                              name="gender"
                              defaultValue="All"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter Name ',
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                defaultValue="All"
                                // value={marital}
                              >
                                {' '}
                                <Option value="All">All</Option>
                                <Option value="Male">Male</Option>
                                <Option value="Female">Female</Option>
                              </Select>
                            </Form.Item>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setDeptLimit(e.target.checked);
                            }}
                          >
                            Limit By Department{' '}
                          </Checkbox>
                          {deptLimit && (
                            <Form.Item
                              name="deptLimit"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Select Department ',
                                },
                              ]}
                            >
                              <Select size="large" mode="multiple">
                                {allDept.map((_) => (
                                  <Option key={_.id} value={_.id}>
                                    {_.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </div>

                        <div className="form-group col-md-4"></div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setWeekend(e.target.checked);
                            }}
                          >
                            Exclude Weekends
                          </Checkbox>
                        </div>

                        <div className="form-group col-xl-4 col-md-12"></div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setConsecutive(e.target.checked);
                            }}
                          >
                            {' '}
                            Limit by Maximum consecutive Days{' '}
                          </Checkbox>
                        </div>

                        <div className="form-group col-xl-4 col-md-12">
                          {consecutive && (
                            <Form.Item
                              name="consecutive"
                              label="Amount of Days"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter Maxium Days ',
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                size="large"
                                placeholder="Amount of Days"
                              />
                            </Form.Item>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setDaysPrior(e.target.checked);
                            }}
                          >
                            Applications should be made days prior{' '}
                          </Checkbox>
                        </div>

                        <div className="form-group col-xl-4 col-md-12">
                          {daysprior && (
                            <Form.Item
                              name="daysprior"
                              label="Amount of Days"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter Amount of Days',
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                size="large"
                                placeholder="Amount of Days"
                              />
                            </Form.Item>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setAttachment(e.target.checked);
                            }}
                          >
                            Enable file Upload{' '}
                          </Checkbox>
                        </div>

                        <div className="form-group col-xl-4 col-md-12"></div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <Checkbox
                            onChange={(e) => {
                              setOverdraft(e.target.checked);
                            }}
                          >
                            Enable Overdraft{' '}
                          </Checkbox>
                        </div>
                      </div>

                      <div className="text-left pt-3">
                        <button
                          htmlType="button"
                          onClick={() => setMysState(0)}
                          className="btn btn-secondary btn-sm mx-2 pr-2 pl-2 text-right"
                        >
                          Previous Step
                          <i className="bx bx-right-arrow-alt ml-3 nav_icon" />
                        </button>

                        <button
                          htmlType="submit"
                          className="btn btn-primary btn-sm pr-2 pl-2 text-right"
                        >
                          Save Policy
                          <i className="bx bx-right-arrow-alt ml-3 nav_icon" />
                        </button>
                      </div>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default memo(Addpolicy);
