import React, { useState, useRef } from 'react';
import { Modal } from 'antd';
import { Spinner } from 'react-bootstrap';

import OtpInput from 'react-otp-input';
import { styles } from './styles';

function OtpInputModal(props) {
  const [otp, setOtp] = useState('');
  const [isResentTimer, setResentTimer] = useState(false);
  const [timer, setTimer] = useState(60);
  const counterRef = useRef(60);

  const onChangeOtp = (value) => {
    setOtp(value);
  };

  const onResendOtp = () => {
    setResentTimer(true);
    props.onResendOtp?.();
    const interval = setInterval(() => {
      if (counterRef.current === 0) {
        setResentTimer(false);
        setTimer(60);
        counterRef.current = 60;
        clearInterval(interval);
      } else {
        counterRef.current -= 1;

        setTimer(counterRef.current);
      }
    }, 1000);
  };

  return (
    <Modal
      className="welcomeModalCon"
      bodyStyle={{ padding: 0, borderRadius: 20, overflow: 'hidden' }}
      centered
      width={600}
      visible={props.isVisible}
      onCancel={() => props.onClose?.()}
      footer={null}
    >
      <div className="modalCont">
        {/* header */}
        <div className="modalHeader">
          <p className="headerHeading" style={styles.modalHeading}>
            Verify your phone number
          </p>
        </div>
        <div style={styles.modalCont}>
          <div>
            <p>Please type the verification code sent to {props.newPhone}</p>
          </div>

          <div style={styles.otpCont}>
            <OtpInput
              value={otp}
              onChange={onChangeOtp}
              numInputs={6}
              separator={<span style={styles.otpDivider}>-</span>}
              containerStyle={styles.otpContStyle}
              inputStyle={styles.otpInput}
            />
          </div>

          <div className="comSaveCont">
            <div>
              <button
                className="btn mb-0 mt-4 py-2"
                onClick={onResendOtp}
                style={styles.resendOtp}
                disabled={isResentTimer}
              >
                {isResentTimer ? `${timer} OTP sent` : 'Resend OTP'}
              </button>
              <button
                disabled={otp.length !== 6}
                onClick={() => props.onVerify(otp)}
                className="btn btn-primary mb-0 mt-4 py-2"
                style={styles.ctaVerify}
              >
                {props.loader ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  'Verify'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OtpInputModal;
