import React, { useEffect, useState, useRef } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { styles } from './style';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { verifyFaceRekognition } from '../../redux/clockinout/action';

function Index({ isShow, closeModal, getImage }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [uri, setURI] = useState(null);
  const [loader, setLoader] = useState(false);
  const streamRef = useRef(null);

  const stopLoader = (response, img) => {
    setLoader(false);
    if (response) {
      getImage(img);
    } else if (response == null) {
      getImage(img, true);
    } else {
      closeModal();
    }
    closeStream();
  };
  const saveScreenshot = (img) => {
    const faceData = {
      image: img,
      id: user?.id,
      company: user?.company_id?.id ?? user?.company_id,
    };
    setLoader(true);
    dispatch(verifyFaceRekognition(faceData, img, stopLoader));
  };

  function handleTakePhoto(dataUri) {
    setURI(dataUri);
    saveScreenshot(dataUri.replace('png', 'jpeg'));
  }

  const closeStream = () => {
    if (streamRef?.current) {
      streamRef?.current?.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  useEffect(() => {
    if (isShow) {
      setURI(null);
    }
  }, [isShow]);

  return (
    <Modal
      visible={isShow}
      centered
      closable={true}
      footer={false}
      onCancel={() => {
        closeStream();
        closeModal();
      }}
      title="Verify It's You!"
      width={830}
      zIndex={1050}
    >
      {isShow ? (
        <div>
          {uri ? (
            <div>
              <img
                src={uri}
                style={{ width: '100%' }}
                className="img-responsive"
              />
              {loader && (
                <div style={styles.buttonLoader}>
                  <Spin tip="Verifying..." className="text-white" />
                </div>
              )}
            </div>
          ) : (
            <Camera
              onTakePhoto={handleTakePhoto}
              isFullscreen={false}
              idealResolution={{
                height: 400,
                width: 500,
              }}
              onCameraStart={(stream) => (streamRef.current = stream)}
            />
          )}
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
}

export default Index;
