import { INSTANCE } from '../../config/networkInstance';

export default class ChargeBeePaymentApi {
  getPlans = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment',
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getCardUrl = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment/addCard',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSubscription = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment/subscribe',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSubscriptionStatus = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment/subscriptionStatus',
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateSubscription = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'chargeBeePayment/updateSubscription',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getPortalUrl = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment/portal_session',
      })
        .then(resolve)
        .catch(reject);
    });
  };
  cancelSubscription = () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment/cancelSubscription',
      })
        .then(resolve)
        .catch(reject);
    });
  };
  subscribe = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'chargeBeePayment/subscribe',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getCustomer = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'chargeBeePayment/customer',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
