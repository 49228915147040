import React, { memo } from 'react';
import { Input, InputNumber, Form, Tag, Select, Typography } from 'antd';
const { Option } = Select;
const { Text } = Typography;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  allDept,
  dLoader,
  customerTypes,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' ? (
      <Select placeholder="Select Type">
        {customerTypes?.map((item, i) => {
          return (
            <Option value={item} key={i}>
              {item}
            </Option>
          );
        })}
      </Select>
    ) : inputType === 'number' ? (
      <InputNumber />
    ) : inputType === 'tag' ? (
      <Select
        className="w-100"
        loading={dLoader}
        disabled={dLoader}
        mode="tags"
        placeholder="Select Departments"
        defaultValue={children[1]}
      >
        {allDept?.length &&
          allDept?.map((item, i) => {
            return (
              <Option key={item.id} value={item?.id}>
                {item?.name}
              </Option>
            );
          })}
      </Select>
    ) : inputType === 'status' ? (
      <Select className="w-100" placeholder="Active Status">
        {[
          { title: 'active', value: true },
          { title: 'disabled', value: false },
        ].map((item, i) => {
          return (
            <Option key={i} value={item?.value}>
              {item?.title}
            </Option>
          );
        })}
      </Select>
    ) : (
      <Input />
    );

  const data =
    dataIndex === 'departments' ? (
      <>
        {children[1].length > 0 ? (
          <>
            {children[1].map((tag) => {
              return (
                <Tag color="blue" key={tag?.id}>
                  {tag?.name}
                </Tag>
              );
            })}
          </>
        ) : (
          <Text>No Department</Text>
        )}
      </>
    ) : (
      children
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          className="m-0 p-0"
          rules={[
            {
              required: dataIndex !== 'departments',
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        data
      )}
    </td>
  );
};

export default memo(EditableCell);
