import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

function Index({ isShow, closeModal, getImage }) {
  const [uri, setURI] = useState(null);
  const streamRef = useRef(null);
  const saveScreenshot = (img) => {
    getImage(img);
    closeModal();
    closeStream();
  };

  function handleTakePhoto(dataUri) {
    setURI(dataUri);
    saveScreenshot(dataUri.replace('png', 'jpeg'));
  }

  useEffect(() => {
    if (isShow) {
      setURI(null);
      getImage(null);
    }
  }, [isShow]);

  const closeStream = () => {
    if (streamRef?.current) {
      streamRef?.current?.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  return (
    <Modal
      visible={isShow}
      centered
      closable={true}
      footer={false}
      onCancel={() => {
        closeStream();
        closeModal();
      }}
      title="Add Facial Recognition"
      width={830}
      zIndex={1050}
    >
      {isShow ? (
        <div>
          {uri ? (
            <div>
              <img
                src={uri}
                style={{ width: '100%' }}
                className="img-responsive"
              />
            </div>
          ) : (
            <Camera
              onTakePhoto={handleTakePhoto}
              isFullscreen={false}
              idealResolution={{
                height: 400,
                width: 500,
              }}
              onCameraStart={(stream) => (streamRef.current = stream)}
            />
          )}
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
}

export default Index;
