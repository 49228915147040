import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { RightOutlined } from '@ant-design/icons';
import AuthLayout from '../layouts/authlayout';
import { Typography, Checkbox, Image, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import authApi from '../../redux/auth/authApi';
import FacebookButton from './facebookButton';
import GoogleButton from './googleButton';
import { signup } from '../../redux/auth/action';
import { styles } from './style';
import Register from '../../assets/images/illustrations/signup.png';
import Logo from '../../assets/images/logos/logo1.png';
//import Register from '../../assets/images/auth/register.png';
let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const { Text } = Typography;
const Api = new authApi();

function Index({ history }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [vloader, setVLoader] = useState(false);

  const stopLoader = (success, user = null) => {
    setLoader(false);
    if (success && user) {
      return history.push('/auth/verifyEmail', {
        email: user.email ? user.email : user.phone,
      });
    }
  };

  const goLogin = () => {
    history.push('/auth/login');
  };

  const registerUser = async (values) => {
    const {
      other_names,
      first_name,
      type,
      password,
      isSocialAuth = false,
      isEmailVerified = false,
    } = values;

    let email = null;
    let phone = null;

    if (type?.match?.(regexEmail)) {
      email = type;
    } else {
      phone = String(type)[0] == '+' ? type : `+${type}`;
      setVLoader(true);
      let res = (await Api.validatePhone(phone)).data;
      setVLoader(false);
      if (!res.valid) {
        message.warning('Invalid Phone Number!');
        return;
      }
    }

    setLoader(true);
    dispatch(
      signup(
        {
          other_names,
          first_name,
          ...(email && { email }),
          ...(phone && { phone }),
          password,
          isSocialAuth,
          isEmailVerified,
        },
        stopLoader
      )
    );
  };

  const handleSocialSignup = (user) => {
    const values = {
      email: user?._profile?.email,
      password: `${user?._profile?.firstName}_${user?._profile?.id}`,
      first_name: user?._profile?.firstName,
      other_names: user?._profile?.lastName,
      isSocialAuth: true,
      isEmailVerified: true,
    };
    registerUser(values);
  };

  const handleSocialSignupFailure = () => {};
  return (
    <AuthLayout
      left={
        <div>
          <Image
            src={Register}
            preview={false}
            className="text-center img-responsive mt-auto mb-auto"
          />
        </div>
      }
      right={
        <>
          <Image
            src={Logo}
            preview={false}
            height={60}
            className="text-center img-responsive mt-auto mb-auto"
          />
          <Row className="w-100 justify-content-center">
            <Col lg={7} xl={8} xxl={9}>
              <Form form={form} layout="vertical" onFinish={registerUser}>
                <Text
                  className="d-block  text-primary font-weight-bold "
                  style={styles.desc}
                >
                  SIGN UP
                </Text>
                <Row className="mt-4">
                  <Col sm={12}>
                    <Form.Item
                      name="type"
                      className="mb-0 pb-0  font-weight-bold"
                      label="YOUR EMAIL / PHONE"
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: 'Email / Phone is required',
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        placeholder="Type Your Email / Phone"
                        style={styles.input}
                      />
                    </Form.Item>
                    <Text
                      style={{ fontSize: 12 }}
                      type="secondary"
                      className="d-block mt-1 mb-3"
                    >
                      {vloader ? 'The information is being validated...' : ''}
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} lg={6}>
                    <Form.Item
                      name="first_name"
                      className="mb-3 font-weight-bold"
                      label="FIRST NAME"
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: 'First Name is required',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Type Your First Name"
                        style={styles.input}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Form.Item
                      name="other_names"
                      className="mb-3 font-weight-bold"
                      label="LAST NAME"
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: 'Last Name is required',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Type Your Last Name"
                        style={styles.input}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={6}>
                    <Form.Item
                      name="password"
                      label="YOUR PASSWORD"
                      className="mb-3 font-weight-bold"
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: 'Password is required',
                        },
                        {
                          min: 8,
                          message: 'Min 8 characters',
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Type Your Password"
                        size="large"
                        style={styles.input}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} lg={6}>
                    <Form.Item
                      name="confirm"
                      label="REPEATED PASSWORD"
                      className="font-weight-bold"
                      dependencies={['password']}
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: 'Confirm Password is required',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('Passwords does not match')
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Type Confirm Password"
                        size="large"
                        style={styles.input}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="text-left mb-1 d-flex">
                  <Form.Item
                    name="terms"
                    valuePropName="checked"
                    className="font-weight-bold"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(new Error('Should accept Terms')),
                      },
                    ]}
                  >
                    <Checkbox>
                      <label>
                        I accept the the{' '}
                        <a
                          className="text-primary"
                          href="https://www.privacypolicies.com/live/a137ab9a-3d9b-48ed-a2a5-4bb78279bdf2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms
                        </a>{' '}
                        and{' '}
                        <a
                          className="text-primary"
                          href="https://www.privacypolicies.com/live/c6eab6d7-3eef-4324-b9da-3cead1424aeb"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>{' '}
                        of Gigsasa
                      </label>
                    </Checkbox>
                  </Form.Item>
                </div>

                <Form.Item className="mb-1">
                  <button
                    className="btn btn-secondary mt-5 d-flex align-items-center justify-content-between"
                    style={styles.loginButton}
                    htmlType="submit"
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      'Continue'
                    )}
                    <RightOutlined />
                  </button>
                </Form.Item>
                <Text className="text-center">
                  Already have an account?{' '}
                  <Text
                    strong
                    className="text-primary c-pointer"
                    onClick={goLogin}
                  >
                    Login
                  </Text>
                </Text>

                <Row className="my-5 align-self-center">
                  <Col sm={12} lg={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div style={styles.line} />
                      <Text strong type="secondary" style={styles.orText}>
                        or
                      </Text>
                      <div style={styles.line} />
                    </div>
                  </Col>
                </Row>

                <div className="d-flex flex-row align-items-center justify-content-center">
                  <GoogleButton
                    provider="google"
                    appId="449482009924-ri7359craqsbv8ohr6pla55qrle5ttes.apps.googleusercontent.com"
                    onLoginSuccess={handleSocialSignup}
                    onLoginFailure={handleSocialSignupFailure}
                  >
                    Signup with google
                  </GoogleButton>
                  <FacebookButton
                    provider="facebook"
                    appId="529437757616015"
                    onLoginSuccess={handleSocialSignup}
                    onLoginFailure={handleSocialSignupFailure}
                  >
                    Signup with facebook
                  </FacebookButton>
                </div>
              </Form>
            </Col>
          </Row>
        </>
      }
    />
  );
}

export default Index;
