import * as constants from './constants';
import { message } from 'antd';
import getLocationApi from './locationApi';

const LocationApi = new getLocationApi();

export const getLocations = (page, cb) => async (dispatch, getState) => {
  try {
    const { company } = getState().auth;

    const dataObj = {
      company: company.id,
      page,
      sortBy: 'createdAt',
      limit: 10,
    };

    let res = await LocationApi.getLocations(dataObj);

    dispatch({
      type: constants.GET_LOCATIONS,
      payload: res.data,
    });

    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};
export const searchLocations = (text, page, cb) => async (dispatch) => {
  try {
    const res = await LocationApi.searchLocations({
      text,
      page,
      limit: 10,
      sortBy: '-createdAt',
    });
    dispatch({
      type: constants.GET_LOCATIONS,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const createLocation = (data, cb) => async (dispatch) => {
  try {
    let res = await LocationApi.createLocation(data);
    dispatch({
      type: constants.ADD_LOCATION,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateLocation = (id, data, cb) => async (dispatch) => {
  try {
    let res = await LocationApi.updateLocation(id, data);
    dispatch({
      type: constants.EDIT_LOCATION,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteLocation = (id, cb) => async (dispatch) => {
  try {
    await LocationApi.deleteLocation(id);
    dispatch({
      type: constants.DELETE_LOCATION,
      payload: id,
    });
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const uploadBulkLocations = (data, page, cb) => async (dispatch) => {
  try {
    await LocationApi.uploadBulkLocations(data);
    dispatch(getLocations(page, () => {}));
    message.success('Locations uploaded!');
    cb();
  } catch (error) {
    cb();
    message.error(error.response?.data.message ?? error.message);
  }
};
