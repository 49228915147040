import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Table as TableRB } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

import {
  Table,
  Avatar,
  Space,
  Typography,
  Card,
  Button,
  Tooltip,
  Badge,
  DatePicker,
  Select,
} from 'antd';
import {
  UserAddOutlined,
  ReloadOutlined,
  ExportOutlined,
  EyeOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DepartmentFilter from '../../components/departmentFilter';
import { getSafetyEmergencies } from '../../redux/activities/action';
import {
  getUserAnswers,
  getUserQuestions,
} from '../../redux/performanceQ/action';
import { styles } from '../dashboard/style';
import SafetyDetails from '../dashboard/safetyDetails';
import socketHandler from '../../config/socket';
import { generateExcelFileGeneral } from '../timeSheets/util';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selectedDpt, setDpt] = useState(null);
  const [period, setPeriod] = useState([
    moment().subtract(1, 'year').startOf('week').toDate(),
    moment().endOf('week').toDate(),
  ]);

  const [endDate, setEndDate] = useState(moment().subtract(30, 'days'));
  const [startDate, setStartDate] = useState(moment());

  const history = useHistory();
  const { sos } = useSelector((state) => state.activitiesReducer);
  const { company } = useSelector((state) => state.auth);
  const { allDeptByIds } = useSelector((state) => state.membersReducer);
  const { user_answer } = useSelector((state) => state.pqReducer);

  const [loader, setLoader] = useState(false);
  const [userDetailModal, setDetailModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);

  const [page, setPage] = useState(1);

  const pRef = useRef(1);
  const depRef = useRef(null);

  const stopLoader = () => {
    if (user_answer[id]) {
      setAnswers(user_answer[id]);
    }

    setLoader(false);
  };

  const stopDataLoader = (_data) => {
    setData(_data);
    setLoader(false);
  };

  const stopDataLoaderAnswers = (_data) => {
    setAnswers(user_answer[id]);
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getUserQuestions(id, stopDataLoader));
  }, []);

  useEffect(() => {
    setLoader(true);

    if (!user_answer[id]) {
      dispatch(getUserAnswers(id, period, stopDataLoaderAnswers));
    } else {
      setAnswers(user_answer[id]);
    }
  }, []);

  const renderDate = (date) => {
    return <Text> {moment(date).format('MMM Do YYYY, h:mm a')} </Text>;
  };

  const renderFile = (date) => {
    if (date.type == 'text' || date.type == 'number' || date.type == 'text') {
      return <Text> {date.answer} </Text>;
    } else {
      return <Text> File / Image </Text>;
    }
  };

  const exportToExcel = () => {
    setLoader(true);
    var results = [];

    data.map((x) => {
      answers
        .filter((y) => {
          return y?.question == x.id;
        })
        .map((y) => {
          results = [
            ...results,
            {
              'Employee Name': y.user.name,
              Department: allDeptByIds[y.user?.organization]?.name
                ? allDeptByIds[y.user?.organization]?.name
                : '',
              Date: moment(y.createdAt).format('YYYY-MM-DD'),
              Time: moment(y.createdAt).format('h:mm a'),
              Question: x.question,
              Answer: y?.answer,
              // Type: x.question,
              // Activity: x.question,
            },
          ];
        });
    });
    var test = results.sort((a, b) => {
      return new Date(a.Date + ' ' + a.Time) - new Date(b.Date + ' ' + b.Time);
    });
    generateExcelFileGeneral(
      test,
      `${answers[0].user.name} (PQ) ${moment(period[0]).format(
        'YYYY-MM-DD'
      )}- ${moment(period[1]).format('YYYY-MM-DD')}`
    );
    setLoader(false);
  };

  const renderColumnAt = () => {
    const result = [
      {
        title: 'Questions',
        dataIndex: 'question',
        align: 'left',
        fixed: true,
        width: 200,
      },
      {
        title: 'Use',
        dataIndex: 'fetchType',
        align: 'center',
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        align: 'center',
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        align: 'center',
        className: 'd-none d-sm-table-cell',
      },
    ];
    return result;
  };

  const renderColumnAns = () => {
    const result = [
      {
        title: 'Questions',
        dataIndex: 'question2',
        align: 'left',
        fixed: true,
        width: 200,
      },
      {
        title: 'Answer',
        dataIndex: 'record',
        align: 'center',
        render: renderFile,
        className: 'd-none d-sm-table-cell',
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        align: 'center',
        render: renderDate,
        className: 'd-none d-sm-table-cell',
      },
    ];
    return result;
  };

  const onChangeDate = (_date) => {
    if (_date == null) return;
    setPeriod([moment(_date[0]).toDate(), moment(_date[1]).toDate()]);
    dispatch(getUserAnswers(id, period, stopDataLoaderAnswers));
  };

  return (
    <>
      <Space className="mb-3 flex-wrap">
        <RangePicker
          onChange={onChangeDate}
          defaultValue={[startDate, endDate]}
        />

        <Tooltip title="Fetch Latest Data">
          <Button onClick={(onChangeDept) => {}}>
            <ReloadOutlined />
          </Button>
        </Tooltip>

        <Button onClick={exportToExcel} className=" btn-primary">
          <ExportOutlined /> Export
        </Button>
      </Space>
      <Row>
        <Col md={12}>
          <Table
            className="table-curved"
            dataSource={data.map((x) => {
              return { ...x, key: x.id };
            })}
            columns={renderColumnAt()}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  columns={renderColumnAns()}
                  dataSource={
                    Array.isArray(answers)
                      ? answers
                          .filter((x) => {
                            return x?.question == record.id;
                          })
                          .map((x) => {
                            return {
                              ...x,
                              question2: record.question,
                              record: { type: record.type, answer: x.answer },
                            };
                          })
                      : []
                  }
                ></Table>
              ),
              rowExpandable: (record) => record.question !== 'Not Expandable',
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
