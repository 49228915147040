import React, { useEffect, useState } from 'react';
import { Typography, Image, Form, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Slide } from 'react-awesome-reveal';
import CountryPhoneInput from 'antd-country-phone-input';
import { styles } from './style';
import Organization from '../../assets/images/auth/createOrg.png';
import Stepper from './stepper';
import authApi from '../../redux/auth/authApi';
const { Text } = Typography;
const Api = new authApi();

function LeftOrg({ steps }) {
  return (
    <div>
      <Image
        src={Organization}
        preview={false}
        className="text-center img-responsive mt-auto mb-auto"
      />
      <Stepper steps={steps} />
    </div>
  );
}

function RightOrg({ setSteps }) {
  const [vloader, setVLoader] = useState(false);

  const [form] = Form.useForm();
  const createOrganization = (values) => {
    localStorage.setItem('companyData', JSON.stringify(values));
    setSteps(3);
  };

  useEffect(() => {
    const savedValues = localStorage.getItem('companyData');
    if (Object.values(savedValues || {}).length) {
      form.setFieldsValue(JSON.parse(savedValues));
    }
  }, []);

  return (
    <Slide direction="right">
      <div>
        <button style={styles.backBtn} onClick={() => setSteps(1)}>
          <i className="feather icon-arrow-left" style={styles.backIcon} />
        </button>
      </div>
      <div>
        <h3 className="font-weight-bold text-center pt-5">
          Create your organization
        </h3>
        <Text
          className="d-block text-capitalize text-center"
          type="secondary"
          style={styles.desc}
        >
          Fill in your organization&apos;s details to get started
        </Text>
        <Form
          form={form}
          layout="vertical"
          onFinish={createOrganization}
          initialValues={{
            phone: {
              short: 'gb',
            },
          }}
        >
          <Form.Item
            name="name"
            className="mb-3 mt-4 font-weight-bold text-uppercase"
            label="Organization name*"
            required={false}
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Type your organization name"
              style={styles.input}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            className="mb-0 pb-0 mt-4 font-weight-bold text-uppercase"
            label="mobile number*"
            required={false}
            rules={[
              {
                required: true,
                message: 'Phone Number is required',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  var test = `+${value.code}${value.phone}`.match(
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                  );
                  if (!test) {
                    return Promise.reject(new Error('Invalid Phone Number!'));
                  }

                  // let res = (
                  //   await Api.validatePhone(`+${value.code}${value.phone}`)
                  // ).data;
                  // setVLoader(false);
                  // if (res.valid) {
                  //   return Promise.resolve();
                  // }
                  // return Promise.reject(new Error('Invalid Phone Number!'));
                },
              }),
            ]}
          >
            <CountryPhoneInput size="large" style={styles.input} />
          </Form.Item>
          <Text
            style={styles.smallText}
            type="secondary"
            className="d-block mt-1 mb-3"
          >
            {vloader ? 'The information is being validated...' : ''}
          </Text>
          <Form.Item
            name="invite_email"
            className="mb-3 mt-4 font-weight-bold text-uppercase"
            label="Invite a Co-owner or Manager"
            required={false}
            rules={[
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
          >
            <Input
              type="email"
              size="large"
              placeholder="Type email address"
              style={styles.input}
            />
          </Form.Item>
          <Form.Item>
            <button
              className="btn btn-primary w-100 mt-5 d-flex align-items-center justify-content-between"
              style={styles.buttonContinue}
              htmlType="submit"
            >
              Continue
              <RightOutlined />
            </button>
          </Form.Item>
        </Form>
      </div>
    </Slide>
  );
}

export { LeftOrg, RightOrg };
