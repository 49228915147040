import { INSTANCE } from '../../config/networkInstance';

export default class CustomersApi {
  addCustomer = (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'POST',
        url: 'customers',
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getCustomers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'customers',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getSpecificCustomers = (params) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'GET',
        url: 'customers/specific',
        params,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  updateCustomer = (id, data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: 'PATCH',
        url: `customers/${id}`,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
