import React from 'react';
import { Tabs } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import { useSelector } from 'react-redux';

import Daily from './summary.attendance';
import Requests from './dashboard.attendance';

const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar color-primary"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

function Index() {
  const { company } = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <h3 className="mb-3">Attendance Analytics</h3>
      <StickyContainer>
        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} size="large">
          {company?.timesheetApproval && (
            <TabPane tab="Dashboard" key="1">
              <Requests />
            </TabPane>
          )}
          <TabPane tab="Summary" key="2">
            <Daily />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </React.Fragment>
  );
}

export default Index;
