import { Modal, Radio, TimePicker } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../../redux/shifts/action';
import { getAllDepartments } from '../../../redux/members/action';
import { getPostedSwapShift, swapShift } from '../../../redux/swapShift/action';

function Index({ show, setClose, reqItem, setActiveTab, setLoaderAppl }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allEmployees } = useSelector((state) => state.shiftsReducer);
  const { allDept } = useSelector((state) => state.membersReducer);

  const [mLoader, setMLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dptLoading, setDptLoading] = useState(false);

  const [audienceType, setAudType] = useState(false);

  const stopLoaderEmp = () => {
    setMLoader(false);
  };

  useEffect(() => {
    setMLoader(true);
    setDptLoading(true);
    dispatch(getAllEmployees(stopLoaderEmp));
    dispatch(getAllDepartments(() => setDptLoading(false)));
  }, []);

  const shiftPostHandler = (values) => {
    const obj = {
      ...values,
      status: 'posted',
    };

    setLoader(true);

    dispatch(
      swapShift(obj, reqItem?.id, reqItem.createdBy?.id, (isSuccess) => {
        setLoader(false);

        if (isSuccess) {
          setClose?.();
          form.resetFields();
          openAppPost?.();
        }
      })
    );
  };
  const openAppPost = () => {
    setActiveTab?.('2');
    setLoaderAppl?.(true);
    dispatch(
      getPostedSwapShift({ page: 1, limit: 10 }, () => {
        setLoaderAppl?.(false);
      })
    );
  };
  return (
    <Modal
      className="confirmModalCon"
      bodyStyle={{ padding: 30, borderRadius: 10, overflow: 'hidden' }}
      width={700}
      footer={null}
      onCancel={setClose}
      visible={show}
    >
      <p
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '24px',
          textTransform: 'capitalize',

          color: '#000000',
        }}
      >
        post shift opening
      </p>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ autoSelectEmploy: true, audienceType: 'general' }}
        form={form}
        onFinish={shiftPostHandler}
        autoComplete="off"
      >
        <Row>
          <Col lg={12}>
            <Form.Item
              label="Posting Pool"
              tooltip="This is a required field"
              className="mb-3"
              name="audienceType"
              rules={[
                {
                  required: true,
                  message: 'Please input Post to',
                },
              ]}
            >
              <Select
                size={'large'}
                placeholder="Select the community to post the shift"
                style={{ width: '100%' }}
                defaultValue={'general'}
                onChange={(val) => {
                  setAudType(val);
                }}
              >
                <Select.Option value="general">General</Select.Option>
                <Select.Option value="department">Department</Select.Option>
                <Select.Option value="specificUser">
                  Choose Specific User
                </Select.Option>
                <Select.Option value="multipleUsers">
                  Multiple Users
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {audienceType === 'department' && (
            <Col lg={12}>
              <Form.Item
                label="Select Department"
                tooltip="This is a required field"
                className="mb-3"
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Department',
                  },
                ]}
              >
                <Select
                  defaultValue="Select Department"
                  size={'large'}
                  dropdownStyle={{ zIndex: 234234234 }}
                  loading={dptLoading}
                  disabled={dptLoading}
                >
                  {allDept.map((item, i) => {
                    return (
                      <Select.Option key={i} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          {['specificUser', 'multipleUsers'].includes(audienceType) && (
            <Col lg={12}>
              <Form.Item
                label={
                  audienceType === 'multipleUsers'
                    ? 'Select Users'
                    : 'Select User'
                }
                tooltip="This is a required field"
                className="mb-3"
                name={
                  audienceType === 'multipleUsers'
                    ? 'targetUsers'
                    : 'targetUserId'
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Select Employees',
                  },
                ]}
              >
                <Select
                  mode={audienceType === 'multipleUsers' && 'multiple'}
                  size={'large'}
                  placeholder="Please select"
                  style={{ width: '100%' }}
                  loading={mLoader}
                  disabled={mLoader}
                >
                  {allEmployees.map((item) => {
                    return (
                      <Select.Option
                        key={item.id}
                      >{`${item.name}`}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col lg={12}>
            <Form.Item name={'description'} label="Description">
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item name="autoSelectEmploy">
              <Radio.Group defaultValue={false}>
                <Radio value={true}>First Come First Server</Radio>
                <Radio value={false}>Best Fit</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <button
            className="btn py-2 btn-primary btn-block w-100 mt-3"
            htmlType="submit"
          >
            {loader ? (
              <Spinner size="sm" animation="border" variant="light" />
            ) : (
              'Post Shift Request'
            )}
          </button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default memo(Index);
