export const GET_SHEETS = 'GET_SHEETS';
export const GET_DAILY_SHEETS = 'GET_DAILY_SHEETS';
export const GET_DAILY_ATTENDANCE = 'GET_DAILY_ATTENDANCE';

export const GET_DAILY_ATTENDANCES = 'GET_DAILY_ATTENDANCES';

export const GET_POWERBI_DATA = 'GET_POWERBI_DATA';
export const GET_TIMESHEET_REQUESTS = 'GET_TIMESHEET_REQUESTS';
export const UPDATE_EXPORT_SCHEMA = 'UPDATE_EXPORT_SCHEMA';
export const RESET_EXPORT_SCHEMA = 'RESET_EXPORT_SCHEMA';
