import React, { useState, useEffect, memo, useCallback } from 'react';
import { Table, Form, Typography, Card, Space, Popconfirm, Button } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getShifts, deleteMainShift } from '../../redux/shifts/action';
import ShiftModal from './createShifts';
import moment from 'moment';
const { Text } = Typography;
const Index = () => {
  const dispatch = useDispatch();
  const { mainShifts, totalMainShifts } = useSelector(
    (state) => state.shiftsReducer
  );
  const limit = 10;

  const renderData = () => {
    const data = mainShifts?.map((item) => {
      return {
        key: item?.id,
        date: moment(item?.createdAt).format('DD-MM-YYYY'),
        name: item?.name,
        employees: item?.users?.length,
        duration: Math.trunc(item?.shiftDuration / 60),
        start: moment(item?.start).format('DD-MM-YYYY'),
        location: item?.location?.name ?? 'No Location',
        item,
      };
    });
    return data;
  };
  const [form] = Form.useForm();
  const [data, setData] = useState(mainShifts?.length > 0 ? renderData() : []);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setModalVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [open, setOpen] = useState(false);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getShifts({ page, limit }, stopLoader));
  }, []);

  useEffect(() => {
    if (Array.isArray(mainShifts)) {
      const data = renderData();
      setData(data);
    }
  }, [mainShifts]);

  const handleCancel = () => {
    setModalVisible(false);
    setItem(null);
  };

  const changePage = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getShifts({ page, limit }, stopLoader));
  };

  const refreshData = () => {
    setLoader(true);
    dispatch(getShifts({ page, limit }, stopLoader));
  };

  const edit = (row) => {
    setItem(row?.item);
    setModalVisible(true);
  };

  const deleteRow = (row) => {
    setLoader(true);
    dispatch(deleteMainShift(row.key, stopLoader));
  };

  const createShift = () => {
    setItem(null);
    setOpen(true);
  };

  const closeCreate = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      align: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'left',
    },

    {
      title: 'Employees',
      dataIndex: 'employees',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Start Time',
      dataIndex: 'start',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Duration (hours)',
      dataIndex: 'duration',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      align: 'center',
      className: 'd-none d-sm-table-cell',
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            <Typography.Link
              onClick={() => edit(record)}
              disabled={new Date(record?.item?.start) < new Date()}
            >
              Edit <EditOutlined />
            </Typography.Link>
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => deleteRow(record)}
              okText="Yes"
              cancelText="No"
              disabled={new Date(record?.item?.start) < new Date()}
            >
              <Typography.Link
                className="text-danger pl-3"
                disabled={new Date(record?.item?.start) < new Date()}
              >
                Delete <DeleteOutlined />
              </Typography.Link>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <h3>Shifts Management</h3>
      <Text type="secondary">{moment().format('MMMM Do YYYY')}~</Text>
      <Button
        type="primary"
        className="bg-primary border-primary d-block mb-3 mt-3"
        icon={<PlusOutlined />}
        onClick={createShift}
      >
        Create Shift
      </Button>
      <Card className="tmb">
        <Form form={form} component={false}>
          <Table
            className="table-curved"
            dataSource={data}
            columns={columns}
            pagination={{
              onChange: changePage,
              current: page,
              total: totalMainShifts,
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            scroll={data.length > 0 && { x: true }}
            loading={loader}
          />
        </Form>
      </Card>
      <ShiftModal
        show={isModalVisible && item}
        setClose={handleCancel}
        refreshData={refreshData}
        item={item}
      />
      <ShiftModal
        show={open}
        setClose={closeCreate}
        refreshData={refreshData}
      />
    </React.Fragment>
  );
};

export default memo(Index);
