import React, { useState, useEffect, useRef } from 'react';
import {
  Space,
  List,
  Typography,
  Spin,
  Popconfirm,
  Button,
  Checkbox,
  Badge,
  message,
} from 'antd';
import Table from 'react-bootstrap/Table';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  toggleTimer,
  fetchTaskPerformance,
  updateTaskStatus,
} from '../../redux/tasks/action';
import PQModal from './performance';
import { styles } from './style';
import Counter from './timer';
import Item from './item';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { getCurrentPosition } from '../../helpers/getCurrentPosition';

import { times } from 'lodash';
const { Text } = Typography;

function Index({ task }) {
  const answersRef = useRef(null);
  const dispatch = useDispatch();
  const { activeShift } = useSelector((state) => state.clockReducer);
  const { user } = useSelector((state) => state.auth);
  const [performance, setPerformance] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderC, setCLoader] = useState(false);
  const [showTBl, setShowTBl] = useState(false);
  let [check, setChecked] = useState(task?.status == 'completed');
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  useEffect(() => {
    setChecked(task?.status == 'completed');
  }, [task]);

  const stopLoader = (item) => {
    setLoader(false);
  };

  const toogleTBl = () => {
    if (showTBl) {
      setShowTBl(false);
    } else {
      setShowTBl(true);
    }
  };
  const truncate = (number, index = 0) => {
    // cutting the number=>
    return +number
      .toString()
      .slice(0, number.toString().indexOf('.') + (index + 1));
  };

  const handleTask = async () => {
    setLoader(true);
    let params = { taskId: task.id };
    let _location = await getCurrentPosition();

    let status =
      task?.times?.filter((_) => _.user == user.id)[
        task?.times?.filter((_) => _.user == user.id)?.length - 1
      ]?.out === null;
    let timeId = status ? task?.times?.[task?.times?.length - 1]?._id : null;

    let obj = {
      status: 'ongoing',
      ...(!timeId
        ? {
            in: new Date(),
            locationIn: _location,
          }
        : {
            out: new Date(),
            locationOut: _location,
          }),
      ...(timeId && { timeId }),
      ...(answersRef.current && { answers: answersRef.current }),
      ...(activeShift && {
        allocation: activeShift?.id,
      }),
    };

    dispatch(toggleTimer(params, obj, stopLoader));
  };

  const stopPerformance = (questions) => {
    if (questions.length > 0) {
      setPerformance(true);
      setLoader(false);
    } else {
      handleTask();
    }
  };

  const fetchQuestions = () => {
    if (user?.role !== 'root') {
      setLoader(true);
      let type = task?.status == 'pending' ? 'in' : 'out';
      let fetchType = 'task';
      dispatch(fetchTaskPerformance(type, fetchType, stopPerformance));
    } else {
      handleTask();
    }
  };

  const getAnswers = (data) => {
    answersRef.current = data;
    closePQ();
    // clock in or out
    handleTask();
  };

  const closePQ = () => {
    setPerformance(false);
  };

  const completeTask = () => {
    if (task?.status == 'progress') {
      message.info('You need to stop the Task first!');
      return;
    }

    setCLoader(true);
    let params = { taskId: task.id };
    let status = !check == true ? 'completed' : 'pending';
    dispatch(
      updateTaskStatus(params, { status }, { ...task, status }, () => {
        setCLoader(false);
        setChecked(!check);
      })
    );
  };

  return (
    <>
      <List.Item size="small">
        <List.Item.Meta
          title={
            <span className="d-flex justify-content-between">
              <span className="">
                {loaderC ? (
                  <Spin size="small" />
                ) : (
                  <>
                    {task?.user_id.id == user.id || task?.user_id == user.id ? (
                      <Popconfirm
                        title="Are you sure to Complete this task?"
                        onConfirm={completeTask}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Checkbox checked={check}>
                          {task?.status == 'complete' ? (
                            <>
                              <span className="mr-2 d-inline-block text-truncate">
                                <Text
                                  delete
                                  italic
                                  truncate
                                  style={{ maxWidth: '200px' }}
                                >
                                  {task?.title}
                                </Text>
                              </span>
                              <Badge
                                className="site-badge-count-109"
                                count={'completed'}
                                style={{
                                  backgroundColor: '#52c41a',
                                  marginRight: 40,
                                }}
                              ></Badge>
                            </>
                          ) : (
                            <>
                              <span className="mr-2 ">
                                <Text>{task?.title}</Text>
                              </span>
                              <Badge
                                className="site-badge-count-109"
                                count={
                                  task?.status == 'pending'
                                    ? 'in pending'
                                    : 'in progress'
                                }
                                style={{ backgroundColor: '#f79425' }}
                              />
                            </>
                          )}
                        </Checkbox>
                      </Popconfirm>
                    ) : task?.status == 'complete' ? (
                      <>
                        <span className="mr-2 d-inline-block text-truncate">
                          <Text
                            delete
                            italic
                            truncate
                            style={{ maxWidth: '200px' }}
                          >
                            {task?.title}
                          </Text>
                        </span>
                        <Badge
                          className="site-badge-count-109"
                          count={'completed'}
                          style={{
                            backgroundColor: '#52c41a',
                            marginRight: 40,
                          }}
                        ></Badge>
                      </>
                    ) : (
                      <>
                        <span className="mr-2 ">
                          <Text>{task?.title}</Text>
                        </span>
                        <Badge
                          className="site-badge-count-109"
                          count={
                            task?.status == 'pending'
                              ? 'in pending'
                              : 'in progress'
                          }
                          style={{ backgroundColor: '#f79425' }}
                        />
                      </>
                    )}
                  </>
                )}
              </span>
            </span>
          }
          description={
            <Space>
              {check ? (
                <Text delete italic>
                  {task?.description}
                </Text>
              ) : (
                <Text>{task?.description}</Text>
              )}
            </Space>
          }
        />
        <div className="mx-3 text-primary">
          {task?.times?.filter((_) => _.user == user.id)[
            task?.times?.filter((_) => _.user == user.id)?.length - 1
          ]?.out === null && (
            <Counter
              className=" text-primary"
              inTime={
                task?.times?.filter((_) => _.user == user.id)[
                  task?.times?.filter((_) => _.user == user.id).length - 1
                ]?.in
              }
            />
          )}
        </div>
        <div className="pr-4">
          <Button
            type="warning"
            className="px-2 mx-1"
            shape="circle"
            onClick={() => toogleTBl()}
          >
            <EyeOutlined />
          </Button>
          {task.status != 'completed' && (
            <>
              {loader ? (
                <Spin />
              ) : (
                <>
                  {task?.times?.filter((_) => _.user == user.id)[
                    task?.times?.filter((_) => _.user == user.id).length - 1
                  ]?.out === null ? (
                    <Button
                      type="warning"
                      className="px-2 mx-1"
                      shape="circle"
                      onClick={fetchQuestions}
                    >
                      <PauseOutlined />
                    </Button>
                  ) : (
                    <Button
                      type="warning"
                      className="px-2 mx-1"
                      shape="circle"
                      onClick={fetchQuestions}
                    >
                      <CaretRightOutlined />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {/* <Space>
          <Text style={styles.date}>
            {moment(task?.endTime).format('MMM DD, YYYY')}
          </Text>
        </Space> */}
        <PQModal
          getAnswers={getAnswers}
          show={performance}
          setClose={closePQ}
        />
      </List.Item>
      {showTBl && (
        <List.Item>
          <List.Item.Meta
            description={
              <Space>
                <Table responsive className="mt-4 table-curved">
                  <thead>
                    <tr>
                      <th width="200">Start Time</th>
                      <th width="200">End Time</th>
                      <th width="250">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {task?.times.map((time, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            {moment(time.in).format('MMMM d, YYYY')}
                            <br></br>
                            {moment(time.in).format('LTS')}
                          </td>

                          {time?.out ? (
                            <td>
                              {moment(time.out).format('MMMM d, YYYY')}
                              <br></br>
                              {moment(time.out).format('LTS')}
                            </td>
                          ) : (
                            <td>---</td>
                          )}

                          {time?.out ? (
                            <td>
                              {truncate(
                                moment(time.out).diff(time.in, 'minutes') / 60
                              ) +
                                ' Hrs : ' +
                                (moment(time.out).diff(time.in, 'minutes') %
                                  60) +
                                ' Min : ' +
                                (moment(time.out).diff(time.in, 'seconds') %
                                  60) +
                                ' Sec'}
                            </td>
                          ) : (
                            <td>---</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Space>
            }
          />
        </List.Item>
      )}
    </>
  );
}

export default Index;
