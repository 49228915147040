import * as constants from './constants';
import { exportSchema } from './exportSchema';
import moment from 'moment';
let initState = {
  timesheets: [],
  leaves: [],
  dailyTimesheet: [],
  dailyAttendance: [],
  dailyAttendances: [],
  dailyLeaves: [],
  powerBiData: [],
  requests: [],
  totalDailyTS: 0,
  totalCustomTS: 0,
  totalRequests: 0,
  exportSchema: exportSchema,
  initialC: true,
  initialD: true,
  initialR: true,
};
function timeSheetReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_SHEETS:
      return {
        ...state,
        timesheets: payload?.results?.timeSheet.filter(
          (item) => item?.allocation !== null
        ),
        leaves: payload?.results?.leaves,
        initialC: false,
      };
    case constants.GET_DAILY_SHEETS:
      return {
        ...state,
        dailyTimesheet: payload?.results?.timeSheet.filter(
          (item) => item?.allocation !== null
        ),
        dailyLeaves: payload?.results?.leaves,
        initialD: false,
      };
    case constants.GET_POWERBI_DATA:
      return {
        ...state,
        powerBiData: payload,
      };

    case constants.GET_TIMESHEET_REQUESTS:
      return {
        ...state,
        requests: payload,
        initialR: false,
      };
    case constants.GET_DAILY_ATTENDANCE:
      return {
        ...state,
        dailyAttendance: payload?.results?.timeSheet.filter(
          (item) => item?.allocation !== null
        ),
        dailyLeaves: payload?.results?.leaves,
        initialR: false,
      };

    case constants.GET_DAILY_ATTENDANCES:
      // var _test = payload?.results?.timeSheet.filter(
      //   (item) => item?.allocation !== null
      // ).map((item)=>{
      //   return  {id:item.user.id + moment(item.data).startOf('day'), data:item}
      // })
      // const ids = _test.map(o => o.id)
      // const filtered = _test.filter(({id}, index) => !ids.includes(id, index + 1))

      return {
        ...state,
        dailyAttendances: payload?.results?.timeSheet.filter(
          (item) => item?.allocation !== null
        ),
        dailyLeaves: payload?.results?.leaves,
        initialR: false,
      };
    case constants.UPDATE_EXPORT_SCHEMA:
      return {
        ...state,
        exportSchema: payload,
      };
    case constants.RESET_EXPORT_SCHEMA:
      return {
        ...state,
        exportSchema: exportSchema,
      };

    default:
      return state;
  }
}

export default timeSheetReducer;
