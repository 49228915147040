export const styles = {
  container: {
    background: '#ffff',
  },
  input: {
    height: 50,
    borderRadius: 5,
    borderWidth: 2,
  },
  fullHeight: {
    minHeight: '100vh',
  },
};
