import React, { useState, useEffect, memo } from 'react';
import {
  Table,
  Typography,
  Card,
  Space,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
} from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Row, Spinner, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeaveTypes,
  createLeaveTypes,
  updateLeaveTypes,
  deleteLeaveTypes,
} from '../../redux/leaveManagement/action';
import EditableCell from './editableType';
const { Text } = Typography;

const EditableTable = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { types } = useSelector((state) => state.leaveManagement);
  const { company } = useSelector((state) => state.auth);
  const [data, setData] = useState(types.length > 0 ? types : []);
  const [loader, setLoader] = useState(false);
  const [cLoader, setCLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getLeaveTypes({ company: company.id }, stopLoader));
  }, []);

  useEffect(() => {
    if (Array.isArray(types)) {
      setData(
        types.map((item) => {
          return {
            key: item.id,
            name: item.name,
          };
        })
      );
    }
  }, [types]);

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const deleteType = (key) => {
    setLoader(true);
    dispatch(deleteLeaveTypes({ typeId: key }, stopLoader));
  };

  const updateApi = (key, row) => {
    setLoader(true);
    dispatch(updateLeaveTypes(row, { typeId: key }, stopLoader));
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      updateApi(key, row);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'left',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      width: 200,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Update?"
              onConfirm={() => save(record.key)}
            >
              <a>Save</a>
            </Popconfirm>

            <a
              href="javascript:;"
              onClick={cancel}
              style={{
                marginLeft: 8,
              }}
            >
              Cancel
            </a>
          </span>
        ) : (
          <Space align="center" size="large">
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit <EditOutlined />
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteType(record.key)}
              disabled={editingKey !== ''}
            >
              <Button danger size="small" disabled={editingKey !== ''}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const setClose = () => {
    setModal(false);
  };

  const setOpen = () => {
    setModal(true);
  };

  const stopCLoader = () => {
    setCLoader(false);
    setClose();
    form.resetFields();
  };

  const createLeave = (values) => {
    setCLoader(true);
    dispatch(createLeaveTypes({ ...values, company: company.id }, stopCLoader));
  };

  return (
    <React.Fragment>
      <Container fluid className="p-5">
        <Row className="w-100 gSettingsRow">
          <Col lg={10}>
            <Card className="tmb" bordered={false}>
              <Button
                type="primary"
                className="bg-primary border-primary mb-3 mt-2"
                icon={<PlusOutlined />}
                onClick={setOpen}
              >
                Add Type
              </Button>
              <Form form={form} component={false}>
                <Table
                  className="table-curved"
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data}
                  columns={mergedColumns}
                  scroll={data.length > 0 && { x: true }}
                  loading={loader}
                  pagination={false}
                />
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        title="Create Leave Type"
        visible={modal}
        onCancel={setClose}
        width={500}
        footer={null}
      >
        <Form layout="vertical" form={form} onFinish={createLeave}>
          <Row>
            <Col md={12} lg={12}>
              <Form.Item
                name="name"
                label="Leave Type"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Leave Type',
                  },
                ]}
              >
                <Input placeholder="Enter Name here" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Space className="mt-3 float-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={setClose}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" htmlType="submit">
                  {cLoader ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Create'
                  )}
                </button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default memo(EditableTable);
