export const styles = {
  cardRounded: {
    borderRadius: 20,
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
  },
  progress: {
    height: 170,
    width: 170,
  },
  listItem: {
    boxShadow: '-2px 0px 17px 2px #F0F0F0',
    background: '#fff',
    borderRadius: 10,
    padding: 10,
    border: '1px solid #F0F0F0',
  },
  firstRow: {
    height: 400,
  },
  time: {
    fontSize: 12,
  },
  roleText: {
    fontSize: 11,
  },
  date: {
    fontSize: 12,
  },
  safe: {
    border: '1px solid #008ae6',
  },
  danger: {
    border: '2px solid #ff8080',
  },
  sideBar: {
    minHeight: '45.5%',
  },
  pickerStyle: { width: 215, height: 40, marginBottom: 10 },
  reason: {
    fontSize: 12,
  },
  isActive: {
    marginBottom: 0,
    color: 'rgb(51, 153, 51)',
    fontWeight: '500',
  },

  isDisabled: {
    marginBottom: 0,
    color: '#ff8080',
    fontWeight: '500',
  },
  sideDetails: {
    minHeight: 300,
  },
};
