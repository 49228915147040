import * as constants from './constants';
let initState = {
  schedular: [],
  totalSchedulers: 0,
};
export default function (state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_SCHEDULES:
      return {
        ...state,
        schedular: payload?.results,
        totalSchedulers: payload?.totalResults,
      };
    case constants.NEW_SCHEDULE:
      return {
        ...state,
        schedular: [payload, ...state.schedular],
      };
    case constants.UPDATE_SCHEDULAR:
      return {
        ...state,
        schedular: state.schedular.map((item) => {
          if (item.id == payload.id) {
            return payload;
          }
          return item;
        }),
      };
    case constants.DELETE_SCHEDULAR:
      return {
        ...state,
        schedular: state.schedular.filter((item) => item.id !== payload),
      };
    default:
      return state;
  }
}
