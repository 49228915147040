import { message } from 'antd';
import classApi from './api';
import * as constants from './constants';
const Api = new classApi();

export const createSchedular = (data, cb) => async (dispatch) => {
  try {
    let res = await Api.createSchedular(data);
    dispatch({
      type: constants.NEW_SCHEDULE,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const updateSchedular = (id, data, cb) => async (dispatch) => {
  try {
    let res = await Api.updateSchedular(id, data);
    dispatch({
      type: constants.UPDATE_SCHEDULAR,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const deleteSchedular = (id, cb) => async (dispatch) => {
  try {
    let res = await Api.deleteSchedular(id);
    dispatch({
      type: constants.DELETE_SCHEDULAR,
      payload: id,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getSchedular = (page, cb) => async (dispatch) => {
  try {
    let res = await Api.getSchedular({
      page,
      limit: 10,
      sortBy: '-createdAt',
    });
    dispatch({
      type: constants.GET_SCHEDULES,
      payload: res.data,
    });
    cb(true);
  } catch (error) {
    cb(null);
    message.error(error.response?.data.message ?? error.message);
  }
};
