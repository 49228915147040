import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import AuthLayout from '../layouts/authlayout';
import { useDispatch } from 'react-redux';
import { Typography, Image, Form, Input } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { styles } from './style';
import Login from '../../assets/images/illustrations/login.png';
import Logo from '../../assets/images/logos/logo1.png';
//import Login from '../../assets/images/auth/login.png';
import { login } from '../../redux/auth/action';
import FacebookButton from './facebookButton';
import GoogleButton from './googleButton';
let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const { Text } = Typography;

function Index({ history }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const stopLoader = (success, user = null) => {
    setLoader(false);
    $crisp.push(['do', 'session:reset']);
    if (success) {
      if (user && !user?.isEmailVerified) {
        history.push('/auth/otp', { email: user?.email });
      }
    }
  };

  const loginUser = (values) => {
    let { type, password } = values;
    let email = null;
    let phone = null;
    if (type?.match(regexEmail)) {
      email = type;
    } else {
      phone = String(type)[0] == '+' ? type : `+${type}`;
    }
    let obj = {
      ...(email && { email }),
      ...(phone && { phone }),
      password: password?.trim(),
    };
    setLoader(true);
    dispatch(login(obj, stopLoader));
  };

  const goRegister = () => {
    history.push('/auth/signup');
  };

  const handleSocialLogin = (user) => {
    const values = {
      email: user?._profile?.email,
      password: user?._profile?.id,
      isSocialAuth: true,
    };
    setLoader(true);
    dispatch(login(values, stopLoader));
  };
  const handleSocialLoginFailure = (err) => {};

  return (
    <AuthLayout
      left={
        <div>
          <Image
            src={Login}
            preview={false}
            className="text-center img-responsive mt-auto mb-auto"
          />
        </div>
      }
      right={
        <>
          <Row className="mb-5">
            <Image
              src={Logo}
              preview={false}
              height={65}
              className="text-left img-responsive "
            />
          </Row>

          <Row className="w-100 justify-content-center">
            <Col lg={7} xl={8} xxl={9} sm={12}>
              <Form form={form} layout="vertical" onFinish={loginUser}>
                {/* <h2 className="font-weight-bold text-center">Welcome back  </h2> */}
                <Text
                  className="d-block  text-primary font-weight-bold "
                  style={styles.desc}
                >
                  Login to access your account
                </Text>

                <Row className="mt-4">
                  <Col sm={12}>
                    <Form.Item
                      name="type"
                      required={false}
                      className="mb-3  font-weight-bold"
                      label="EMAIL / PHONE"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Email/Phone!',
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        placeholder="Type Your Email/Phone"
                        style={styles.input}
                        suffix={<MailOutlined style={{ color: '#C0C0C0' }} />}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <Form.Item
                      name="password"
                      label="PASSWORD"
                      required={false}
                      className="mb-1 font-weight-bold"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                        {
                          min: 8,
                          message: 'Min 8 characters',
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Type Your Password"
                        size="large"
                        style={styles.input}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <NavLink to="/auth/reset-password">
                  <Text strong className="float-right text-primary">
                    Forgot Password?
                  </Text>
                </NavLink>
                <Form.Item className="pb-1 mb-0 w-100">
                  <button
                    className="btn btn-secondary mt-4"
                    style={styles.loginButton}
                    htmlType="submit"
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      'Login'
                    )}
                  </button>
                </Form.Item>
                <Text className=" text-center">
                  Don’t have an account?{' '}
                  <Text
                    strong
                    className="text-primary c-pointer"
                    onClick={goRegister}
                  >
                    Register
                  </Text>
                </Text>
              </Form>
              <Row className="my-5 align-self-center">
                <Col sm={12} lg={12}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div style={styles.line} />
                    <Text strong type="secondary" style={styles.orText}>
                      or
                    </Text>
                    <div style={styles.line} />
                  </div>
                </Col>
              </Row>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <GoogleButton
                  provider="google"
                  appId="449482009924-ri7359craqsbv8ohr6pla55qrle5ttes.apps.googleusercontent.com"
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  Login with google
                </GoogleButton>
                <FacebookButton
                  provider="facebook"
                  appId="529437757616015"
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  Login with facebook
                </FacebookButton>
              </div>
            </Col>
          </Row>
        </>
      }
    />
  );
}

export default Index;
