import { message } from 'antd';
import * as constants from './constants';

import SwapeShiftApi from './swapShiftApi';
import socketHandler from '../../config/socket';
const ShiftApi = new SwapeShiftApi();

export const createSwapShift = (data, cb) => async (dispatch) => {
  try {
    const res = await ShiftApi.createSwapShift(data);

    socketHandler.emit('createSwapRequest', data.companyId);
    cb(true);
  } catch (error) {
    message.error(error.response?.data.message ?? error.message);
    cb();
  }
};

export const getSwapShifts =
  ({ page, limit }, cb) =>
  async (dispatch) => {
    try {
      const res = await ShiftApi.getSwapShifts({
        page,
        limit,
        sortBy: '-createdAt',
      });
      dispatch({
        type: constants.GET_SWAP_SHIFT,
        payload: res?.data,
      });
      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const getPostedSwapShift =
  ({ page, limit }, cb) =>
  async (dispatch) => {
    try {
      const res = await ShiftApi.getPostedSwapShifts({
        page,
        limit,
        sortBy: '-createdAt',
      });

      dispatch({
        type: constants.POSTED_SWAP_SHIFTS,
        payload: res?.data,
      });

      cb();
    } catch (error) {
      cb();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const swapShift = (data, id, createdBy, cb) => async (dispatch) => {
  try {
    const res = await ShiftApi.updateSwap(data, {
      id,
    });

    dispatch({
      type: constants.UPDATE_SWAP,
      payload: id,
    });

    socketHandler.emit('SwapPost', data, createdBy);
    cb?.(true);
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getAvailableShifts =
  ({ page, limit }, cb) =>
  async (dispatch) => {
    try {
      const shifts = await ShiftApi.getAvailableShifts({
        page,
        limit,
        sortBy: '-createdAt',
      });

      dispatch({ type: constants.GET_AVAILABLE_SHIFTS, payload: shifts?.data });
      cb?.(true);
    } catch (error) {
      cb?.();
      message.error(error.response?.data.message ?? error.message);
    }
  };

export const applyForShift = (id, companyId, cb) => async (dispatch) => {
  try {
    const res = await ShiftApi.applyForShift(id);
    socketHandler.emit('applySwapRequest', companyId);
    cb?.(true);
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const assignShift = (data, createdBy, cb) => async (dispatch) => {
  try {
    const params = { id: data?.id, user: data?.user };
    const res = await ShiftApi.assignShift(params);

    dispatch({
      type: constants.ASSIGN_FOR_SHIFT,
      payload: data,
    });
    socketHandler.emit('updateSwapRequest', createdBy);
    socketHandler.emit('assignSwapRequest', data.user);

    cb?.(true);
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const declineRequest = (params, createdBy, cb) => async (dispatch) => {
  try {
    await ShiftApi.declineSwapReq(params);

    dispatch({
      type: constants.DECLINE_REQUEST,
      payload: params.reqId,
    });
    socketHandler.emit('updateSwapRequest', createdBy);
    cb?.(true);
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};

export const getUserSwap = (params, cb) => async (dispatch) => {
  try {
    const res = await ShiftApi.getUserSwaps({
      ...params,
      sortBy: '-createdAt',
    });

    dispatch({
      type: constants.GET_USER_REQ,
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb?.();
    message.error(error.response?.data.message ?? error.message);
  }
};
