import * as constants from './constants';
import uuid from '../../helpers/uniqueID';
import _ from 'lodash';
let initState = {
  shifts: [],
  mainShifts: [],
  totalMainShifts: 0,
  clockedIn: [],
  clockedOut: [],
  breakIn: [],
  totalClockIn: 0,
  totalClockOut: 0,
  totalBreakIn: 0,
  stats: {
    clockedin_employees: 0,
    employees_on_break: 0,
    unscheduled_shifts: 0,
    unscheduled_hours: 0,
    employees: 0,
  },
  allEmployees: [],
  allLocations: [],
  patterns: [],
  initial: true,
};

function shiftReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_SHIFTS:
      return {
        ...state,
        shifts: payload.map((item) => ({ ...item, uid: uuid() })),
      };
    case constants.GET_MAIN_SHIFTS: {
      return {
        ...state,
        mainShifts: payload.docs,
        totalMainShifts: payload.totalDocs,
      };
    }
    case constants.DELETE_MAIN_SHIFT: {
      let dShifts = [...state.mainShifts];
      dShifts.splice(_.indexOf(dShifts, _.find(dShifts, { id: payload })), 1);
      return {
        ...state,
        mainShifts: dShifts,
        totalMainShifts: state.totalMainShifts - 1,
      };
    }
    case constants.GET_CLOCKED_IN:
      const inResults = payload?.results || [];

      return {
        ...state,
        clockedIn: _.uniqBy(inResults, 'user.id'),
        totalClockIn: payload.totalResults,
      };
    case constants.GET_CLOCKED_OUT:
      const outResults = payload?.results || [];
      return {
        ...state,
        clockedOut: _.uniqBy(outResults, 'user.id'),
        totalClockOut: payload.totalResults,
      };

    case constants.GET_BREAK_IN:
      const breakResults = payload?.results || [];
      return {
        ...state,
        breakIn: _.uniqBy(breakResults, 'user.id'),
        totalBreakIn: payload.totalResults,
      };
    case constants.SHIFTS_STATS:
      return {
        ...state,
        stats: payload,
      };
    case constants.ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: payload,
      };
    case constants.ALL_LOCATIONS_SHIFT:
      return {
        ...state,
        allLocations: payload,
      };
    case constants.NEW_EMPLOYEE_SHIFT:
      return {
        ...state,
        allEmployees: [payload, ...state.allEmployees],
      };
    case constants.SHIFT_PATTERNS:
      return {
        ...state,
        patterns: payload,
      };
    case constants.DELETE_SHIFT:
      let nShifts = [...state.shifts];
      nShifts.splice(_.indexOf(nShifts, _.find(nShifts, { id: payload })), 1);
      return {
        ...state,
        shifts: nShifts,
      };
    case constants.CLOCK_IN:
      return {
        ...state,
        shifts: state.shifts.map((item) =>
          item.uid === payload ? { ...item, status: 'going' } : item
        ),
      };
    case constants.CLOCK_OUT:
      return {
        ...state,
        shifts: state.shifts.map((item) =>
          item.uid === payload ? { ...item, status: 'completed' } : item
        ),
      };
    case constants.RESET_INITIAL_DASHBOARD:
      return {
        ...state,
        initial: false,
      };
    default:
      return state;
  }
}

export default shiftReducer;
